/** @jsx jsx */
import {jsx} from '@emotion/core'
import { Card, CardContent, CardHeader, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { buildingAppData } from '../../../App';
import { Feature } from '../../../model/kaava/feature';
import { Kaava } from '../../../model/kaava/kaava';
import { getCodeStyles, KaavaStyles } from '../../../model/kaava/KaavaStyles';
import { OskariFeature, OskariFeatureEvent, OskariMapClickedEvent, OskariMapLocationEvent } from '../../../model/kaava/OskariModels';
import { CodeLang, CodeList, getCodeItemByPrefLabel } from '../../../model/koodisto/Koodistot';
import { KaavaAPI, KoodistoAPI } from '../../../utils/api';
import { escapeHtml } from '../../../utils/escapeHtml';
import BuildingPlanView from '../kaava/buildingPlanView';
import { Api as KaavatietomalliApi, RAKKaava, RAKKaavakohde } from '../../../api/kaavatietomalli/kaavatietomalli-api';
import LabelValuesBlock from '../../common/LabelValuesBlock';
import LabelValue from '../../common/LabelValue';
import { LocationUtils } from '../../../model/kaava/locationUtils';
import { KtkmBuildingApiResponse } from '../../../model/KtmkBuildingApiModels';
import { convertCoordinates, parseCoordinates } from '../../../utils/oskariHelper';
import Features from './features';

// Only load asemakaava kind plans to the tabs
const ASEMAKAAVA_START = "3"; // https://koodistot.suomi.fi/codescheme;registryCode=rytj;schemeCode=RY_Kaavalaji

interface Props {
  data: buildingAppData
}
const AreaView: React.FC<Props> = (props: Props) => {
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [koodisto, setKoodisto] = useState<CodeList|null>(null);
  const [kaavaStyles, setKaavaStyles] = useState<KaavaStyles|null>(null);
  const [selectedKaava, setSelectedKaava] = useState<string|null>("Valkeakosken tanssilava");
  const [maakuntaJaYleisKaavat, setMaakuntaJaYleisKaavat] = useState<RAKKaava[]>([]);
  const [channel, setChannel] = useState<any>(undefined);
  const [initOskari, setInitOskari] = useState<boolean>(true);
  const [layers, setLayers] = useState<string[]>([]);
  const [kaava, setKaava] = useState<RAKKaava|null>(null);
  //const [allLayers, setAllLayers] = useState<IOskariLayer[]>([]);
  const [featureEvent, setFeatureEvent] = useState<OskariFeatureEvent|null>(null);
  const [buildingFeatureEvent, setBuildingFeatureEvent] = useState<KtkmBuildingApiResponse|null>(null);
  const [clickEvent, setClickEvent] = useState<OskariMapClickedEvent|null>(null);
  const history = useHistory();

  // Run this useEffect only once when component mounts
  useEffect(() => {
    new KoodistoAPI().GetCodeList("/data/kaavatiedot/codelist_RY_KaavamaaraysLaji_AK.json")
    .then(koodisto => {
      console.log("Koodisto", koodisto);
      setKoodisto(koodisto);
    });

    new KaavaAPI().GetKaavaStyles()
    .then(kaavaStyles => {
      console.log("KaavaStyles", kaavaStyles);
      setKaavaStyles(kaavaStyles);
    });

    const script = document.createElement("script");
    script.src = "https://oskari.org/js/rpc/rpc-client.min.js";
    script.async = true;
    script.onload = () => onScriptLoaded();
    document.body.appendChild(script);
    console.log(`Oskari script added`);
  }, []);


  useEffect(() => {
    if (scriptLoaded && kaava) {
      
      addKaavakohteetToOskari();

    }
  }, [kaava, scriptLoaded]);

  useEffect(() => {
    if (channel && props.data.kaavat) {
      setMaakuntaJaYleisKaavat([]);
      for (const kaava of props.data.kaavat) {
        if (!kaava.kaavalaji?.startsWith(ASEMAKAAVA_START) && kaava.versiointitiedot && kaava.versiointitiedot.identiteettitunnus) {
          new KaavatietomalliApi().kaava.kaavaGetById(kaava.versiointitiedot.identiteettitunnus, { select: "versiointitiedot,ylaluokka,koostuu.liittyvaasiakirja,kaavanliite,kaavamaarays,kaavakohde.kaavamaarays,kaavakohde.kaavasuositus,kaavakohde.versiointitiedot,kaavakohde.kaavamaaraysryhma,kaavakohde.koskee,kaavakohde.sijainti,kaavaselostus.liittyvaasiakirja"}).then(x => {
            setMaakuntaJaYleisKaavat(maakuntaJaYleisKaavat.concat(x.data));
            drawKaavakohteet(channel, x.data, false);
          })
        }
      }
    }
  }, [props.data.kaavat, channel]);

  

  useEffect(() => {
    if (scriptLoaded && selectedKaava) {
      switch (selectedKaava) {
        default:
          // TODO only load once? or reset map when changing it?
          new KaavatietomalliApi().kaava.kaavaGetById(selectedKaava, { select: "versiointitiedot,ylaluokka,koostuu.liittyvaasiakirja,kaavanliite,kaavamaarays,kaavakohde.kaavamaarays,kaavakohde.kaavasuositus,kaavakohde.versiointitiedot,kaavakohde.kaavamaaraysryhma,kaavakohde.koskee,kaavakohde.sijainti"}).then(x =>
          {
            setKaava(x.data);
          });
          break;
      }
    }
  }, [selectedKaava, scriptLoaded]);


  // InfoBox is opened with the help of this useEffect because
  // coordinates of the event and the actual feature event
  // are delivered in separate events.
  useEffect(() => {
    if (clickEvent) {
      // setBuildingFeatureEvent(null);
      // channel.postRequest('MapModulePlugin.GetFeatureInfoRequest', [clickEvent.lon, clickEvent.lat]);

      const data = {
        "searchKey": "oulu",
        "epsg": "EPSG:3067"
      };
      channel.postRequest('SearchRequest', [data]);
    }

    if (featureEvent && clickEvent) {
      setClickEvent(null);
    }
    
  }, [featureEvent, clickEvent]);

  const onScriptLoaded = () => {
    const IFRAME_DOMAIN = 'https://kartta.paikkatietoikkuna.fi';
    const iFrame = document.getElementById('publishedMap');
    console.log("OskariRPC version: " + (window as any).OskariRPC.VERSION);
    const channel = (window as any).OskariRPC.connect(
        iFrame,
        IFRAME_DOMAIN
    );
    
    setChannel(channel);
    setScriptLoaded(true);
  }


  /*const updateLegends = () => {
    if (kaava) {
      channel.onReady(() => {

        channel.getAllLayers(function (layers: IOskariLayer[]) {
          
          channel.log('GetAllLayers:', layers);
          setAllLayers([...layers])
        });

      });
    }
  };*/

  const addKaavakohteetToOskari = () => {
    if (kaava && selectedKaava) {
      channel.onReady(() => {

        const selectedLayerId = "features-layer-" + selectedKaava.replace(/\s+/g, "-");
        if (layers.find(s => s === selectedLayerId) === undefined) {
          // Create layer for Kaavakohteet with common hover styles
        
          channel.postRequest('VectorLayerRequest', [{
            layerId: selectedLayerId,
            opacity: 80,
            hover: {
              featureStyle: {
                fill: {
                  color: "#ff00ff"
                },
                stroke: {
                  color: "#000000"
                }
              },
              content: [
                {
                  key: "Klikkaa elementtiä nähdäksesi lisätietoja",
                }
              ]
            }
          }]);

          //drawBuildings(channel);
          
          drawKaavakohteet(channel, kaava);
          setLayers([...layers, selectedLayerId]);
          console.log(`${selectedKaava} building plan added to Oskari`);
        }
        // Locate map to selected building plan
        channel.postRequest('MapModulePlugin.ZoomToFeaturesRequest',[{'layer': [selectedLayerId]}]);
        
        channel.getFeatures([true], function(data: any) {
          channel.log('GetFeatures: ', data);
        });
        // channel.getAllLayers((layers: any) => {
        //   channel.log("layers", layers);
        // });
        if (initOskari) {
          // Register event listeners only once
          registerEventListeners(channel);
          setInitOskari(false);
        }
      })
    }
  }

  const drawKaavakohteet = (channel: any, kaava: RAKKaava, convert: boolean = true) => {

    if (kaava && kaava.kaavakohde) {

      
      kaava.kaavakohde.forEach(kaavakohde => {
        //const feature = new Feature();
        if (kaavakohde.sijainti) {
          if (kaavakohde.sijainti.aluemainensijainti && kaavakohde.sijainti.aluemainensijainti.length > 0) {

            // Add some styles for drawing the geometry
            const codeItem = getCodeItemByPrefLabel(koodisto, CodeLang.FI, kaavakohde.laji||"");
            const codeStyles = codeItem ? getCodeStyles(kaavaStyles, codeItem.codeValue) : undefined;
            // Default feature styles
            const featureStyle = {
              fill: {
                color: ""
              }, 
              text: {
                font: "bold 12px Arial",
                textAlign: "center", 
                labelProperty: "label",
              }
            } as any;
            // Override default styles with custom styles
            if (kaava.kaavalaji?.startsWith(ASEMAKAAVA_START)) {
              if (codeStyles?.fill) {
                featureStyle["fill"] = { color: codeStyles.fill.color };
              }
              if (codeStyles?.stroke) {
                featureStyle["stroke"] = { color: codeStyles.stroke.color, width: 5 };
              }
            }
            else
            {
              // Draw maakunta and yleiskaava without fill color to avoid hiding map geometry
              featureStyle["fill"] = { 
                  "color": "#00000000", // fill color
                  "area": {
                    "pattern": -1 // fill style
                  }
                };
              featureStyle["stroke"] = { color: "#FF0000", width: 3 };
            }

            const feature = {
              type: "Feature",
              geometry: {
                type: "MultiPolygon",
                //coordinates: kaavakohde.sijainti.aluemainensijainti ? JSON.parse(kaavakohde.sijainti.aluemainensijainti) : [] 
                coordinates: kaavakohde.sijainti.aluemainensijainti ? (!convert ? JSON.parse(kaavakohde.sijainti.aluemainensijainti) : parseCoordinates(kaavakohde.sijainti.aluemainensijainti, kaavakohde.sijainti.eTRS89koordinaattijarjestelma||"EPSG:3067")) : [] 
              }, // TODO:
              properties: {
                fid: kaavakohde.versiointitiedot?.viittaustunnus, /// TODO use identiteettitunnus, if no other maps?
                ...kaavakohde, // kaavakohde contains kaavamaarays and kaavasuositus arrays
                label: codeStyles?.label ?? "",
                codeValue: codeItem?.codeValue,
                color: featureStyle.fill.color,
                colorDesc: codeStyles?.fill?.description ?? "",
                //featuresCount: featuresCount
              }
            };

            const featureCollection = {
              "type": "FeatureCollection",
              "crs": {
                "type": "name",
                "properties": {
                  "name": "EPSG:3067", //"EPSG:3857" // EPSG:4326 // "EPSG:3067"
                }
              },
              "features": [] as unknown[] // TODO use IFeature or use Feature?
            };

            // Do we need to convert coordinates or can we set correct EPSG name?
            featureCollection.features.push(feature);        
      

            const oskariFeatureOptions = {
              layerId: "features-layer-" + kaava.versiointitiedot?.identiteettitunnus?.replace(/\s+/g, "-"),
              //prio: priority,
              showLayer: true,
              layerName: "kaavakohteet: " + kaava.versiointitiedot?.identiteettitunnus,
              layerDescription: "Kaavakohteet",
              //visible: true,
              //minResolution: 128,
              //maxResolution: 0.25,
              featureStyle
            };
            // if (feature.properties["fid"] === 129) {
            //   console.log("Fid 129 priority: ", priority);
            // }
            // console.log("options", options);
            
            channel.postRequest('MapModulePlugin.AddFeaturesToMapRequest', [featureCollection, oskariFeatureOptions]);

          }
          else {
            // TODO handle other types?
          }
          
        }
        
        
      });

      
    }
  };

  const registerEventListeners = (channel: any) => {

    channel.handleEvent("DataForMapLocationEvent", function(data: OskariMapLocationEvent) {
      if (data && data.type === "text" && typeof data.content === "string") {

        // Building info:
        const splitted = data.content.split("<td>");
        if (splitted.length > 2 && splitted[0].startsWith("<table") && splitted[1].startsWith("BU.Building.fid--")) {
          const sourceId = parseInt(splitted[2], 10);
          if (!isNaN(sourceId)) {
            fetch("https://kaavatietomalli.azurewebsites.net/api/KtmkGetBuildingItem?sourceId="+sourceId).then(resp => resp.json() as Promise<KtkmBuildingApiResponse>).then(data => {
              setBuildingFeatureEvent(data);
            });

          }
        }

        // Address info:
        //<table>\n <caption>\n  pno\n </caption>\n <tbody>\n  <tr>\n  </tr>\n </tbody>\n</table>\n<table>\n <tbody>\n  <tr>\n   <td>objectid</td>\n   <td>1105</td>\n  </tr>\n  <tr>\n   <td>posti_alue</td>\n   <td>37630</td>\n  </tr>\n  <tr>\n   <td>vuosi</td>\n   <td>2022</td>\n  </tr>\n  <tr>\n   <td>nimi</td>\n   <td>Vääräkoivu</td>\n  </tr>\n  <tr>\n   <td>namn</td>\n   <td>Vääräkoivu</td>\n  </tr>\n  <tr>\n   <td>kunta</td>\n   <td>908</td>\n  </tr>\n  <tr>\n   <td>kuntanro</td>\n   <td>908</td>\n  </tr>\n  <tr>\n   <td>pinta_ala</td>\n   <td>38676456</td>\n  </tr>\n </tbody>\n</table><br><br></br>
      }
      
    });

    channel.handleEvent("SearchResultEvent", (data: unknown) => {

    });

    // Save MapClickedEvent and FeatureEvent into state and useEffect will open the infoBox
    channel.handleEvent("MapClickedEvent", (data: OskariMapClickedEvent) => {
      // Reset features before next feature event...
      
      
      // ongelmana nyt se, että
      // clicked tapahtuu heti feature eventin jälkeen
      // kun tehdään clicked muualle, feature ei tapahdu... niin ennen feature eventtiä ei voi tehdä clicked juttua...
      //
      //setFeatureEvent(null);
      
      if (clickEvent === null) {
        setClickEvent(data);
        setFeatureEvent(null);
        setBuildingFeatureEvent(null);
        channel.postRequest('MapModulePlugin.GetFeatureInfoRequest', [data.lon, data.lat]);
      }
      else {
        
      }
    });

    channel.handleEvent('FeatureEvent', function(data : OskariFeatureEvent) {
      // Use timeout to prevent clearing feature event after click event
      // Feature event seems to happen just before click event, and on click event we want to reset old features
      // If user clicks area where is no features, only click event happens...
      setTimeout(() => {
        if (data.operation === "click" && featureEvent === null) {
          setFeatureEvent(data);
        }
      }, 1);
      
    });
  }

  const showInfoBox = (clickEvent: OskariMapClickedEvent, data: OskariFeatureEvent) => {
    channel.postRequest('InfoBox.HideInfoBoxRequest', ["myInfoBox"]);

    // TODO poista rakennukset? uudessa kartassa toteutettu toisin
    if (data.features[0].layerId === 'features-layer-buildings') {
      const properties = data.features[0].geojson.features[0].properties as any;
      history.push(`../rakennus/${escapeHtml(properties.VTJprt)}`);   
    }
    else 
    {
      // const topRightCoordinates = getTopRightCoordinates(data.features[0].geojson.features[0].geometry.coordinates[0]);
      const content = data.features.flatMap(fw => {
        return fw.geojson.features.map((f: OskariFeature|any) => {
          return {
            'html': 
            '<div>' + 
              '<p><strong>' + escapeHtml(f.properties.kaavamaarayslaji) +
                (f.properties.tekstiarvo !== null || f.properties.numeerinen_arvo !== null ?
                ":</strong> " + (f.properties.tekstiarvo !== null ? escapeHtml(f.properties.tekstiarvo) : f.properties.numeerinen_arvo) + "</p>"
                : "</strong></p>") +
              
              '<p><strong>Kaavoitusteema: </strong>' + escapeHtml(f.properties.kaavoitusteema) + '</p>' +
            '</div>'
          }
        })
      });
      const postData = [
        'myInfoBox',
        'Kaavamääräyslajit',
        content,
        {
          'lon': clickEvent.lon,
          'lat': clickEvent.lat
        },
        {
          colourScheme: {
            bgColour: '#0091FF',
            titleColour: '#FFFFFF',
            headerColour: '#0091FF',
            iconCls: 'icon-close-white',
            buttonBgColour: '#0091FF',
            buttonLabelColour: '#FFFFFF',
            linkColour: '#000000'
          },
          font: 'Source Sans Pro',
        }
      ];
      channel.postRequest('InfoBox.ShowInfoBoxRequest', postData);
    }
  }

  const getTopRightCoordinates = (polygon: number[][]): number[] => {
    const first = polygon[0];
    // Longitude changes when going east or west (increases when going west).
    // Latitude changes when going north or south (increases when going north).
    // Index 0 in polygon contains longitude value and index 1 latitude value.
    let [longitude, latitude] = [first[0], first[1]];
    polygon.forEach(point => {
      if (longitude < point[0]) {
        longitude = point[0];
      }
      if (latitude < point[1]) {
        latitude = point[1];
      }
    });
    return [longitude, latitude];
  }

  // const getFeaturesMap = (k: Kaava) => {
  //   const featuresMap: {[id_kaavakohde: string]: Feature[]} = {};
  //   k.features.forEach(f => {
  //     var feature = new Feature();
  //     feature.Clone(f);
  //     if (featuresMap[feature.properties.id_kaavakohde]) {
  //       featuresMap[feature.properties.id_kaavakohde].push(feature);
  //     }
  //     else {
  //       featuresMap[feature.properties.id_kaavakohde] = [feature];
  //     }
  //   });
  //   return featuresMap;
  // }

  /*function handleLayerSelection(e: React.ChangeEvent<HTMLInputElement>) {

    const layerId = e.target.value;  
    const currentVisibility = allLayers.find(x => x.id.toString() === layerId)?.visible; 
    channel.postRequest('MapModulePlugin.MapLayerVisibilityRequest', [layerId, !currentVisibility]);
    //setAllLayers([...allLayers.map(x => { return { x }) ]);
    channel.getAllLayers(function (layers: IOskariLayer[]) {
          
      channel.log('GetAllLayers:', layers);
      setAllLayers([...layers])
    });
  }*/

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any): void => {
    setSelectedKaava(newValue);
  };

  const kaavat = props.data.kaavat;
  const features = kaavat && featureEvent ? featureEvent.features.flatMap(x => x.geojson.features) : null;

  const buildingFeatures = buildingFeatureEvent?.features;
  
  
  let kerrosala = 0;
  let rakennettuala = 0;
  if (kaava) {
    // Calculate "sallittu kerrosala" and "rakennettuala" amounts
    kaava.kaavakohde?.forEach(kaavakohde => {
      kaavakohde.kaavamaarays?.forEach(km => {
        if (km.kaavamaarayslaji && km.kaavamaarayslaji.toLowerCase().indexOf("sallittu kerrosala") >=0 ) { // Sallittu kerrosala OR Kellarin sallittu kerrosalaosuus
          try {
            const parsed = km.arvo ? parseFloat(km.arvo) : NaN;
            if (!isNaN(parsed)) {
              kerrosala += parsed;
            }
          }
          catch {
            // Could not parse kerrosala
            console.log("could not parse kerros-ala! Kerrosala kaava-alueella might be incorrect");
          }
        }
        if (km.kaavamaarayslaji && km.kaavamaarayslaji.toLowerCase().indexOf("rakennettuala") >=0 ) {
          try {
            const parsed = km.arvo ? parseFloat(km.arvo) : NaN;
            if (!isNaN(parsed)) {
              rakennettuala += parsed;
            }
          }
          catch {
            // Could not parse kerrosala
            console.log("could not parse rakennettuala!");
          }
        }
      })
    })
  }
  
  // We do not have enough sample data and some samples have incorrect VTJprt values, so fix those issues with this function
  const getFakeBuildingId = (realVTJprt: string) => {
    
    switch(realVTJprt) {
      case "1018450506": return "101848155B";
      case "101845004P": return "1018388849";
      case "417748637": return "106003070V";
      case "103677081Y": return "102500030K";
      case "103518667U": return "102906977W";
      case "101462466S": return "100173167F"; 
    }

    const hash = realVTJprt && realVTJprt.length === 10 ? realVTJprt.charCodeAt(9) % 6 : 0;
    const array: string[] = ["100173167F", "1018388849", "101848155B", "102500030K", "102906977W", "106003070V"];
    return array[hash];
  };


  // At the moment Kaava does not have sijainti information so just fake it here...
  let postinumero = "";
  let postitoimipaikka = "";
  let kaupunginosa = "";
  let koordinaatit = "";
  let maakuntakaava: RAKKaava|null|undefined;
  if (kaava) {
    switch (kaava.versiointitiedot?.identiteettitunnus) {
      case "Valkeakosken tanssilava":
        postinumero = "05043";
        kaupunginosa = "Lintula";
        postitoimipaikka = "Valkeakoski";
        koordinaatit = "61°8′40″N, 21°18′10″E"; 
        maakuntakaava = maakuntaJaYleisKaavat && maakuntaJaYleisKaavat.find(x => x.versiointitiedot?.identiteettitunnus === "Pirkanmaan maakunta")
        break;
      case "Tapiolan keskus":
        postinumero = "02100";
        kaupunginosa = "Tapiola";
        postitoimipaikka = "Espoo";
        koordinaatit = "P 60° 10,479' ja I 24° 48,156'";
        maakuntakaava = maakuntaJaYleisKaavat && maakuntaJaYleisKaavat.find(x => x.versiointitiedot?.identiteettitunnus === "Uudenmaan maakunta")
        break;
      default:
        console.log("not implemented, fetch the address information for the kaava")
        break;
    }
  }
  
  return (
    <Paper variant="outlined" square>
      <Typography variant='h1'>Alueidenkäyttö</Typography>
      <Typography>Valitse kartalta kaavakohde, mitä haluat tarkastella</Typography>
      { kaavat && kaavat.length > 0 &&
      
      <Tabs value={selectedKaava} onChange={handleChange} textColor="primary"
        indicatorColor="primary"
        TabIndicatorProps={{ style: { height: "4px" } }} variant="scrollable" scrollButtons="auto" aria-label="sub page tabs">
        { kaavat.filter(x => (x.kaavalaji?.startsWith(ASEMAKAAVA_START))).map((kaava: RAKKaava, i) => 
          <Tab key={i} value={ kaava.versiointitiedot?.identiteettitunnus } label={ kaava.versiointitiedot?.identiteettitunnus } />
        )}

      </Tabs> 

      }
      <Grid container spacing={2}>
        <Grid item xs={8} md={8}>
          <iframe id="publishedMap" title="map" style={{height:700, width:"100%", boxSizing: "border-box", border: "1px solid #ccc", marginBottom: "8px"}}src={ "https://kartta.paikkatietoikkuna.fi/published/fi/c95e69fb-a3ef-421d-bf7f-5153bc427255" } allow="geolocation"></iframe>         
        </Grid>
        <Grid item xs={4} md={4}>
          <Card variant="elevation" elevation={3}>
            <CardHeader title="Kaavakohteen tiedot"></CardHeader>
            <CardContent style={{maxHeight: "600px", overflowY: "auto"}}>
              { buildingFeatures?.map(x => 
                <LabelValuesBlock key={x.properties.VTJprt} title='Rakennustunnus'>
                  <LabelValue label='SourceId' value={x.properties.sourceId} oneLine={true}></LabelValue>
                  {/* <LabelValue label='kmtkId' value={x.properties.kmtkId} oneLine={true}></LabelValue> */}
                  <LabelValue label='VTJ-prt' value={<a href={"/rakennus/"+getFakeBuildingId(x.properties.VTJprt)}>{x.properties.VTJprt}</a> } oneLine={true} tooltip="pysyvä rakennustunnus; väestötietojärjestelmän pysyvä rakennustunnus;"></LabelValue>                                   
                </LabelValuesBlock>
                )
              }

              { features ?
                <Features features={features}></Features>
                :
                <Typography>kaavakohdetta ei valittu</Typography>
              }
       
            </CardContent>
          </Card>
        </Grid>
      </Grid>
        
      { kaava && 
      <Card>
        <CardHeader title="Perustiedot ja asemakaavan tiedot"></CardHeader>
        <CardContent>
          <Grid container>

            <Grid item xs={6}>
              <LabelValuesBlock title="Alueen perustiedot">
                <LabelValue label="Kaupunki/kunta" value={postitoimipaikka}></LabelValue>
                <LabelValue label="Kaupunginosa" value={kaupunginosa}></LabelValue>
                <LabelValue label="Postinumerot" value={postinumero}></LabelValue>
                <LabelValue label="Koordinaatit" value={koordinaatit}></LabelValue>
              </LabelValuesBlock>

              { kaava.kaavamaarays1 && kaava.kaavamaarays1.map((kaavamaarays1, i) =>
                <LabelValuesBlock key={i} title="Liittyvät asiakirjat">
                  <React.Fragment>
                  { kaavamaarays1.liittyvaasiakirja && kaavamaarays1.liittyvaasiakirja.length > 0 && kaavamaarays1.liittyvaasiakirja.map((asiakirja, j) =>
                      <LabelValue key={j} label={asiakirja.nimi} value={asiakirja.viitetiedostoon} format="url"></LabelValue>                 
                  )
                  }
                  </React.Fragment>
                </LabelValuesBlock>
              )
              }
            </Grid>

            <Grid item xs={6}>
              <LabelValuesBlock title="Asemakaava">
                <LabelValue label="Voimassaolon alkamispäivä" value={kaava.ylaluokka?.voimassaolonalkamisajankohta} format="date"></LabelValue>
                <LabelValue label="Voimassaolon päättymispäivä" value={kaava.ylaluokka?.voimassaolonpaattymisajankohta} format="date"></LabelValue>
                <LabelValue label="Elinkaaren tila" value={kaava.elinkaarentila} code='rytj_RY_KaavanElinkaaritila'></LabelValue>
                <LabelValue label="Maanalaisuus" value={kaava.maanalaisuus} code='rytj_RY_MaanalaisuudenLaji'></LabelValue>
                <LabelValue label="Kerrosala kaava-alueella" value={/*rakennettuala + " / " +*/ kerrosala}></LabelValue>
                <LabelValue label="kaavatiedoston URI" value={kaava.kaavatiedosto} format="url"></LabelValue>
              </LabelValuesBlock>

              { kaava.kaavanliite && kaava.kaavanliite.length > 0 && 
              <LabelValuesBlock title="Kaavan liitteet">
                { kaava.kaavanliite.map((liite, i) =>
                  <LabelValue key={i} label={liite.nimi} value={liite.asiakirjatyyppi} format="url"></LabelValue>
                )}
                
              </LabelValuesBlock>
              }

              
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
      }

      <Card>
        <CardHeader title="Yleis- ja maakuntakaavan tiedot"></CardHeader>
        <CardContent>
          <Grid container>

            <Grid item xs={6}>
              <LabelValuesBlock title="Yleiskaava">
                <LabelValue label="Voimassaolon alkamispäivä" value={"25.1.1995"}></LabelValue>
                <LabelValue label="Voimassaolon päättymispäivä" value={"-"}></LabelValue>
                <LabelValue label="Elinkaaren tila" value={"04 - Kaavaehdotus"}></LabelValue>
                <LabelValue label="Maanalaisuus" value={"01 - Maanalainen"}></LabelValue>
                <LabelValue label="Kaavatiedoston URI" value={"https://www.lounaistieto.fi/maakuntakaavat/"} format="url"></LabelValue>
              </LabelValuesBlock>

              {/* { kaava.koostuu?.liittyvaasiakirja && kaava.koostuu.liittyvaasiakirja.length > 0 && 
              <LabelValuesBlock title="Aasiakirjat">
                { kaava.koostuu.liittyvaasiakirja.map((asiakirja, i) =>
                    <LabelValue key={i} label={asiakirja.nimi} value={asiakirja.viitetiedostoon} format="url"></LabelValue>                 
                )}
                
              </LabelValuesBlock>
              } */}
            </Grid>

            { maakuntakaava && 
            <Grid item xs={6}>
              <LabelValuesBlock title="Maakuntakaava">
                <LabelValue label="Voimassaolon alkamispäivä" value={maakuntakaava.ylaluokka?.voimassaolonalkamisajankohta}></LabelValue>
                <LabelValue label="Voimassaolon päättymispäivä" value={maakuntakaava.ylaluokka?.voimassaolonpaattymisajankohta}></LabelValue>
                <LabelValue label="Elinkaaren tila" value={maakuntakaava.elinkaarentila} code='rytj_RY_KaavanElinkaaritila'></LabelValue>
                <LabelValue label="Maanalaisuus" value={maakuntakaava.maanalaisuus} code='rytj_RY_MaanalaisuudenLaji'></LabelValue>
                <LabelValue label="Kaavatiedoston URI" value={maakuntakaava.kaavatiedosto} format="url"></LabelValue>
              </LabelValuesBlock>

              { maakuntakaava.kaavanliite && maakuntakaava.kaavanliite.length > 0 && 
              <LabelValuesBlock title="Kaavan liitteet">
                { maakuntakaava.kaavanliite.map((liite, i) =>
                  <LabelValue key={i} label={liite.nimi} value={liite.asiakirjatyyppi} format="url"></LabelValue>
                )}
                
              </LabelValuesBlock>
              }

              { maakuntakaava.kaavaselostus && maakuntakaava.kaavaselostus.length > 0 && 
              <LabelValuesBlock title="Kaavaselostus">
                { maakuntakaava.kaavaselostus.map((kaavaselostus, i) =>
                  <React.Fragment key={i}>
                    { kaavaselostus.liittyvaasiakirja &&
                    <LabelValue label={kaavaselostus.liittyvaasiakirja.nimi} value={kaavaselostus.liittyvaasiakirja.viitetiedostoon} format="url"></LabelValue>
                    }
                  </React.Fragment>
                )}
                
              </LabelValuesBlock>
              }

              
            </Grid>
            }
          </Grid>
          
        </CardContent>
      </Card>
    </Paper>
  );
}

export default AreaView;