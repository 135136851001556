import { Title, Description } from "../utils/tooltipUtil";
import { Base } from "./base";

export class Property extends Base {
    @Title("Kiinteistötunnus")
    @Description("Kiinteistörekisteriin merkityn rekisteriyksikön yksilöivä tunnus")
    RealEstateId: string = "";
    @Title("Kiinteistölaji")
    @Description("Kiinteistörekisteriin merkittävä rekisteriyksikön laji")
    PropertyType: string = "";
    @Title("Nimi")
    @Description("Kiinteistön (KTJ:n rekisteriyksikön) nimi")
    Name: string = "";
    @Title("Rekisteröintipäivämäärä")
    @Description("Päivämäärä, jolloin kiinteistö (rekisteriyksikkö) on merkitty Kiinteistörekisteriin")
    RegistrationDate: Date = new Date();
    @Title("Lakkaamispäivämäärä")
    @Description("Päivämäärä, jolloin kiinteistö (rekisteriyksikkö) on poistettu Kiinteistörekisteristä")
    ExpiryDate: Date = new Date();
    @Title("Maapinta-ala")
    @Description("Kiinteistön maa-alueen pinta-ala (neliömetreinä?)")
    LandArea: number = 0;
    @Title("Vesipinta-ala")
    @Description("Kiinteistön (rekisteriyksikön) vesialueen pinta-ala neliömetreinä")
    WaterArea: number = 0;
    @Title("Omistaja")
    @Description("Kiinteistön omistaja eli toimija, jolle on myönnetty lainhuuto")
    OwnerShip: OwnerShip[] = [];
    @Title("Sijainti")
    @Description("paikka, jossa jokin sijaitsee")
    Location: Location = new Location();
}

export class OwnerShip extends Base {
    @Title("Henkilöomistaja")
    @Description("Kiinteistön omistava henkilö")
    Owner: Owner = new Owner();
    @Title("Omistusosuus")
    @Description("Pitääkö jakaa osoittajaan ja nimittäjään (kokonaislukuja?)")
    Share: number = 1;
    @Title("Saanto")
    @Description("Omistajan omistusoikeuden perustana oleva saanto")
    Rule: Rule | null = null; 
}

export class Owner extends Base {
    @Title("Etunimet")
    @Description("henkilön kaikki etunimet lueteltuna")
    FirstNames: string = "";
    @Title("Sukunimi")
    @Description("pääasiallisesti sukuun viittaava rekisteröidyn henkilönnimen osa")
    LastName: string = "";
    @Title("Syntymäpäivä")
    @Description("Henkilön syntymäpäivämäärä")
    
    BirthDate: Date = new Date();
    @Title("Kuolinpäivä")
    @Description("Henkilön kuolinpäivämäärä")
    
    DateOfDeath?: Date;
    @Title("Ulkomaalainen")
    @Description("Tieto siitä onko henkilö Suomen vai muun valtion kansalainen")
    
    Resident: boolean = true;
    @Title("Henkilötunnus")
    @Description("Henkilön suomalainen henkilötunnus")
    
    PersonalId: string = "";
}

export class Rule extends Base {
    @Title("Saantolaji")
    @Description("Kiinteistön omistusoikeuden perusteena olevan saannon laji")
    
    RuleType: string = "";
    @Title("Saantopäivämäärä")
    @Description("Omistusoikeuden siirtymisen päivämäärä saannon perusteella")
    
    RuleDate: Date = new Date();
    @Title("Vastike")
    @Description("Saannon vastike, esim. kaupassa kauppahinta valuutassa (yleensä euroja)")
    
    Compensation: Compensation = new Compensation();
}

export class Compensation extends Base {
    @Title("Määrä")
    @Description("määrän määritelmä")
    
    Amount: number = 0;
    @Title("Valuuttatunnus")
    @Description("Valuuttatunnus")
    
    Currency: string = "";
}

export class Location extends Base {
    @Title("Osoite")
    @Description("sijaintipaikan osoittavien tietojen kokonaisuus")
    
    Address: Address = new Address();
    @Title("Sijaintikunta")
    @Description("kunta, jossa kohde sijaitsee")
    
    Municipality: Municipality = new Municipality();
}
export class Municipality extends Base {
    @Title("Nimi")
    @Description("Kunnan nimi")
    
    Name: string = "";
    @Title("Kuntanumero")
    @Description("Digi- ja väestötietoviraston (DVV) kunnalle antama kolminumeroinen tunnus")
    
    MunicipalityNumber: string = "";
}

export class Address extends Base {
    @Title("Kadun nimi")
    @Description("Kadun tai vastaavan kulkuväylän tms. nimi suomalaisessa osoitejärjestelmässä.")
    
    StreetName: string = "";
    @Title("Postitoimipaikka")
    @Description("Postitoimipaikka")
    
    PostalDistrict: string = "";

    @Title("Postinumero")
    @Description("Postinumero")
    
    PostalCode: string = "";
}
