import { PermitApplication } from "./permitApplication";
import { PermitDecision } from "./permitDecision";
import { BuildingSite } from "./buildingSite";
import { BuildingAction } from "./buildingAction";
import { CaseIdentifier } from "./caseIdentifier";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export class ConstructionSupervisionCase extends Base{
    @Title("Kuntanumero")
    @Description("Digi- ja väestötietoviraston (DVV) kunnalle antama kolminumeroinen tunnus")
    MunicipalityNumber: number = 0;
    
    @Title("Lupahakemus")
    @Description("Lupahakemus")
    PermitApplication: PermitApplication = new PermitApplication();

    @Title("Päätös")
    @Description("Päätös")
    Decision: PermitDecision = new PermitDecision();
    
    @Title("Rakentamispaikka")
    @Description("Rakentamispaikka")
    BuildingSite: BuildingSite = new BuildingSite();
    
    @Title("Rakentamistoimenpide")
    @Description("Rakentamistoimenpide")
    BuildingActions: BuildingAction[] = [];
    
    @Title("Asian tunnus")
    @Description("Asian tunnus kuntajärjestelmässä tai muussa tietojärjestelmässä")
    CaseIdentifiers: CaseIdentifier[]= [];
    
    @Title("Viranomainen")
    @Description("Toimivaltainen viranomainen")
    Authorative: string = "";
    
    @Title("Vireilletulopäivämäärä")
    @Description("Päivämäärä, jona rakennusvalvonta-asia on tullut vireille. Jos hakemus on jätetty virka-ajan ulkopuolella, vireilletulopäivämäärä voi poiketa hakemuksen saapumispäivämäärästä.")
    InitiationDate: Date = new Date();

    public Clone(data: any) {
        super.Clone(data);

        if (data.BuildingActions !== undefined && data.BuildingActions.length > 0) {
            data.BuildingActions.forEach((element : any) => {
                var b = new BuildingAction();
                b.Clone(element);
                this.BuildingActions.push(b);
            });
        }

        if (data.CaseIdentifiers !== undefined && data.CaseIdentifiers.length > 0) {
            data.CaseIdentifiers.forEach((element : any) => {
                var b = new CaseIdentifier();
                b.Clone(element);
                this.CaseIdentifiers.push(b);
            });
        }
    }
}