import { Description, Title } from "../utils/tooltipUtil";

export class BuildingSpecification {
    @Title("Rakennustunnus (pysyvä)")
    @Description("Tunnus, jolla rakennus yksilöidään koko sen elinkaaren ajaksi. Pysyvä rakennustunnus ei sisällä mitään informaatiota rakennuksen sijaintikiinteistöstä tai -kunnasta.")
    PermanentBuildingID?: string;
    @Title("Viemäriliittymä")
    @Description("Tieto siitä onko rakennus liitetty kunnalliseen viemäriverkostoon.")
    SewerConnection?: boolean;
    @Title("Vesijohtoliittymä")
    @Description("tieto siitä onko rakennus liitetty kunnalliseen vesijohtoverkkoon")
    WaterSupplyConnection: boolean = false;
    @Title("Sähköverkkoliittymä")
    @Description("Tieto siitä onko rakennus liitetty sähköverkkoon.")
    ElectricityConnection: boolean = false;
    @Title("Kaapeliliittymä")
    @Description("Tieto siitä onko rakennus liitetty alueella käytössä olevaan tietoliikenne-, puhelin- tai kaapelitelevisioverkkoon.")
    CableConnection: boolean = false;
    @Title("Sähkövaruste")
    @Description("Tieto siitä onko rakennukseen asennettu sähköjärjestelmä ja siihen liittyvät sähkön käyttöön tarpeelliset varusteet.")
    ElectricalEquipment: boolean = false;
    @Title("Kaasuvaruste")
    @Description("Tieto siitä onko rakennukseen asennettu kaasujärjestelmä ja kaasun käytön mahdollistavat varusteet.")
    GasSupply: boolean = false;
    @Title("Jätevesiviemäri")
    @Description("Tekninen rakennelma/järjestelmä, joka mahdollistaa jäteveden poistamisen rakennuksesta määräysten vaatimalla tavalla.")
    Sewage: boolean = false;
    @Title("Vesijohto")
    @Description("vesijohdon määritelmä")
    Waterpipe: boolean = false;
    @Title("Lämmin vesi")
    @Description("lämpimän veden määritelmä")
    WarmWater: boolean = false;
    @Title("Koneellinen ilmastointi")
    @Description("koneellisen ilmastoinnin määritelmä")
    Airconditioning: boolean = false;
    @Title("Aurinkopaneeli")
    @Description("aurinkopaneelin määritelmä")
    SolarPanel: boolean = false;
    @Title("Hissi")
    @Description("hissin määritelmä")
    Elevator: boolean = false;
    @Title("Uima-allas")
    @Description("uima-altaan määritelmä")
    SwimmingPool: boolean = false;
    @Title("Sauna")
    @Description("saunan määritelmä")
    Sauna: boolean = false;
    @Title("Väestönsuoja")
    @Description("Väenstonsuojan määritelmä")
    Shelter: boolean = false;
    @Title("Väestösuoja henkilömäärä")
    @Description("Väestösuojan kapasiteetti suurimman sallitun henkilömäärän mukaisesti ilmaistuna.")
    ShelterCapacity: number = 0;
}