import { Box, Breadcrumbs, Typography } from '@material-ui/core'
import React from 'react'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';

// TODO tee sivukartta ja generoi murupolut siitä automaattisesti?

export interface IBreadcrumbItem
{
    text: string;
    link: string;
}

type Props = {
    items: IBreadcrumbItem[]
}


export function OmaBreadcrumbs(props: Props) {

    const breadcrumbs = [
        props.items.map((x: IBreadcrumbItem, i: number) => {
            if (i === props.items.length -1) {
                return <Typography key="3">{x.text}</Typography>
            } else {
                return <Link key={i.toString()} color="inherit" to={x.link}>{x.text}</Link>
            }           
        })
    ];
      
    return (
        <Box sx={{ my: 2 }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
        </Box>
    )
}

export default OmaBreadcrumbs;
