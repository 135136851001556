import { Title } from '../../utils/tooltipUtil';
import { Description } from "../../utils/tooltipUtil";
import { Base } from '../base';

export default class TaxBuildingGrounds extends Base {
    @Title("Alkupäivä")
    @Description("Päivämäärä, josta lähtien maapohja on olemassa Verohallinnon kiinteistötietokannassa.")
    StartDate: Date = new Date();
    @Title("Loppupäivä")
    @Description("Päivämäärä, jona maapohjan voimassaolo lakkaa.")
    EndDate: Date = new Date();
    @Title("Kaavan laji")
    @Description("Maankäyttö- ja rakennuslaissa määritellyn maankäyttösuunnitelman tai -rajoituksen luokittelu. Arvot asemakaava, ranta-asemakaava, ei asemakaavaa. (tässä kokeilussa koodistoksi valittu RYTJ:in kaavalaji-koodisto)")
    ZoningPlanType: string = "";
    @Title("Kaavan mukainen käyttötarkoitus")
    @Description("Asema- tai ranta-asemakaavaan merkitty korttelin tai sen osan käyttötarkoitusmerkintä. Verohallinto käyttää Ympäristöministeriön maankäyttö- ja rakennuslain mukaisissa kaavoissa käytettävistä merkinnöistä antaman asetuksen (2000) mukaista luokitusta. Verohallinnon kiinteistötietoihin merkitty kaavan mukainen käyttötarkoitus voi siis poiketa kunnan asemakaavan kaavamerkinnästä, sillä asemakaavassa voidaan käyttää kuntakohtaisia tarkenteita. Asemakaava-alueella sijaitsevaan maapohjaan sovelletava aluehinta määräytyy kaavan mukaisen käyttötarkoituksen perusteella.")
    ZoningPlanUse: string = "";
    @Title("Pinta-ala")
    @Description("Maapohjan pinta-ala.")
    Area: number = 0;
    @Title("Rakennusoikeus")
    @Description("Kunnalta saatava tieto rakennusoikeudesta (k-m2).  Tieto voi olla maapohjatyypeillä rakennusmaa, muu maa ja 3D-kiinteistö.")
    BuildingRight: number = 0;
}