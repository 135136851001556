import { Title, Description } from '../../utils/tooltipUtil';
import { Isannoitsija } from './isannoitsija';
import { Kiinteistohuoltoyhtio } from './kiinteistohuoltoyhtio';
import { Sahkoyhtio } from './sahkoyhtio';
import { Vesiyhtio } from './vesiyhtio';

export class Osapuolet {
    @Title("Isännoitsija")
    @Description("Isännoitsija")
    isannoitsija: Isannoitsija = new Isannoitsija();

    @Title("Energiayhtiö")
    @Description("Energiayhtiö")
    energiayhtio: Sahkoyhtio = new Sahkoyhtio();

    @Title("Vesiyhtiö")
    @Description("Vesiyhtiö")
    vesiyhtio: Vesiyhtio = new Vesiyhtio();

    @Title("Kiinteistöhuoltoyhtiö")
    @Description("Kiinteistöhuoltoyhtiö")
    kiinteistohuolto: Kiinteistohuoltoyhtio = new Kiinteistohuoltoyhtio();
}