import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Huontest from "../../../model/Huoneistotiedot";
import { Description, Title } from "../../../utils/tooltipUtil";
import { UnitSpecification } from "../../../model/unitSpecification";
import GeneralTableView from '../../../utils/GeneralTableView';
import TooltippedItem from "../../../utils/tooltippedItem";
import { GetTitleForObject } from "../../../utils/tooltipUtil";

export class HuoneistokohtaisetTiedot {
  constructor(hallintakohde?: Huontest.Hallintakohde, osakeryhma?: Huontest.Osakeryhma, omistaja?: Huontest.Omistaja, unitSpecification?: UnitSpecification) {
    this.hallintakohde = hallintakohde;
    this.osakeryhma = osakeryhma;
    this.omistaja = omistaja;
    this.unitSpecification = unitSpecification;
  }
  @Title("Hallintakohde")
  @Description("Yhtiön hallinnassa oleva huoneisto, autopaikka tai muu tila.")
  hallintakohde?: Huontest.Hallintakohde;
  @Title("Osakeryhmä")
  @Description("1.1.2019 jälkeen syntyneiden yhtiöiden osakeryhmätiedot ovat kaupparekisterin tietoa, ellei tiedon kohdalla ole erikseen merkitty muuta. Ennen 1.1.2019 syntyneiden yhtiöiden osakeryhmätiedot ovat yhtiön tallentamia siihen saakka, kunnes yhtiö on ensimmäisen kerran antanut yhtiöjärjestyksen huoneistoselitelmineen rakenteisessa muodossa kaupparekisterissä.")
  osakeryhma?: Huontest.Osakeryhma;
  @Title("Omistaja")
  @Description("Osakeryhmän omistaja.")
  omistaja?: Huontest.Omistaja;
  unitSpecification?: UnitSpecification;
}

interface Props {
  index: number,
  title: string,
  item?: HuoneistokohtaisetTiedot
}

const getUnitNumber = (unitSpecs?: UnitSpecification): string => {
  if (unitSpecs) {
    const unitStair = unitSpecs.Stair || "";
    const unitNumber = unitSpecs.Number?.toString() || "";
    return unitStair + unitNumber;
  }
  return "";
}

export const HuoneistokohtaisetTiedotView: React.FC<Props> = ({index, title, item}: Props) => {
  return (
    <Accordion key={`huoneistotiedot-${index}`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
      <Typography><b>{title}</b></Typography>
      </AccordionSummary>
      <AccordionDetails>
        { item ? 
        <Box sx={{ flexGrow: 1 }}>
          <Grid container xs={12} md={12}>
            <Grid container xs={12} md={12} key={"hallintakohde-" + index}>
              <TooltippedItem data = {GetTitleForObject(item, "hallintakohde")} filter={["pysyvahuoneistotunnus", "hallintakohdetyyppi", "porras", "huoneistonumero", "jakokirjain", "tunnus", "huoneistotyyppi", "sijaintikerros", "autopaikkatyyppi"]} flatView = {true}/>
            </Grid>
            <Grid container xs={12} md={12} key={"hallintakohde-paakayttotarkoitus-" + index}>
              <TooltippedItem data = {GetTitleForObject(item.hallintakohde, "paakayttotarkoitus")} filter={["kayttotarkoitus", "selite"]} flatView = {true} indentation={10}/>
            </Grid>
            <Grid container xs={12} md={12} key={"hallintakohde-omistaja-" + index}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Omistaja"}>    
                  <b style={{paddingLeft: `10px`}}>{"Omistaja"}</b>
                </Tooltip>
              </Grid>
              {item.omistaja?.henkiloomistaja && 
                <GeneralTableView key={`hallintakohde-henkiloomistaja-${index}`} data={item.omistaja} filter={[{henkiloomistaja: ["etunimet", "sukunimi", "henkilotunnus"]}, "omistusosuudennimittaja", "omistusosuudenjakaja"]} flatView = {true} indentation = {20}/>
              }
              {item.omistaja?.organisatioomistaja && 
                <GeneralTableView key={`hallintakohde-organisaatioomistaja-${index}`} data={item.omistaja} filter={[{organisatioomistaja: ["toiminimi", "ytunnus"]}, "omistusosuudennimittaja", "omistusosuudenjakaja"]} flatView = {true} indentation = {20}/>
              }
            </Grid>
            <Grid container xs={12} md={12} key={"hallintakohde-osakesarja-" + index}>
              <TooltippedItem data = {GetTitleForObject(item.osakeryhma, "osakesarja")} filter={["alkunumero", "loppunumero"]} flatView = {true} indentation={10}/>
            </Grid>
            <Grid container xs={12} md={12} key={"hallintakohde-osakeryhma-" + index}>
              <TooltippedItem data = {GetTitleForObject(item, "osakeryhma")} filter={["osakkeet", "osakelukumaara"]} flatView = {true} indentation={10}/>
            </Grid>
            <Grid container xs={12} md={12} key={"huoneisto-" + index}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Rakennuksessa sijaitseva yhden tai useamman huoneen kokonaisuus, jolla on yksi tai useampi oma sisäänkäynti"}>    
                  <b>{"Huoneisto"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView key={`huoneisto-${index}-${getUnitNumber(item?.unitSpecification)}`} data={item?.unitSpecification} filter={["PermanentShareID", "huoneistoTunnuksenJakokirjain", "huoneistoTunnuksenKirjainosa", "huoneistoTunnuksenNumero", "Specifier", "osoitejarjestysnumero", "hallintaperustelaji", "huoneistonkaytossaolo", "Area", "Rooms"]} flatView = {true} indentation = {10}></GeneralTableView>
            </Grid>
            <Grid container xs={12} md={12} key={"huoneiston-varustetiedot-" + index}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Tieto siitä, minkälaisia laitteita, kalusteita ja vastaavia välineitä huoneistossa on."}>    
                  <b style={{paddingLeft: `10px`}}>{"Huoneiston varustetiedot"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView key={`huoneiston-varustetiedot-${index}-${getUnitNumber(item?.unitSpecification)}`} data={item?.unitSpecification} filter={["Toilet", "Shower", "Sauna", "Balcony", "WarmWater"]} flatView = {true} indentation = {20}></GeneralTableView>
            </Grid>
          </Grid>
        </Box>
        : <div>Ei tietoja saatavilla</div>};
      </AccordionDetails>
    </Accordion>
  );
}
