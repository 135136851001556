import { Title } from '../utils/tooltipUtil';
import { Description } from "../utils/tooltipUtil";
export class EnergyInitialData {
    @Title("Pysyvä rakennustunnus")
    @Description("Rakennuksen pysyvä tunnus.")
    PermanentBuildingID: string = "";
    @Title("Kiinteistötunnus")
    @Description("kiinteistörekisteriin merkityn rekisteriyksikön yksilöivä tunnus")
    BuildingID: string = "";
    @Title("Lämmitetty nettoala")
    @Description("Lämmitetty nettoala")
    HeatedNetArea: number = 0;
    @Title("Ilmanvuotoluku")
    @Description("Rakennuksen vaipan keskimääräinen vuotoilmavirta tunnissa 50 Pa:n paine-erolla rakennuksen kokonaissisämittojen mukaan laskettua rakennuksen vaipan pinta-alaa kohden.")
    AirLeakageRate: number = 0;
    @Title("Lämpökapasiteetti")
    @Description("Rakennusvaipan kyky sitoa ja varastoida itseensä lämpöä (syn: \"lämmönvaraamiskyky\")")
    HeatCapacity: number = 0;
    @Title("Ilmatilavuus")
    @Description("Ilmatilavuus")
    AirVolume: number = 0;
    @Title("Ulkoneinät, Pinta-ala")
    @Description("Ulkoneinät, Pinta-ala")
    ExteriorWalls_Lower: number = 0;
    @Title("Ulkoseinät, Lämmönläpäisykerroin")
    @Description("Määritelmä asetus 1010/2017")
    ExteriorWalls_U: number = 0;
    /*@Title("")
    @Description("")
    ExteriorWalls_Ua: number = 0;*/
    @Title("Ikkunat -> Pinta-ala")
    @Description("Ikkunat -> Pinta-ala")
    Windows_Lower: number = 0;
    @Title("Ikkunat > Lämpöläpäisykerroin")
    @Description("Ikkunat > Lämpöläpäisykerroin")
    Windows_U: number = 0;
    @Title("Ilmanvaihtotapa")
    @Description("Ilmanvaihtotapa")
    VentilationType: number = 0;
    @Title("Ilmanvaihto > Tulo")
    @Description("Ilmanvaihtotapa, tulo")
    VentilationIN: number = 0;
    @Title("Ilmanvaihto > Poisto")
    @Description("Ilmanvaihtotapa, poisto")
    VentilationOUT: number = 0;
    @Title("Lämmitystapa")
    @Description("Rakennuksen lämmitystapa; koodisto: mm. Kaukolämpö; Sähkö; Maalämpöpumppu; Öljy; Kaasu. Sallittava ilmeisesti useampi arvo?")
    HeatingMethod: number = 0;
    @Title("Sisäiset kuormat > Henkilöt > Käyttöaste")
    @Description("Sisäiset kuormat > Henkilöt > Käyttöaste")
    InternalLoad_People_OccupancyRate: number = 0;
    @Title("Sisäiset kuormat > Henkilöt > Lämpökuorma")
    @Description("Rakennuksen vakioidusta käytöstä aiheutuva lämpökuorma. Esim. ihmisen läsnäolo rakennuksessa vähentää rakennuksen lämmitystarvetta.")
    InternalLoad_People_HeatLoad: number = 0;
    @Title("Sisäiset kuormat > Kuluttajalaitteet > Käyttöaste")
    @Description("Sisäiset kuormat > Kuluttajalaitteet > Käyttöaste")
    InternalLoad_Equipment_OccupancyRate: number = 0;
    @Title("Sisäiset kuormat > Kuluttajalaitteet > Lämpökuorma")
    @Description("Rakennuksen vakioidusta käytöstä aiheutuva lämpökuorma. Esim. ihmisen läsnäolo rakennuksessa vähentää rakennuksen lämmitystarvetta.")
    InternalLoad_Equipment_HeatLoad: number = 0;
    @Title("Sisäiset kuormat > Kuluttajalaitteet > Käyttöaste")
    @Description("Sisäiset kuormat > Kuluttajalaitteet > Käyttöaste")
    InternalLoad_Lights_OccupancyRate: number = 0;
    @Title("Sisäiset kuormat > Valaistus > Lämpökuorma")
    @Description("Rakennuksen vakioidusta käytöstä aiheutuva lämpökuorma. Esim. ihmisen läsnäolo rakennuksessa vähentää rakennuksen lämmitystarvetta.")
    InternalLoad_Lights_HeatLoad: number = 0;
}
