/** @jsx jsx */
import {jsx} from '@emotion/core'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EnergyData from './energyData';
import GeneralTableView from '../../../utils/GeneralTableView';

interface Props {
  data: EnergyData
}

class EnergiaView extends React.Component<Props, {data: EnergyData}> {
  constructor(props: Props) {
      super(props);
      this.state = {data: props.data};
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  render() {
    return (
      this.state.data.basicEnergyData === undefined || this.state.data.initialEnergyData === undefined?
      <div>Ei energiatietoja saatavilla</div> :
      <div>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
            <Typography><b>Perustiedot</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='text-columns-2'>
                <GeneralTableView data={this.state.data.basicEnergyData}></GeneralTableView>
              </div>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
            <Typography><b>Yksityiskohtaiset tiedot</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='text-columns-2'>
                <GeneralTableView data={this.state.data.initialEnergyData}></GeneralTableView>
              </div>
            </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default EnergiaView;
