/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import {FlexCol } from "../../common/CommonStyles";
import Card from '../../common/Card';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid, TextField } from '@material-ui/core';
import GeneralTableView from '../../../utils/GeneralTableView';
import TooltippedItem from '../../../utils/tooltippedItem';
import { GetDescription, GetTitle } from '../../../utils/tooltipUtil';
import _ from 'lodash';
import { Building } from '../../../model';
import { KiinteistonHuoltokirja } from '../../../model/servicePlan/kiinteistonHuoltokirja';
import RakenneEditor, { buildingTypes, structureEditValue, timeFrames } from './rakenneEditor';
import { Rakenne } from '../../../model/servicePlan/rakenne';
import { TaxAdministrationApi } from '../../../utils/api';
import { Osapuolet } from '../../../model/servicePlan/osapuolet';

interface Props {
  building: Building;
  serviceplan: KiinteistonHuoltokirja;
}

interface InternalProps {
  building: Building;
  serviceplan: InternalKiinteistonHuoltokirja;
}

class InternalKiinteistonHuoltokirja
{
  osapuolet: Osapuolet = new Osapuolet();
  rakenteet: structureEditValue[] = [];
}

class HuoltokirjaView extends React.Component<Props, InternalProps> {
  constructor(props: Props) {
      super(props);

      this.state = {building: props.building, serviceplan: { osapuolet: props.serviceplan.osapuolet, rakenteet: props.serviceplan.rakenteet.map(r => this.convertTo(r)) }};  

      new TaxAdministrationApi().GetServicePlan(props.building.PermanentBuildingID).then(r => {
        var s = this.state;
        
        if (r !== undefined) {
          s.serviceplan.rakenteet = r.map(rak => this.convertTo(rak));
        }

        this.setState(s);
      })
  }

  private permanentBuildingID : string = "";

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
  }

  componentDidUpdate() {
    if (this.permanentBuildingID !== this.props.building.PermanentBuildingID) {
      this.permanentBuildingID = this.props.building.PermanentBuildingID;
      this.state = {building: this.props.building, serviceplan: { osapuolet: this.props.serviceplan.osapuolet, rakenteet: this.props.serviceplan.rakenteet.map(r => this.convertTo(r)) }};  

      new TaxAdministrationApi().GetServicePlan(this.props.building.PermanentBuildingID).then(r => {
        var s = this.state;
        
        if (r !== undefined) {
          s.serviceplan.rakenteet = r.map(rak => this.convertTo(rak));
        } else {
          s.serviceplan.rakenteet = [];
        }

        this.setState(s);
      })
      }
  }

  convertTo(item: Rakenne) : structureEditValue {
    var returnValue = new structureEditValue();
    
    returnValue.toimenpiteenNimi = item.toimenpiteenNimi ?? "";
    returnValue.toimenpideVuosi = (item.toimenpideVuosi ?? 0).toString();
    returnValue.kustannukset = item.kustannukset ?? "";
    returnValue.peruste = item.peruste ?? "";

    returnValue.rakennetyyppi = item.rakennetyyppi;
    returnValue.rakennusosa = buildingTypes.filter(t => t.id === item.rakennusosa)[0].value;
    returnValue.huoltovaliaikavali = item.tarkastusJaHuoltovali.huoltovali.aikavali.toString();
    returnValue.huoltovaliyksikko = timeFrames.filter(t => t.id === item.tarkastusJaHuoltovali.huoltovali.yksikko)[0].value;
    returnValue.tarkastusvaliaikavali = item.tarkastusJaHuoltovali.tarkastusvali.aikavali.toString();
    returnValue.tarkastusvaliyksikko = timeFrames.filter(t => t.id === item.tarkastusJaHuoltovali.tarkastusvali.yksikko)[0].value;

    return returnValue;
  } 

  convertFrom(item: structureEditValue) : Rakenne {
    var returnValue = new Rakenne();
    returnValue.rakennetyyppi = item.rakennetyyppi;
    returnValue.toimenpiteenNimi = item.toimenpiteenNimi;
    returnValue.toimenpideVuosi = Number.parseInt(item.toimenpideVuosi);
    returnValue.kustannukset = item.kustannukset;
    returnValue.peruste = item.peruste;

    returnValue.rakennusosa = buildingTypes.filter(t => t.value === item.rakennusosa)[0].id;
    returnValue.tarkastusJaHuoltovali.huoltovali.aikavali = Number.parseInt(item.huoltovaliaikavali);
    returnValue.tarkastusJaHuoltovali.huoltovali.yksikko = timeFrames.filter(t => t.value === item.huoltovaliyksikko)[0].id;
    returnValue.tarkastusJaHuoltovali.tarkastusvali.aikavali = Number.parseInt(item.tarkastusvaliaikavali);
    returnValue.tarkastusJaHuoltovali.tarkastusvali.yksikko = timeFrames.filter(t => t.value === item.tarkastusvaliyksikko)[0].id;

    return returnValue;
  } 

  structuresUpdated(rakenteet: structureEditValue[]) {
    var backendData = rakenteet.map(r => this.convertFrom(r))
    var stringData = JSON.stringify(backendData);
    var buildingID = (this.state as any).data.building.PermanentBuildingID;
    new TaxAdministrationApi().SaveServicePlan(buildingID, stringData);
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps};
  }

  render() {
    return (
      
      <Card title="Sähköinen huoltokirja">
        
      <FlexCol>

      <Accordion>
        <AccordionSummary>
        <Typography>Rakennus</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <GeneralTableView data={this.state.building} flatView = {true}></GeneralTableView>

        </AccordionDetails>
       </Accordion>

        { 
        this.state !== undefined && this.state.serviceplan !== undefined && this.state.serviceplan.rakenteet !== undefined?
       <Accordion expanded={true}>
        <AccordionSummary>
        <Typography>Kunnossapitosuunnitelma</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RakenneEditor data={this.state.serviceplan.rakenteet} buildingId={this.props.building.PermanentBuildingID} updated={this.structuresUpdated.bind(this)}></RakenneEditor>
        </AccordionDetails>
       </Accordion> : <div></div>
       }

      </FlexCol>
      <FlexCol>
      <Grid container xs={12}>
          <Grid item xs={6} md={6}>
          <TooltippedItem data = {GetTitle(this.state.building, "PermanentBuildingID")} />
          </Grid>
          <Grid item xs={6} md={6}>
          <TooltippedItem data = {GetDescription(this.state.building, "PermanentBuildingID")} />
          </Grid>
      </Grid>
      
      <Accordion expanded={true}>
        <AccordionSummary>
        <Typography>Isännöitsijä</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <TextField name='nimi' style={{width: 500}} label="Nimi" value={this.state.serviceplan.osapuolet.isannoitsija.nimi} onChange={this.handleChange.bind(this)} variant="outlined" />
          <TextField name='nimi' style={{width: 500}} label="Y-tunnus" value={this.state.serviceplan.osapuolet.isannoitsija.ytunnus} onChange={this.handleChange.bind(this)} variant="outlined" />
        </AccordionDetails>
       </Accordion>

      <Accordion expanded={true}>
        <AccordionSummary>
        <Typography>Energiayhtiö</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField name='nimi' style={{width: 500}} label="Nimi" value={this.state.serviceplan.osapuolet.energiayhtio.nimi} onChange={this.handleChange.bind(this)} variant="outlined" />
          <TextField name='nimi' style={{width: 500}} label="Y-tunnus" value={this.state.serviceplan.osapuolet.energiayhtio.ytunnus} onChange={this.handleChange.bind(this)} variant="outlined" />
        </AccordionDetails>
       </Accordion>

       <Accordion expanded={true}>
        <AccordionSummary>
        <Typography>Kiinteistöhuoltoyhtiö</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField name='nimi' style={{width: 500}} label="Nimi" value={this.state.serviceplan.osapuolet.kiinteistohuolto.nimi} onChange={this.handleChange.bind(this)} variant="outlined" />
          <TextField name='nimi' style={{width: 500}} label="Y-tunnus" value={this.state.serviceplan.osapuolet.kiinteistohuolto.ytunnus} onChange={this.handleChange.bind(this)} variant="outlined" />
        </AccordionDetails>
       </Accordion>

       <Accordion expanded={true}>
        <AccordionSummary>
        <Typography>Vesiyhtiö</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField name='nimi' style={{width: 500}} label="Nimi" value={this.state.serviceplan.osapuolet.vesiyhtio.nimi} onChange={this.handleChange.bind(this)} variant="outlined" />
          <TextField name='nimi' style={{width: 500}} label="Y-tunnus" value={this.state.serviceplan.osapuolet.vesiyhtio.ytunnus} onChange={this.handleChange.bind(this)} variant="outlined" />
        </AccordionDetails>
       </Accordion>

       

     

      
      </FlexCol>
    </Card>
    );
  }
}

export default HuoltokirjaView;
