import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router';
import { useFormik } from "formik";
import { Box, Button, Grid } from '@material-ui/core';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FormTextField, HazardousItemForm, InputWrapper, ItemCertificateForm, ItemCommodityClassificationForm, ItemDimensionForm, ItemInstanceForm, ItemPropertyForm, ItemSpecificationDocumentReferenceForm, TextFieldListForm, FormCard } from './CustomFormikComponents';
import { JsonObject } from '../../../model/JsonObject';
import Card from '../../common/Card';
import { GetProductTemplate, Product } from '../../../model/Product';
import { PeppolAPI } from "../../../utils/api";

const CatalogueProductItemView: React.FC = () => {
  const history = useHistory();
  const { transaction } = useParams<{transaction: string}>();
  const { productId } = useParams<{productId: string}>();
  const [existingProductObject, setExistingProductObject] = useState<JsonObject<Product>|undefined>(undefined);
  const [existingProduct, setExistingProduct] = useState<Product|undefined>(undefined);

  useEffect(() => {
    if (transaction && productId) {
      new PeppolAPI().GetCatalogueProductItems(transaction)
      .then(productObjects => {
        const matchingObject = productObjects.find(po => po.id === productId);
        setExistingProductObject(() => matchingObject);
        setExistingProduct(() => matchingObject?.jsonData);
        formik.resetForm({ values: matchingObject?.jsonData});
      })
    }
  }, []);

  const submit = async (productToSubmit: Product) => {
    console.log("onSubmit", productToSubmit);
  }

  const formik = useFormik({
    initialValues: GetProductTemplate(),
    onSubmit: submit
  });

  if (productId && !existingProduct) {
    return (
      <Card title={"Tuotetta ei löytynyt"}>
      </Card>
    );
  }

  return (
    <Card title={"Tuotteen tiedot"}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <FormTextField fieldName="Name" fieldPath="name.value" memberOfClass="Item" formikProps={formik} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <FormTextField fieldName="Brand name" fieldPath="brandName[0].value" memberOfClass="Item" formikProps={formik} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <FormTextField fieldName="Description" fieldPath="description[0].value" memberOfClass="Item" formikProps={formik} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <FormTextField fieldName="Pack quantity" fieldPath="packQuantity.value" memberOfClass="Item" formikProps={formik} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <FormTextField fieldName="Pack size numeric" fieldPath="packSizeNumeric.value" memberOfClass="Item" formikProps={formik} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <TextFieldListForm fieldName="Keyword" fieldPath="keyword" memberOfClass="Item" formikProps={formik} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <FormTextField fieldName="Origin country" fieldPath="originCountry.identificationCode.value" memberOfClass="Item" formikProps={formik} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper>
              <FormTextField fieldName="Manufacturer party" fieldPath="manufacturerParty[0].partyName[0].name.value" memberOfClass="Item" formikProps={formik} />
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <FormTextField fieldName="Sellers item identification" fieldPath="sellersItemIdentification.iD.value" memberOfClass="Item" formikProps={formik} />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField fieldName="schemeID" fieldPath="sellersItemIdentification.iD.schemeID" memberOfClass="IdentifierType" formikProps={formik} />
                </Grid>
              </Grid>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <FormTextField fieldName="Manufacturers item identification" fieldPath="manufacturersItemIdentification[0].iD.value" memberOfClass="Item" formikProps={formik} />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField fieldName="Scheme ID" fieldPath="manufacturersItemIdentification[0].iD.schemeID" memberOfClass="IdentifierType" formikProps={formik} />
                </Grid>
              </Grid>
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <FormCard fieldName="Standard item identification" memberOfClass="Item">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                  <InputWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={8}>
                        <FormTextField fieldName="Standard item identification" fieldPath="standardItemIdentification.iD.value" memberOfClass="Item" formikProps={formik} />
                      </Grid>
                      <Grid item xs={4}>
                        <FormTextField fieldName="Scheme ID" fieldPath="standardItemIdentification.iD.schemeID" memberOfClass="IdentifierType" formikProps={formik} />
                      </Grid>
                    </Grid>
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <FormTextField fieldName="Extended ID" fieldPath="standardItemIdentification.extendedID.value" memberOfClass="ItemIdentification" formikProps={formik} />
                      </Grid>
                    </Grid>
                  </InputWrapper>
                </Grid>
              </Grid>
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard fieldName="Classified tax category" memberOfClass="Item">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <InputWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormTextField fieldName="ID" fieldPath="classifiedTaxCategory[0].iD.value" memberOfClass="TaxCategory" formikProps={formik} />
                      </Grid>
                      <Grid item xs={6}>
                        <FormTextField fieldName="ListID" fieldPath="classifiedTaxCategory[0].iD.listID" memberOfClass="CodeType" formikProps={formik} disabled />
                      </Grid>
                    </Grid>
                  </InputWrapper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputWrapper>
                    <FormTextField fieldName="Percent" fieldPath="classifiedTaxCategory[0].percent.value" memberOfClass="TaxCategory" formikProps={formik} />
                  </InputWrapper>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <InputWrapper>
                    <FormTextField fieldName="Tax scheme" fieldPath="classifiedTaxCategory[0].taxScheme.iD.value" memberOfClass="TaxCategory" formikProps={formik} disabled />
                  </InputWrapper>
                </Grid>
              </Grid>
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <FormCard fieldName="Transaction conditions" memberOfClass="Item">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <InputWrapper>
                    <FormTextField fieldName="Action code" fieldPath="transactionConditions[0].actionCode.value" memberOfClass="TransactionConditions" formikProps={formik} />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputWrapper>
                    <FormTextField fieldName="Description" fieldPath="transactionConditions[0].description[0].value" memberOfClass="TransactionConditions" formikProps={formik} />
                  </InputWrapper>
                </Grid>
              </Grid>
            </FormCard>
          </Grid>
          <Grid item xs={12}>
            <ItemSpecificationDocumentReferenceForm formikProps={formik}/>
          </Grid>
          <Grid item xs={12}>
            <ItemCommodityClassificationForm formikProps={formik}/>
          </Grid>
          
          <Grid item xs={12}>
            <HazardousItemForm formikProps={formik}/>
          </Grid>
          <Grid item xs={12}>
            <ItemPropertyForm formikProps={formik}/>
          </Grid>
          <Grid item xs={12}>
            <ItemInstanceForm formikProps={formik}/>
          </Grid>
          <Grid item xs={12}>
            <ItemCertificateForm formikProps={formik}/>
          </Grid>
          <Grid item xs={12}>
            <ItemDimensionForm formikProps={formik}/>
          </Grid>
          <Grid item component={Box} xs={12} sx={{display: "flex", width: "100%", justifyContent: "space-between", marginY: tokens.spacing.m}}>
            <Button
              variant="outlined"
              onClick={() => history.goBack()}
            >
              Takaisin
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}

export default CatalogueProductItemView;