import { Description, Title } from "../utils/tooltipUtil";

export class Share {
    @Title("Tunnus")
    @Description("Autopaikan tai muut tilan tunnus. Esimerkiksi \"AP1\".")
    Code?: string;
    @Title("Osakeryhmätunnus")
    @Description("Osakeryhmän yksilöivä tunnus. Yksilöi koko aineistossa. Muodoltaan tunnus on 16 merkkiä pitkä. Se sisältää 14 aakkosnumeerista merkkiä (kaksi ensimmäistä tunnuksen muodostaja KR =kaupparekisteri tai OH = osakehuoneistorekisteri) ja lopussa kaksinumeroisen tarkistusnumeron, esimerkiksi KRD7AR4QP2NRHP97.")
    ShareGroupCode?: string;
    @Title("Osakkeet")
    @Description("Osakkeet")
    Shares?: string;
    @Title("Asuntomuoto")
    @Description("Asuntomuoto")
    Type?: string;
    @Title("Huoneistonumero")
    @Description("Huoneistonumero")
    Unit?: string;
    @Title("Lisätiedot")
    @Description("Lisätiedot")
    Specifier?: string;
    @Title("Nimi")
    @Description("Huoneiston nimi")
    Name?: string;
    @Title("Osakejako")
    @Description("Osakejako")
    Share?: string;
}