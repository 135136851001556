import { Document } from "./document";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export class BuildingDataModel extends Base {
    @Title("Dokumentti")
    @Description("Liittyvät dokumentit ja asiakirjat")
    Document: Document = new Document();
    @Title("Rakennuksen tietomallin laji")
    @Description("BIM-tietomallin tyyppi.\nEsim. suunnittelumalli, toteumamalli")
    BuildingDataModelType: string = "";
}