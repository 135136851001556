import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GeneralTableView from '../../../utils/GeneralTableView';
import { BuildingPlan } from "../../../model/buildingPlan/buildingPlan";

export class TaloyhtionAlueenKaavatiedot {
  constructor(buildingPlan?: BuildingPlan) {
    this.buildingPlan = buildingPlan;
  }
  buildingPlan?: BuildingPlan;
  abstraktiMaankayttoasia: any;
  geometriaArvo: any;
}

interface Props {
  title: string,
  item?: TaloyhtionAlueenKaavatiedot
}

export const TaloyhtionAlueenKaavatiedotView: React.FC<Props> = ({title, item}: Props) => {
  return (
    <Accordion key={`taloyhtionalueenkaavatiedot`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
      <Typography><b>{title}</b></Typography>
      </AccordionSummary>
      <AccordionDetails>
        { item ? 
          <Box sx={{ flexGrow: 1 }}>
            <Grid container xs={12} md={12}>
              <Grid container xs={12} md={12} key={"kaavakohde"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"Kaavan sisältyvä paikkatietokohde, johon voi kohdistua kaavamääräyksiä tai kaavasuosituksia"}>    
                    <b>{"Kaavakohde"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.buildingPlan?.Target} filter={["ValidyStart", "ValidyEnd"]} flatView = {true} indentation = {10}></GeneralTableView>
              </Grid>
              <Grid container xs={12} md={12} key={"kaavamaarays"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"kohteeseen liitetty määräys"}>    
                    <b style={{paddingLeft: `10px`}}>{"Kaavamääräys"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.buildingPlan?.Target?.PlanRegulation} filter={["RegulationType", "LifeCycleState", "AdditionalInfo"]} flatView = {true} indentation = {20}></GeneralTableView>
              </Grid>
              <Grid container xs={12} md={12} key={"kaavasuositus"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"kaavamääräyskohteen alueeseen kohdistuva, ei-velvoittava suositus, joka ilmentää esimerkiksi toteutuksen tapaa ja tavoitetta."}>    
                    <b style={{paddingLeft: `10px`}}>{"Kaavasuositus"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.buildingPlan?.Target?.PlanRecommandation} filter={["LifeCycleState", "PlanTheme"]} flatView = {true} indentation = {20}></GeneralTableView>
              </Grid>
              <Grid container xs={12} md={12} key={"abstrakti-maankayttoasia"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"Abstrakti maankäyttöasia"}>    
                    <b style={{paddingLeft: `10px`}}>{"Abstrakti maankäyttöasia"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.abstraktiMaankayttoasia} filter={[]} flatView = {true} indentation = {20}></GeneralTableView>
              </Grid>
              <Grid container xs={12} md={12} key={"geometri-arvo"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"Geometria-arvo"}>    
                    <b style={{paddingLeft: `10px`}}>{"Geometria-arvo"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.geometriaArvo} filter={[]} flatView = {true} indentation = {20}></GeneralTableView>
              </Grid>
            </Grid>
          </Box>
        : <div>Ei tietoja saatavilla</div>}
      </AccordionDetails>
    </Accordion>
  );
}