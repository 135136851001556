/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Accordion, AccordionDetails, Box, Card, CardContent, CardHeader, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect } from "react";
import { buildingAppData } from '../../../App';
import { OwnerAddress } from '../../../model/ownerAddress';
import EnergiaView from '../energia/energiaView';
import HuoneistoView from '../huoneisto/huoneistoView';
import KiinteistoView from '../kiinteisto/kiinteistoView';
import KuntaView from '../kunta/kuntaView';
import OsoiteView from '../osoite/osoiteView';
import RhrView from '../rhr/rhrView';
import ThreeDView from './threeDView';
import AssetView from '../vero/assetView';
import RytjView from '../rytj/rytjView';
import { Feature, KtkmBuildingApiResponse } from '../../../model/KtmkBuildingApiModels';
import { oskariDrawFeatures } from '../../../utils/oskariHelper';
import { TabPanel } from '../../../utils/tabPanel';

interface Props {
  data: buildingAppData,
  id?: string
}


export default function BuildingInfo(props: Props) {

  const [data, setData] = React.useState(props.data);
  //const [selectedValue, setBuildingId] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [value, setValue] = React.useState(0);

  // TODO is it loading oskari rpc twice? also areaview have this..
  const [channel, setChannel] = React.useState<any>(undefined);
  //const [initOskari, setInitOskari] = React.useState<boolean>(true);
  const [scriptLoaded, setScriptLoaded] = React.useState<boolean>(true);
  const [buildingFeature, setBuildingFeature] = React.useState<Feature[]|null>(null);

  useEffect(() => {

    const getLocation = (): string => {
      var a = props.data.address as OwnerAddress;
      if (a === undefined || a === null) {
        return "";
      }

      var town = a.PostalDistrict

      var addressParts = a.Address.split(" ");

      if (addressParts.length > 1) {
        if (addressParts[1].indexOf('-') >= 0) {
          var temp = addressParts[1].split('-');
          addressParts[1] = temp[temp.length - 1];
        }

        var retreturn = addressParts[0] + "_" + addressParts[1] + "_" + town;
        retreturn = retreturn.replace('´', '\'');
        return retreturn;
      }


      return "";
    };

    setData(props.data);
    setAddress(getLocation());

    //setBuildingId(props.data.selectedValue);
    //setAddress(getLocation());
  }, [props.data]);

  useEffect(() => {
    
    if (scriptLoaded && data.selectedValue) {
      let VTJprt = "";
      // sample data is not perfect, so fake some values to get building from the correct area.. 
      switch(data.selectedValue) {
        case "100173167F": VTJprt = "101462466S"; break;    
        case "1018388849": VTJprt = "101845004P"; break;
        case "101848155B": VTJprt = "1018450506"; break;
        case "102500030K": VTJprt = "103677081Y"; break;
        case "102906977W": VTJprt = "103518667U"; break; 
        case "106003070V": VTJprt = "1036766214"; break;      
        default:
          VTJprt = data.selectedValue;
          break;
      }
      
      fetch("https://kaavatietomalli.azurewebsites.net/api/KtmkGetBuildingItem?VTJprt="+VTJprt).then(resp => resp.json() as Promise<KtkmBuildingApiResponse>).then(result => {
        if (result && result.features) {
          setBuildingFeature(result.features);
        }
      });
    }
  }, [data.selectedValue]);

  useEffect(() => {

    if (channel && buildingFeature) {
      //if (data.selectedValue === buildingFeature.properties.VTJprt) {
        oskariDrawFeatures(channel, "rakennus", "Rakennus: " + data.selectedValue, buildingFeature);

        if (buildingFeature.length > 0) {
          // quick calculate avarage geometry point
          try {
            let sumX = 0;
            let sumY = 0;
            let count = 0;
            for (var coords of buildingFeature[0].geometry.coordinates[0][0]) {
              sumX += coords[0];
              sumY += coords[1];
              count++;
            }
            if (count) {
              const x = sumX / count;
              const y = sumY / count;
              const zoomLevel = 13;
              channel.postRequest('MapMoveRequest', [x, y, zoomLevel]);
            }          
          }
          catch
          {
            // could not find the coordinate
          }
          
        }
    }
    
  }, [channel, buildingFeature, scriptLoaded])

  // On component mount, no depencies
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://oskari.org/js/rpc/rpc-client.min.js";
    script.async = true;
    script.onload = () => onScriptLoaded();
    document.body.appendChild(script);
    console.log(`Oskari script added`);
  }, []);
  
  const onScriptLoaded = () => {
    const IFRAME_DOMAIN = 'https://kartta.paikkatietoikkuna.fi';
    const iFrame = document.getElementById('publishedMap');
    console.log("OskariRPC version: " + (window as any).OskariRPC.VERSION);
    const channel = (window as any).OskariRPC.connect(
        iFrame,
        IFRAME_DOMAIN
    );

    // if (initOskari) {
    //   // Register event listeners only once
    //   registerEventListeners(channel);
    //   setInitOskari(false);
    // }
    
    setChannel(channel);
    setScriptLoaded(true);
  }

  // TODO poista nämä viritykset...
  const addrHTML = '<div class="mapouter"><div class="map_canvas"><iframe width="100%" height="600" id="gmap_canvas" src="https://hkp.maanmittauslaitos.fi/hkp/published/fi/dec9d7bb-ce5a-4840-8528-74268034c134?address=ADDRESS_FIELD" /></div></div>';

  const building3DView = '<div style="text-align: center"><iframe style="width:530px; margin: auto; border: none" overflow: hidden; margin: auto; height="500" id="gmap_canvas" src="https://omarakennus3dviewer.azurewebsites.net/?BUILDINGID" /></dic>';


  const handleChange = (event: React.ChangeEvent<{}>, newValue: any): void => {
    setValue(newValue as number);
  };


  return (
    <Paper square style={{ flexGrow: 1}}>
      <Typography variant="h1">Rakennuksen tiedot</Typography>

      <Box sx={{ borderColor: 'divider' }} mb={4}>
        <Tabs value={value} onChange={handleChange} textColor="primary" TabIndicatorProps={{ style: { height: "4px" }, "color": "primary" }} variant="scrollable" scrollButtons="auto" aria-label="sub page tabs">
          <Tab label="Perustiedot" id="simple-tab-0" />
          <Tab label="RYTJ-tiedot" id="simple-tab-1"  />
          <Tab label="RH-tiedot" id="simple-tab-2"  />
          <Tab label="Huoneistotiedot" id="simple-tab-3" />
          <Tab label="Kiinteistötiedot" id="simple-tab-4"  />
          <Tab label="Verotiedot" id="simple-tab-5"  />
          <Tab label="Kunnan tiedot" id="simple-tab-6"  />
          <Tab label="Energiatodistus" id="simple-tab-7"  />
          <Tab label="Rakennuksen suojelutiedot" id="simple-tab-8" />
          {/* <Tab label="Muut" {...a11yProps(9)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} prefix="simple-tab-" index={0}>
        <Typography variant="h2">Perustiedot</Typography>
        <br/>
        <Typography variant="h5">Kartta</Typography>
        <Grid container spacing={4}>
          <Grid item xs={6} md={7} lg={8}>
            
            <iframe id="publishedMap" title="map" style={{height:700, width:"100%", boxSizing: "border-box", border: "1px solid #ccc", marginBottom: "8px"}}src={ "https://kartta.paikkatietoikkuna.fi/published/fi/c95e69fb-a3ef-421d-bf7f-5153bc427255" } allow="geolocation"></iframe>         
          </Grid>
          <Grid item xs={6} md={5} lg={4}>            
            
            <Card variant="elevation" elevation={3}>
              <CardHeader title="Osoitetiedot"></CardHeader>
              <CardContent style={{maxHeight: "600px", overflowY: "auto"}}>
                {!data.address ? 
                  <div>Ei osoitetietoja saatavilla</div>
                  :
                  <OsoiteView data={data.address} />
                }
              </CardContent>
            </Card>
            
          </Grid>
        </Grid>


        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>

            <Typography variant='h5'>Rakennuksen suunnitelmamalli</Typography>
            <br />
            {!address ?
              <div>Ei valittua rakennusta</div>
              :
              <Card>
                <CardContent>
                  <ThreeDView data={address} />
                </CardContent>
              </Card>
            }

          </Grid>

          <Grid item xs={12} md={8}>
            <Typography variant='h5'>Laserkeilakuva 2020</Typography>
            <Typography>Maamittauslaitoksen kuva todellisesta tilanteesta</Typography>
            {data.selectedValue === undefined || data.selectedValue === "" ?
              <div>Ei valittua rakennusta</div> :
              <Card>
                <CardContent>
                  <div dangerouslySetInnerHTML={{ __html: building3DView.replace('BUILDINGID', data.selectedValue) }} />
                </CardContent>
              </Card>

            }

          </Grid>
        </Grid>


      </TabPanel>
      <TabPanel value={value} prefix="simple-tab-" index={1}>
        <Box mb={2} >
          <Typography variant="h2">RYTJ-tiedot</Typography>
        </Box>
        <RytjView rakennus={data.rakennus}></RytjView>
        
      </TabPanel>
      <TabPanel value={value} prefix="simple-tab-" index={2}>
        <Box mb={2} >
          <Typography variant="h2">RH-tiedot</Typography>
        </Box>
        <RhrView data={data.buildingData} />
       
      </TabPanel>
      <TabPanel value={value} prefix="simple-tab-" index={3}>

        <Grid item xs={12} md={12}>
          <Card title="Huoneistotiedot">
            <HuoneistoView data={data.buildingData} yhtio={data.huoneistoData} />
          </Card>
        </Grid>

      </TabPanel>
      <TabPanel value={value} prefix="simple-tab-" index={4}>

        <KiinteistoView data={data.property} />

      </TabPanel>
      {/* <TabPanel value={value} index={3}>

        <Grid item xs={12} md={12}>
          <Card title="Rakennuslupatiedot">
            <ConstructionCaseView data={data.buildingCase} />
          </Card>
        </Grid>


      </TabPanel> */}
      <TabPanel value={value} prefix="simple-tab-" index={5}>


        <Grid item xs={12} md={12}>
          <Card title="Verotiedot">
            <AssetView data={data.taxAdministrationBuilding} />
          </Card>
        </Grid>


      </TabPanel>
      <TabPanel value={value} prefix="simple-tab-" index={6}>

        <Grid item xs={12} md={12}>
          <Card title="Kunnan tiedot">
            <KuntaView data={data.buildingData} />
          </Card>
        </Grid>

      </TabPanel>
      <TabPanel value={value} prefix="simple-tab-" index={7}>
        <Box mb={2} >
          <Typography variant="h2">Energiatodistus</Typography>
        </Box>
        <EnergiaView data={data.energyData} />
        {/* <Grid item xs={12} md={12}>
          <Card title="Rakennuksen energiatiedot">
            
          </Card>
        </Grid> */}

        {/* <Grid item xs={12} md={12}>
          <Card title="Rakennuksen hiilijalanjälki">
            <Accordion expanded={true}>
              <AccordionDetails>
                <Typography>Placeholder hankkeelle</Typography>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid> */}

      </TabPanel>
      <TabPanel value={value} prefix="simple-tab-" index={8}>

        <Grid item xs={12} md={12}>
          <Card title="Rakennuksen suojelutiedot">
            <Accordion expanded={true}>
              <AccordionDetails>
                <Typography>
                  <ul style={{ marginLeft: 20 }}>
                    <li>Onko rakennus suojeltu, jos on niin miten</li>
                    <li></li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid>

      </TabPanel>
    </Paper>
  );
}
