import { Title, Description } from '../../utils/tooltipUtil';
import { Rakennusosa } from './rakennusOsa';
import { TarkastusJaHuolto } from './tarkastusJaHuoltokierros';

export class Rakenne {
    @Title("Nimi")
    @Description("ainutkertaisen tarkoitteen nimitys")
    rakennetyyppi: string = "";

    @Title("Toimenpiteen nimi")
    @Description("Toimenpiteen nimi")
    toimenpiteenNimi: string = "";
    
    @Title("Vuosi")
    @Description("Toimenpiteen vuosi")
    toimenpideVuosi: number = 0;
    
    @Title("Kustannukset")
    @Description("Toimenpiteen kustannukset")
    kustannukset: string = "";
    
    @Title("Peruste")
    @Description("Peruste")
    peruste: string = "";

    rakennusosa: Rakennusosa = Rakennusosa.NotSet;
    
    tarkastusJaHuoltovali: TarkastusJaHuolto = new TarkastusJaHuolto();
}