/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class ApiFrameworksView extends React.Component<any> {
  constructor(props: any, data : any) {
      super(props);
      this.state = props.data;
  }
  render() {

      return (
        <div>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
               <Typography>
                  <b>Käytetyt tietomallit</b>
               </Typography>
               </AccordionSummary>
               <AccordionDetails>
                <div>
                  <a href="https://tietomallit.suomi.fi/model/vtjrak/" target="__top">VTJ Rakennustietomalli</a><br/>
                  <a href="https://tietomallit.suomi.fi/model/kive/" target="__top">Kiinteistöt ja rakennukset kiinteistöverotuksessa</a><br/>
                  <a href="https://tietomallit.suomi.fi/model/huontest/" target="__top">Huoneistotiedot OmaRakennus-kokeiluprojektissa</a><br/>
                  <a href="https://tietomallit.suomi.fi/model/kiintest/" target="__top">Kiinteistötiedot OmaRakennus-kokeiluprojektissa</a><br/>
                  <a href="https://tietomallit.suomi.fi/model/ostieto/" target="__top">Osoitetietojärjestelmän osoitetieto</a><br/>
                  <a href="https://tietomallit.suomi.fi/model/enetod/" target="__top">Energiatodistus</a>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
               <Typography>
                  <b>Rakennus- ja huoneistorekisterin API</b>
               </Typography>
               </AccordionSummary>
               <AccordionDetails>
                <div>
                  <table><tbody>
                    <tr><td width="400">Endpoint</td><td><a href="https://rhrapibackend.azurewebsites.net/api"><code>https://rhrapibackend.azurewebsites.net/api</code></a></td></tr>
                    <tr><td>Hae rakennuslista</td><td><b>GET</b> <code>/buildings</code></td></tr>
                    <tr><td>Hae rakennustiedot</td><td><b>GET</b> <code>/buildings/[tunnus]</code></td></tr>
                    <tr><td>Hae henkilö/yritys tunnuksella</td><td><b>GET</b> <code>/instance/[tahon ID]</code></td></tr>
                    <tr><td>Hae rakennuksen omistava henkilö</td><td><b>GET</b> <code>/buildings/[tunnus]/person</code></td></tr>
                    <tr><td>Hae rakennuksen omistava yritys</td><td><b>GET</b> <code>/buildings/[tunnus]/company</code></td></tr>
                    <tr><td>Hae kiinteistön varustustiedot</td><td><b>GET</b> <code>/buildings/[tunnus]/equipment</code></td></tr>
                    <tr><td>Hae energian perustiedot</td><td><b>GET</b> <code>/buildings/[tunnus]/EnergyBasics</code></td></tr>
                    <tr><td>Hae energian lisätiedot</td><td><b>GET</b> <code>/buildings/[tunnus]/EnergyInitialData</code></td></tr>
                    <tr><td>Hae huoneiston varustustiedot</td><td><b>GET</b> <code>/buildings/[tunnus]/unitequipment</code></td></tr>
                  </tbody></table>  
                  <i>
                    <br/>[tunnus] = pysyvä rakennustunnus
                    <br/>[tahon ID] = Henkilön tai yrityksen ID (uuid)
                  </i>         
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
               <Typography>
                  <b>Energiatodistus API</b>
               </Typography>
               </AccordionSummary>
               <AccordionDetails>
                <div>
                  <table><tbody>
                    <tr><td width="400">Endpoint</td><td><a href="https://energiatodistus.azurewebsites.net/"><code>https://energiatodistus.azurewebsites.net</code></a></td></tr>
                    <tr><td>Hae energiatiedot</td><td><b>GET</b> <code>/api/Rakennukset/[tunnus]/EnergyBasics</code></td></tr>
                    <tr><td>Hae energialuokituksen perusteina käytetyt mittaustiedot</td><td><b>GET</b> <code>/api/Rakennukset/[tunnus]/EnergyInitialData</code></td></tr>
                  </tbody></table>  
                  <i>
                    <br/>[tunnus] = pysyvä rakennustunnus
                  </i>         
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
               <Typography>
                  <b>Verohallinnon rakennustiedot API</b>
               </Typography>
               </AccordionSummary>
               <AccordionDetails>
                <div>
                  <table><tbody>
                    <tr><td width="400">Endpoint</td><td><a href="https://omarakennusbackend.azurewebsites.net/"><code>https://omarakennusbackend.azurewebsites.net</code></a></td></tr>
                    <tr><td>Hae rakennustiedot</td><td><b>GET</b> <code>/api/Rakennukset/[tunnus]/TaxAdministrationInfo</code></td></tr>
                  </tbody></table>  
                  <i>
                    <br/>[tunnus] = pysyvä rakennustunnus
                  </i>         
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
               <Typography>
                  <b>MLL paikkatieto -API</b>
               </Typography>
               </AccordionSummary>
               <AccordionDetails>
                <div>
                  <table><tbody>
                    <tr><td width="400">Endpoint</td><td><a href="https://beta-paikkatieto.maanmittauslaitos.fi"><code>https://beta-paikkatieto.maanmittauslaitos.fi</code></a></td></tr>
                    <tr><td>Hae paikkatieto</td><td>GET <code>/address-kky/features/v1/collections/address/items?VTJprt=[tunnus]</code></td></tr>
                  </tbody></table>
                  <br/><i>[tunnus] = pysyvä rakennustunnus</i>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
               <Typography>
                  <b>Kiinteistötietojärjestelmän API</b>
               </Typography>
               </AccordionSummary>
               <AccordionDetails>
                <div>
                  <table><tbody>
                    <tr><td width="400">Endpoint</td><td><a href="https://ktjapifunction.azurewebsites.net/api"><code>https://ktjapifunction.azurewebsites.net/api</code></a></td></tr>
                    <tr><td>Hae kiinteistölistaus</td><td>GET <code>/Kiinteistot</code></td></tr>
                    <tr><td>Hae kiinteistö kiinteistötunnuksella</td><td>GET <code>/Kiinteisto/[kiinteistötunnus]</code></td></tr>
                    <tr><td>Hae kiinteistö pysyvällä rakennustunnuksella</td><td>GET <code>/Kiinteisto/[rakennustunnus]</code></td></tr>
                  </tbody></table>
                </div>
              </AccordionDetails>
            </Accordion>            
        </div>
      );
  }
}

export default ApiFrameworksView;
