/** @jsx jsx */
import {jsx} from '@emotion/core'
import { Button } from '@material-ui/core';
import React from "react";
import Modal from 'react-modal';
import icon from '../../icons/building.png';

interface Props {
  data: string
}

class ThreeDView extends React.Component<Props, {visible: boolean, address: string}> {
  constructor(props: Props) {
      super(props);
      this.state = {
        visible: false,
        address: props.data
      };
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {
      data: nextProps.data
    };
  }

  render() {
      return (
        <div style={{display: 'flex',  justifyContent:'center'}}>
           {this.state.address === '' ?
              <div>Ei valittua rakennusta</div>:
              <div>
              <Modal isOpen={this.state.visible} style={{
                content: {
                  top: '0%',
                  margin: '0 auto',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'white'
                }
              }} contentLabel="Ladataan...">
                <div dangerouslySetInnerHTML={{__html: '<iframe width=1200 height=800 src="https://omarakennus.azurewebsites.net/xeokit.html?projectId=test" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0"></iframe>'}} />
                <Button onClick={() => this.setState({visible: false})} style={{ fontSize: 28 }}>Sulje</Button>
              </Modal>
              <img alt="" onClick={() => this.setState({visible: true})} src={icon} />
              </div>
            }
        </div>
      );
  }
}

export default ThreeDView;
