import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export enum ExceptionTypeEnum {
    _01 = 1,
    _02 = 2,
    _03 = 3,
    _04 = 4,
    _05 = 5,
    _06 = 6,
    _07 = 7,
    _80 = 8,
    _90 = 9,
    _10 = 10,
    _11 = 11,
    _12 = 12,
    _13 = 13
}
export class RequestedException extends Base {
    @Title("Poikkeamisen laji")
    @Description("Velvoittava määräys, josta luvan yhteydessä haetaan poikkeamiselle lupaa.")
    ExceptionType: ExceptionTypeEnum = ExceptionTypeEnum._01;
    @Title("Poikkeamisen sisältö")
    @Description("Teksti, jolla kuvataan, miten määräyksestä poiketaan.")
    ExceptionContent: string = "";
}
