/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from "react"
import { TooltippedText } from './tooltippedText';
import Tooltip from '@material-ui/core/Tooltip';
import check from'../components/icons/checked.png';
import uncheck from'../components/icons/unchecked.png';
import GeneralTableView from './GeneralTableView';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  data: TooltippedText,
  filter?: any[],
  flatView?: boolean,
  indentation?: number,
  bold?: true
}

// const log = (item: any) => {
//   console.log("TooltippedItem item: ", item);
//   return true;
// }

export default class TooltippedItem extends React.Component<Props, any> {
  constructor(props: Props, data : any) {
    super(props);
    this.state = {
      data: props.data,
      filter: props.filter,
      flatView: props.flatView || false,
      indentation: props.indentation || 0,
      bold: props.bold
    }
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {
      data: nextProps.data,
      flatView: nextProps.flatView || false,
      indentation: nextProps.indentation || 0
    }
  }

  render() {
    var value = this.state.data.Value;
    var tooltip = this.state.data.Tooltip;
    var objectTooltip = this.state.data.ObjectTooltip;

    var type = typeof value;

    if (tooltip === undefined || tooltip.length === 0) {
      return (this.state.bold ? <span>{value}</span> : <span><b>{value}</b></span>);
    }

    if (type === "string") {

      if (value !== undefined && String(value).indexOf('T00:00:00') >= 0){
      // Hirveä purkkaviritys, pitäis tietty jo aikaisemmin parsia dateksi ja formatoida oikein, älkää kukaan plagioiko tätä mihinkään :D
      var d = new Date(String(value));
      return (
        <Tooltip placement="top" title={tooltip}>  
        <span>{d.toLocaleDateString()}</span>
        </Tooltip>
      );
      }
      else 
      {
        
        
        return (
          <Tooltip placement="top" title={tooltip}>    
          <span css={{fontWeight: (this.state.bold ? 600 : 400), paddingLeft: `${this.state.indentation}px`}}>{value}</span>
          </Tooltip>);
      }
    }
    else if (type === "boolean") {
    return (
          <Tooltip placement="top" title={tooltip}>    
          <span>{value ? 
          "Kyllä"
          // <img alt="checked" src={check} />
          : 
          "Ei"
          // <img alt="unchecked" src={uncheck} />
          
          }</span>
          </Tooltip>
    );
    }
    else if (type === "number") {
      return (
            <Tooltip placement="top" title={tooltip}>    
            <span>{value}</span>
            </Tooltip>
      );
      }
    else if (value instanceof Date){
        return (
          <Tooltip placement="top" title={tooltip}>    
          <span>{value.toLocaleDateString()}</span>
          </Tooltip>
    );
    }
    else if (value instanceof Number){
      return (
        <Tooltip placement="top" title={tooltip}>    
        <span>{value.toLocaleString()}</span>
        </Tooltip>
    );
    }
    else if (value instanceof Array){
          return(
            value.map(a => { return <TooltippedItem data = {a} filter={this.state.filter} flatView={this.state.flatView} indentation={this.state.indentation}/>})
          );
        }
    else if (value === null || value === undefined) {
      return (<span></span>);
    }
    else if (this.state.flatView) {
      return (
        <Grid container>
          <Grid item xs={12} md={12}>
            <Tooltip placement="top" title={tooltip}>    
              <b css={{paddingLeft: `${this.state.indentation}px`}}>{objectTooltip as string}</b>
            </Tooltip>
          </Grid>
          <GeneralTableView data={this.state.data.Value} filter={this.state.filter} flatView = {true} indentation = {this.state.indentation + 10}></GeneralTableView>
        </Grid>
      );
    }
      return (
          <Accordion>
              <Tooltip placement="top" title={tooltip}> 
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                <Typography><b>{objectTooltip as string}</b></Typography>
                </AccordionSummary>
              </Tooltip>
              <AccordionDetails>
              <GeneralTableView data={this.state.data.Value} filter={this.state.filter}></GeneralTableView>
              </AccordionDetails>
          </Accordion>   
    );
  }
}
