import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';
import { PlanRecommendation } from "./planRecommendation";
import { PlanRegulation } from "./planRegulation";

export class BuildingPlanTarget extends Base {
    
    @Title("Voimassaoloaika, alku")
    @Description("aikaväli, jona kaavakohde ja sen kaavamääräykset ovat lainvoimaisia, ellei yksittäisten kaavamääräysten voimassaoloa ole erikseen rajattu.")
    ValidyStart: Date | undefined;

    @Title("Voimassaoloaika, loppu")
    @Description("aikaväli, jona kaavakohde ja sen kaavamääräykset ovat lainvoimaisia, ellei yksittäisten kaavamääräysten voimassaoloa ole erikseen rajattu.")
    ValidyEnd: Date | undefined;

    @Title("Kaavamääräys")
    @Description("kohteeseen liitetty määräys")
    PlanRegulation: PlanRegulation = new PlanRegulation();

    @Title("Kaavasuositus")
    @Description("kaavamääräyskohteen alueeseen kohdistuva, ei-velvoittava suositus, joka ilmentää esimerkiksi toteutuksen tapaa ja tavoitetta.")
    PlanRecommandation: PlanRecommendation = new PlanRecommendation();


    public Clone(data: any) {
        super.Clone(data);

        if (data.PlanRegulation !== undefined) {
            var reg = new PlanRegulation();
            reg.Clone(data.PlanRegulation);
            this.PlanRegulation = reg;
        }

        if (data.PlanRecommandation !== undefined) {
            var rec = new PlanRecommendation();
            rec.Clone(data.PlanRecommandation);
            this.PlanRecommandation = rec;
        }
    }
}