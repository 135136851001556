/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Building } from '../../../model';
import BasicInfoView from './basicInfoView';
import GeneralTableView from '../../../utils/GeneralTableView';

interface Props {
  data: Building
}

class RhrView extends React.Component<Props, {data: Building}> {
  constructor(props: Props) {
      super(props);
      this.state = {data: props.data};
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  render() {
    const BuildingView: any = this.state === undefined || this.state.data.BuildingID === undefined
                 ? <div>Ei rakennustietoja saatavilla</div>
                 : <div>
{/* HUONEISTOTIEDOT */}
{
                           this.state.data.UnitSpecifications !== undefined ?

                          //  <Accordion defaultExpanded>
                          //     <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          //         aria-controls="panel1a-content"
                          //         id="panel1a-header">
                          //     <Typography><b>Huoneistotiedot</b></Typography>
                          //     </AccordionSummary>
                          //     <AccordionDetails>
                                <table>
                                {
                                  this.state.data.UnitSpecifications.map(u => 
                              
                                    <tr>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                        <Typography><b>Huoneisto {u.Stair as any + u.Number as any}</b></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <div className='text-column-2'>
                                            <GeneralTableView  data={u}></GeneralTableView>
                                          </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    </tr>
                                        ) 
                                }
                                </table>
                          //     </AccordionDetails>
                          // </Accordion>
                              :
                              <div></div>
                            }


                          {/* RAKENNUSKOHTAISET PERUSTIEDOT */}
                          <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header">
                              <Typography><b>Rakennus- ja huoneistorekisterin tiedot</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails>

                                <div className='text-columns-2'>
                                {/* <Grid container>
                                  <Grid item xs={6} md={6}> */}
                                    <Typography><b>Perustiedot</b></Typography>
                                    < BasicInfoView data={this.state.data}></ BasicInfoView>

                                    <Typography><b>Yksityiskohtaiset tiedot</b></Typography>
                                    <GeneralTableView data={this.state.data}></GeneralTableView>

                                  {/* </Grid>
                                  <Grid item xs={6} md={6}> */}
                                    <br />
                                    <Typography><b>Rakennuskohtaiset tiedot</b></Typography>

                                    <GeneralTableView data={this.state.data.BuildingSpecifications}></GeneralTableView>
       
                                  {/* </Grid>
                                </Grid> */}
                                </div>


                              </AccordionDetails>
                          </Accordion>
                          
                     

                          
                          

                        {/* RAKENNUSLUVAT */}
                        {
                           this.state.data.BuildingPermit !== undefined ?

                           <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header">
                              <Typography><b>Rakennusluvat</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <table>
                                {
                                  this.state.data.BuildingPermit.map(permit => 
                                    <tr>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header">
                                        <Typography><b>Rakennuslupa {permit.PermitID }</b></Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <GeneralTableView  data={permit}></GeneralTableView>
                                        </AccordionDetails>
                                    </Accordion>
                                    </tr>
                                    ) 
                                }
                                </table>
                              </AccordionDetails>
                          </Accordion>
                              :
                              <div></div>
                            }
                          
                          
                    </div>
    return (BuildingView);
  }
}

export default RhrView;
