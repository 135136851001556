/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from "react"
import { Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components"
import { Link } from 'react-router-dom'
import { FlexColTight, panelWithShadowCss } from "./CommonStyles"


const Card: React.FC<Props> = props => {
  return (
    <FlexColTight css={panelWithShadowCss} style={{margin: 10, minHeight: 200}}>
      <Heading.h2 smallScreen>
        {props.title}
      </Heading.h2>

      {props.children}

      { 
        !props.additionalInfo 
          ? null
          : (<div style={{ marginTop: "auto" }}>
              <Link to={props.additionalInfo.path}>
                <span style={{ color: tokens.colors.accentBase }}>&rsaquo; </span>
                  {props.additionalInfo.text}
                </Link>
            </div>)
      }
    </FlexColTight>
  );
};

type Props = {
  title: string,
  additionalInfo?: {
    text: string,
    path: string
  }
}

export default Card;
