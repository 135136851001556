export class Base {
    public Clone(data: any, skipNulls = false) {
        
        if (data === null || data === undefined) {
            return;
        }

        var keys = Object.keys(this);

        if (skipNulls) {
            keys = keys.filter(k => data[k] !== null);
        }

        keys.forEach(
            key => 
            {
                
                if ((this as any)[key] instanceof Base) {
                    ((this as any)[key] as Base).Clone(data[key]);
                }
                else{
                    try {
                    (this as any)[key] = data[key];
                    }
                    catch (err) {
                        console.log("Error while recursively rendering key '" + key + "' :  " + err);
                    }
                }
            });
    }
}