import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { RhrApi } from '../utils/api';
import { Data } from 'react-data-grid-addons';

interface IBuildingListItem {
  id: string;
  permanentId: string;
  year: string;
  usage: string;
}

export interface BuildingPickerProps {
    selectedValue: string;
    onChange: (value?: string) => void;
}

// TODO tee funktio?

class BuildingPicker extends React.Component<any, any> {
  constructor(props: BuildingPickerProps, data : any) {
      super(props);
      this.state = {...props};
  }

  public buildings: IBuildingListItem[] = [];

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //history.push(`../rakennus/${escapeHtml(properties.buildingId)}`);
    this.props.onChange(event.target.value);
  }


  componentDidMount() {
    this.setState({rows: []});
    this.buildings = [];
    
    RhrApi.getBuildings().then((result) => {
      this.buildings = result.map((x: any) => { return { id: x.BuildingID, permanentId: x.PermanentBuildingID, year: x.CompletionYear, usage: x.BuildingUsage } as IBuildingListItem; });
      this.setState({rows: this.buildings});
    })
  }

  render() {

    const getBuildingUsage = (permanentId: string) => {
      switch(permanentId) {
        case "100173167F": return "Omakotitalo";
        case "1018388849": return "Paritalo";
        case "101848155B": return "Kerrostalo";
        case "102500030K": return "Kerrostalo";
        case "102906977W": return "Rivitalo";
        case "106003070V": return "Omakotitalo";
      }
    }

    const getBuildingLocation = (permanentId: string) => {
      switch(permanentId) {
        case "100173167F": return "Valkeakoski";
        case "1018388849": return "Valkeakoski";
        case "101848155B": return "Valkeakoski";
        case "102500030K": return "Espoo";
        case "102906977W": return "Espoo";
        case "106003070V": return "Espoo";
      }
    }

    return (
      <Paper square style={{marginBottom: "10px"}}>
        <Box sx={{ m: 2 }}>
          <FormControl>
            <FormLabel id="building-picker-form" style={{color: "black", fontWeight: "bold"}}>Valitse käytettävä rakennus</FormLabel>
            <RadioGroup 
              onChange={this.handleChange}
              defaultValue="female"
              row
              aria-labelledby="building-picker-form"
              name="row-radio-buttons-group"
              
            >
              {this.buildings.map((building: IBuildingListItem, i) => {
                return <Tooltip key={i} placement='top' classes={{ popper: 'BuildingTooltip'}} title={ 
                            <React.Fragment>
                              <Typography variant='h6'>{building.permanentId}</Typography>
                              <Typography>Rakennusvuosi: {building.year}</Typography>
                              <Typography>Talotyyppi: {getBuildingUsage(building.permanentId)}</Typography>   
                            </React.Fragment>}>
                          <FormControlLabel className='oma-radio-button' style={{ minWidth: "30%" }} key={building.id} value={building.permanentId} control={<Radio color="primary" checked={this.props.selectedValue === building.permanentId} />} label={`${building.permanentId} ${ getBuildingLocation(building.permanentId) }` }  />
                       </Tooltip> 
                
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      </Paper>  
      );
  }
}

export default BuildingPicker;

