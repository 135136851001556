import React from 'react'
import { Link, useRouteMatch } from "react-router-dom"
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components'

const Navigation: React.FC = () => {

  //const [person] = useContext(AuthContext);

  return (
    <nav style={{
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <ul style={{
        display: 'flex',
        listStyle: 'none',
        margin: 0,
        padding: 0,
      }}>
              <li>
                <NavItem to="/rakennus" label="Rakennuksen tiedot" />
              </li>
              <li>
                <NavItem to="/alue" label="Alueidenkäyttö" />
              </li>
              <li>
                <NavItem to="/omistus" label="Omistus" />
              </li>
              <li>
                <NavItem to="/eHuoltoKirja" label="eHuoltokirja" />
              </li>
              <li>
                <NavItem to="/home" label="Omarakennus-demo" />
              </li>
              <li>
                <NavItem to="/rajapinnat" label="API rajapinnat" />
              </li>
      </ul>
    </nav>
  )
}

const NavItem: React.FC<NavItemProps> = ({ to, label }: NavItemProps) => {
  let match = useRouteMatch({
    path: to,
    exact: to === '/'
  });

  return (
    <Link to={to} style={{
      color: tokens.colors.blackBase,
      borderBottom: match ? `4px solid ${tokens.colors.highlightBase}` : `none`,
      lineHeight: 2.5,
      padding: `${tokens.spacing.xxs} 0`,
      marginRight: tokens.spacing.l,
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    }}>
      {label}
    </Link>
  );
};

interface NavItemProps {
  to: string;
  label: string;
}

export default Navigation;
