/** @jsx jsx */
import {jsx} from '@emotion/core'
import { Box, Grid } from '@material-ui/core';
import React from "react";
import { Base } from '../model/base';
import TooltippedItem from './tooltippedItem';
import {GetTitle, GetDescription, GetTitleForObject, } from './tooltipUtil'

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

interface Props {
  data: any,
  filter?: any[],
  suppressDetails?: boolean,
  flatView?: boolean,
  indentation?: number
}

// const log = (msg: string, item: any) => {
//   console.log(`GeneralTableView ${msg}: `, item);
//   return true;
// }

export class GeneralTableView extends React.Component<Props, Props> {
  constructor(props: Props, data : any) {
    super(props);
    this.state = {
      data: props.data,
      filter: props.filter,
      suppressDetails: props.suppressDetails || false,
      flatView: props.flatView || false,
      indentation: props.indentation || 0
    }
  }

  static getDerivedStateFromProps(nextProps: Props) {
    // log("nextProps.data", nextProps.data);
    return {
      data: nextProps.data
    }
  }
  
  private getFilterItem = (itemName: string): any => {
    if (this.state.filter?.includes(itemName)) {
      return this.state.filter?.find(it => it === itemName);
    }
    return this.state.filter?.find(it => !(it instanceof String) && (it as Object).hasOwnProperty(itemName));
  }

  private getSubFilterValues = (filterName: string) => {
    const filter = this.state.filter?.find(it => !(it instanceof String) && (it as Object).hasOwnProperty(filterName));
    return filter ? filter[filterName] : undefined;
  }

  // Renderöi tableen "Title" ja "Description" attribuuteilla täytetyn olion
  render() {
    return (
      this.state.data !== undefined && this.state.data !== null? 
        <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          {/* <div className='text-columns-2'> */}
            {
              Object.getOwnPropertyNames(this.state.data)
              .filter(p => !this.state.filter || this.getFilterItem(p))
              .filter(p => GetTitle(this.state.data, p).Value !== undefined).map((p, i) => 
                {
                  if (this.state.data[p] instanceof Array) {
                    var arrayItems = this.state.data[p] as [];
                    
                    return(
                      <Grid container xs={12} md={12} key={p + i}>
                        <TooltippedItem data = {GetTitle(this.state.data, p)} indentation = {this.state.indentation}/>
                        {
                          arrayItems.map((item: any) => {
                            return (
                              <GeneralTableView 
                                data = {item} 
                                flatView = {this.state.flatView}
                                indentation = {this.state.indentation as number + 10}
                              />)
                          })}
                      </Grid>
                    )
                  }
                  else {
                    return (this.state.data[p] instanceof Base && !this.state.suppressDetails)?
                      <Grid container key={p + i}>
                        <TooltippedItem 
                          data = {GetTitleForObject(this.state.data, p)} 
                          filter={this.getSubFilterValues(p)} 
                          flatView = {this.state.flatView} 
                          indentation = {this.state.indentation}
                        />
                      </Grid>
                    :
                    <Grid container key={p + i}>
                      <Grid item xs={12} md={12}>
                        &nbsp;&nbsp;<TooltippedItem bold data = {GetTitle(this.state.data, p)} indentation = {this.state.indentation}/>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        &nbsp;&nbsp;<TooltippedItem data = {GetDescription(this.state.data, p)} indentation = {this.state.indentation} />
                      </Grid>
                    </Grid>
                  }
                }
              )
            }
          {/* </div> */}
        </Grid>
        </Box>
        :
        <div style={{paddingLeft: this.state.indentation}}>Ei tietoja saatavilla</div>
      );
  }
}

export default GeneralTableView;
