import { Apartment } from "./apartment";
import { ApartmentEquipment } from "./apartmentEquipment";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export class ApartmentRenovation extends Base {
    @Title("Huoneisto")
    @Description("Huoneisto")
    Apartment: Apartment = new Apartment();
    
    @Title("Huoneiston varusteen muutos")
    @Description("Huoneiston varusteen muutos")
    Huoneistonvarusteenmuutos: ApartmentEquipment[] = [];
    
    @Title("Kuvaus")
    @Description("Muutoksen syy tai muu kuvaus")
    Description: string = "";
    
    @Title("Muutoksen laji")
    @Description("Lisäys, muutos, poisto")
    DescriptionType: string = "";

    public Clone(data: any) {
        super.Clone(data);

        if (data.Huoneistonvarusteenmuutos !== undefined && data.Huoneistonvarusteenmuutos !== null  && data.Huoneistonvarusteenmuutos.length > 0) {
            data.Huoneistonvarusteenmuutos.forEach((element : any) => {
                var b = new ApartmentEquipment();
                b.Clone(element);
                this.Huoneistonvarusteenmuutos.push(b);
            });
        }
    }
}