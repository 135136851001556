import { SpawnSyncOptionsWithBufferEncoding } from "child_process";
import { BuildingPermit } from "./model/buildingPermit";
import { BuildingSpecification } from "./model/buildingSpecification";
import { UnitSpecification } from "./model/unitSpecification";
import { Description, Title } from "./utils/tooltipUtil";
import { Share as ShareClass } from "./model/share";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Blablabla.
 */
 export interface GetBuildingsRequest {
    /** blablabla */
    OwnerIdentifier?: string;
  }

  /**
   * Blablabla.
   */
  export interface GetBuildingsResponse {
    /** Jonkinlainen lista rakennuksia. */
    Buildings?: Building[];
  }

  /**
   * Osoitetieto suomalaisen osoitejärjestelmän mukaisesti.
   */
  export interface Address {
    /** katua tai tietä tarkoittava paikannimi */
    kadunnimi?: string;

    /** osoitenumeroon kuuluva numero */
    osoitenumeronnumeroOsa?: string;

    /** kirjain, joka on lisätty kiinteistön osoitenumeroon silloin, kun alkuperäinen kiinteistö on jaettu kahdeksi tai useammaksi erilliseksi kiinteistöksi */
    osoitenumeronjakokirjain?: string;

    /** useamman kuin yhden huoneiston käsittävässä rakennuksessa tai rakennuksissa sijaitsevien huoneistojen tunnuksiin kuuluva kirjain */
    huoneistoTunnuksenKirjainosa?: string;

    /** useamman kuin yhden huoneiston käsittävässä rakennuksessa sijaitsevan huoneiston tunnukseen kuuluva numero */
    huoneistoTunnuksenNumero?: number;

    /** kirjain, joka on lisätty huoneistotunnuksen numero-osaan silloin, kun alkuperäinen huoneisto on jaettu kahdeksi tai useammaksi erilliseksi huoneistoksi */
    huoneistoTunnuksenJakokirjain?: string;

    /** opastava tieto, joka täydentää muita osoitetietoja */
    osoitteenlisamaare?: string;

    /** care of eli jonkun luona */
    co?: string;

    /** osoite, joka ilmaisee postin vastaanottajan vuokraamaa tai postinjakelijan osoittamaa postinjättöpaikkaa */
    postilokeroOsoite?: string;

    /** osoitteen osa, joka viittaa tiettyyn maantieteelliseen alueeseen tai organisaatioon */
    postitoimipaikka?: string;

    /** osoitteen numero-osa, joka viittaa postitoimipaikkaan */
    postinumero?: string;
  }

  /**
   * Varallisuussuhde ilmaisee osapuolen ja kiinteän varallisuuden omistus- tai hallintaoikeuden. Varallisuussuhteita ovat: omistussuhde, hallintaoikeussuhde, muu oikeus (metsänhakkuuoikeus, syytinkisuhde) sekä perintö- ja lahjaverotuksen varallisuuserien hallintaoikeuden suhteet.
   */
  export interface AssetRelation {
    /**
     * Varallisuussuhteen lajin  alkamispäivämäärä. Esim. hallinta- tai omistusoikeuden siirtopäivä.
     * Luovutetun omaisuuden omistusaika (alku- ja loppupäivä) hankintameno-olettaman vähentämisessä.
     * Lahjoituspäivä = asiakkaan ilmoittama lahjan saantopäivä.
     * Omistuksen alkupäivä, yleensä kiinteistönluovutusilmoituksen vahvistamispäivä.
     * Omaisuuden omistusoikeuden siirtymispäivä (pp.kk.vvvv) luovuttajalta luovutuksensaajalle
     * @format date
     */
    startDate: string;

    /** Omistus-, hallinta, vuokra ymv suhteen tieto, että kohde on omistajan tai haltijan asumiskäytössä */
    residentialUse: boolean;

    /** Ostajakohtainen tieto. Tieto saadaan varainsiirtoveroilmoituksen kohdasta Kauppahinta ja muut vastikkeet yhteensä, josta on vähennetty luku kohdasta Osuutesi osakkeisiin kohdistuvasta yhtiölainasta luku tai Osuutesi yhtiölainaosuudesta tai Yhtiölainaosuus. */
    purchasePrice: number;

    /** Hallinta- tai omistusoikeuden tmv. oikeuden osuus varallisuudesta. Tai hankinnan kohteen osuus varallisuuteen.  Osuus ilmoitetaan murtolukuna. */
    shareOfAssetsAsFraction: string;

    /**
     * Tieto kertoo mihin sopimukseen tai tapahtumaan saanto perustuu. Tieto saadaan ostajan varainsiirtoveroilmoitukselta tai ASREK tietovirran perusteella tai muista prosesseista saatavista omistajavaihdostiedoista. Saantolajeja voi olla yksi tai useampi.
     *
     * Saantolajit (laaditaan koodisto):
     * - Kauppa
     * - Erittelemätön perhe- tai perintöoikeudellinen saanto
     * - Vaihtokauppa
     * - Pakkohuutokauppa
     * - Apportti tai muu pääomansijoitus
     * - Perustajaosakas
     * - Muu
     * - Ositus
     * - Erottelu
     * - Perinnönjako
     * - Testamentti (PELA)
     * - Lahja  (PELA)
     * - Perintö  (PELA)
     */
    acquisitionType: string;

    /** Varallisuussuhteita ovat Omistus- ja hallintaoikeus, Hallintaoikeus, Omistusoikeus sekä Muu oikeus. */
    assetRelationType: string;

    /** Varallisuuskohde, joka on lajiltaan kiinteistön maapohja. */
    groundAsset: Ground;

    /** Rakennus, joka lasketaan oikeudenhaltijan varallisuussuhteen mukaiseksi varallisuudeksi. */
    buildingAsset: Building;
  }

  /**
   * Rakennus on yksi osapuolen varallisuuden laji. Se on kiinteä tai paikallaan pidettäväksi tarkoitettu rakennelma, rakenne tai laitos.
   */
   export interface Building {
     /** Rakennuksen tai sen osan pääasiallinen käyttötarkoitus. */
     purposeOfUse?: "Rakennusluokitus";

     /** Rakennuksen pysyvä tunnus. */
     permanentBuildingCode?: string;

     /** paikka, jossa jokin sijaitsee */
     location?: Location;

     /** Kerrosala poikkeaa normaalisti rakennuksen tietona esitettävästä pinta-alasta. Kerrosalaan ei lasketa ullakolla tai kellarissa sijaitsevia, muita kuin pääasiallisen käyttötarkoituksen mukaisia tiloja. */
     floorArea?: number;

     /** Rakennuksen osien yhteenlaskettu tilavuus. */
     totalBuildingVolume: number;

     /** Rakennuksen kaikkien osien yhteenlaskettu kokonaisala. */
     totalBuildingArea: number;

     /** Rakennuksen yksilöivä nimitieto, esimerkiksi asiakkaan rakennukselle antama nimi. */
     nameOfBuilding: string;

    /** Arvostamisen vuoksi rakennus joudutaan tietyissä tilanteissa jakamaan osiin. */
    sectionOfTheBuilding: SectionOfTheBuilding;

    UnitSpecifications: UnitSpecification[];

    BuildingSpecifications: BuildingSpecification;

    BuildingPermit: BuildingPermit[];
  }


  /**
   * Maapohja on osapuolen varallisuuden laji, osa kiinteistöä. Kiinteistö koostuu yhdestä tai useammasta maapohjasta, joihin kiinteistö jaetaan arvostamista varten. Saman kiinteistön eri maapohjiin voidaan soveltaa eri kiinteistöveroprosenttia maapohjan käytön mukaan. Maapohja voi olla myös kiinteistöverosta vapaa.
   */
  export interface Ground {
    /**
     * Päivämäärä, josta lähtien maapohja on olemassa Verohallinnon kiinteistötietokannassa.
     * @format date
     */
    startDate: string;

    /** Maankäyttö- ja rakennuslaissa määritellyn maankäyttösuunnitelman tai -rajoituksen luokittelu. Arvot asemakaava, ranta-asemakaava, ei asemakaavaa. (tässä kokeilussa koodistoksi valittu RYTJ:in kaavalaji-koodisto) */
    zoningPlanType: string
      | "1"
      | "11"
      | "12"
      | "2"
      | "21"
      | "22"
      | "23"
      | "24"
      | "25"
      | "26"
      | "3"
      | "31"
      | "32"
      | "33"
      | "34"
      | "35"
      | "36";

    /** Asema- tai ranta-asemakaavaan merkitty korttelin tai sen osan käyttötarkoitusmerkintä. Verohallinto käyttää Ympäristöministeriön maankäyttö- ja rakennuslain mukaisissa kaavoissa käytettävistä merkinnöistä antaman asetuksen (2000) mukaista luokitusta. Verohallinnon kiinteistötietoihin merkitty kaavan mukainen käyttötarkoitus voi siis poiketa kunnan asemakaavan kaavamerkinnästä, sillä asemakaavassa voidaan käyttää kuntakohtaisia tarkenteita. Asemakaava-alueella sijaitsevaan maapohjaan sovelletava aluehinta määräytyy kaavan mukaisen käyttötarkoituksen perusteella. */
    zoningPlanUse: string;

    /**
     * Päivämäärä, jona maapohjan voimassaolo lakkaa.
     * @format date
     */
    endDate: string;

    /** Maapohjan pinta-ala. */
    area: number;

    /** Kunnalta saatava tieto rakennusoikeudesta (k-m2).  Tieto voi olla maapohjatyypeillä rakennusmaa, muu maa ja 3D-kiinteistö. */
    buildingRight: number;
  }

  /**
   * Kohteen sijainti, joka voidaan esittää mm. koordinaattitietona tai tietyn osoitejärjestelmän osoitteena.
   */
  export interface Location {
    /** sijaintipaikan osoittavien tietojen kokonaisuus */
    address: Address;
  }

  /**
   * Henkilö, jolla on oikeus varallisuuteen, tässä tapauksessa kiinteistöön ja mahdollisiin rakennuksiin. Mitä jos oikeus kohdistuu vain rakennukseen?
   */
  export interface Person {
    /** henkilön yksilöivä tunnus, jonka toimivaltainen viranomainen on antanut henkilölle */
    personalIdentityCode?: string;

    /** henkilön kaikki etunimet lueteltuna */
    givenNames?: string;

    /** sukunimen lisänä käytettävä rekisteröidyn henkilönnimen osa, joka ei viittaa henkilön sukuun */
    givenName?: string;

    /** pääasiallisesti sukuun viittaava rekisteröidyn henkilönnimen osa */
    familyName?: string;

    /** Henkilön varallisuuteen kuuluva kiinteistö. */
    kiinteisto: RealEstateUnit[];
  }

  /**
  * Kiinteistö on yksi osapuolen varallisuuden laji, jolla on omia muista varallisuuden lajeista poikkeavia ominaisuuksia.

  Kiinteistöllä tarkoitetaan kiinteistöverolaissa tonttia, tilaa ja muuta Suomessa olevaa itsenäistä omistuksen yksikköä, joka on merkitty tai olisi merkittävä kiinteistönä kiinteistörekisterilaissa tarkoitettuun kiinteistörekisteriin.
  */
  export interface RealEstateUnit {
    /** Kiinteistötunnus on Maanmittauslaitoksen antama, kiinteistön yksilöivä tunnus, joka muodostuu neliosaisesta numerosarjasta. Verohallinnon tietokannassa kiinteistötunnus on muotoa xxx-xxx-xxxx-xxxx. */
    propertyIdentifier?: string;

    /** Kiinteistölle Maanmittauslaitoksen kiinteistörekisteriin merkitty nimi. */
    nameOfRealEstateUnit?: string;

    /**
     * Kiinteistön alkupäivä Verohallinnon kiinteistötiedoissa. Ensisijaisesti sama päivämäärä, jona kiinteistö on rekisteröity Maanmittauslaitokseen kiinteistörekisteriin.
     * @format date
     */
    startDate: string;

    /** Kiinteistön kaikkien maapohjien ja erottamattomien määräalojen yhteenlaskettu pinta-ala. */
    totalArea: string;

    /**
     * Päivämäärä, josta lähtien kiinteistö lakkaa olemasta aktiivisena Verohallinnon kiinteistötiedoissa. Yleensä MML:n kiiinteistörekisteriin merkitty lakkaamispäivä tai määräaloilla lohkomispäivä.
     * @format date
     */
    endDate: string;

    /** Kunta, jonka alueella kiinteistö sijaitsee. */
    municipalityOfLocation: string;

    /** Varallisuussuhde ilmaisee osapuolen ja kiinteän varallisuuden omistus- tai hallintaoikeuden. Varallisuussuhteita ovat: omistussuhde, hallintaoikeussuhde, muu oikeus (metsänhakkuuoikeus, syytinkisuhde) sekä perintö- ja lahjaverotuksen varallisuuserien hallintaoikeuden suhteet. */
    varallisuussuhde: AssetRelation;

    hallintakohteet: HallintaKohde[];
    rakennustiedot: Rakennustiedot;
  }

  export interface HallintaKohde {
    hallintakohdetyyppi: string;
    paahallintakohde: string;
    huoneistonumero: string;
    jakokirjain: string;
    porras: string;
    lakkaamispvm: string;
    tunnus: string;
    muutospvmOsakehuone: string;
    tietolahde: string;
    poikkeavaHuoneistonumero: string;
    autopaikkatyyppi: string;
    sijaintikerros: string;
    rekisterointipvm: string;
    huoneistotyyppi: string;
    pysyvahuoneistotunnus: string;
    paakayttotarkoitus: string;
    muukayttotarkoitus: string;
    omistaja: string;
  }

  export interface Rakennustiedot {
    sijainti: string;
    valmistumisvuosi: string;
    pysyvarakennustunnus: string;
    huoneistoala: string;
    perusparannuspaivamaara: string;
    kerrosluku: string;
    kerrosala: string;
    rakennustunnus: string;
    kaavatilanne: string;
    kantavienrakenteidenrakennusaine: string;
    aanestysaluenumero: string;
    kellariala: string;
    paaasiallinenlammitystapa: string;
    omistaja: string;
    rakennuksenliittymatiedot: string;
    kiinteisto: string;
    sijaintikunta: string;
    valmistumispaivamaara: string;
    julkisivumateriaali: string;
    kokonaisala: string;
    poikkeamispaatos: string;
    rakennuksentilavuus: string;
    kayttotarkoitus: string;
    paaasiallinenlammitysaine: string;
    perusparannus: string;
    rakennuksenkaytossaolo: string;
  }

  /**
   * List of buildings to be selected from the grid
   */
  export interface Buildings {
    PermanentBuildingID:          string;
    BuildingID:                   string;
    OwnerID:                      string;
    CompletionYear:               number;
  }

  /**
   * Building address details as received from MML API
   */
  interface BuildingAddress {
    featureClass?:                string;
    locationId?:                  string;
    addressId?:                   string;
    version?:                     string;
    sourceId?:                    string;
    startTime?:                   string;
    versionStartTime?:            string;
    versionEndTime?:              string;
    endTime?:                     string;
    dataSource?:                  string;
    sourceModificationTime?:      string;
    verificationDate?:            string;
    updateReason?:                string;
    VTJprt?:                      string;
    propertyIdentifier?:          string;
    validFrom?:                   string;
    validUntil?:                  string;
    nameFI?:                      string;
    nameSV?:                      string;
    nameSE?:                      string;
    number?:                      string;
    municipality?:                string;
    targetType?:                  string;
    priority?:                    string;
  }

  interface BuildingGeometry {
    type:                         string;
    coordinates:                  number[];
  }

  /**
   * Detailed information about the building
   */
  export class Building {
    @Title("Rakennustunnus (pysyvä)")
    @Description("Tunnus, jolla rakennus yksilöidään koko sen elinkaaren ajaksi. Pysyvä rakennustunnus ei sisällä mitään informaatiota rakennuksen sijaintikiinteistöstä tai -kunnasta.")
    PermanentBuildingID:           string = "";
    @Title("Muuttuva rakennustunnus")
    @Description("Rakennuksen yksilöivä tunnus, joka muodostuu sijaintikiinteistön voimassaolevasta kiinteistötunnuksesta ja sen tarkistemerkistä sekä rakennuksen järjestysnumerosta kiinteistöllä.\n\nMuuttuva rakennustunnus on VTJ:n näkökulmasta poistuva tunnus.")
    BuildingID?:                    string;
    OwnerID:                       string = "";
    @Title("Valmistumisvuosi")
    @Description("valmistumisajankohta päivämäärän tarkkuudella ilmaistuna")
    CompletionYear?:                number;
    @Title("Valmistumispäivämäärä")
    @Description("Tieto siitä, minä vuonna rakennus on valmistunut. Tätä tietoa käytetään jos rakennuksen valmistumisajankohtaa ei voida ilmaista päivämäärän tarkkuudella.")
    CompletionDate?:                string;
    @Title("Rakennusluokka 2018")
    @Description("Tilastokeskuksen määrittelemä rakennusluokitus 2018")
    BuildingCategory2018?:         string;
    @Title("Käyttötarkoitus")
    @Description("Rakennuksen pääasiallinen käyttötarkoitus Tilastokeskuksen vahvistaman rakennusluokituksen mukaisesti. (ks. JHS 186)")
    IntendedUse:                   number = 0;
    @Title("Sijaintikunta")
    @Description("kunta, jossa kohde sijaitsee")
    LocationMunicipality?:         string;
    @Title("Äänestysaluenumero")
    @Description("Suomen vaaleissa käytettävä äänestysalueen numero")
    VotingArea:                   number = 0;
    @Title("Kerrosala")
    @Description("kerrosten alat ulkoseinien ulkopinnan mukaan laskettuina sekä se kellarikerroksen tai ullakon ala, johon sijoitetaan tai voidaan näiden tilojen sijainnista, yhteyksistä, koosta, valoisuudesta ja muista ominaisuuksista päätellen sijoittaa rakennuksen pääasiallisen käyttötarkoituksen mukaisia tiloja")
    FloorArea?:                    number;
    @Title("Kaavatilanne")
    @Description("alueella voimassaolevan maankäytön suunnitelman laji")
    AreaPlan:                     number = 0;
    @Title("Huoneistoala")
    @Description("huoneistoa ympäröivien seinien sisäpintojen mukaan laskettu pinta-ala. Pinta-alaan lasketaan mukaan vain se osa, jossa huonekorkeus ylittää 160 cm.")
    LivingArea:                   number = 0;
    @Title("Kokonaisala")
    @Description("kaikkien kerrosten, kellareiden ja lämpöeristettyjen ullakoiden pinta-ala yhteensä ulkomitoin. Kokonaisalaan ei lasketa parvekkeita, katoksia eikä 160 cm:ä matalampia tiloja.")
    TotalArea:                    number = 0;
    @Title("Kellariala")
    @Description("Kellarikerrosten yhteenlaskettu pinta-ala. Kellarikerroksen pinta-ala on kellarikerrosta ympäröivien seinien ulkopintojen rajoittama ala. Mukaan ei lasketa 160 cm:ä matalampia tiloja.\n\nKellarikerroksiksi luetaan kaikki kokonaan tai pääasiallisesti maanpinnan alapuolella olevat kerrokset.")
    BasementArea:                 number = 0;
    @Title("Rakennuksen tilavuus")
    @Description("Rakennuksen tilavuus lasketaan sen tilan perusteella, jota rajoittavat ulkoseinien ulkopinnat, alapohjan alapinta ja yläpohjan yläpinta.")
    BuildingVolume:               string = "";
    @Title("Kerrosluku")
    @Description("Rakennuksen maanpäällisten kerrosten lukumäärä.\n\nKerroslukuun lasketaan kaikki ne kokonaan tai pääasiallisesti maanpinnan yläpuolella olevat kerrokset (=ei kellarikerrokset), joissa on asuin- tai työ (toimi-) tiloja tai rakennuksen pääasiallisen käyttötarkoituksen  mukaisia tiloja.")
    NumberOfFloors:               number = 0;
    @Title("Rakennuksen käytössäolo")
    @Description("Rakennuksen käytön tilanne. Rakennuksen käytössäolotieto päätellään mm. asumistietojen perusteella VTJ:ssä.")
    BuildingUsage:                number = 0;
    @Title("Pääasiallinen lämmitystapa")
    @Description("Rakennuksen tärkein lämmitystapa.")
    MainHeatingMethod:            string = "";
    @Title("Pääasiallinen lämmitysaine")
    @Description("Rakennuksen tärkein polttoaine tai lämmönlähde.")
    MainHeatingAgent:             string = "";
    @Title("Kantavien rakenteiden rakennusaine")
    @Description("Kantavien rakenteiden pääasiallinen rakennusaine.")
    LoadBearingStructureMaterial: string = "";
    @Title("Julkisivumateriaali")
    @Description("Materiaali, josta rakennuksen julkisivu on pääasiallisesti rakennettu.")
    FacadeMaterial:               string = "";
    @Title("Poikkeamispäätöksen syy")
    @Description("Tieto siitä mistä syystä poikkeamislupaa on haettu, käytännössä joko rakennuskielto tai \"muu syy\".")
    DeviationDecisionReason:      string = "";
    @Title("Perusparannus")
    @Description("Tieto siitä, onko rakennus perusparannettu (vai ei).")
    Renovation:                   boolean = false;
    @Title("Perusparannuspäivämäärä")
    @Description("Päivämäärä, jolloin rakennusluvan mukainen perusparannus on valmistunut.")
    RenovationDate:               string = "";

    taxData?:                      taxData;

    Location?: {
      Address?:           BuildingAddress;
      Geometry?:         BuildingGeometry;
    }

    Owner?:  {
      Type:                       boolean;
      Name?:                        string;
      //Details:           Company | Person;
    }

    Shares?:                  ShareClass[];
    Equipments?:               Equipment[];
    UnitEquipments?:       UnitEquipment[];
    RealEstateInfo?:            RealEstate;
  }

  export interface RealEstate {
    PermanentBuildingID:            string;   
    Rekisteriyksikkolaji:           string;  
    Nimi:                           string;                  
    Kiinteistotunnus:               string;      
    RekisterointiPvm:               string;      
    MaapintaAala:                   number | null;          
    VesipintaAla:                   number | null;          
    LakkaamisPvm:                   string | null;          
    Omistaja:                       string | null;              
    Sijainti:                       string | null;                  
  }
  
  export interface Equipment {
    ID:                            string;
    OwnerID:                       string;
    Name:                          string;
    NumVal:                        number;
    FloatVal:                      number;
    StrVal:                        string;
    BoolVal:                       number;
  }

  export interface UnitEquipment {
    Name: string;
    Value: string;
    BoolVal: number;
 }

  export interface Share {
    PermanentBuildingID:           string;
    BuildingID:                    string;
    Code:                          string;
    ShareGroupCode:                string;
    Shares:                        string;
    Type:                          string;
    Unit:                          string;
    Specifier:                     string;
    Name:                          string;
    Share:                         string;
  }

  export interface Company {
    ID:                            string;
    CompanyName:                   string;
    PlaceOfBusiness:               string;
    CompanyForm:                   string;
    Status:                        number;
    State:                         string;
    Code:                          string;
  }

  export interface Person {
    ID:                            string;
    FirstNames:                    string;
    LastName:                      string;
    Birthday:                      string;
    Gender:                        number;
    PrimaryLanguage:               string;
    Nationality:                   string;
    MaritalStatus:                 number;
    ChildCount:                    number;
    HasTrustee:                    number;
    HasProtectionOrder:            number;
    DateOfDeath:                   string;
    Code:                          string;
    HomeMunicipality:              string;
  }

  export interface taxData {
    nameOfRealEstateUnit:          string;
    municipalityOfLocation:        string;
    startDate:                     string;
    endDate:                       string;
    assetsData?:                   string;
  }
  /**
   * Arvostamisen vuoksi rakennus joudutaan tietyissä tilanteissa jakamaan osiin.
   */
  export interface SectionOfTheBuilding {
    /** Päivämäärä, jolloin rakennusta (rakennuksen osaa) on alettu rakentaa. Vanhemmilla rakennuksilla ei ole aloittamispäivää, ainoastaan valmistumisvuosi. */
    startDate: string;

    /** Pysäköintitalo -rakennustyypin ominaisuustieto, yksikkö kpl. */
    numberOfParkingSpaces: number;

    /** Asuinkerrostalo -rakennustyypin ominaisuustieto. Arvot: kyllä/ei. */
    elevator: boolean;

    /** Pientalo (paritalot, rivitalot) ja asuinkerrostalo -rakennustyyppien ominaisuustieto (kpl). */
    numberOfApartments: number;

    /** Kaikkien rakennustyyppien ominaisuustieto. Arvot: Puu, Kivi/metalli. Tieto vaikuttaa sovellettavaan ikäalennusprosenttiin. */
    bearingStructure: string;

    /** Asuinkerrostalo ja myymälärakennus -rakennustyyppien ominaisuustieto. */
    floorHeight: string;

    /** Kerrosten lukumäärä. Asuinkerrostalo, myymälärakennus ja muu rakennus -rakennustyyppien ominaisuustieto (kpl). */
    numberOfFloors: number;

    /** Rakennuksen tai sen osan kokonaisala eli pinta-ala ulkomitoin sisältäen kaikkien kerrosten, kellareiden ja lämpöeristettyjen ullakkohuoneiden pinta-alat. Kokonaisalaan ei lasketa parvekkeita, katoksia eikä tiloja, joissa vapaa korkeus on alle 160 cm. */
    totalArea: string;

    /** Pientalo -rakennustyypin ominaisuustieto. Arvot keskuslämmitys, muu kuin keskuslämmitys. */
    heating: string;

    /** Eri kohteisiin kohdistuvat peruskorjaukset vähentävät ikäalennuksia eri prosenttiyksikkömäärän. Kohteena voi olla seuraavat: Lämmitysjärjestelmä, Vesijohto ja viemäri, Sähkölaitteet, Ovet ja ikkunat, Vesikate ja ulkoseinien pinnoite, Ulkopuolinen lisäeriste, Koneellinen ilmastointi, Muu. */
    // renovationObject: 01 | 02 | 03 | 04 | 05 | 06 | 07 | 99;

    /** Tieto esiintyy muodossa peruskorjausvuosi ja perusparannusvuosi. Kuvaa vuotta, jona peruskorjaukset on tehty. Jos peruskorjaushanke on kestänyt useita vuosia, korjausten valmistumisvuosi. */
    renovationYear: string;
    buildingType: number;

    /** Pientalo ja vapaa-ajan asunto -rakennustyyppien ominaisuustieto. Arvot: kyllä/ei. Rakennuksessa katsotaan olevan sähkö, kun rakennus on liitetty sähköverkkoon tai siihen johdetaan 230V sähkövirta riippumatta sähköenergian tuotantotavasta. */
    electricity: boolean;

    /** Rakennuksen tai sen osan tilavuus kuutiometreinä */
    volume: number;

    /**
     * Päivämäärä, jolloin rakennus katsotaan valmistuneeksi. Rakennus katsotaan kiinteistöverotuksessa valmistuneeksi silloin, kun rakennukselle on myönnetty käyttöönottolupa.
     * @format date
     */
    dateOfCompletion: string;

    /** Vuosi, jonka viimeiseen päivään mennessä rakennus katsotaan valmistuneeksi. */
    yearOfCompletion: number;

    /** Pientalo- ja vapaa-ajan asunto -rakennustyyppien ominaisuustieto. Arvot: kyllä/ei. Vesijohtona pidetään sellaista rakennukseen kiinteästi asennettua vesijohtojärjestelmää, johon vesi tulee alueellisesta vesijohtoverkostosta tai kaivosta (rengas- tai porakaivo) joko paineella tai pumpulla. */
    water: boolean;

    /** Pientalon ja vapaa-ajan asunto -rakennustyyppien ominaisuustieto. Viemärinä pidetään sellaista rakennukseen kiinteästi asennettua viemäröintijärjestelmää, joka päättyy alueelliseen viemäriverkostoon, saostus- tai umpikaivoon tai pienpuhdistamoon. */
    sewer: boolean;
  }
