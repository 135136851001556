/**
 * Escapes unsafe html text
 * @param unsafe 
 * @returns escaped html text
 */
export const escapeHtml = (unsafe: string|undefined|null): string =>
{
    if (typeof unsafe === "undefined" || unsafe === null) {
        return "";
    }
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }