import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class AllowedException extends Base {
    @Title("Poikkeamisen laji")
    @Description("Velvoittava määräys, josta luvan yhteydessä myönnetään lupa poiketa.")
    ExceptionType: string = "";
    
    @Title("Poikkeamisen sisältö")
    @Description("Teksti, jolla kuvataan, miten määräyksestä poiketaan.")
    ExceptionDescription: string = "";
}