export enum Rakennusosa {
    NotSet,
    Alapohjakanaalit,
    Alapohjalaatat,
    Alapohjat,
    Anturat,
    Erityisetalapohjat,
    Erityisetperustukset,
    Erityisetrunkorakenteet,
    Kantavatseinat,
    PPP,
    Palkit,
    Perustukset,
    Pilarit,
    Runko,
    Runkoportaat,
    TaloOsat,
    Vaestonsuojat,
    Valipohjat,
    Ylapohjat
}