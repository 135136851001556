import { Title, Description } from '../../utils/tooltipUtil';
import { Osapuolet } from './osapuolet';
import { Rakenne } from './rakenne';

export class KiinteistonHuoltokirja {
    @Title("Sopimuskumppanit")
    @Description("Sopimuskumppanit")
    osapuolet: Osapuolet = new Osapuolet();

    rakenteet: Rakenne[] = [];
}
