// tsrafce

import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LabelValuesBlock from '../../common/LabelValuesBlock';
import LabelValue from '../../common/LabelValue';
import { RAKKatselmus, RAKRakennus, RAKRakentamishanke } from '../../../api/kaavatietomalli/kaavatietomalli-api';
import { TabPanel } from '../../../utils/tabPanel';
import { Api as KaavatietomalliApi } from '../../../api/kaavatietomalli/kaavatietomalli-api';

type Props = {
  rakennus: RAKRakennus|null
}



const RytjView = (props: Props) => {
  const [value, setValue] = React.useState(0);
  const [rakentamishanke, setRakentamishanke] = React.useState<RAKRakentamishanke|null>(null)
  const [katselmus, setKatselmus] = React.useState<RAKKatselmus|null>(null)
  //const [rakennus , setRakennus] = React.useState<RAKRakennus|null>(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any): void => {
    setValue(newValue as number);
  };

  // setRakennus(props.rakennus);

  useEffect(() => {
    if (props.rakennus?.pysyvarakennustunnus) {
      new KaavatietomalliApi().hakemus.rakentamishankeGetById(props.rakennus?.pysyvarakennustunnus, { 
        select: "hankkeentoimenpide.rakentamistoimenpide,lupapaatos.lupahakemus,lupapaatos.lupamaarays,lupapaatos.myonnetytpoikkeamiset,lupapaatos.ylaluokka" }).then(x => {
        if (x.data) {
          setRakentamishanke(x.data);
        } 
      })
      new KaavatietomalliApi().rakennuskohde.katselmusGetById(props.rakennus?.pysyvarakennustunnus, { 
        select: "" }).then(x => {
        if (x.data) {
          setKatselmus(x.data);
        } 
        })
      
    }
    else {
      //setRakentamishanke(null);
      setKatselmus(null);
    }
    
  
  }, [props.rakennus])
  


  if (props.rakennus && props.rakennus.ylaluokka) {
    const rakennuskohde = props.rakennus.ylaluokka;
    const rakennus = props.rakennus;
    //const rakennushanke = rak
    return (
      <React.Fragment>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            <Typography variant='h3'>Rakennuksen perustiedot</Typography>
          </AccordionSummary>
          <AccordionDetails>
            
            <div className="text-columns-2">

              <LabelValuesBlock title='Rakennuskohde'>
                <LabelValue label='Rakennuksen tyyppi' value={ rakennuskohde.rakennuskohteentyyppi } code='rytj_RakennuskohteenTyyppi' />
                <LabelValue label='Tiedonlähde' value={ rakennuskohde.tiedonlahde} code='rytj_RakennuskohteenTiedonlahde' />
              </LabelValuesBlock>
  
              <LabelValuesBlock title='Rakennus'>
                <LabelValue label='Pääasiallinen käyttökohde' value={ rakennus.kayttotarkoitus } code='jhs_rakennus_1_20180712' />
                <LabelValue label='Käyttöönottopäivämäärä' value={ rakennus.kayttoonottopaivamaara } format="date" />
                <LabelValue label='Pysyvä rakennustunnus' value={ rakennus.pysyvarakennustunnus } />
              </LabelValuesBlock>

            </div>
            
          </AccordionDetails>
        </Accordion>

        { rakentamishanke !== null && 
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h3'>Rakennuksen hanketiedot</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="text-columns-2">
              <LabelValuesBlock title='Rakennuskohde'>
                <LabelValue label='Rakennuksen tyyppi' value={ rakennuskohde.rakennuskohteentyyppi } code='rytj_RakennuskohteenTyyppi' />
                <LabelValue label='Tiedonlähde' value={ rakennuskohde.tiedonlahde} code='rytj_RakennuskohteenTiedonlahde' />
              </LabelValuesBlock>

              <LabelValuesBlock title='Rakentamishanke'>
                <LabelValue label='Rakentamishankkeen kuvaus' value={ rakentamishanke.rakentamishankkeenkuvaus } />
                <LabelValue label='Aloittamispäivämäärä' value={ rakentamishanke.aloittamispaivamaara } format='date' />
                <LabelValue label='Päättymispäivämäärä' value={  rakentamishanke.paattymispaivamaara } format='date' />
              </LabelValuesBlock>

              {/* toimeenpiteentila siirtynyt eri paikkaan, nyt löytyy rakentamishanke->lupapäätös->rakentamistoimenpide kautta... 
              tai sitten hankkeentoimenpide->rakentamistoimenpide */}
              { rakentamishanke.hankkeentoimenpide &&
                <LabelValuesBlock title='Rakentamistoimenpide'>
                { rakentamishanke.hankkeentoimenpide.map((hankkeentoimenpide, i) => 
                  <React.Fragment key={i}>
                    { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                    <LabelValue label='Rakentamistoimenpiteen laji' value={ hankkeentoimenpide.rakentamistoimenpide?.rakentamistoimenpiteenlaji } />
                    <LabelValue label='Rakentamistoimenpiteen kuvaus' value={ hankkeentoimenpide.rakentamistoimenpide?.rakentamistoimenpiteenkuvaus }  />
                    <LabelValue label='Raukeamispäivä' value={ hankkeentoimenpide.rakentamistoimenpide?.raukeamispaivamaara } format='date' />
                    <LabelValue label='Jatkoajan päivämäärä' value={ hankkeentoimenpide.rakentamistoimenpide?.jatkoajanpaivamaara } format='date' />
                    <LabelValue label='Perusparannus' value={ hankkeentoimenpide.rakentamistoimenpide?.perusparannus }  />
                    <LabelValue label='Purkamisen syy' value={ hankkeentoimenpide.rakentamistoimenpide?.purkamisensyy }  />
                    <LabelValue label='Muutosala' value={ hankkeentoimenpide.rakentamistoimenpide?.muutosala }  />
                    <LabelValue label='Korjausaste' value={ hankkeentoimenpide.rakentamistoimenpide?.korjausaste + " %" }  />
                  </React.Fragment>
                )}
                </LabelValuesBlock>
              }
              
              { rakentamishanke.hankkeentoimenpide &&
                <LabelValuesBlock title='Toimeenpiteen tila'>
                { rakentamishanke.hankkeentoimenpide.map((hankkeentoimenpide, i) => 
                  <React.Fragment key={i}>
                    { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                    <LabelValue label='Rakentamistöiden aloituspäivämäärä' value={ hankkeentoimenpide.rakentamistoidenaloituspaivamaara }  format='date' />
                    <LabelValue label='Valmistumispäivämäärä' value={ hankkeentoimenpide.valmistumispaivamaara } format='date' />
                    <LabelValue label='Käyttöönottopäivämäärä' value={  hankkeentoimenpide.kayttoonottopaivamaara } format='date' />
                    <LabelValue label='Hyväksytty käyttöön kokonaan' value={hankkeentoimenpide.hyvaksyttykayttoonkokonaan } />
                  </React.Fragment>
                )}
                </LabelValuesBlock>
              } 

 


              <LabelValuesBlock title='Katselmus'>
                <LabelValue label='Katselmuksen laji' value={ katselmus?.katselmuksenlaji } code='rytj_Katselmuslaji' />
                <LabelValue label='Vaadittu lupamääräyksessä' value={ katselmus?.vaadittulupamaarayksissa } />
                <LabelValue label='Katselmuksen päivämäärä' value={ katselmus?.katselmuksenpaivamaara } format='date' />
                <LabelValue label='Katselmuksen tilanne' value={ katselmus?.katselmuksentilanne } />
                <LabelValue label='Katselmuksen huomautukset' value={ katselmus?.katselmuksenhuomautukset } />
                {/* <LabelValue label='Läsnäolijat' value={ katselmus. } /> */}
                <LabelValue label='Katselmuksen kohteen tarkennus' value={ katselmus?.katselmuksenkohteentarkennus } />
                <LabelValue label='Osittainen/lopullinen' value={ katselmus?.osittainenlopullinen } />
              </LabelValuesBlock>

                     {/* { rakentamishanke.hankkeentoimenpide && rakentamishanke.hankkeentoimenpide.map((hankkeentoimenpide, i) => 
            <React.Fragment>
              <LabelValuesBlock title='Lupamääräys'>
                <LabelValue label='Lupamääräyksen laji' value={ rakentamishanke.hankkeentoimenpide } />
                <LabelValue label='Lupamääräysteksti' value={ "todo" } />
              </LabelValuesBlock>

              <LabelValuesBlock title='Lupapäätös'>
                <LabelValue label='Sijoittamis-/toteuttamislupa' value={  } />
              </LabelValuesBlock>
            </React.Fragment>
          )} */}
             
            </div>
          </AccordionDetails>
        </Accordion>
        }

        <Typography variant="h2">Rakennuksien osiin liittyvät tiedot</Typography>

        <Tabs value={value} onChange={handleChange} textColor="primary" TabIndicatorProps={{ style: { height: "4px" }, "color": "primary" }} variant="scrollable" scrollButtons="auto" aria-label="sub page tabs">
          { rakennus.rakennuksenosa && rakennus.rakennuksenosa.map((rakennuksenosa, i) => 
           <Tab key={i} label={rakennuksenosa.rakennuksenosatunnus||/*rakennuksenosa.pysyvarakennustunnus||*/rakennuksenosa.valiaikainentunnus} id={"part-tab-"+i} />
          )
          }
        </Tabs>
        { rakennus.rakennuksenosa && rakennus.rakennuksenosa.map((rakennuksenosa, i) => 
          <TabPanel key={i} value={value} prefix='part-tab-' index={i}>
          

            { rakennuksenosa.huoneisto && rakennuksenosa.huoneisto.map((huoneisto, i) =>
            <Accordion key={i} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h3'>{`Huoneisto ${huoneisto.porraskirjain||""}${huoneisto.huoneistonnumero}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>

                <div className="text-columns-2">
                  <LabelValue label='Huoneistotunnus' value={ huoneisto.huoneistotunnus } />
                  <LabelValue label='Huoneiston jakokirjain' value={ huoneisto.huoneistonjakokirjain } />
                  <LabelValue label='Huoneiston numero' value={ huoneisto.huoneistonnumero } />
                  <LabelValue label='Huoneiston käyttötarkoitus' value={ huoneisto.huoneistonkayttotarkoitus } />
                  <LabelValue label='Huoneiston osoitejärjestysnumero' value={ huoneisto.osoitejarjestysnumero } />
                  <LabelValue label='Huoneistoala' value={ huoneisto.huoneistoala } />
                  <LabelValue label='Käyttöala' value={ huoneisto.kayttoala } />
                  <LabelValue label='Huoneiden lukumäärä' value={ huoneisto.koostuu?.length||0 } />
                  <LabelValue label='Keittiötyyppi' value={ huoneisto.keittiotyyppi } code='rytj_Keittiotyyppi' />
                  <LabelValue label='Muuttovalmis' value={ huoneisto.muuttovalmis } />
                  <LabelValue label='Muuttokielto' value={ huoneisto.muuttokielto } />
                </div>

              </AccordionDetails>
            </Accordion>
            )}

            { rakennuksenosa.tila3 && rakennuksenosa.tila3.map((tila, i) =>
            <Accordion key={i} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h3'>{`${tila.tilannimi}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>

                <div className="text-columns-2">
                  <LabelValue label='Pysyvä tilatunnus' value={ tila.pysyvatilatunnus } />
                  <LabelValue label='Tilavuus' value={ tila.tilavuus } />
                  <LabelValue label='Tilan nimi' value={ tila.tilannimi } />
                  <LabelValue label='Pohjapinta-ala' value={ tila['pohjapinta-ala'] } />
                  <LabelValue label='Tilatyyppi' value={ tila.tilatyyppi } />
                  <LabelValue label='Tilalaji' value={ tila.tilalaji } code='rytj_huonelaji'/>
                  <LabelValue label='Tilan käyttötarkoitus' value={ tila.tilankayttotarkoitus } />
                  <LabelValue label='On asuinhuone' value={ tila.onasuinhuone } />
                  <LabelValue label='Huoneala' value={ tila.huoneala } />
                  <LabelValue label='Kokoontumistilan henkilömäärä' value={ tila.kokoontumistilanhenkilomaara } />
                  <LabelValue label='Tilaryhmä' value={ tila.tilaryhma } />
                </div>

              </AccordionDetails>
            </Accordion>
            )}


            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography variant='h3'>Rakennuksen osan omistajan tiedot</Typography>
              </AccordionSummary>
              <AccordionDetails >
                <div className="text-columns-2">

                  {/* nyt löytyy rakennuskohteen kautta... */}
                  {/* <LabelValuesBlock title='Rakennuksen osan omistaja'>
                    { rakennuksenosa.rakennuksenOmistaja1 && rakennuksenosa.rakennuksenOmistaja1.map((rakennuksenOmistaja, i) => 
                    <React.Fragment key={"rak_omi"+i}> 
                      { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                      <LabelValue label='Omistajan laji' value={ rakennuksenOmistaja.omistajalaji } code='rytj_Omistajalaji' />
                      <LabelValue label='Omistuksen laji' value={ rakennuksenOmistaja.omistuksenlaji } code='rytj_Omistuksenlaji' />

                    </React.Fragment>
                    )
                    }        
                  </LabelValuesBlock> */}

                  {/* <LabelValuesBlock title='Rakennuksen osan haltija'>
                    { 
                      rakennuksenosa.rakennuksenHaltija1 && rakennuksenosa.rakennuksenHaltija1.map((rakennuksenHaltija, i) => 
                      <React.Fragment key={i}> 
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Omistajan laji' value={ rakennuksenHaltija.omistajalaji } code='rytj_Omistajalaji' />
                        <LabelValue label='Omistuksen laji' value={ rakennuksenHaltija.omistuksenlaji } code='rytj_Omistuksenlaji' />

                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock> */}
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography variant='h3'>Rakennuksen osan ominaisuustiedot</Typography>
              </AccordionSummary>
              <AccordionDetails >
                <div className="text-columns-2">
                  <LabelValuesBlock key={i} title='Rakennuksen osa' allowBreak={true}>

                    {/* <LabelValue label='Pysyvä rakennustunnus' value={ rakennuksenosa.pysyvarakennustunnus } /> */}
                    <LabelValue label='Rakennuksen osatunnus' value={ rakennuksenosa.rakennuksenosatunnus } />
                    <LabelValue label='Väliaikainen tunnus' value={ rakennuksenosa.valiaikainentunnus } />

                    <LabelValue label='Valmistumisvuosi' value={ rakennuksenosa.valmistumisvuosi } format="date" />
                    <LabelValue label='Valmistumispäivämäärä' value={ rakennuksenosa.valmistumispaivamaara } format="date" />
                    <LabelValue label='Käyttöönottopäivämäärä' value={ rakennuksenosa.kayttoonottopaivamaara } format="date" />

                    <LabelValue label='Kuntanumero' value={ rakennuksenosa.kuntanumero } />
                    <LabelValue label='Äänestysaluenumero' value={ rakennuksenosa.aanestysaluenumero } />

                    <LabelValue label='Kulttuurihistoriallinen merkittävyys' value={ rakennuksenosa.kulttuurihistoriallinenmerkittavyys } />

                    <LabelValue label='Elinkaaren vaihe' value={ rakennuksenosa.elinkaarenvaihe } code="rakrek_rakelinvaih" />                  
                    <LabelValue label='Rakennuksen käytössäolo' value={ rakennuksenosa.rakennuksenkaytossaolo } />

                    <LabelValue label='Suhde maanpintaan' value={ rakennuksenosa.suhdemaanpintaan } />
                    <LabelValue label='Korkeus' value={ rakennuksenosa.korkeus } unit="m" />
                    <LabelValue label='Lentoeste' value={ rakennuksenosa.lentoeste } />
                
                    <LabelValue label='Kerrosluku' value={ rakennuksenosa.kerrosluku } />
                    <LabelValue label='Kokonaisala' value={ rakennuksenosa.kokonaisala } unit="m2" />
                    <LabelValue label='Kerrosala' value={ rakennuksenosa.kerrosala } unit="m2" />
                    <LabelValue label='Rakennusoikeudellinen kerrosala' value={ rakennuksenosa.rakennusoikeudellinenkerrosala } />
                    <LabelValue label='Huoneistoala' value={ rakennuksenosa.huoneistoala } unit="m2" />
                    <LabelValue label='Kellariala' value={ rakennuksenosa.kellariala } unit="m2" />
                    <LabelValue label='Tilavuus' value={ rakennuksenosa.tilavuus } unit="m3" />
                    
                    
                    <LabelValue label='Lämmitetty nettopintaala' value={ rakennuksenosa['lammitettynettopinta-ala'] } unit="m2" />
                    <LabelValue label='Lämmitettytilavuus' value={ rakennuksenosa.lammitettytilavuus } unit="m3" />

                    <LabelValue label='Laajarunkoinen rakennus' value={ rakennuksenosa.laajarunkoinenrakennus } />
                    <LabelValue label='Paloluoka' value={ rakennuksenosa.paloluokka } code="rytj_Paloluokka" />
                    
                    <LabelValue label='Energiatehokkuus' value={ rakennuksenosa.energiatehokkuusluku } unit="kWh/brm2/vuosi"/>
                    <LabelValue label='Energialuokka' value={ rakennuksenosa.energialuokka } />{ /* code="jhs_enerluok" */}
                    <LabelValue label='Energiatodistus' value={ rakennuksenosa.energiatodistus } />

                    <LabelValue label='Ilmastoselvitys' value={ rakennuksenosa.ilmastoselvitys } />
                    <LabelValue label='Hiilijalanjälki' value={ rakennuksenosa.hiilijalanjalki } unit="kWh/brm2/vuosi"/>
                    <LabelValue label='Hiilikädenjälki' value={ rakennuksenosa.hiilikadenjalki } unit="kWh/brm2/vuosi"/>

                    <LabelValue label='Kokoontumistilan henkilömäärä' value={ rakennuksenosa.kokoontumistilanhenkilomaara } />

                    <LabelValue label='Väliaikainen rakennus' value={ rakennuksenosa.valiaikainenrakennus } />
                    <LabelValue label='Väliaikainen rakennus, asti' value={ rakennuksenosa.valiaikainenrakennusasti } format="date" />

                  </LabelValuesBlock>


                  <LabelValuesBlock title='Rakennuksen osan hissit'>
                    { 
                      rakennuksenosa.hissi1 && rakennuksenosa.hissi1.map((hissi, i) => 
                      <React.Fragment key={"hissi1"+i}>
                        {/* { <Typography variant='h6'>{'Hissi ' +i }</Typography> } */}
                        {/* <b>{ "Hissi " + i }</b> */}
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Hissin laji' value={ hissi.hissinlaji } code='rytj_hissi' />
                        <LabelValue label='Sisäpituus' value={ hissi.sisapituus } />
                        <LabelValue label='Sisäleveys' value={ hissi.sisaleveys } />
                        <LabelValue label='Oviaukon leveys' value={ hissi.oviaukonleveys } />
                        <LabelValue label='Oviaukon korkeus' value={ hissi.oviaukonkorkeus } />
                        <LabelValue label='Henkilömäärä' value={ hissi.henkilomaara } />
                        <LabelValue label='Kantavuus' value={ hissi.kantavuus } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>

                  <LabelValuesBlock title='Julkisivun rakennusaineet'>
                    { 
                      rakennuksenosa.julkisivunRakennusaine1 && rakennuksenosa.julkisivunRakennusaine1.map((julkisivunRakennusaine, i) => 
                      <React.Fragment key={"julkisivunRakennusaine1"+i}>
                        {/* { <Typography variant='h6'>{'Hissi ' +i }</Typography> } */}
                        <b>{ "Rakennusaine " + i }</b>
                        {/* { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> } */}
                        <LabelValue label='Julkisivun rakennusaineen laji' value={ julkisivunRakennusaine.julkisivunrakennusaineenlaji } code='rytj_julkisivunrakennusaine' />
                        <LabelValue label='Primääri' value={ julkisivunRakennusaine.primaari } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>

                  <LabelValuesBlock title='Kantavan rakenteen rakennusaineet'>
                    { 
                      rakennuksenosa.kantavanRakenteenRakennusaine1 && rakennuksenosa.kantavanRakenteenRakennusaine1.map((kantavanRakenteenRakennusaine, i) => 
                      <React.Fragment key={"kantavanRakenteenRakennusaine1"+i}>
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Kantavan rakenteen rakennusaineen laji' value={ kantavanRakenteenRakennusaine.kantavanrakenteenrakennusaineenlaji } code='rytj_kantavanrakenteenrakennusaine' />
                        <LabelValue label='Primääri' value={ kantavanRakenteenRakennusaine.primaari } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>


                  { rakennuksenosa.talousvesi1 && 
                  <LabelValuesBlock title='Talousvesi'>
                    { rakennuksenosa.talousvesi1.map((talousvesi, i) => 
                        <LabelValue key={i} label='Talousveden laji' value={ talousvesi.talousvedenlaji } code='rytj_talousvesi' />
                      )
                    }        
                  </LabelValuesBlock>
                  }

                  { rakennuksenosa.jaahdytysenergianlahde1 && 
                  <LabelValuesBlock title='Jäähdytysenergianlähde'>
                    { rakennuksenosa.jaahdytysenergianlahde1.map((jaahdytysenergianlahde, i) => 
                      <React.Fragment key={i}>
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Jäähdytysenergianlähteen laji' value={ jaahdytysenergianlahde.jaahdytysenergianlahteenlaji } code='rytj_lammitysenergianlahde' /> {/* todo oikea rytj_jaahdytysenergianlahde koodisto puuttuu? */}
                        <LabelValue label='Primääri' value={ jaahdytysenergianlahde.primaari } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>
                  }

                  { rakennuksenosa.lammitysenergianlahde1 && 
                  <LabelValuesBlock title='Jäähdytysenergianlähde'>
                    { rakennuksenosa.lammitysenergianlahde1.map((lammitysenergianlahde, i) => 
                      <React.Fragment key={i}>
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Lämmitysenergianlähteen laji' value={ lammitysenergianlahde.lammitysenergialahteenlaji } code='rytj_lammitysenergianlahde' /> 
                        <LabelValue label='Primääri' value={ lammitysenergianlahde.primaari } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>
                  }

                  { rakennuksenosa.sahkoenergianlahde1 && 
                  <LabelValuesBlock title='Sähköenergianlähde'>
                    { rakennuksenosa.sahkoenergianlahde1.map((sahkoenergianlahde, i) => 
                      <React.Fragment key={i}>
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Sähköenergianlähteen laji' value={ sahkoenergianlahde.sahkoenergialahteenlaji } code='rytj_sahkoenergianlahde' />
                        <LabelValue label='Primääri' value={ sahkoenergianlahde.primaari } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>
                  }

                  
                  { rakennuksenosa.jaahdytystapa1 && 
                  <LabelValuesBlock title='Jäähdytystapa'>
                    { rakennuksenosa.jaahdytystapa1.map((jaahdytystapa, i) => 
                      <React.Fragment key={i}>
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Jäähdytystavan laji' value={ jaahdytystapa.jaahdytystavanlaji } code='rytj_jaahdytystapa' />
                        <LabelValue label='Primääri' value={ jaahdytystapa.primaari } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>
                  }

                  { rakennuksenosa.ostoenergia1 && 
                  <LabelValuesBlock title='Ostoenergia'>
                    { rakennuksenosa.ostoenergia1.map((ostoenergia, i) => 
                      <React.Fragment key={i}>
                        { i > 0 && <hr style={{margin: "0 30% 8px 0", borderColor: "#888"}} /> }
                        <LabelValue label='Ostoenergian laji' value={ ostoenergia.ostoenergianlaji } code='rytj_ostoenergia' />
                        <LabelValue label='Ostoenergian lajin osuus' value={ ostoenergia.ostoenergianlajinosuus } />
                      </React.Fragment>
                      )
                    }        
                  </LabelValuesBlock>
                  }

                  { rakennuksenosa.verkostoliittyma1 && 
                    <LabelValuesBlock title='Verkostoliittymät'>
                      { rakennuksenosa.verkostoliittyma1.map((verkostoliittyma, i) => 
                      <LabelValue key={i} value={ verkostoliittyma.verkostoliittymanlaji } code='rytj_verkliit' />
                      )
                    }        
                  </LabelValuesBlock>
                  }

                  {/* todo oikea koodisto code='rytj_viemarointitapa' puuttuu? */}
                  { rakennuksenosa.viemarointitapa1 && 
                    <LabelValuesBlock title='Viemäröintitapa'>
                      { rakennuksenosa.viemarointitapa1.map((viemarointitapa, i) => 
                      <LabelValue key={i} value={ viemarointitapa.viemarointitavanlaji } />  
                      )
                    }        
                  </LabelValuesBlock>
                  }



                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h3'>Rakennuksen osan sisäänkäynnit</Typography>
              </AccordionSummary>
              <AccordionDetails>

                {
                  rakennuksenosa.sisaankaynti && rakennuksenosa.sisaankaynti.map((sisaankaynti, j) =>
                  <LabelValuesBlock key={j} title={'Sisäänkäynti ' + j}>
                    <LabelValue label='Sisäänkäynnin osoitetunnus' value={ sisaankaynti.sisaankaynninosoitetunnus } code='jhs_rakennus_1_20180712' />
                    <LabelValue label='Status' value={ sisaankaynti.status } format="date" />
                    <LabelValue label='Sisäänkäynnin tyyppi' value={ sisaankaynti.sisaankaynnintyyppi } />{/* TODO koodit puuttuu sisäänkäynnille */}
                    <LabelValue label='On esteetön' value={ sisaankaynti.onesteeton } />
                    <LabelValue label='ETRS89 Koordinaattijärjestelmä' value={ sisaankaynti['etrs-gk-kaista'] } />  
                    <LabelValue label='Pohjoiskoordinaatti' value={ sisaankaynti.pohjoiskoordinaatti } />  
                    <LabelValue label='Itäkoordinaatti' value={ sisaankaynti.itakoordinaatti } />  
                  </LabelValuesBlock>
                  )
                }

              </AccordionDetails>
            </Accordion>

            

          </TabPanel>
          )
        }

        {/* <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            <Typography variant='h3'>Rakennuksen tarkemmat tiedot</Typography>
          </AccordionSummary>
          <AccordionDetails className="text-columns-2">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h3'>Rakennuksen omistajan tiedot</Typography>
            <AccordionDetails className="text-columns-2">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
          </AccordionSummary>
        </Accordion> */}

        
      </React.Fragment>)
  }
  return (
    <div>Ei rakennustietoja saatavilla, valitse rakennus.</div>
  )
}

export default RytjView