/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** RAK_AlueidenkayttopaatoksenLaatija */
export interface RAKAlueidenkayttopaatoksenLaatija {
  /** Etunimi: sukunimen lisänä käytettävä rekisteröidyn henkilönnimen osa, joka ei viittaa henkilön sukuun */
  etunimi?: string;
  /** Sukunimi: pääasiallisesti sukuun viittaava rekisteröidyn henkilönnimen osa */
  sukunimi?: string;
  nimike?: any;
}

/**
 * RAK_Alueidenkayttopaatos
 * Yläluokka: Yläluokka
 */
export interface RAKAlueidenkayttopaatos {
  /** Ohjaava säädös */
  ohjaavasaados?: string;
  /**
   * Voimassaolon alkamisajankohta: voimassaolon alkamisajankohdan määritelmä
   * @format date-time
   */
  voimassaolonalkamisajankohta?: string;
  /**
   * Voimassaolon päättymisajankohta: voimassaolon päättymisajankohdan määritelmä
   * @format date-time
   */
  voimassaolonpaattymisajankohta?: string;
  ylaluokka?: RAKPaatos;
  /**
   * Julkistamisaika
   * @format date-time
   */
  julkistamisaika?: string;
  /** Digitaalinen alkuperä: Tieto alueidenkäyttöpäätöksen digitaalisen muodon tuotantotavasta ja siihen liittyvästä juridisuuden asteesta<br/>http://uri.suomi.fi/codelist/rytj/RY_DigitaalinenAlkupera */
  digitaalinenalkupera?: string;
  /** Lähtötietoaineisto */
  lahtotietoaineisto?: RAKLahtotietoaineisto;
  /** Alueidenkäyttöpäätöksen laatija */
  alueidenkayttopaatoksenlaatija?: RAKAlueidenkayttopaatoksenLaatija[];
  /** Alueidenkäyttöpäätöksen tunnus */
  alueidenkayttopaatoksentunnus?: RAKTunnus;
}

/**
 * RAK_Asiakirja
 * Liittyvä asiakirja
 */
export interface RAKAsiakirja {
  /** Nimi: ainutkertaisen tarkoitteen nimitys */
  nimi?: string;
  /** Kieli */
  kieli?: string;
  /**
   * Päivämäärä: Päivämäärä, jolloin asiakirja tai dokumentti on lisätty asiaan
   * @format date-time
   */
  paivamaara?: string;
  /**
   * Vahvistuspäivämäärä: Päivämäärä jolloin asiakirja on viranomaisen vahvistama
   * @format date-time
   */
  vahvistuspaivamaara?: string;
  /** Saavutettavuus */
  saavutettavuus?: string;
  /** Asiasanasto: Tiettyyn aihepiiriin koottu asiasanojen arvojoukko. Asiasana ja asiasanasto tulee esittää parina, jotta aina tiedetään mistä asiasanastosta kyseinen asiasana on poimittu.<br/><br/>Huom! Asiasanasto on tarpeellinen metatieto vain tilanteissa, joissa EI käytetä Fintoon URI:lla viittaavaa "asiasana" -ominaisuutta. */
  asiasanasto?: string;
  /** Asiakirjatyyppi: Tieto, joka kuvaa tuotettavan asiakirjan lajia. Asiakirjoja tuotetaan yleensä asiankäsittelyyn liittyvissä yksittäisissä toimissa tai tapahtumissa (toimenpide). <br/>Asiakirjatyyppi noudattaa yhteistä luokitusta/koodistoa, esimerkkejä arvoille ovat raportti, päätös, ilmoitus, muistio, tiedote... */
  asiakirjatyyppi?: string;
  /** Asiakirjatyypin tarkenne: Tieto, joka täsmentää asiakirjatyypin yleistä luokittelua. Määritellään organisaatiokohtaisesti. */
  asiakirjatyypintarkenne?: string;
  /** Viite tiedostoon: linkki tiedoston sijaintiin */
  viitetiedostoon?: string;
  /** Julkisuusluokka<br/>http://uri.suomi.fi/codelist/rytj/julkisuus */
  julkisuusluokka?: string;
  /** Säilytysaika: Asiakirjan säilytysaika ennen sen hävittämistä<br/>http://uri.suomi.fi/codelist/rytj/sailytysaika */
  sailytysaika?: string;
  /** Henkilötietosisältö<br/>http://uri.suomi.fi/codelist/rytj/henkilotietosisalto */
  henkilotietosisalto?: string;
  versiointitiedot?: RYTJVersiointitiedot;
}

/**
 * RAK_Hakemus
 * Yläluokka: Yläluokka
 */
export interface RAKHakemus {
  /**
   * Saapumispäivämäärä: Päivämäärä, jolloin hakemus on saapunut sähköiseen asiointipalveluun tai kirjaamoon.
   * @format date-time
   */
  saapumispaivamaara?: string;
  /** Hakemuksen sisältö: Hakemuksen pääasiallinen sisältö */
  hakemuksensisalto?: string;
  versiointitiedot?: RYTJVersiointitiedot;
  /** Liittyvä asiakirja */
  liittyvaasiakirja?: RAKAsiakirja;
}

/** RAK_HankkeenToimenpide */
export interface RAKHankkeenToimenpide {
  /**
   * Rakentamistöiden aloituspäivämäärä: Päivämäärä, jolloin rakentamistoimenpiteeseen liittyvät rakennustyöt katsotaan aloitetuiksi.
   * @format date-time
   */
  rakentamistoidenaloituspaivamaara?: string;
  /**
   * Valmistumispäivämäärä: Päivämäärä, jolloin rakentamistoimenpide katsotaan kokonaan valmistuneeksi, esim. lopullisen loppukatselmuksen päivämäärä.
   * @format date-time
   */
  valmistumispaivamaara?: string;
  /**
   * Käyttöönottopäivämäärä: Rakentamistoimenpiteen osittaisen valmistumisen, toimenpiteen alaisen rakentamiskohteen käyttöönottamisen päivämäärä. Uudisrakentamisen osalta tämä vastaa rakennuksen käyttöönottopäivämäärää, muutos- ja laajennustoimenpiteiden osalta ajankohtaa, jolloin laajennus- tai muutosalan osittaisesti tai kokonaan on hyväksytty käyttöönotettavaksi.
   * @format date-time
   */
  kayttoonottopaivamaara?: string;
  /** Hyväksytty käyttöön kokonaan: Rakentamiskohde on hyväksytty käyttöönotettavaksi kokonaisuudessaan. */
  hyvaksyttykayttoonkokonaan?: boolean;
  /** Rakentamistoimenpide: maankäyttöprosessi jossa määriteltyjen vaiheiden mukaisesti tuotetaan rakennuskohde */
  rakentamistoimenpide?: RAKRakentamistoimenpide;
}

/** RAK_Hissi */
export interface RAKHissi {
  /** Hissin laji<br/>http://uri.suomi.fi/codelist/rytj/hissi */
  hissinlaji?: string;
  /**
   * Sisäpituus: Hissikorin sisäpituus millimetreinä
   * @format int64
   */
  sisapituus?: number;
  /**
   * Sisäleveys: Hissikorin sisäleveys millimetreinä
   * @format int64
   */
  sisaleveys?: number;
  /**
   * Oviaukon leveys: Oven kulkuaukon leveys millimetreinä
   * @format int64
   */
  oviaukonleveys?: number;
  /**
   * Oviaukon korkeus: Oven kulkuaukon korkeus millimetreinä
   * @format int64
   */
  oviaukonkorkeus?: number;
  /**
   * Henkilömäärä: Henkilöiden lukumäärä.
   * @format int64
   */
  henkilomaara?: number;
  /**
   * Kantavuus: Rakenteen, rakennusosan, nostinlaitteen tms. kantavuus kilogrammoina
   * @format int64
   */
  kantavuus?: number;
}

/** RAK_Huone */
export interface RAKHuone {
  /** Huonelaji: Huoneen käyttötarkoituksen mukainen laji<br/>http://uri.suomi.fi/codelist/jhs/huonelaji */
  huonelaji?: string;
  /** Yläluokka: Yläluokka */
  ylaluokka?: RAKTila;
}

/** RAK_Huoneisto */
export interface RAKHuoneisto {
  /** Huoneistotunnus: Pysyvä huoneistotunnus (VTJ-PHT) on jokaiselle huoneistolle ja uudisrakennushankkeen huoneistolle annettava 10-merkkinen tunnus */
  huoneistotunnus?: string;
  /** Huoneiston jakokirjain: Kirjain, joka on lisätty huoneistotunnuksen numero-osaan silloin, kun alkuperäinen huoneisto on jaettu kahdeksi tai useammaksi erilliseksi huoneistoksi. */
  huoneistonjakokirjain?: string;
  /** Porraskirjain: Useamman kuin yhden huoneiston käsittävässä rakennuksessa tai rakennuksissa sijaitsevien huoneistojen tunnuksiin kuuluva kirjain */
  porraskirjain?: string;
  /** Huoneiston numero: Useamman kuin yhden huoneiston käsittävässä rakennuksessa sijaitsevan huoneiston tunnukseen kuuluva numero */
  huoneistonnumero?: string;
  /** Huoneiston käyttötarkoitus: Tieto siitä, mihin tarkoitukseen huoneistoa saa käyttää rakennusluvan mukaisesti. VTJ:ssä sallittuja arvoja ovat asuinhuoneisto ja toimitila.<br/>http://uri.suomi.fi/codelist/rytj/Huoneistotyyppi */
  huoneistonkayttotarkoitus?: string;
  /**
   * Osoitejärjestysnumero: Tieto siitä, mikä rakennuksen osoitteista on huoneiston osoite.
   * @format int64
   */
  osoitejarjestysnumero?: number;
  /**
   * Huoneistoala: Huoneistoa ympäröivien seinien sisäpintojen mukaan laskettu pinta-ala. Pinta-alaan lasketaan mukaan vain se osa, jossa huonekorkeus ylittää 160 cm.
   * @format double
   */
  huoneistoala?: number;
  /**
   * Käyttöala: rakennuksen kokonaislaajuus, johon lasketaan bruttoalan lisäksi mukaan myös rakennetut, ei-maanvaraiset ulkotilat, kuten parvekkeet, luhtikäytävät, terassit ja kannet
   * @format double
   */
  kayttoala?: number;
  /**
   * Huoneiden lukumäärä: Huoneeksi katsotaan rakennuksessa oleva tila, jota ympäröivät lattia, katto ja seinät. Huoneiden lukumäärään ei lasketa keittiötä.
   * @format int64
   */
  huoneidenlukumaara?: number;
  /** Käymälätyyppi: Koodisto kertoo, minkä tyyppinen käymälä huoneessa on. */
  kaymalatyyppi?: string;
  /** Keittiötyyppi: Tieto siitä, minkälainen keittiö huoneistossa on.<br/>http://uri.suomi.fi/codelist/rytj/Keittiotyyppi */
  keittiotyyppi?: string;
  /** Muuttovalmis: Onko huoneisto muuttovalmis (käyttöönotettavissa). Voidaan todeta osittaisessa loppukatselmuksessa. */
  muuttovalmis?: boolean;
  /** Muuttokielto: Tieto siitä, että asuinkäyttöön rakennettuun huoneistoon ei saa muuttaa asumaan vakinaisesti. */
  muuttokielto?: boolean;
  /** Huoneiston varuste */
  huoneistonvaruste?: RAKVaruste[];
  /** Koostuu */
  koostuu?: RAKHuone[];
}

/** RAK_Ilmanvaihtotapa */
export interface RAKIlmanvaihtotapa {
  /** Ilmanvaihtotavan laji<br/>http://uri.suomi.fi/codelist/rytj/ilmanvaihto */
  ilmanvaihtotavanlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_Jaahdytysenergianlahde */
export interface RAKJaahdytysenergianlahde {
  /** Jäähdytysenergianlähteen laji */
  jaahdytysenergianlahteenlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_Jaahdytystapa */
export interface RAKJaahdytystapa {
  /** Jäähdytystavan laji<br/>http://uri.suomi.fi/codelist/rytj/jaahdytystapa */
  jaahdytystavanlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_JalanjalkiprojektionNurkkapiste */
export interface RAKJalanjalkiprojektionNurkkapiste {
  /** ETRS89 koordinaattijärjestelmä: ETRS89 koordinaattijärjestelmän tunnus (esim. ETRS89 / GK25FIN)<br/>http://uri.suomi.fi/codelist/rakrek/ETRS89 */
  eTRS89koordinaattijarjestelma?: string;
  /**
   * Pohjoiskoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen pohjoiskoordinaatti desimaalimuotoisena.
   * @format double
   */
  pohjoiskoordinaatti?: number;
  /**
   * Itäkoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen itäkoordinaatti desimaalimuotoisena.
   * @format double
   */
  itakoordinaatti?: number;
  /**
   * Korkeusasema: Rakennuskohteen N2000 korkojärjestelmän mukainen korkeusasema metreinä kahden desimaalin tarkkuudella.
   * @format double
   */
  korkeusasema?: number;
}

/** RAK_JulkisivunRakennusaine */
export interface RAKJulkisivunRakennusaine {
  /** Julkisivun rakennusaineen laji<br/>http://uri.suomi.fi/codelist/rytj/julkisivunrakennusaine */
  julkisivunrakennusaineenlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_Kaava */
export interface RAKKaava {
  /** Yläluokka: Yläluokka */
  ylaluokka?: RAKAlueidenkayttopaatos;
  /** Kaavalaji: alueiden käytön ohjaustarpeeseen, kaavaan sisältövaatimuksiin, prosessiin ja vastuulliseen hallintoviranomaiseen perustuva luokittelu<br/>http://uri.suomi.fi/codelist/rytj/RY_Kaavalaji */
  kaavalaji?: string;
  /** Kaavan kuvaus: Kuvaus kaavasta */
  kaavankuvaus?: string;
  /** Kaavan teema */
  kaavanteema?: string;
  /** Elinkaaren tila: Elinkaaren tila, jossa kaavan versio on<br/>http://uri.suomi.fi/codelist/rytj/RY_KaavanElinkaaritila */
  elinkaarentila?: string;
  /** Maanalaisuus: Koskeeko kaava maanalaista vai maanpäällistä rakentamista ja maankäyttöä<br/>http://uri.suomi.fi/codelist/rytj/RY_MaanalaisuudenLaji */
  maanalaisuus?: string;
  /** Kaavatiedosto: viittaus kaavatietomallin mukaiseen kaavatiedostoon */
  kaavatiedosto?: string;
  versiointitiedot?: RYTJVersiointitiedot;
  /** Kaavan liite: Sisältää vaikutusten arviointi, selvitykset ym. */
  kaavanliite?: RAKAsiakirja[];
  /** Kaavaselostus: kaavaan liittyvä selostus, jossa esitetään kaavan tavoitteiden, mahdollisten vaihtoehtojen ja niiden vaikutusten sekä ratkaisujen perusteiden arvioimiseksi tarpeelliset tiedot */
  kaavaselostus?: RAKKaavaselostus[];
  /** Kaavakohde: Kaavan sisältyvä paikkatietokohde, johon voi kohdistua kaavamääräyksiä tai kaavasuosituksia */
  kaavakohde?: RAKKaavakohde[];
  /** Osallistumis- ja arviointisuunnitelma: kaavahankkeen alkuvaiheessa laadittava maankäyttösuunnitelma, jossa kuvataan kaavan tarkoitukseen ja merkitykseen nähden tarpeelliset tiedot suunnittelualueesta ja sen sijainnista, kaavoituksen lähtökohdista, tavoitteista ja suunnitellusta aikataulusta, osallistumis- ja vuorovaikutusmenettelyistä, kaavan vaikutusten arvioinnista sekä kaavan toteuttamiseksi laadittavista muista suunnitelmista ja mahdollisista maankäyttösopimuksista, jos niiden laatiminen on tiedossa kaavan aloitusvaiheessa */
  "osallistumis-jaarviointisuunnitelma1"?: RAKOsallistumisJaArviointisuunnitelma;
  /** Kumoamistieto */
  kumoamistieto?: RAKKaavanKumoamistieto;
  /** Kaavamääräys: kaavaan sisältyvä sanallinen määräys, jolla ohjataan alueiden suunnittelua ja rakentamista */
  kaavamaarays1?: RAKKaavamaarays[];
  /** Kaavasuositus: kaavasuunnitelmaan sisältyvä ei-velvoittava ohje, joka ilmentää esimerkiksi toteutuksen tapaa ja tavoitetta */
  kaavasuositus1?: RAKKaavasuositus[];
  /** Muu kaava-aineisto */
  "muukaava-aineisto"?: RAKMuuKaavaAineisto[];
}

/** RAK_Kaavakohde */
export interface RAKKaavakohde {
  /** Kirjaintunnus: Kaavakohteen kirjaintunnus */
  kirjaintunnus?: string;
  /** Kohteen nimi: Nimitys, jolla kaavakohdetta kutsutaan */
  kohteennimi?: string;
  /** Kohteen kuvaus: Kuvaus kaavakohteesta */
  kohteenkuvaus?: string;
  /** Laji: kohteen laji */
  laji?: string;
  /** Sijainnin sitovuus: miten sitovasti kohteen sijaintia on tulkittava<br/>http://uri.suomi.fi/codelist/rytj/RY_Sitovuuslaji */
  sijainninsitovuus?: string;
  /** Voimassaoloaika: Aikaväli, jona kaavakohde ja sen kaavamääräykset ovat lainvoimaisia, ellei yksittäisten kaavamääräysten voimassaoloa ole erikseen rajattu */
  voimassaoloaika?: string;
  /** Liittyvän lähtötietokohteen tunnus: kohteeseen liittyvän, kaavan lähtötietoihin sisältyvän kohteen pysyvä ja yksilöivä tunnus */
  liittyvanlahtotietokohteentunnus?: string;
  /** Maanalaisuus: Koskeeko kaavamääräyskohde maanalaista vai maanpäällistä rakentamista ja maankäyttöä */
  maanalaisuus?: string;
  /** Kaavamääräys: kohteeseen liitetty määräys */
  kaavamaarays?: RAKKaavamaarays[];
  /** Kaavasuositus: kaavamääräyskohteen alueeseen kohdistuva, ei-velvoittava suositus, joka ilmentää esimerkiksi toteutuksen tapaa ja tavoitetta. */
  kaavasuositus?: RAKKaavasuositus[];
  /** Värikoodi: Kaavamääräyksen värikoodi ilmoitetaan RGB heksadesimaali -arvona, esim. #58AD41. */
  varikoodi?: string;
  /** Ympäristömuutoksen laji: Kuvaa kaavakohteen toteuttamisen aiheuttamaa muutosta alueen ympäristöön */
  ymparistomuutoksenlaji?: string;
  /** Koskee */
  koskee?: RAKKiinteisto[];
  sijainti?: RAKSijainti;
  versiointitiedot?: RYTJVersiointitiedot;
  /** Kaavamääräysryhmä */
  kaavamaaraysryhma?: RAKKaavamaaraysryhma[];
}

/** RAK_Kaavamaarays */
export interface RAKKaavamaarays {
  /** Aihetunniste: Asiasana, joka kuvaa kaavamääräystä hakemisen helpottamiseksi. Esim. #venepaikka. */
  aihetunniste?: string;
  /** Kaavamääräyslaji: kuvaa määräyksen tyypin */
  kaavamaarayslaji?: string;
  /** Liittyvä asiakirja: Määräystä perusteleva tai siihen muutoin liittyvä asiakirja */
  liittyvaasiakirja?: RAKAsiakirja[];
  /** Voimassaoloaika: Aikaväli, jona kaavamääräys on lainvoimainen */
  voimassaoloaika?: string;
  /** Kaavoitusteema: Kaavoituksen piiriin kuuluva temaattinen aihealue */
  kaavoitusteema?: string;
  /** Sanallisen määräyksen luokka: Koodisto tulossa */
  sanallisenmaarayksenluokka?: string;
  /** Elinkaaren tila: Elinkaaren tila, jossa kaavamääräyksen versio on<br/>http://uri.suomi.fi/codelist/rytj/RY_KaavanElinkaaritila */
  elinkaarentila?: string;
  /** Arvo: kaavamääräyksen lajia tarkentava tekstiarvo */
  arvo?: string;
  /** Kaavamääräyksen kohde: Kaavamääräyksen kaavakohde */
  kaavamaarayksenkohde?: string;
  /** Kaavamääräyksen lisätieto: kaavamääräyksen tietosisältöä tarkentava nimetty ominaisuus ja sen mahdollinen arvo. */
  kaavamaarayksenlisatieto?: string;
}

/** RAK_Kaavamaaraysryhma */
export interface RAKKaavamaaraysryhma {
  /** Kaavamääräyksen otsikko: Otsikko kaavamääräykselle */
  kaavamaarayksenotsikko?: string;
  /**
   * Ryhmänumero
   * @format int64
   */
  ryhmanumero?: number;
}

/**
 * RAK_KaavanKumoamistieto
 * Kumoamistieto
 */
export interface RAKKaavanKumoamistieto {
  /** Kumottavan kaavan tunnus: Sen kaavan kaavatunnus, joka kumotaan kokonaan tai osittain tämän kaavan tullessa voimaan */
  kumottavankaavantunnus?: string;
  /** Kumoaa kaavan kokonaan: Viitattu kaava kumoutuu kokonaisuudessaan tämän kaavan tullessa voimaan */
  kumoaakaavankokonaan?: boolean;
  /** Kumottava kaavan alue: Aluemainen geometria, joka rajaa viitattavan kaavan osan, johon kohdistetut kaavakohteet ja -määräykset kumoutuvat */
  kumottavakaavanalue?: string;
  /** Kumottavan määräyksen tunnus: Viittaustunnus kumottavalle kaavamääräykselle, joka sisältyy kumottavaan kaavaan */
  kumottavanmaarayksentunnus?: string;
  /** Kumottavan suosituksen tunnus: Viittaustunnus kumottavalle kaavasuositukselle, joka sisältyy kumottavaan kaavaan */
  kumottavansuosituksentunnus?: string;
}

/** RAK_Kaavaselostus */
export interface RAKKaavaselostus {
  /** Liittyvä asiakirja */
  liittyvaasiakirja?: RAKAsiakirja;
}

/** RAK_Kaavasuositus */
export interface RAKKaavasuositus {
  /** Elinkaaren tila: Elinkaaren tila, jossa kaavan versio on.<br/>http://uri.suomi.fi/codelist/rytj/RY_KaavanElinkaaritila */
  elinkaarentila?: string;
  /** Kaavoitusteema: Kaavoituksen piiriin kuuluva temaattinen aihealue */
  kaavoitusteema?: string;
  /**
   * Suositusnumero: Kaavasuosituksen suositusnumero
   * @format int64
   */
  suositusnumero?: number;
  /** Voimassaoloaika: aikaväli, jona asiasta tehty päätös suunnitelmineen ja säännöksineen on lainvoimainen */
  voimassaoloaika?: string;
  /** Arvo: kaavasuosituksen lajia tarkentava tekstiarvo */
  arvo?: string;
}

/** RAK_Kaavayksikko */
export interface RAKKaavayksikko {
  /** Kaavayksikkötunnus: tunnus, joka yksilöi rekisteröitävän asema-, rakennus- tai ranta-asemakaavaan merkityn korttelin tai sen osan ja vastaavasti muun kuin korttelialueen tai sen osan (JHS134) */
  kaavayksikkotunnus?: string;
  /** Käyttötarkoitus: Tieto siitä mihin tai miten jotakin tulisi käyttää<br/><br/>Kaavayksikön osalta kyse on määrätyn koodiston mukaisesta arvosta (onko näin?) */
  kayttotarkoitus?: string;
  /** Sijainti: paikka, jossa jokin sijaitsee */
  sijainti?: RAKSijainti[];
  /** Kaavayksikön elinkaaren tila: kaavayksikön elinkaaren tila (yleisimmät arvot vireillä oleva, hyväksytty tai voimassa) */
  kaavayksikonelinkaarentila?: string;
  /** Kaavayksikön laji: kaavayksikön laji */
  kaavayksikonlaji?: string;
  /** Kaavayksikön muutoslaji<br/>http://uri.suomi.fi/codelist/rytj/RY_KaavanMuutostieto */
  kaavayksikonmuutoslaji?: string;
  /** Rakennettu: Tieto mm. kiinteistöverotusta varten, onko tonttijakotontti rakennettu asemakaavan mukaisesti */
  rakennettu?: boolean;
  /** Rakennuskielto: Kunnan antama tai suoraan laista johtuva kaavan laatimisen tai muuttamisen turvaava rakentamista koskeva määräaikainen ja ehdoton kielto */
  rakennuskielto?: boolean;
  /** Voimassaolon alkamispäivämäärä */
  voimassaolonalkamispaivamaara?: string;
  /** Voimassaolon loppumispäivämäärä */
  voimassaolonloppumispaivamaara?: string;
  /** Tonttijakotontin muodostajakiinteistö: Tieto muodostajakiinteistöistä tai -määräaloista, josta tonttijakotontti muodostetaan */
  tonttijakotontinmuodostajakiinteisto?: RAKMuodostajakiinteisto[];
  /** Kaavakohde: Kaavan sisältyvä paikkatietokohde, johon voi kohdistua kaavamääräyksiä tai kaavasuosituksia */
  kaavakohde?: RAKRakentamisenArvojenJyvitys;
  /** Liittyvä tonttijakotontti */
  liittyvatonttijakotontti?: RAKTonttijakotontti[];
  /** Kumoaa tonttijakotontin */
  kumoaatonttijakotontin?: RAKTonttijakotontti[];
  /** Liittyvä kaavayksikkö */
  liittyvakaavayksikko?: RAKKaavayksikko[];
  /** Peruskiinteistö */
  peruskiinteisto?: RAKKiinteisto[];
}

/** RAK_KantavanRakenteenRakennusaine */
export interface RAKKantavanRakenteenRakennusaine {
  /** Kantavan rakenteen rakennusaineen laji<br/>http://uri.suomi.fi/codelist/rytj/kantavanrakenteenrakennusaine */
  kantavanrakenteenrakennusaineenlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_Katselmus */
export interface RAKKatselmus {
  /** Katselmuksen laji: Kaavoitus- ja rakentamislain mukainen viranomaiskatselmuksen laji.<br/>http://uri.suomi.fi/codelist/rytj/Katselmuslaji */
  katselmuksenlaji?: string;
  /** Vaadittu lupamääräyksissä: Katselmuslajin mukainen katselmus on vaadittu lupapäätöksen määräyksissä */
  vaadittulupamaarayksissa?: boolean;
  /**
   * Katselmuksen päivämäärä: Katselmuksen pitopäivämäärä
   * @format date-time
   */
  katselmuksenpaivamaara?: string;
  /** Katselmuksen tilanne: Katselmuksen tilannetieto, arvot koodistosta Katselmuksen tilanne<br/>http://uri.suomi.fi/codelist/rytj/KatselmuksenTilanne */
  katselmuksentilanne?: string;
  /** Katselmuksen huomautukset: Katselmuksessa havaitut huomautukset */
  katselmuksenhuomautukset?: string;
  /** Katselmuksen kohteen tarkennus: Katselmuksen kohteen tarkempi kuvaus (rakennuksen osa, laite, rappu, huoneisto...) */
  katselmuksenkohteentarkennus?: string;
  /** Osittainen/lopullinen: Onko katselmus osittainen vai lopullinen (=kyseisen katselmuslajin viimeinen katselmus kyseisessä rakennushankkeessa).<br/>http://uri.suomi.fi/codelist/rytj/OsittainenLopullinen */
  osittainenlopullinen?: string;
  /** Liittyvä asiakirja */
  poytakirja?: RAKAsiakirja;
  rakentamishanke?: RAKRakentamishanke;
  katselmuksenkohde?: RAKRakennuskohde;
  /** Liittyvä asiakirja */
  liittyvaasiakirja?: RAKAsiakirja;
}

/** RAK_Kayttotarkoitus */
export interface RAKKayttotarkoitus {
  /** Käyttötarkoituksen laji: koodi */
  kayttotarkoituksenlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/**
 * RAK_Kiinteisto
 * Muodostajakiinteistö: viittaus muodostajakiinteistöön
 */
export interface RAKKiinteisto {
  /** Kiinteistötunnus: kiinteistörekisteriin merkityn rekisteriyksikön yksilöivä tunnus */
  kiinteistotunnus?: string;
  /** Kiinteistölaji: Kiinteistörekisteriin merkittävä rekisteriyksikön laji. */
  kiinteistolaji?: string;
  /** Nimi: kiinteistön nimi */
  nimi?: string;
  /** Rakennuskohde: asumista, työskentelyä tm. toimintaa varten vars. maan päälle rakennettu suoja */
  rakennuskohde?: RAKRakennuskohde[];
  sijainti?: RAKSijainti;
  /** Kiinteistön omistaja: oikeussubjekti, joka omistaa kiinteistön */
  kiinteistonomistaja?: string;
}

/**
 * RAK_Lahtotietoaineisto
 * Lähtötietoaineisto
 */
export interface RAKLahtotietoaineisto {
  ylaluokka?: RYTJVersiointitiedot;
  /** Aineistotunnus: Tietoaineiston pysyvä tunnus */
  aineistotunnus?: string;
  /** Nimi: ainutkertaisen tarkoitteen nimitys */
  nimi?: any[];
  /** Lähtötietoaineiston laji: lähtötietoaineiston laji<br/>http://uri.suomi.fi/codelist/rytj/RY_LahtotietoaineistonLaji */
  lahtotietoaineistonlaji?: string;
  /** Lisätietolinkki: URL-osoite, jonka viittaama resurssi tarjoaa lisätietoa asiaan liittyen */
  lisatietolinkki?: string;
  /** Metatietokuvaus: viittaus ulkoiseen metatietokuvaukseen */
  metatietokuvaus?: string;
  /** Aluerajaus: Geometrian aluerajaus */
  aluerajaus2?: RAKSijainti[];
}

/** RAK_Lammitysenergianlahde */
export interface RAKLammitysenergianlahde {
  /** Lämmitysenergialähteen laji<br/>http://uri.suomi.fi/codelist/rytj/lammitysenergianlahde */
  lammitysenergialahteenlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_Lammitystapa */
export interface RAKLammitystapa {
  /** Lämmitystavan laji<br/>http://uri.suomi.fi/codelist/rytj/lammitystapa */
  lammitystavanlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_Lupamaarays */
export interface RAKLupamaarays {
  /** Lupamääräyksen laji: Lupamääräyksen tyyppi, kuvaa asian, jota määräys koskee<br/>http://uri.suomi.fi/codelist/rytj/Lupamaarayshierarkinen */
  lupamaarayksenlaji?: string;
  /** Lupamääräysteksti: Lupamääräyksen sisältö */
  lupamaaraysteksti?: string;
}

/** RAK_Lupapaatos */
export interface RAKLupapaatos {
  /** Lupamääräys: rakentamisluvassa kuvattu velvoittava määräys */
  lupamaarays?: RAKLupamaarays[];
  /** Lupahakemus */
  lupahakemus?: RYTJLupahakemus;
  /** Myönnetyt poikkeamiset: Rakentamislupapäätökseen sisältyvät hyväksytyt poikkeamiset rakentamispaikkaan kohdistuvista velvottavista määräyksistä. */
  myonnetytpoikkeamiset?: RAKPoikkeaminen[];
  ylaluokka?: RAKPaatos;
  /** Rakennuspaikka */
  rakennuspaikka?: RAKRakennuspaikka;
  /** Sijoittamis-/toteuttamislupa: Haetaanko lupaa sijoittamiselle, toteuttamiselle vai molemmille<br/>http://uri.suomi.fi/codelist/rytj/LuvanSisalto */
  "sijoittamis-toteuttamislupa"?: string;
  sijainti?: RAKSijainti;
  /** Alueidenkäyttöpäätöksen tunnus */
  lupatunnus?: RAKTunnus;
  rakennuskohde?: RAKRakennuskohde;
  /**
   * Rakennustyöt aloitettava viimeistään: Päivämäärä, jolloin rakennustyöt on aloitettava viimeistään. 3 vuotta lainvoimaisuuspäivämäärän jälkeen.
   * @format date-time
   */
  rakennustyotaloitettavaviimeistaan?: string;
  /**
   * Rakennustyöt valmistuttava viimeistään: Päivämäärä, jolloin rakennustöiden on valmistuttava viimeistään. 5 v lainvoimaisuuspäivämäärän jälkeen.
   * @format date-time
   */
  rakennustyotvalmistuttavaviimeistaan?: string;
}

/** RAK_Muodostajakiinteisto */
export interface RAKMuodostajakiinteisto {
  /**
   * Sisältyvä pinta-ala: muodostavalta kiinteistöltä sisällytettävä pinta-ala
   * @format double
   */
  "sisaltyvapinta-ala"?: number;
  /** Muodostajakiinteistö: viittaus muodostajakiinteistöön */
  muodostajakiinteisto?: RAKKiinteisto;
}

/** RAK_MuuKaavaAineisto */
export type RAKMuuKaavaAineisto = object;

/**
 * RAK_OsallistumisJaArviointisuunnitelma
 * Osallistumis- ja arviointisuunnitelma: kaavahankkeen alkuvaiheessa laadittava maankäyttösuunnitelma, jossa kuvataan kaavan tarkoitukseen ja merkitykseen nähden tarpeelliset tiedot suunnittelualueesta ja sen sijainnista, kaavoituksen lähtökohdista, tavoitteista ja suunnitellusta aikataulusta, osallistumis- ja vuorovaikutusmenettelyistä, kaavan vaikutusten arvioinnista sekä kaavan toteuttamiseksi laadittavista muista suunnitelmista ja mahdollisista maankäyttösopimuksista, jos niiden laatiminen on tiedossa kaavan aloitusvaiheessa
 */
export interface RAKOsallistumisJaArviointisuunnitelma {
  /** Liittyvä asiakirja */
  liittyvaasiakirja?: RAKAsiakirja;
  versiointitiedot?: RYTJVersiointitiedot;
}

/**
 * RAK_Osoite
 * Osoite: sijaintipaikan osoittavien tietojen kokonaisuus
 */
export interface RAKOsoite {
  /** Osoitenimi: katua tai tietä tarkoittava paikannimi */
  osoitenimi?: string;
  /** Osoitenumeron numero-osa: osoitenumeroon kuuluva numero */
  "osoitenumeronnumero-osa"?: string;
  /** Osoitenumeron jakokirjain: kirjain, joka on lisätty kiinteistön osoitenumeroon silloin, kun alkuperäinen kiinteistö on jaettu kahdeksi tai useammaksi erilliseksi kiinteistöksi */
  osoitenumeronjakokirjain?: string;
  /** Huoneistotunnuksen kirjainosa: useamman kuin yhden huoneiston käsittävässä rakennuksessa tai rakennuksissa sijaitsevien huoneistojen tunnuksiin kuuluva kirjain */
  huoneistoTunnuksenKirjainosa?: string;
  /**
   * Huoneistotunnuksen numero-osa: useamman kuin yhden huoneiston käsittävässä rakennuksessa sijaitsevan huoneiston tunnukseen kuuluva numero
   * @format int64
   */
  huoneistoTunnuksenNumero?: number;
  /** Huoneistotunnuksen jakokirjain: kirjain, joka on lisätty huoneistotunnuksen numero-osaan silloin, kun alkuperäinen huoneisto on jaettu kahdeksi tai useammaksi erilliseksi huoneistoksi */
  huoneistoTunnuksenJakokirjain?: string;
  /** Osoitteen lisämääre: opastava tieto, joka täydentää muita osoitetietoja */
  osoitteenlisamaare?: string;
  /** C/o: care of eli jonkun luona */
  co?: string;
  /** Postilokero-osoite: osoite, joka ilmaisee postin vastaanottajan vuokraamaa tai postinjakelijan osoittamaa postinjättöpaikkaa */
  "postilokero-osoite"?: string;
  /** Postitoimipaikka: osoitteen osa, joka viittaa tiettyyn maantieteelliseen alueeseen tai organisaatioon */
  postitoimipaikka?: string;
  /** Postinumero: osoitteen numero-osa, joka viittaa postitoimipaikkaan */
  postinumero?: string;
  /**
   * Osoitejärjestysnumero
   * @format int64
   */
  osoitejarjestysnumero?: number;
}

/** RAK_Ostoenergia */
export interface RAKOstoenergia {
  /** Ostoenergian laji<br/>http://uri.suomi.fi/codelist/rytj/ostoenergia */
  ostoenergianlaji?: string;
  /**
   * Ostoenergian lajin osuus
   * @format double
   */
  ostoenergianlajinosuus?: number;
}

/** RAK_Paatos */
export interface RAKPaatos {
  /** Päätöksen nimi: Päätöksen nimitys, joka kertoo mitä päätös koskee<br/>http://uri.suomi.fi/codelist/rytj/paatoksennimi */
  paatoksennimi?: string;
  /** Päätöspäivämäärä: Päätöksen päivämäärä */
  paatospaivamaara?: string;
  /** Päätöksen tulos: Lupapäätöksen tulos<br/>http://uri.suomi.fi/codelist/rytj/PaatoksenTulos */
  paatoksentulos?: string;
  /** Päättäjän laji: Päätöksen tekijä, monijäseninen toimielin tai viranhaltija<br/>http://uri.suomi.fi/codelist/rytj/PaatoksenTekija */
  paattajanLaji?: string;
  /** Päätöksen tekijä: Viittaus toimijaan, joka on tehnyt päätöksen */
  paatoksentekija2?: string;
  /** Päätöspykälä: Päätöksen pykälä kuntajärjestelmässä */
  paatospykala?: string;
  /** Päätösteksti: Päätöksen teksti sisältää varsinaisen päätöstekstin, perustelut, sovelletut oikeusohjeet. */
  paatosteksti?: string;
  /**
   * Päätöksen antopäivämäärä: Päätöksen antopäivämäärä. Päivämäärä määrittää valitusajan sekä päätöksen lainvoimaisuuden sekä sitä kautta aloittamisoikeuden alkamisen.
   * @format date-time
   */
  paatoksenantopaivamaara?: string;
  /**
   * Päätös voimassa saakka: Päivämäärä, johon saakka päätös on voimassa, jollei sitä erillisellä päätöksellä myöhemmin jatketa.
   * @format date-time
   */
  paatosvoimassasaakka?: string;
  /** Päätöksen raukeaminen: Päivämäärä, jolloin päätöksen on todettu rauenneen. Päätös katsotaan rauenneeksi, kun rakennustöitä ei ole aloitettu tai saatettu loppuun päätöksen voimassa oloajan tultua loppuun. Päätös merkitään rauenneeksi, kun koko lupapäätös on rauennut eikä rakentamistoimenpiteitä tule valmiiksi, vaan kaikki toimenpiteet perutaan. Päätös voidaan todeta osittain rauenneeksi merkitsemällä joku hakemuksen toimenpiteistä rauenneeksi. */
  paatoksenraukeaminen?: string;
  /**
   * Päätöksen lainvoimaisuuspäivämäärä: Päivämäärä, jolloin lupapäätös tulee tai on tullut lainvoimaiseksi. Päivämäärä erityisesti tarvitaan, mikäli lupapäätöstieto julkistetaan RYTJ:ään ennen päätöksen lainvoimaisuutta.
   * @format date-time
   */
  paatoksenlainvoimaisuuspaivamaara?: string;
  /**
   * Julkaisemisen päivämäärä: Päivämäärä, jolloin lupapäätös on julkaistu. Päivämäärällä on merkitystä lainvoimaisuuden määrittämiseen. Päivämäärä erityisesti tarvitaan, mikäli lupapäätöstieto julkistetaan RYTJ:ään ennen päätöksen lainvoimaisuutta.
   * @format date-time
   */
  julkaisemisenpaivamaara?: string;
  /** Päätöksen tila: Maankäyttöpäätöksen tila; sallittuja arvoja ovat Lainvoimainen, Osittain voimassa, Voimassa, Kumottu, Valituksen alainen */
  paatoksentila?: string;
  versiointitiedot?: RYTJVersiointitiedot;
  /** Liittyvä asiakirja */
  liittyvaasiakirja?: RAKAsiakirja;
}

/** RAK_Poikkeaminen */
export interface RAKPoikkeaminen {
  /** Poikkeamisen laji: Velvoittava määräys, josta luvan yhteydessä haetaan poikkeamiselle lupaa.<br/>http://uri.suomi.fi/codelist/rytj/PoikkeamisenLaji */
  poikkeamisenlaji?: string;
  /** Poikkeamisen sisältö: Teksti, jolla kuvataan, miten määräyksestä poiketaan. */
  poikkeamisensisalto?: string;
}

/** RAK_RakennuksenOsa */
export interface RAKRakennuksenOsa {
  /** Rakennuksen osatunnus: Rakennuksen osalle määritetty rakennuksen osan tunnus. Tunnus on tyhjä, jos rakennus koostuu vain yhdestä osasta. Kullakin rakennuksen osalla on erillinen rakennuksen osatunnus. Pysyvä rakennustunnus on jokaisella samaan rakennukseen liittyvällä osalla sama. */
  rakennuksenosatunnus?: string;
  /** Väliaikainen tunnus */
  valiaikainentunnus?: string;
  /** Valmistumisvuosi: Tieto siitä, minä vuonna rakennus on valmistunut. Tätä tietoa käytetään, jos rakennuksen valmistumisajankohtaa ei voida ilmaista päivämäärän tarkkuudella. */
  valmistumisvuosi?: string;
  /**
   * Valmistumispäivämäärä: Rakennuksen lopullisen loppukatselmuksen hyväksymisen päivä
   * @format date-time
   */
  valmistumispaivamaara?: string;
  /**
   * Käyttöönottopäivämäärä: Rakennuksen ensimmäisen käyttöönottokatselmuksen hyväksymisen päivä
   * @format date-time
   */
  kayttoonottopaivamaara?: string;
  /** Kuntanumero: Digi- ja väestötietoviraston (DVV) kunnalle antama kolminumeroinen tunnus */
  kuntanumero?: string;
  /** Äänestysaluenumero: Suomen vaaleissa käytettävä äänestysalueen numero */
  aanestysaluenumero?: string;
  /** Kulttuurihistoriallinen merkittävyys: Tieto kohteen kulttuurihistoriallisesta merkittävyydestä.<br/>http://uri.suomi.fi/codelist/rakrek/kulthistmer */
  kulttuurihistoriallinenmerkittavyys?: string;
  /** Elinkaaren vaihe: Rakennuksen elinkaaren vaiheet suunnittelusta rakennusosien uudelleenkäyttöön asti.<br/>http://uri.suomi.fi/codelist/rakrek/rakelinvaih */
  elinkaarenvaihe?: string;
  /** Rakennuksen käytössäolo: Rakennuksen käytön tilanne. Rakennuksen käytössäolotieto päätellään mm. asumistietojen perusteella VTJ:ssä. */
  rakennuksenkaytossaolo?: string;
  /** Suhde maan pintaan: Tieto siitä, missä rakennus sijaitsee suhteessa maan pintaan. */
  suhdemaanpintaan?: string;
  /**
   * Korkeus: Absoluuttinen korkeus rakennuksen tai rakennuksen osan korkeimmasta kohdasta.
   * @format double
   */
  korkeus?: number;
  /** Lentoeste: Tieto siitä, onko kohde lentoeste. Kohde tulee määritellä lentoesteeksi EUROCONTROL Terrain and Obstacle Data Manual Edition 2.1 -dokumentin perusteella (ISBN: 978-2-87497-079-5). Kohteen määrittelyyn lentoesteeksi vaikuttavat mm. kohteen etäisyys kiitoradasta, kohteen suhteellinen korkeus ja maanpinnan korkeus. */
  lentoeste?: boolean;
  /**
   * Kerrosluku: Rakennuksen maanpäällisten kerrosten lukumäärä. Kerroslukuun lasketaan kaikki ne kokonaan tai pääasiallisesti maanpinnan yläpuolella olevat kerrokset (=ei kellarikerrokset), joissa on asuin- tai työ (toimi-) tiloja tai rakennuksen pääasiallisen käyttötarkoituksen mukaisia tiloja. Kerroslukuun ei lasketa ullakkoa.
   * @format int64
   */
  kerrosluku?: number;
  /**
   * Kokonaisala: Rakennuksen pinta-ala, johon lasketaan ulkomitoin kaikkien kerrosten, kellareiden ja lämpöeristettyjen ullakkohuoneiden pinta-alat. Pinta-alaan ei lasketa parvekkeita, katoksia eikä tiloja, joissa vapaa korkeus on alle 160 cm.
   * @format int64
   */
  kokonaisala?: number;
  /**
   * Kerrosala: Kerrosten alat ulkoseinien ulkopinnan mukaan laskettuina sekä se kellarikerroksen tai ullakon ala, johon sijoitetaan tai voidaan näiden tilojen sijainnista, yhteyksistä, koosta, valoisuudesta ja muista ominaisuuksista päätellen sijoittaa rakennuksen pääasiallisen käyttötarkoituksen mukaisia tiloja.
   * @format int64
   */
  kerrosala?: number;
  /**
   * Rakennusoikeudellinen kerrosala: Kerrosalasta huomioidaan ulkoseinien sisällä oleva pinta-ala ja ulkoseinistä vain se osa, joka on paksuudeltaan enintään 250 mm.
   * @format int64
   */
  rakennusoikeudellinenkerrosala?: number;
  /**
   * Huoneistoala: Ala, jota rajaavat toisaalta huoneistoa ympäröivien seinien, toisaalta huoneiston sisällä olevien kantavien seinien ja muiden rakennukselle välttämättömien rakennusosien huoneiston puoleiset pinnat. Pinta-alaan lasketaan mukaan vain se osa, jossa huonekorkeus ylittää 160 cm.
   * @format int64
   */
  huoneistoala?: number;
  /**
   * Kellariala: Kellarikerrosten yhteenlaskettu pinta-ala. Kellarikerroksen pinta-ala on kellarikerrosta ympäröivien seinien ulkopintojen rajoittama ala. Mukaan ei lasketa 160 cm:ä matalampia tiloja. Kellarikerroksiksi luetaan kaikki kokonaan tai pääasiallisesti maanpinnan alapuolella olevat kerrokset.
   * @format int64
   */
  kellariala?: number;
  /**
   * Tilavuus: Rakennuksen tilavuus lasketaan sen tilan perusteella, jota rajoittavat ulkoseinien ulkopinnat, alapohjan alapinta ja yläpohjan yläpinta.
   * @format int64
   */
  tilavuus?: number;
  /**
   * Lämmitetty nettopinta-ala
   * @format double
   */
  "lammitettynettopinta-ala"?: number;
  /**
   * Lämmitetty tilavuus
   * @format int64
   */
  lammitettytilavuus?: number;
  /** Rakentamistapa: selvitettävä onko tarvetta, sisältyy KuntaGML:ään merkityksessä "paikalla rakennettu tai elementtirakenteinen"<br/>http://uri.suomi.fi/codelist/vtj/Rake_runkotapa */
  rakentamistapa?: string;
  /** Laajarunkoinen rakennus */
  laajarunkoinenrakennus?: boolean;
  /** Paloluokka: Rakennuksen paloluokka<br/>http://uri.suomi.fi/codelist/rytj/Paloluokka */
  paloluokka?: string;
  /** Energiatehokkuusluku: kWh/brm2/vuosi */
  energiatehokkuusluku?: string;
  /** Energialuokka: Rakennuksen energiatehokkuusdirektiivin mukainen energialuokka.<br/>http://uri.suomi.fi/codelist/rytj/Energialuokka */
  energialuokka?: string;
  /** Energiatodistus */
  energiatodistus?: boolean;
  /** Ilmastoselvitys */
  ilmastoselvitys?: boolean;
  /**
   * Hiilijalanjälki
   * @format int64
   */
  hiilijalanjalki?: number;
  /**
   * Hiilikädenjälki
   * @format int64
   */
  hiilikadenjalki?: number;
  /**
   * Kokoontumistilan henkilömäärä: Kokoontumistilaan hyväksyttävä enimmäishenkilömäärä
   * @format int64
   */
  kokoontumistilanhenkilomaara?: number;
  /** Väliaikainen rakennus: Onko rakennus väliaikainen */
  valiaikainenrakennus?: boolean;
  /**
   * Väliaikainen rakennus, asti: Mihin asti väliaikainen rakennus on voimassa
   * @format date-time
   */
  valiaikainenrakennusasti?: string;
  /** Huoneisto: rakennukseen liittyvä hallintakohde, joka käyttötarkoituksensa ja omistuksensa perusteella luetaan yhdeksi kokonaisuudeksi ja jolla on oma välitön sisäänkäynti */
  huoneisto?: RAKHuoneisto[];
  /** Rakennuksen tila */
  tila3?: RAKTila[];
  /** Sisäänkäynti */
  sisaankaynti?: RAKSisaankaynti[];
  sijainti?: RAKSijainti;
  /** Verkostoliittymä */
  verkostoliittyma1?: RAKVerkostoliittyma[];
  /** Lämmitysenergianlähde */
  lammitysenergianlahde1?: RAKLammitysenergianlahde[];
  /** Jäähdytysenergianlähde */
  jaahdytysenergianlahde1?: RAKJaahdytysenergianlahde[];
  /** Hissi */
  hissi1?: RAKHissi[];
  /** Ilmanvaihtotapa */
  ilmanvaihtotapa1?: RAKIlmanvaihtotapa[];
  /** Jäähdytystapa */
  jaahdytystapa1?: RAKJaahdytystapa[];
  /** Lämmitystapa */
  lammitystapa1?: RAKLammitystapa[];
  /** Viemäröintitapa */
  viemarointitapa1?: RAKViemarointitapa[];
  /** Ostoenergia */
  ostoenergia1?: RAKOstoenergia[];
  /** Julkisivun rakennusaine */
  julkisivunRakennusaine1?: RAKJulkisivunRakennusaine[];
  /** Kantavan rakenteen rakennusaine */
  kantavanRakenteenRakennusaine1?: RAKKantavanRakenteenRakennusaine[];
  /** Talousvesi */
  talousvesi1?: RAKTalousvesi[];
  /** Jalanjälkiprojektion nurkkapiste */
  jalanjalkiprojektionNurkkapiste1?: RAKJalanjalkiprojektionNurkkapiste[];
  /** Referenssipiste */
  referenssipiste1?: RAKReferenssipiste[];
  /** Sähköenergianlähde */
  sahkoenergianlahde1?: RAKSahkoenergianlahde[];
  /** Käyttötarkoitus */
  kayttotarkoitus1?: RAKKayttotarkoitus[];
}

/** RAK_RakennuksenTietomalli */
export interface RAKRakennuksenTietomalli {
  /** Jalanjälkiprojektion nurkkapiste */
  jalanjalkiprojektionnurkkapiste?: RAKJalanjalkiprojektionNurkkapiste[];
  /** Liittyvä asiakirja */
  asiakirja?: RAKAsiakirja;
  /** Rakennuksen tietomallin laji: BIM-tietomallin tyyppi: suunnittelumalli, toteumamalli<br/>http://uri.suomi.fi/codelist/rytj/rakennuksentietomallinlaji */
  rakennuksentietomallinlaji?: string;
}

/** RAK_Rakennus */
export interface RAKRakennus {
  /** Pääasiallinen käyttötarkoitus: Rakennuksen pääasiallinen käyttötarkoitus. Attribuutin arvo määräytyy Tilastokeskuksen Rakennusluokituksen mukaisesti.<br/>http://uri.suomi.fi/codelist/jhs/rakennus_1_20180712 */
  kayttotarkoitus?: string;
  /**
   * Käyttöönottopäivämäärä
   * @format date-time
   */
  kayttoonottopaivamaara?: string;
  /** Pysyvä rakennustunnus: Väestötietojärjestelmään tallennettu pysyvä rakennustunnus. */
  pysyvarakennustunnus?: string;
  /**
   * Kerrosluku: Rakennuksen maanpäällisten kerrosten lukumäärä. Kerroslukuun lasketaan kaikki ne kokonaan tai pääasiallisesti maanpinnan yläpuolella olevat kerrokset (=ei kellarikerrokset), joissa on asuin- tai työ (toimi-) tiloja tai rakennuksen pääasiallisen käyttötarkoituksen mukaisia tiloja. Kerroslukuun ei lasketa ullakkoa.
   * @format int64
   */
  kerrosluku?: number;
  /** Rakennuksen osa */
  rakennuksenosa?: RAKRakennuksenOsa[];
  ylaluokka?: RAKRakennuskohde;
  rakennuksenvaruste?: RAKVaruste;
}

/** RAK_Rakennuskohde */
export interface RAKRakennuskohde {
  /** Rakennuspaikka */
  rakentamispaikka?: RAKRakennuspaikka;
  /** Rakennuksen tietomalli: tietomalli, joka mahdollistaa rakennustietojen vaihdon, jakamisen ja käytön */
  rakennuksentietomalli?: RAKRakennuksenTietomalli[];
  /** Sijainti: paikka, jossa jokin sijaitsee */
  sijainti?: RAKSijainti[];
  /** Rakennuskohteen osoite */
  rakennuskohteenosoite?: RAKOsoite[];
  /** Rakennuskohteen omistaja */
  rakennuskohteenomistaja?: RAKRakennuskohteenOmistaja[];
  /** Rakennuskohteen tyyppi: Tieto siitä, onko rakennuskohde rakennus, rakennelma vai erityistä toimintaa varten suunniteltu alue<br/>http://uri.suomi.fi/codelist/rytj/RakennuskohteenTyyppi */
  rakennuskohteentyyppi?: string;
  /** Tiedon lähde: mitä kautta tieto rakennuskohteesta on saatu, esim rakennuslupa, oma ilmoitus<br/>http://uri.suomi.fi/codelist/rytj/RakennuskohteenTiedonlahde */
  tiedonlahde?: string;
  versiointitiedot?: RYTJVersiointitiedot;
  /** Alueidenkäyttöpäätöksen tunnus */
  rakennuskohteentunnus?: RAKTunnus;
  rakennuskohde?: RAKRakennuskohde;
}

/** RAK_RakennuskohteenOmistaja */
export interface RAKRakennuskohteenOmistaja {
  /** Omistajalaji: Rakennuskohteen omistajalaji<br/>http://uri.suomi.fi/codelist/rytj/Omistajalaji */
  omistajalaji?: string;
  /** Omistuksen laji: Rakennuskohteen omistuksen laji<br/>http://uri.suomi.fi/codelist/rytj/Omistuksenlaji */
  omistuksenlaji?: string;
  /** Tunnuksettoman omistajan tunnustiedot */
  tunnuksettomanomistajantunnustiedot?: RAKTunnuksettomanOmistajanTunnustiedot[];
}

/**
 * RAK_Rakennuspaikka
 * Rakennuspaikka
 */
export interface RAKRakennuspaikka {
  /** Kaavalaji: Maankäyttöpäätös, jonka tuloksena syntyy alueiden käyttöä ohjaava kaava.<br/>http://uri.suomi.fi/codelist/rytj/RY_Kaavalaji */
  kaavalaji?: string;
  /**
   * Pinta-ala: Rakennuspaikan pinta-ala
   * @format double
   */
  "pinta-ala"?: number;
  kaavayksikko?: RAKKaavayksikko;
  sijainti?: RAKSijainti;
  /** Osoite: sijaintipaikan osoittavien tietojen kokonaisuus */
  osoite?: RAKOsoite[];
}

/**
 * RAK_RakentamisenArvojenJyvitys
 * Kaavakohde: Kaavan sisältyvä paikkatietokohde, johon voi kohdistua kaavamääräyksiä tai kaavasuosituksia
 */
export interface RAKRakentamisenArvojenJyvitys {
  /** Kaavakohde: Kaavan sisältyvä paikkatietokohde, johon voi kohdistua kaavamääräyksiä tai kaavasuosituksia */
  kaavakohde?: RAKKaavakohde[];
  /**
   * Jyvitettävä pinta-ala
   * @format double
   */
  "jyvitettavapinta-ala"?: number;
}

/** RAK_Rakentamishanke */
export interface RAKRakentamishanke {
  /** Rakentamishankkeen kuvaus: Rakentamishankkeen sanallinen kuvaus */
  rakentamishankkeenkuvaus?: string;
  /**
   * Aloittamispäivämäärä: Hankkeen aloittamispäivämäärä, päivämäärä, jolloin asiaan liittyvien rakentamistoimenpiteiden on katsottu alkaneeksi/arvioitu alkavaksi
   * @format date-time
   */
  aloittamispaivamaara?: string;
  /**
   * Päättymispäivämäärä: Päivämäärä, jolloin hankkeen katsotaan päättyneen, yleensä lopullisen loppukatselmuksen päivämäärä.
   * @format date-time
   */
  paattymispaivamaara?: string;
  /** Rakennuspaikka */
  rakentamispaikka?: RAKRakennuspaikka;
  /** Mahdollistava lupa: Lupapäätös, joka mahdollistaa rakentamishankkeen toteuttamisen */
  mahdollistavalupa?: RAKLupapaatos[];
  /** Hankkeen toimenpide: Liittyvän rakentamistoimenpiteen hankkeenaikainen edistymis- ja valmistumistieto */
  hankkeentoimenpide?: RAKHankkeenToimenpide[];
}

/**
 * RAK_Rakentamistoimenpide
 * Rakentamistoimenpide: maankäyttöprosessi jossa määriteltyjen vaiheiden mukaisesti tuotetaan rakennuskohde
 */
export interface RAKRakentamistoimenpide {
  /** Rakentamistoimenpiteen laji: Rakentamistoimenpiteen laji<br/>http://uri.suomi.fi/codelist/rytj/Rakentamistoimenpide */
  rakentamistoimenpiteenlaji?: string;
  /** Rakentamistoimenpiteen kuvaus: Toimenpiteen tarkentava kuvaus */
  rakentamistoimenpiteenkuvaus?: string;
  /**
   * Raukeamispäivämäärä: Päivämäärä, jolloin lupapäätöksen katsotaan rauenneen kyseisen rakentamistoimenpiteen osalta.
   * @format date-time
   */
  raukeamispaivamaara?: string;
  /**
   * Jatkoajan päivämäärä: Hankkeen loppuunsaattamiselle myönnetyn jatkoajan päivämäärä.
   * @format date-time
   */
  jatkoajanpaivamaara?: string;
  /** Perusparannus: Kyseessä on rakennuksen perusparannustoimenpide. */
  perusparannus?: boolean;
  /** Purkamisen syy: Rakennuksen purkamisen syy (ainoastaan purkamistoimenpiteelle).<br/>http://uri.suomi.fi/codelist/rytj/PurkamisenSyy */
  purkamisensyy?: string;
  /**
   * Muutosala: Muutostyön pinta-alatieto
   * @format int64
   */
  muutosala?: number;
  /**
   * Korjausaste: Korjausaste (%)
   * @format int64
   */
  korjausaste?: number;
  /** Rakennuspaikka */
  rakentamispaikka?: RAKRakennuspaikka;
  liittyy?: RAKLupapaatos;
}

/** RAK_Referenssipiste */
export interface RAKReferenssipiste {
  /** ETRS89 koordinaattijärjestelmä: ETRS89 koordinaattijärjestelmän tunnus (esim. ETRS89 / GK25FIN)<br/>http://uri.suomi.fi/codelist/rakrek/ETRS89 */
  eTRS89koordinaattijarjestelma?: string;
  /**
   * Pohjoiskoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen pohjoiskoordinaatti desimaalimuotoisena.
   * @format double
   */
  pohjoiskoordinaatti?: number;
  /**
   * Itäkoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen itäkoordinaatti desimaalimuotoisena.
   * @format double
   */
  itakoordinaatti?: number;
  /**
   * Korkeusasema: Rakennuskohteen N2000 korkojärjestelmän mukainen korkeusasema metreinä kahden desimaalin tarkkuudella.
   * @format double
   */
  korkeusasema?: number;
}

/** RAK_Sahkoenergianlahde */
export interface RAKSahkoenergianlahde {
  /** Sähköenergialähteen laji<br/>http://uri.suomi.fi/codelist/rytj/sahkoenergianlahde */
  sahkoenergialahteenlaji?: string;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
}

/** RAK_Sijainti */
export interface RAKSijainti {
  /** Osoite: sijaintipaikan osoittavien tietojen kokonaisuus */
  osoite?: RAKOsoite;
  /** Pistemäinen sijainti: Koordinaattipiste */
  pistemainensijainti?: string;
  /** Viivamainen sijainti: Kohteen viivamainen sijainti */
  viivamainensijainti?: string;
  /** Aluemainen sijainti: Kohteen aluemainen sijainti */
  aluemainensijainti?: string;
  /** Sijainnin luontitapa: Tapa, jolla sijainti on luotu tai määritetty */
  sijainninluontitapa?: string;
  /** Sijaintiepävarmuus: Sijaintiepävarmuustasot */
  sijaintiepavarmuus?: string;
  /** Koordinaatisto: Sijainnin määrittämiseen käytetty koordinaatisto */
  koordinaatisto?: string;
  /** Kolmiulotteinen sijainti: Rakennuksen tai muun rakentamisen kohteen kolmiulotteinen sijaintitieto */
  kolmiulotteinensijainti?: string;
  /** ETRS89 koordinaattijärjestelmä: ETRS89 koordinaattijärjestelmän tunnus (esim. ETRS89 / GK25FIN) */
  eTRS89koordinaattijarjestelma?: string;
  /**
   * Pohjoiskoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen pohjoiskoordinaatti desimaalimuotoisena.
   * @format double
   */
  pohjoiskoordinaatti?: number;
  /**
   * Itäkoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen itäkoordinaatti desimaalimuotoisena.
   * @format double
   */
  itakoordinaatti?: number;
}

/** RAK_Sisaankaynti */
export interface RAKSisaankaynti {
  /** Sisäänkäynnin osoitetunnus: Merkkiyhdistelmä, joka yksilöi sisäänkäynnin ja on kirjattu rakennusta koskeviin virallisiin asiakirjoihin. */
  sisaankaynninosoitetunnus?: string;
  /** Status: Tiedon lähteeseen perustuva kirjaus, joka voidaan ottaa huomioon tiedon laatua tai luotettavuutta arvioitaessa. */
  status?: string;
  /** Sisäänkäynnin tyyppi: Sisäänkäynnin käyttötarkoitusta tai -tapaa kuvaava tieto.<br/>http://uri.suomi.fi/codelist/rytj/sisaankaynti */
  sisaankaynnintyyppi?: string;
  /** On esteetön */
  onesteeton?: boolean;
  /** ETRS89 koordinaattijärjestelmä: ETRS89 koordinaattijärjestelmän tunnus (esim. ETRS89 / GK25FIN)<br/>http://uri.suomi.fi/codelist/rakrek/ETRS89 */
  "etrs-gk-kaista"?: string;
  /**
   * Pohjoiskoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen pohjoiskoordinaatti desimaalimuotoisena.
   * @format double
   */
  pohjoiskoordinaatti?: number;
  /**
   * Itäkoordinaatti: ETRS-GK koordinaattijärjestelmän mukainen itäkoordinaatti desimaalimuotoisena.
   * @format double
   */
  itakoordinaatti?: number;
  /** Primääri: Primääri-attribuutilla kuvataan ensijaista kohdetta, jolle asetetaan arvoksi 1. */
  primaari?: boolean;
  /** Sisäänkäynnin tunnus */
  sisaankaynnintunnus?: string;
}

/** RAK_Talousvesi */
export interface RAKTalousvesi {
  /** Talousveden laji<br/>http://uri.suomi.fi/codelist/rytj/talousvesi */
  talousvedenlaji?: string;
}

/**
 * RAK_Tila
 * Yläluokka: Yläluokka
 */
export interface RAKTila {
  /** Pysyvä tilatunnus */
  pysyvatilatunnus?: string;
  /**
   * Tilavuus: tilan vaipan sisäpintojen rajaama tilavuus
   * @format double
   */
  tilavuus?: number;
  /** Tilan nimi: Tilan ihmisluettava nimi, esimerkiksi. "Luokka 2A", "pukuhuone" */
  tilannimi?: string;
  /**
   * Pohjapinta-ala: Jonkin tilan pohjaksi laskettavan osuuden pinta-ala
   * @format double
   */
  "pohjapinta-ala"?: number;
  /** Tilatyyppi: tilan luokittelu jonkin taksonomian (esim TAKU tai TALO2000 tilanimikkeistön) mukaan */
  tilatyyppi?: string;
  /** Tilalaji: Huoneen tai muun tilan käyttötarkoituksen laji<br/>http://uri.suomi.fi/codelist/jhs/huonelaji */
  tilalaji?: string;
  /** Tilan käyttötarkoitus: Sisätilan käyttötarkoitus */
  tilankayttotarkoitus?: string;
  /** On asuinhuone: täyttääkö tila asuinhuoneen kriteerit */
  onasuinhuone?: boolean;
  /**
   * Huoneala: ala, jonka rajoina ovat huonetta ympäröivien seinien pinnat tai niiden ajateltu jatke
   * @format double
   */
  huoneala?: number;
  /**
   * Kokoontumistilan henkilömäärä: Kokoontumistilaan hyväksyttävä enimmäishenkilömäärä.
   * @format int64
   */
  kokoontumistilanhenkilomaara?: number;
  /** Tilaryhmä: Tilaryhmä johon tila liittyy */
  tilaryhma?: string;
  /** Tilan varuste */
  tilanvaruste?: RAKVaruste[];
  versiointitiedot?: RYTJVersiointitiedot;
}

/** RAK_Tonttijakotontti */
export interface RAKTonttijakotontti {
  /** Liittyvä kaavayksikkö */
  liittyvakaavayksikko?: RAKKaavayksikko[];
}

/** RAK_TunnuksettomanOmistajanTunnustiedot */
export interface RAKTunnuksettomanOmistajanTunnustiedot {
  /** Rakennuksen omistajan poiston syy */
  rakennuksenomistajanpoistonsyy?: string;
  /**
   * Rakennuksen omistajan poistopäivämäärä
   * @format date-time
   */
  rakennuksenomistajanpoistopaivamaara?: string;
  /** Tunnuksettoman omistajan kotimainen tai ulkomainen osoite */
  tunnuksettomanomistajankotimainentaiulkomainenosoite?: string;
  /** Tunnuksettoman omistajan tunnistetiedot */
  tunnuksettomanomistajantunnistetiedot?: string;
}

/**
 * RAK_Tunnus
 * Alueidenkäyttöpäätöksen tunnus
 */
export interface RAKTunnus {
  /** Tunnus: merkkijono, jota voidaan käyttää yksilöintiin tietyn järjestelmän sisällä */
  tunnus?: string;
  /** Tunnuksen antaja: Taho, joka on alunperin antanut kyseisen tunnuksen. Esim. kunta */
  tunnuksenantaja?: string;
}

/** RAK_Varuste */
export interface RAKVaruste {
  /** Varusteen tyyppi: Ylätason luokittelu, minkä tyyppisestä varusteesta on kyse<br/>http://uri.suomi.fi/codelist/rytj/VarusteenLaji */
  varusteentyyppi?: string;
  /** Varuste: Rakennukseen, rakennelmaan tai huoneistoon kiinteästi asennettu järjestelmä tai laite. */
  varuste?: string;
  versiointitiedot?: RYTJVersiointitiedot;
}

/** RAK_Verkostoliittyma */
export interface RAKVerkostoliittyma {
  /** Verkostoliittymän laji<br/>http://uri.suomi.fi/codelist/rytj/verkliit */
  verkostoliittymanlaji?: string;
}

/** RAK_Viemarointitapa */
export interface RAKViemarointitapa {
  /** Jätevesien käsittelyn laji<br/>http://uri.suomi.fi/codelist/rytj/jatevesienkasittely */
  viemarointitavanlaji?: string;
  /** Huleveden käsittelyn laji */
  hulevedenkasittelynlaji?: string;
}

/**
 * RYTJ_Lupahakemus
 * Lupahakemus
 */
export interface RYTJLupahakemus {
  /** Yläluokka: Yläluokka */
  ylaluokka?: RAKHakemus;
  /** Sijoittamis-/toteuttamislupa: Haetaanko lupaa sijoittamiselle, toteuttamiselle vai molemmille<br/>http://uri.suomi.fi/codelist/rytj/LuvanSisalto */
  "sijoittamis-toteuttamislupa"?: string;
  /** Rakentamislupahakemuksen tyyppi: Hakemuksen tyyppi kuvaa, mistä asiassa on kysymys, onko kyseessä rakentamislupa-, purkamislupa-, maisematyöasia jne.<br/>http://uri.suomi.fi/codelist/rytj/HakemuksenTyyppi */
  rakentamislupahakemuksentyyppi?: string;
  rakentamishanke?: RAKRakentamishanke;
  /** Haetut poikkeamiset */
  haetutpoikkeamiset?: RAKPoikkeaminen[];
  /** Lupahakemus */
  liittyvalupahakemus?: RYTJLupahakemus;
}

/** RYTJ_Versiointitiedot */
export interface RYTJVersiointitiedot {
  /** Nimiavaruus: Tietomallin objektien pysyvien tunnusten yhteinen osa */
  nimiavaruus?: string;
  /** Viittaustunnus: Globaalisti yksilöivä, pysyvä tunnus tietomallin objektille. Muodostetaan liittämällä nimiavaruus ja paikallinen tunnus yhteen. */
  viittaustunnus?: string;
  /** Identiteettitunnus: pysyvä ja kansallisesti yksilöivä tunnus, joka kytkeytyy kohteen identiteettiin. Tunnus säilyy sellaisissa muutoksissa, joiden jälkeen kohteen identiteetin katsotaan olevan oleellisesti sama kuin ennen muutosta. */
  identiteettitunnus?: string;
  /** Tuottajakohtainen tunnus: Kohteen pysyvä tunnus RYTJ:n ulkopuolella, esimerkiksi tiedon tuottajan tietojärjestelmässä */
  tuottajakohtainentunnus?: string;
  /** Version käsittelijä: Version päivittäneen tai tallentaneen käyttäjän organisaatio. */
  versionkasittelija?: string;
  /**
   * Viimeisin muutos: Ajanhetki, jolloin objektiin on tehty viimeisin sisällöllinen muutos
   * @format date-time
   */
  viimeisinmuutos?: string;
  /**
   * Tallennusaika: Ajanhetki, jolloin tieto on tallennettu onnistuneesti RYTJ:ään
   * @format date-time
   */
  tallennusaika?: string;
  korvaaobjektin?: RYTJVersiointitiedot;
  korvattuobjektilla?: RYTJVersiointitiedot;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://kaavatietomalli.azurewebsites.net/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Kaavatietomalli API
 * @version 0.0.1
 * @baseUrl https://kaavatietomalli.azurewebsites.net/api
 *
 * Tämä API on luotu <a href="https://tietomallit.suomi.fi/model/rytj/"/>RYTJ</a> tietomallin mukaisesti. Tämä API palauttaa pääasiassa keksittyjä tietoja, osa tiedoista voi kuitenkin olla oikeanlaisia, kuten karttaan pohjautuvat geometriat.<br/>Yleisesti REST API toteutuksissa GET toiminnoilla haetaan tietoja, POST toiminnallisuuksilla tallennetaan tietoja, UPDATE toiminnoilla päivitetään tietoja ja DELETE toiminnoilla poistetaan tietoja.Tämän API:n tapauksessa POST-komennoilla voidaan myös päivittää tietoja, jos tieto on jo olemassa samalla tunnuksella, se tekee siitä uuden version jäettäen aikaisemmat tiedot talteen. Poiston avulla voi palauttaa aikaisemman version, jos sellainen on olemassa.POST ja DELETE toimintoihin tarvitsee API-avaimen, sen avulla voi avata lukot. API avainta ei saa käyttää suoraan avoimella Web-sivulla, jottei ulkopuolisit pääsisi selaimen kautta käsiksi avaimeen. Avain tulisi vaihtaa, jos on epäilys, että joku ulkopuolinen on päässyt käsiksi siihen.<br/>Huom, Get kyselyt pyytää selainta cachettamaan tuloksen päivän ajaksi, eli data ei päivity heti ellei vaihda kyselyn parametrejä tai pakota selainta hakemaan sen ilman cachea.Tätä 0.0.1 versiota tehtäessä RYTJ-tietomalli oli vasta kehitysvaiheessa ja oli osittain viallinen mallien linkityksien nimien suhteen. Esim. samalla mallilla saattoi olla useampi 'koostuu'- tai 'sisaltaa'-linkki, mutta JSON tiedostoon tulostui vain yksittäisen linkin tiedot. Tämän johdosta kaikkia tietomallin malleja ei voitu ottaa käyttöön.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  kaava = {
    /**
     * @description Hakee kaikki kaavat käyttäen viimeisintä versiointitietoa. Se ei lataa alitietoja, ellei niitä ole listattu **select** kentässä.
     *
     * @tags Kaava
     * @name KaavaGetAll
     * @summary Hakee kaikki kaavat
     * @request GET:/kaava
     */
    kaavaGetAll: (
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista alitiedoista. Alitiedojen alitiedot erotellaan pisteillä.
         * @example "ylaluokka,koostuu"
         */
        select?: string;
        /**
         * Sivustusta varten, tämän avulla voi kertoa mistä rivistä lähtien tietoja halutaan lukea.
         * @format int32
         * @example "0"
         */
        skip?: number;
        /**
         * Sivustusta varten, tämän avulla voidaan kertoa kuinka monta riviä halutaan lukea kerralla.
         * @format int32
         * @example "10"
         */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKKaava[], any>({
        path: `/kaava`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Huom. **versiointitiedoissa** pitää antaa Identiteettitunnus. Palvelin kykenee täyttämään versiointitietojen muut tiedot itsenäisesti niitä ei tarvitse antaa.
     *
     * @tags Kaava
     * @name KaavaPost
     * @summary Tallentaa uuden version kaavasta ja sen sisällöstä, jos kaavaa ei ole olemassa, se luo ensimmäisen version.
     * @request POST:/kaava
     * @secure
     */
    kaavaPost: (
      data: RAKKaava,
      query?: {
        /** Salli vain luonti. Jos tämä on asetettu todeksi, ei se yritä päivittää olemassa olevaa. */
        createOnly?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKKaava, void>({
        path: `/kaava`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Hakee kaavan viimeisimmän version käyttäen versiointitietojen identiteetti tunnusta.
     *
     * @tags Kaava
     * @name KaavaGetById
     * @summary Hakee kaavan tunnuksella
     * @request GET:/kaava/{id}
     */
    kaavaGetById: (
      id: string,
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä.
         * @example "ylaluokka,koostuu"
         */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKKaava, void>({
        path: `/kaava/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Poistaa kaavan viimeisimmän version ja kaikki sen sisällöt. Jos deleteAllVersions on asetettu todeksi, niin se poistaa myös vanhemmat versiot.
     *
     * @tags Kaava
     * @name KaavaDelete
     * @summary Poistaa kaavan viimeisimmän version
     * @request DELETE:/kaava/{id}
     * @secure
     */
    kaavaDelete: (
      id: string,
      query?: {
        /** Jos tämä on asetettu todeksi, poistaa se myös kaikki vanhemmat versiot. */
        deleteAllVersions?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, void>({
        path: `/kaava/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Hakee kaikki kaavakohteen käyttäen viimeisintä versiontitietoa. Jos haluat ladata myös alikenttien tiedot, lisää niiden kenttien nimet **select** listaan.
     *
     * @tags Kaavakohde
     * @name KaavakohdeGetAll
     * @summary Hakee kaikki kaavakohteet kaavan tunnuksella
     * @request GET:/kaava/{kaavatunnus}/kaavakohde
     */
    kaavakohdeGetAll: (
      kaavatunnus: string,
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä.
         * @example "kaavamaarays,kaavasuositus"
         */
        select?: string;
        /**
         * Sivustusta varten, tämän avulla voi kertoa mistä rivistä lähtien tietoja halutaan lukea.
         * @format int32
         * @example "0"
         */
        skip?: number;
        /**
         * Sivustusta varten, tämän avulla voidaan kertoa kuinka monta riviä halutaan lukea kerralla.
         * @format int32
         * @example "10"
         */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKKaavakohde[], any>({
        path: `/kaava/${kaavatunnus}/kaavakohde`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Tallentaa kaavakohteita kaavaan
     *
     * @tags Kaavakohde
     * @name KaavakohdePost
     * @summary Kaavakohteiden lisäys/päivitys kaavaan
     * @request POST:/kaava/{kaavatunnus}/kaavakohde
     * @secure
     */
    kaavakohdePost: (kaavatunnus: string, data: RAKKaavakohde[], params: RequestParams = {}) =>
      this.request<RAKKaavakohde[], void>({
        path: `/kaava/${kaavatunnus}/kaavakohde`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Poistaa annetun kaavan kaikki kaavakohteet versiointitietoineen.
     *
     * @tags Kaavakohde
     * @name KaavakohdeDeleteAll
     * @summary Poistaa kaavan kaikki kaavakohteet
     * @request DELETE:/kaava/{kaavatunnus}/kaavakohde
     * @secure
     */
    kaavakohdeDeleteAll: (kaavatunnus: string, params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/kaava/${kaavatunnus}/kaavakohde`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Hakee kaikki kaavakohteen käyttäen viimeisintä versiontitietoa. Jos haluat ladata myös alikenttien tiedot, lisää niiden kenttien nimet **select** listaan.
     *
     * @tags Kaavakohde
     * @name KaavakohdeGet
     * @summary Hakee yksittäisen kaavakohteet tiedot tunnuksella
     * @request GET:/kaava/{kaavatunnus}/kaavakohde/{kaavakohteentunnus}
     */
    kaavakohdeGet: (
      kaavatunnus: string,
      kaavakohteentunnus: string,
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä.
         * @example "kaavamaarays,kaavasuositus"
         */
        select?: string;
        /**
         * Jos tämä asetettu, se palauttaa kaavakohteen version versiointitietojen tallennusajan perusteella. Muulloin se palauttaa viimeisimmän version.
         * @format date-time
         */
        versionDateAndTime?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKKaavakohde[], any>({
        path: `/kaava/${kaavatunnus}/kaavakohde/${kaavakohteentunnus}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Poistaa kaavakohteen kaavan ja kaavakohteen tunnuksien avulla
     *
     * @tags Kaavakohde
     * @name KaavakohdeDelete
     * @summary Poistaa kaavakohteen
     * @request DELETE:/kaava/{kaavatunnus}/kaavakohde/{kaavakohdetunnus}
     * @secure
     */
    kaavakohdeDelete: (
      kaavatunnus: string,
      kaavakohdetunnus: string,
      query: {
        /** Poista vain viimeisin versio */
        onlyDeleteLatestVersion: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, void>({
        path: `/kaava/${kaavatunnus}/kaavakohde/${kaavakohdetunnus}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  rakennuskohde = {
    /**
     * No description
     *
     * @tags Katselmus
     * @name KatselmusGetById
     * @summary Katselmusen haku rakennuskohteen tunnuksella
     * @request GET:/rakennuskohde/{id}/katselmus
     */
    katselmusGetById: (
      id: string,
      query?: {
        /** Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä. */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKKatselmus, void>({
        path: `/rakennuskohde/${id}/katselmus`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Katselmusen tallennus ja päivitys rakennuskohteelle.
     *
     * @tags Katselmus
     * @name KatselmusPost
     * @summary Katselmuksen tallennus
     * @request POST:/rakennuskohde/{id}/katselmus
     * @secure
     */
    katselmusPost: (id: string, data: RAKKatselmus, params: RequestParams = {}) =>
      this.request<RAKKatselmus, any>({
        path: `/rakennuskohde/${id}/katselmus`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Rakennuskohteen tallennus. Ei päivitä olemassa olevia, jos löytyy tunnuksella.
     *
     * @tags Rakennus
     * @name RakennusPost
     * @summary Rakennuskohteen tallennus
     * @request POST:/rakennuskohde/{rakennuskohteentunnus}/rakennus
     * @secure
     */
    rakennusPost: (rakennuskohteentunnus: string, data: RAKRakennus, params: RequestParams = {}) =>
      this.request<RAKRakennus, any>({
        path: `/rakennuskohde/${rakennuskohteentunnus}/rakennus`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Hakee kaikki rakennuskohteet käyttäen viimeisintä versiointitietoa. Se ei lataa alikenttien tietoja, ellei niitä ole listattu **select** kentässä.
     *
     * @tags Rakennuskohde
     * @name RakennuskohdeGetAll
     * @summary Hakee kaikki rakennuskohteet
     * @request GET:/rakennuskohde
     */
    rakennuskohdeGetAll: (
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista alitiedoista. Alitietojen alitiedot erotellaan pisteillä.
         * @example "rakennuskohteentunnus"
         */
        select?: string;
        /**
         * Sivustusta varten, tämän avulla voi kertoa mistä rivistä lähtien tietoja halutaan lukea.
         * @format int32
         * @example "0"
         */
        skip?: number;
        /**
         * Sivustusta varten, tämän avulla voidaan kertoa kuinka monta riviä halutaan lukea kerralla.
         * @format int32
         * @example "10"
         */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKRakennuskohde[], any>({
        path: `/rakennuskohde`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Rakennuskohteen tallennus. Ei päivitä olemassa olevia, jos löytyy tunnuksella.
     *
     * @tags Rakennuskohde
     * @name RakennuskohdePost
     * @summary Rakennuskohteen tallennus
     * @request POST:/rakennuskohde
     * @secure
     */
    rakennuskohdePost: (data: RAKRakennuskohde, params: RequestParams = {}) =>
      this.request<RAKRakennuskohde, any>({
        path: `/rakennuskohde`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Rakennuskohteen haku tunnuksella VTJprt tunnuksella
     *
     * @tags Rakennuskohde
     * @name RakennuskohdeGetById
     * @summary Rakennuskohteen haku tunnuksella
     * @request GET:/rakennuskohde/{id}
     */
    rakennuskohdeGetById: (
      id: string,
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä.
         * @example "rakennuskohteentunnus"
         */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKRakennuskohde, void>({
        path: `/rakennuskohde/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Poistaa rakennuskohteen viimeisimmän version ja kaikki sen sisällöt. Jos deleteAllVersions on asetettu todeksi, niin se poistaa myös vanhemmat versiot.
     *
     * @tags Rakennuskohde
     * @name RakennuskohdeDelete
     * @summary Poistaa rakennuskohteen viimeisimmän version
     * @request DELETE:/rakennuskohde/{id}
     * @secure
     */
    rakennuskohdeDelete: (
      id: string,
      query?: {
        /** Jos tämä on asetettu todeksi, poistaa se myös kaikki vanhemmat versiot. */
        deleteAllVersions?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, void>({
        path: `/rakennuskohde/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  paatos = {
    /**
     * No description
     *
     * @tags Paatos
     * @name PaatosGetById
     * @summary Päätöksen haku tunnuksella
     * @request GET:/paatos/{id}
     */
    paatosGetById: (
      id: string,
      query?: {
        /** Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä. */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKPaatos, void>({
        path: `/paatos/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Paatos
     * @name PaatosPutRakennuskohdeById
     * @summary Päätöksen haku tunnuksella
     * @request PUT:/paatos/{paatosId}/rakennuskohde/{rakennuskohdeId}
     * @secure
     */
    paatosPutRakennuskohdeById: (
      id: string,
      paatosId: string,
      rakennuskohdeId: string,
      query?: {
        /** Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä. */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, void>({
        path: `/paatos/${paatosId}/rakennuskohde/${rakennuskohdeId}`,
        method: "PUT",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Päätöksen tallennus.
     *
     * @tags Paatos
     * @name PaatosPost
     * @summary Päätöksen tallennus
     * @request POST:/paatos/{paatosId}/hakemus/{hakemusId}
     * @secure
     */
    paatosPost: (id: string, paatosId: string, hakemusId: string, data: RAKPaatos, params: RequestParams = {}) =>
      this.request<RAKPaatos, any>({
        path: `/paatos/${paatosId}/hakemus/${hakemusId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rakentamishanke
     * @name RakentamishankeGetByIdViaPaatos
     * @summary Rakentamishankeen haku päätöksen tunnuksella
     * @request GET:/paatos/{id}/hanke
     */
    rakentamishankeGetByIdViaPaatos: (
      id: string,
      query?: {
        /** Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä. */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKRakentamishanke, void>({
        path: `/paatos/${id}/hanke`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  rakennus = {
    /**
     * @description Hakee kaikki rakennukset käyttäen rakennuskohteen viimeisintä versiointitietoa. Se ei lataa alikenttien tietoja, ellei niitä ole listattu **select** kentässä.
     *
     * @tags Rakennus
     * @name RakennusGetAll
     * @summary Hakee kaikki rakennukset
     * @request GET:/rakennus
     */
    rakennusGetAll: (
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista alitiedoista. Alitietojen alitiedot erotellaan pisteillä.
         * @example "rakennuksenosa.huoneisto.koostuu,rakennuksenosa.tila3.tilanvaruste"
         */
        select?: string;
        /**
         * Sivustusta varten, tämän avulla voi kertoa mistä rivistä lähtien tietoja halutaan lukea.
         * @format int32
         * @example "0"
         */
        skip?: number;
        /**
         * Sivustusta varten, tämän avulla voidaan kertoa kuinka monta riviä halutaan lukea kerralla.
         * @format int32
         * @example "10"
         */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKRakennus[], any>({
        path: `/rakennus`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Rakennuskohteen haku tunnuksella VTJprt tunnuksella
     *
     * @tags Rakennus
     * @name RakennusGetById
     * @summary Rakennuskohteen haku tunnuksella
     * @request GET:/rakennus/{id}
     */
    rakennusGetById: (
      id: string,
      query?: {
        /**
         * Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä.
         * @example "rakennuksenosa.huoneisto.koostuu,rakennuksenosa.tila3.tilanvaruste"
         */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKRakennus, void>({
        path: `/rakennus/${id}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  hakemus = {
    /**
     * No description
     *
     * @tags Rakentamishanke
     * @name RakentamishankeGetById
     * @summary Rakentamishankeen haku päätöksen tunnuksella
     * @request GET:/hakemus/{id}/hanke
     */
    rakentamishankeGetById: (
      id: string,
      query?: {
        /** Pilkulla eroteltu lista ladattavista tiedoista. Alitaulut erotellaan pisteillä. */
        select?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RAKRakentamishanke, void>({
        path: `/hakemus/${id}/hanke`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Rakentamishankeen tallennus, tämä lisää automaattisesti hakemuksen, jos sitä ei vielä ole.
     *
     * @tags Rakentamishanke
     * @name RakentamishankePost
     * @summary Rakentamishankkeen tallennus
     * @request POST:/hakemus/{id}/hanke
     * @secure
     */
    rakentamishankePost: (id: string, data: RAKRakentamishanke, params: RequestParams = {}) =>
      this.request<RAKRakentamishanke, any>({
        path: `/hakemus/${id}/hanke`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  versiointitiedot = {
    /**
     * @description Hakee versiontitietoja nimiavaruuden kanssa
     *
     * @tags Versiointitiedot
     * @name VersiointitiedotGet
     * @summary Hakee versiointitietoja
     * @request GET:/versiointitiedot
     */
    versiointitiedotGet: (
      query: {
        /**
         * Suodattaa hakua nimiavaruuden avulla
         * @example "RAK_Kaava"
         */
        nimiavaruus: string;
        /** Hakee vain viimeisimmät versiotiedot */
        onlyLatestVersions?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<RYTJVersiointitiedot[], any>({
        path: `/versiointitiedot`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
}
