import React from 'react';
import {BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {css, Global} from '@emotion/core'
import {Heading, suomifiDesignTokens as tokens} from 'suomifi-ui-components';
import {
  ContentContainer,
  HeaderBackground,
  TopNavigationBackground
} from "./components/common/CommonStyles";
import Header from "./components/common/Header";
import Navigation from "./components/common/Navigation";
import AssetView from "./components/pages/vero/assetView";
import Home from "./components/home/Home";
import Modal from 'react-modal';
import KuntaView from './components/pages/kunta/kuntaView';
import HuoneistoView from './components/pages/huoneisto/huoneistoView';
import KiinteistoView from './components/pages/kiinteisto/kiinteistoView';
import OsoiteView from './components/pages/osoite/osoiteView';
import EnergiaView from './components/pages/energia/energiaView';
import ApiFrameworksView from './components/pages/rajapinnat/apiFrameworksView';
import RhrView from './components/pages/rhr/rhrView';
import  BuildingPicker from './components/buildingPicker';
import { Building } from './model';
import energyData from './components/pages/energia/energyData';
import Spinner from './components/common/Spinner';
import { BuildingPermitAPI, BuildingPlanAPI, EnergyApi, HuoneistotiedotAPI, KoodistoAPI, PropertyApi, RhrApi, TaxAdministrationApi } from './utils/api';
import { OwnerAddress } from './model/ownerAddress';
import { ParsingUtils } from './utils/parsingUtils';
import { EnergyBasics } from './model/energyBasics';
import { EnergyInitialData } from './model/energyInitialData';
import { Property } from './model/property';
import BuildingInfo from './components/pages/rakennus/buildingInfo';
import AreaView from './components/pages/alue/areaView';
import { ConstructionSupervisionCase } from './model/buildingPermit/constructionSupervisionCase';
import AiheetView from './components/aiheet/AiheetView';
import { BuildingPlan } from './model/buildingPlan/buildingPlan';
import { Yhtio } from './model/Huoneistotiedot';
import TaxBuilding from './model/tax/taxBuilding';
import TaxBuildingSection from './model/tax/taxBuildingSection';
import HuoltokirjaView from './components/pages/huoltokirja/huoltokirjaView';
import { KiinteistonHuoltokirja } from './model/servicePlan/kiinteistonHuoltokirja';
import { CodeLang, CodeList, getCodeItemLabelByCode } from './model/koodisto/Koodistot';
import OmaBreadcrumbs from './components/OmaBreadcrumbs';
import { Api as KaavatietomalliApi, RAKKaava, RAKRakennus, RAKRakennuskohde } from './api/kaavatietomalli/kaavatietomalli-api';
import CatalogueProductItemView from './components/pages/huoltokirja/CatalogueProductItemView';

export interface buildingAppData{
      open: boolean;
      selectedValue: string;
      taxAdministrationBuilding: TaxBuilding;
      buildingData: Building;
      energyData: energyData;
      address?: OwnerAddress;
      property: Property | null;
      buildingCase: ConstructionSupervisionCase;
      buildingPlan: BuildingPlan;
      loading: boolean;
      huoneistoData: Yhtio;
      rakennus: RAKRakennus|null; // selected RAKRakennus
      rakennuskohteet: RAKRakennuskohde[];
      kaavat: RAKKaava[];
      //kaava: RAKKaava; // selected RAKKaava
      elinkaariKoodisto?: CodeList; // TODO figure out better place to store this...
      teemaKoodisto?: CodeList; // TODO yhdistä kooditiedostot, lataa suoraan koodiin?
}

export default class App extends React.Component<any, buildingAppData> {

  constructor(props : any) {
    super(props);

    this.state = {
      open: false,
      selectedValue: "100173167F", //"100173167F", //"101848155B",
      taxAdministrationBuilding: {} as TaxBuilding,
      buildingData: {} as Building,
      energyData: {} as energyData,
      address: undefined,
      property: {} as Property,
      buildingCase: {} as ConstructionSupervisionCase,
      buildingPlan: {} as BuildingPlan,
      loading: false,
      huoneistoData: {} as Yhtio,
      rakennus: null as RAKRakennus | null,
      rakennuskohteet: [] as RAKRakennuskohde[],
      kaavat: [] as RAKKaava[]
    };
  }

  async componentDidMount() {
    // TODO muuta sivuston rakennetta, nyt hankala saada nämä parametrit...
    const params = window.location.href.split("/");
    if (params.length >= 2 && params[params.length - 2] === "rakennus") {
      this.handleChange(params[params.length - 1]);
    }
    else {
      //this.setState({ selectedValue: });
      this.handleChange(this.state.selectedValue);
    }

    try {
      const kaavatietomalliApi = new KaavatietomalliApi(); 
      const [rakennuskohteet, kaavat, elinkaariKoodisto, teemaKoodisto] = await Promise.all([
        (await kaavatietomalliApi.rakennuskohde.rakennuskohdeGetAll({select: "rakennuskohteentunnus"})).data,
        (await kaavatietomalliApi.kaava.kaavaGetAll({select: "versiointitiedot"})).data,
        new KoodistoAPI().GetCodeList("/data/kaavatiedot/codelist_RY_KaavanElinkaaritila.json"),
        new KoodistoAPI().GetCodeList("/data/kaavatiedot/codelist_RY_Kaavoitusteema_YK.json")
      ]);

      // const res = ;
      // const kaavat = res.data;
      this.setState({ rakennuskohteet: rakennuskohteet });
      this.setState({ kaavat: kaavat });
      this.setState({ elinkaariKoodisto: elinkaariKoodisto });
      this.setState({ teemaKoodisto: teemaKoodisto });
    } catch (e) {
      console.error(e);
    }
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleChange(value?: string) {
    if (value !== undefined) {
      this.setState({ open: false, loading: true });
      const loadBuildings = new TaxAdministrationApi().GetBuildingData(value).then(response => {
        var t = new TaxBuilding();
        t.Clone(response);
        if (response.Sections !== undefined && response.Sections.length > 0) {
          t.Sections = [];
          response.Sections.forEach(s => {
            var section = new TaxBuildingSection();
            section.Clone(s);
            t.Sections.push(section);
          });
        }
        this.setState( {
          selectedValue: value,
          taxAdministrationBuilding: t,
        });
      });

      const loadProperty = new PropertyApi().GetPropertyData(value).then(response => {
        this.setState({
          property: response ? ParsingUtils.ParseProperty(response) : null
        });
      });

      const loadBuildingData = RhrApi.getBuildingById(value).then(response => {
        this.setState( {
          selectedValue: value,
          buildingData: response as Building,
        });
      });

      new BuildingPermitAPI().GetBuildingPermit(value).then(response => {
        const b = new ConstructionSupervisionCase();
        b.Clone(response);

        this.setState( {
          buildingCase : b
        });
      });

      const loadEnergyData = new EnergyApi().GetEnergyData(value);
      const loadEnergyInitialData = new EnergyApi().GetEnergyInitialData(value);
      Promise.all([loadEnergyData, loadEnergyInitialData]).then(r => {
        this.setState( {
          selectedValue: value,
          energyData: {
            basicEnergyData:  ParsingUtils.CastObject(r[0], () => new EnergyBasics()),
            initialEnergyData: ParsingUtils.CastObject(r[1], () => new EnergyInitialData()),
          }
        });
      });

      const loadAddress = new TaxAdministrationApi().GetAddress(value).then(response => {
        // YUCK! Disgusting hack but seemes to work - the coordinates were quickly stored as a string in the DB  :D
        if (response !== undefined) {
          response.Coordinates = JSON.parse(response.Coordinates as any);
        }
       
        this.setState( {
          selectedValue: value,
          address: response
        });
      });

      const loadBuildingPlan = new BuildingPlanAPI().GetBuildingPlan(value).then(r =>  {
        // TODO mieti parempi ratkaisu...
        if (this.state.elinkaariKoodisto && r && r.Target) {
          if (r.Target.PlanRegulation?.LifeCycleState) {
            r.Target.PlanRegulation.LifeCycleState = getCodeItemLabelByCode(this.state.elinkaariKoodisto, CodeLang.FI, r.Target.PlanRegulation.LifeCycleState);
          }
          if (r.Target.PlanRecommandation?.LifeCycleState) {
            r.Target.PlanRecommandation.LifeCycleState = getCodeItemLabelByCode(this.state.elinkaariKoodisto, CodeLang.FI, r.Target.PlanRecommandation.LifeCycleState);
          }
        }       
        if (this.state.teemaKoodisto && r && r.Target) {
          if (r.Target.PlanRecommandation?.PlanTheme) {
            r.Target.PlanRecommandation.PlanTheme = getCodeItemLabelByCode(this.state.teemaKoodisto, CodeLang.FI, r.Target.PlanRecommandation.PlanTheme);
          }
        }
       
        const plan = new BuildingPlan();
        plan.Clone(r);

        this.setState( {
          selectedValue : value,
          buildingPlan: plan
        })
      });

      const loadHuoneistoData = new HuoneistotiedotAPI().GetHuoneistotiedot(value).then(response => {
        if (response) {
          var yhtio = new Yhtio();
          yhtio.Clone(response);
          this.setState( {
            selectedValue: value,
            huoneistoData: yhtio
          });
        }
        else {
          this.setState( {
            selectedValue: value,
            huoneistoData: {} as Yhtio
          });
        }
      });

     

      
      // TODO: urlista tulee liian pitkä?
      const loadRakennus = new KaavatietomalliApi().rakennus.rakennusGetById(value, { select: 
        "ylaluokka,rakennuksenosa.huoneisto.koostuu," + //rakennuksenosa.sisaankaynti,rakennuksenosa.tila3.tilanvaruste," +
        //"rakennuksenosa.verkostoliittyma1,rakennuksenosa.hissi1,rakennuksenosa.julkisivunRakennusaine1," +
        //"rakennuksenosa.huoneisto," +

"rakennuksenosa.tila3," + // .tilanvaruste
"rakennuksenosa.sisaankaynti," +
// "rakennuksenosa.sijainti," +
"rakennuksenosa.verkostoliittyma1," +
"rakennuksenosa.lammitysenergianlahde1," +
"rakennuksenosa.jaahdytysenergianlahde1," +
"rakennuksenosa.hissi1," +
"rakennuksenosa.ilmanvaihtotapa1," +
"rakennuksenosa.jaahdytystapa1," +
"rakennuksenosa.lammitystapa1," +
"rakennuksenosa.viemarointitapa1," +
"rakennuksenosa.ostoenergia1," +
"rakennuksenosa.rakennuksenOmistaja1," +
"rakennuksenosa.julkisivunRakennusaine1," +
"rakennuksenosa.kantavanRakenteenRakennusaine1," +

// "rakennuksenosa.jalanjalkiprojektionNurkkapiste1," +
// "rakennuksenosa.referenssipiste1," +
"rakennuksenosa.sahkoenergianlahde1," +
"rakennuksenosa.kayttotarkoitus1," +

//"rakennuksenosa.rakennuksenHaltija1," +
"rakennuksenosa.talousvesi1"} ).then(response => {
        if (response && response.data) {
          this.setState({ 
            selectedValue: value,
            rakennus: response.data as RAKRakennus 
          });
        }
        else {
          this.setState({ 
            selectedValue: value,
            rakennus: null 
          });
        }
      });

      Promise.all([loadRakennus, loadBuildings, loadBuildingData, loadEnergyData, loadEnergyInitialData, loadAddress, loadProperty, loadBuildingPlan, loadHuoneistoData]).then(done => {
        this.setState({ loading: false });
      });
    }
    else {
      this.setState({ open: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Global styles={css`
          body {
            background-color:  ${tokens.colors.whiteBase};
            font-family: ${tokens.values.typography.bodyText.fontFamily};
            font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: ${tokens.values.typography.bodyText.lineHeight.value}${tokens.values.typography.bodyText.lineHeight.unit};
            margin: 0;
          }
          a {
            color: ${tokens.colors.highlightBase};
            text-decoration: none; 
          }
          a:visited {
            color: ${tokens.colors.accentTertiaryDark1}
          }
          code {
            white-space: pre-wrap !important;
          }
          label {
            font-weight: ${tokens.values.typography.bodySemiBold.fontWeight}
          } 
          hr {
            border: 0;
            border-bottom: 1px solid ${tokens.colors.depthLight3};
            margin: ${tokens.spacing.m} 0;
          }
          ul, ol {
            margin: 0;
            padding: 0;
            @media (max-width: 1200px) {
              list-style-position: inside;              
            }
          }
          li {
            margin: 0;
            padding: 0;            
          }
          p {
            margin: ${tokens.spacing.m} 0;
          }
        `}
        />
        <Router>
          <HeaderBackground>
            <ContentContainer>
              <Header/>
            </ContentContainer>
          </HeaderBackground>

          <TopNavigationBackground>
            <ContentContainer>
              <Navigation/>
              
            </ContentContainer>
          </TopNavigationBackground>


          <ContentContainer css={css}>

            <Switch>
              <Route path="/home">
                <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"} ]}></OmaBreadcrumbs>
                <Home/>
              </Route>

              <Route path="/rakennus/:id?" render={(props) => 
                <React.Fragment>
                  <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"}, { text: "Rakennus", link: "/rakennus"}]}></OmaBreadcrumbs>

                  <BuildingPicker selectedValue={this.state.selectedValue} onChange={this.handleChange.bind(this)} data={this.state.buildingData} />  
        
                  <BuildingInfo data={this.state} id={props.match.params.id} />
                </React.Fragment>
              } />
              <Route path="/eHuoltoKirja">
              <div style={{width: 1500, marginLeft: -150}}>
                <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"}, { text: "Sähköinen huoltokirja", link: "/eHuoltokirja"}]}></OmaBreadcrumbs>

                <HuoltokirjaView building={this.state.buildingData} serviceplan={new KiinteistonHuoltokirja()}/>
                </div>
              </Route>

              <Route path="/product/:transaction/:productId">
                <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"}, { text: "Sähköinen huoltokirja", link: "/eHuoltokirja"}, { text: "Tuote", link: "/eHuoltoKirja/product/:transaction/:productId" }]}></OmaBreadcrumbs>
                <CatalogueProductItemView/>
              </Route>

              <Route path="/alue">
                <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"}, { text: "Alueidenkäyttö", link: "/alue" }]}></OmaBreadcrumbs>
                <AreaView data={this.state}/>
              </Route>

              <Route path="/rhr">
                <RhrView data={this.state.buildingData} />
              </Route>

              <Route path="/kunta">
                <KuntaView data={this.state.buildingData}/>
              </Route>

              <Route path="/vero">
                <AssetView data={this.state.taxAdministrationBuilding}/>
              </Route>

              <Route path="/huoneisto" >
                <HuoneistoView data={this.state.buildingData} yhtio={this.state.huoneistoData}/>
              </Route>

              <Route path="/kiinteisto">
                <KiinteistoView data={this.state.property}/>
              </Route>

              {/* TODO onko alempi käytössä, voiko poistaa? */}
              <Route path="/osoite" >
                <OsoiteView data={this.state.address}/>
              </Route>

              <Route path="/energia">
                <EnergiaView data={this.state.energyData}/>
              </Route>

              <Route path="/rajapinnat">
              <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"}, { text: "Rajapinnat", link: "/rajapinnat" }]}></OmaBreadcrumbs>
                <ApiFrameworksView/>
              </Route>
              
              <Route path="/omistus">
                <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"}, { text: "Omistus", link: "/omistus"}]}></OmaBreadcrumbs>
                <AiheetView data={this.state}/>
              </Route>

              {/* <Route path="/" exact>
                <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"} ]}></OmaBreadcrumbs>
                <Home/>
              </Route> */}

              <Route path="/" exact render={(props) => 
                <React.Fragment>
                  <OmaBreadcrumbs items={[ { text: "Etusivu", link: "/home"}, { text: "Rakennus", link: "/rakennus"}]}></OmaBreadcrumbs>

                  <BuildingPicker selectedValue={this.state.selectedValue} onChange={this.handleChange.bind(this)} data={this.state.buildingData} />  
        
                  <BuildingInfo data={this.state} />
                </React.Fragment>
              } />

            </Switch>
          </ContentContainer>
        </Router>

        <Modal isOpen={this.state.loading} style={{
          content: {
            top: '20%',
            margin: '0 auto',
            width: '150px',
            height: '150px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: tokens.spacing.xl,
            border: `0px`,
            borderRadius: "0px",
            background: 'transparent'
          }
        }} contentLabel="Ladataan...">
          <Spinner/>
          <Heading.h3 style={{marginTop: tokens.spacing.l}}>
            Ladataan...
          </Heading.h3>
        </Modal>
      </React.Fragment>
    );
};
}

//export default App;
