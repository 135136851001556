import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export enum LocationCreationMethodEnum {
    _01 = 1,
    _02 = 2,
    _03 = 3,
    _04 = 4,
    _05 = 5,
    _06 = 6,
    _07 = 7,
    _80 = 8
}

export enum LocationInaccuracyEnum {
    _01 = 1,
    _02 = 2,
    _03 = 3,
    _04 = 4,
    _05 = 5,
    _06 = 6,
    _07 = 7,
    _80 = 8,
    _90 = 9,
    A = 10,
    B = 11
}

export class Location extends Base {
    @Title("Sijainnin luontitapa")
    @Description("Tapa, jolla sijainti on luotu tai määritetty.")
    LocationCreationMethod: LocationCreationMethodEnum = LocationCreationMethodEnum._01;
    
    @Title("Sijaintiepävarmuus")
    @Description("Sijaintiepävarmuustasot")
    LocationInaccuracy: LocationInaccuracyEnum = LocationInaccuracyEnum._01;
    
    @Title("Aluemainen sijainti")
    @Description("Kohteen aluemainen sijainti")
    RegionalLocation: string = "";
    
    @Title("Koordinaatisto")
    @Description("Sijainnin määrittämiseen käytetty koordinaatisto")
    LocationCoordinateType: string = "";
    
    @Title("Pistemäinen sijainti")
    @Description("Koordinaattipiste")
    Coordinates: string = "";
    
    @Title("Viivamainen sijainti")
    @Description("Kohteen viivamainen sijainti")
    LinearLocation: string = "";
}
