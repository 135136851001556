import { Box } from '@material-ui/core';
import React from 'react'

export interface TabPanelProps {
    children?: React.ReactNode;
    prefix: string;
    index: number;
    value: number;
  }
  
export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, prefix, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${prefix}-${index}`}
      aria-labelledby={`${prefix}-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{ padding: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
};
