import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class PlanRecommendation extends Base {
    @Title("Elinkaaritila")
    @Description("elinkaaren tila, jossa kaavan versio on.")
    LifeCycleState: string = "";
    
    @Title("Teema")
    @Description("Kaavoituksen piiriin kuuluva temaattinen aihealue")
    PlanTheme: string = "";
}