/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TaxBuilding from '../../../model/tax/taxBuilding';
import GeneralTableView from '../../../utils/GeneralTableView';

interface Props {
  data: TaxBuilding
}

class RealEstateUnitView extends React.Component<Props, {data: TaxBuilding}> {
  constructor(props: Props) {
    super(props);
    this.state = {data: props.data};
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  render() {
    const taxView: any = 
    this.state.data === undefined || this.state.data === null
            ? <div>Ei verotietoja saatavilla</div>
            : <div>
                <Accordion defaultExpanded>
                 <AccordionSummary expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header">
                 <Typography><b>Rakennustiedot</b></Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                 <GeneralTableView data={this.state.data} flatView = {true}></GeneralTableView>
                 </AccordionDetails>
             </Accordion>
            </div>
    return (<div>{taxView}</div>);
  }
}

export default RealEstateUnitView;
