import React from "react";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import { buildingAppData } from "../../App";
import { TaloyhtiokohtaisetTiedot, TaloyhtiokohtaisetTiedotView, } from "./asunnonomistus/TaloyhtiokohtaisetTiedot";
import { TaloyhtionKiinteistonTiedot, TaloyhtionKiinteistonTiedotView } from "./asunnonomistus/TaloyhtionKiinteistonTiedot";
import { TaloyhtionAlueenKaavatiedot, TaloyhtionAlueenKaavatiedotView } from "./asunnonomistus/TaloyhtionAlueenKaavatiedot";


class ViewParts {
  // huoneistokohtaisetTiedot?: HuoneistokohtaisetTiedot;
  taloyhtiokohtaisetTiedot?: TaloyhtiokohtaisetTiedot;
  taloyhtionKiinteistonTiedot?: TaloyhtionKiinteistonTiedot;
  taloyhtionAlueenKaavatiedot?: TaloyhtionAlueenKaavatiedot;
}

export const mapToOKTViewParts = (data: buildingAppData): ViewParts | undefined => {
  if (data.buildingData.BuildingCategory2018 && data.buildingData.BuildingCategory2018 === "0110" && data.property) {
    const wp = new ViewParts();
    wp.taloyhtiokohtaisetTiedot = new TaloyhtiokohtaisetTiedot(undefined, data.buildingData, data.buildingData.BuildingSpecifications, data.energyData);
    wp.taloyhtionKiinteistonTiedot = new TaloyhtionKiinteistonTiedot(undefined, data.property);
    wp.taloyhtionAlueenKaavatiedot = new TaloyhtionAlueenKaavatiedot(data.buildingPlan);
    return wp;
  }
  return undefined;
}

interface Props {
  wp?: ViewParts;
}

const OmakotitalonOmistusView: React.FC<Props> = (props: Props) => {

  const OmakotitalonOmistusTable = () => {
    return (
      props.wp ? 
      <FlexCol>
        <FlexRow>
          <TaloyhtiokohtaisetTiedotView title={"Rakennuskohtaiset tiedot"} item={props.wp.taloyhtiokohtaisetTiedot} />
        </FlexRow>
        <FlexRow>
          <TaloyhtionKiinteistonTiedotView title={"Kiinteistön tiedot"} item={props.wp.taloyhtionKiinteistonTiedot} />
        </FlexRow>
        <FlexRow>
          <TaloyhtionAlueenKaavatiedotView title={"Kiinteistön alueen kaavatiedot"} item={props.wp.taloyhtionAlueenKaavatiedot} />
        </FlexRow>
      </FlexCol>
      : <div>Ei tietoja saatavilla</div>
    );
  }

  return (
    <>
      {OmakotitalonOmistusTable()}
    </>
  )
}

export default OmakotitalonOmistusView;