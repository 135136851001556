/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Building } from '../../../model';

interface Props {
  data: Building
}

class BasicInfoView extends React.Component<Props, {data: Building}> {
  constructor(props: Props, data : Building) {
      super(props);
      this.state = {data: props.data};
  }
  
  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  render() {
    return (
        <table>
            <tbody>
            <tr><td>Valmistumisvuosi</td><td>{this.state.data.CompletionYear ?? 'Ei tietoa'}</td></tr>
            <tr><td>Omistaja</td><td>{this.state.data.Owner ? this.state.data.Owner.Name : 'Ei tietoa'}</td></tr>
            <tr><td>Katuosoite</td><td>{this.state.data.Location?.Address?.nameFI ?? ''}</td></tr>
            <tr><td></td><td>{this.state.data.Location?.Address?.nameSV ? ` (${this.state.data.Location?.Address?.nameSV}) ` : ''}</td></tr>
            <tr><td></td><td>{this.state.data.Location?.Address?.number ?? ''}</td></tr>
            </tbody>
        </table>
      );
  }
}

export default BasicInfoView;
