/**
 * Tämän tiedoston luokat on tehty seuraavan tietomallin pohjalta:
 * Huoneistotiedot OmaRakennus-kokeiluprojektissa
 * https://tietomallit.suomi.fi/model/huontest
 */

import { Description, Title } from "../utils/tooltipUtil";
import { Base } from "./base";

export class Hallintakohde extends Base {
  @Title("Pysyvä huoneistotunnus")
  @Description("Pysyvä huoneistotunnus (VTJ-PHT) on jokaiselle huoneistolle ja uudisrakennushankkeen huoneistolle annettava 10-merkkinen tunnus.")
  pysyvahuoneistotunnus?: string;
  @Title("Päähallintakohde")
  @Description("Tieto kertoo, onko hallintakohde osakeryhmän päähallintakohde. Esimerkki: Osakeryhmä voi antaa hallintaoikeuden huoneistoon sekä siihen liittyvään autopaikkaan ja muuhun tilaan, jolloin huoneisto on päähallintakohde.")
  paahallintakohde?: string;
  @Title("Hallintakohdetyyppi")
  @Description("Hallintakohdetyyppi voi olla huoneisto, autopaikka tai muu tila. Koodisto.")
  hallintakohdetyyppi?: string;
  @Title("Porras")
  @Description("Portaan tunniste. Esimerkiksi \"A\".")
  porras?: string;
  @Title("Huoneistonumero")
  @Description("Huoneistonumero")
  huoneistonumero?: number;
  @Title("Huoneistotunnuksen jakokirjain")
  @Description("Huoneistotunnuksen jakokirjain")
  jakokirjain?: string;
  @Title("Poikkeava huoneistonumero")
  @Description("Poikkeava huoneistonumero")
  poikkeavaHuoneistonumero?: string;
  @Title("Tunnus")
  @Description("Autopaikan tai muut tilan tunnus. Esimerkiksi \"AP1\".")
  tunnus?: string;
  @Title("Tietolähde")
  @Description("Tietolähde voi olla Kaupparekisteri tai Yhtiö. Koodisto.")
  tietolahde?: string;
  @Title("Huoneistotyyppi")
  @Description("Esimerkiksi \"2h+k\" tai \"3h+k+s\".")
  huoneistotyyppi?: string;
  @Title("Sijaintikerros")
  @Description("Sijaintikerros")
  sijaintikerros?: string;
  @Title("Autopaikkatyyppi")
  @Description("Autopaikkatyyppi")
  autopaikkatyyppi?: string;
  @Title("Rekisteröintipäivämäärä")
  @Description("Päivä, jolloin hallintakohteen syntyminen on rekisteröity kaupparekisteriin.")
  rekisterointipvm?: string;
  @Title("Lakkaamispäivämäärä")
  @Description("Päivä, jolloin hallintakohteen lakkaaminen on rekisteröity kaupparekisteriin.")
  lakkaamispvm?: string;
  @Title("Muutospäivämäärä Osakehuoneistorekisterissä")
  @Description("Viimeisin muutosaikaleima osakehuoneistorekisterissä.")
  muutospvmOsakehuoneistorekisterissa?: string;
  @Title("Pääkäyttötarkoitus")
  @Description("Hallintakohteen pääkäyttötarkoitus.")
  paakayttotarkoitus?: Paakayttotarkoitus;
  @Title("Muu käyttötarkoitus")
  @Description("Hallintakohteen muu käyttötarkoitus.")
  muukayttotarkoitus?: MuuKayttotarkoitus;
}

export class LuonnollinenHenkilo extends Base {
  @Title("Etunimet")
  @Description("Henkilön kaikki etunimet lueteltuna.")
  etunimet?: string;
  @Title("Sukunimi")
  @Description("Pääasiallisesti sukuun viittaava rekisteröidyn henkilönnimen osa.")
  sukunimi?: string;
  @Title("Henkilötunnus")
  @Description("Henkilön yksilöivä tunnus, jonka toimivaltainen viranomainen on antanut henkilölle.")
  henkilotunnus?: string;
}

export class JuridinenHenkilo extends Base {
  @Title("Toiminimi")
  @Description("Organisaatio joka harjoittaa elinkeinotoimintaa käyttää yleensä elikeino- eli yritystoiminnassaan toiminimeksi rekisteröityä nimeään.")
  toiminimi?: string;
  @Title("Y-tunnus")
  @Description("Organisaation yritys- ja yhteisötunnus.")
  ytunnus?: string;
  @Title("Organisaatiomuoto")
  @Description("Ammatin- tai liikkeenharjoittajan tai yhteisön oikeudellinen muoto.")
  organisaatiomuoto?: string;
}

export class Kunta extends Base {
  @Title("Kuntatunnus")
  @Description("Kolminumeroinen kuntatunnus etunollineen.")
  kuntatunnus?: string;
  @Title("Nimet")
  @Description("Kunnan nimet eri kielillä.")
  nimet?: string;
}

export class Maa extends Base {
  @Title("Maatunnus")
  @Description("Kolminumeroinen maatunnus ISO 3166-1 -standardin mukaan etunollineen.")
  maatunnus?: string;
  @Title("Nimet")
  @Description("Maan nimet eri kielillä.")
  nimet?: string;
}

export class MuuKayttotarkoitus extends Base {
  @Title("Käyttötarkoitus")
  @Description("Koodisto, joka on joko huoneiston käyttötarkoitus, autopaikan käyttötarkoitus tai muun tilan käyttötarkoitus.")
  kayttotarkoitus?: string;
  @Title("Selite")
  @Description("Käyttötarkoitukseen 999=Muu liittyvä yksikielinen seliteteksti.")
  selite?: string;
  @Title("Järjestysnumero")
  @Description("Järjestysnumeroilla osoitetaan käyttötarkoitusten tärkeysjärjestys. Pienimmällä järjestysnumerolla oleva käyttötarkoitus on tärkein.")
  jarjestysnumero?: string;
}

export class Nimi extends Base {
  @Title("Nimi")
  @Description("Nimi.")
  nimi?: string;
  @Title("Kieli")
  @Description("Kieli, jolla nimi on tallennettu.")
  kieli?: string;
}

export class Omistaja extends Base {
  @Title("Henkilöomistaja")
  @Description("Osakeryhmän omistava henkilö.")
  henkiloomistaja?: LuonnollinenHenkilo;
  @Title("Organisatioomistaja")
  @Description("Osakeryhmän omistava organisaatio.")
  organisatioomistaja?: JuridinenHenkilo;
  @Title("Omistusosuuden nimittäjä")
  @Description("Omistusosuuden nimittäjä.")
  omistusosuudennimittaja?: number;
  @Title("Omistusosuuden jakaja")
  @Description("Omistusosuuden jakaja.")
  omistusosuudenjakaja?: number;
}

export class Osakeryhma extends Base {
  @Title("Osakeryhmätunnus")
  @Description("Osakeryhmän yksilöivä tunnus. Yksilöi koko aineistossa. Muodoltaan tunnus on 16 merkkiä pitkä. Se sisältää 14 aakkosnumeerista merkkiä (kaksi ensimmäistä tunnuksen muodostaja KR =kaupparekisteri tai OH = osakehuoneistorekisteri) ja lopussa kaksinumeroisen tarkistusnumeron, esimerkiksi KRD7AR4QP2NRHP97.")
  osakeryhmatunnus?: string;
  @Title("Osakeryhmän nimi")
  @Description("Osakeryhmän päähallintakohteena olevan huoneiston portaasta, huoneistonumerosta ja jakokirjaimesta tai poikkeavasta huoneistonumerosta esittämistä varten muodostettu merkkijono. Osakeryhmän päähallintakohteena olevan autopaikan ja muun tilan tunnuksesta esittämistä varten muodostettu merkkijono. Max 50 merkkiä. Tieto ei välttämättä ole yksilöivä edes yhtiön sisällä. sakehuoneistorekisterin tieto.")
  osakeryhmanimi?: string;
  @Title("Osakkeet")
  @Description("Osakesarjojen osakkeiden numeroista ja mahdollisista tarkenteista esittämistä varten muodostettu merkkijono.")
  osakkeet?: string;
  @Title("Osakelukumäärä")
  @Description("Osakesarjojen osakkeiden lukumääristä yhteenlaskettu tieto.")
  osakelukumaara?: number;
  @Title("Hallintakohdetyyppi")
  @Description("Osakeryhmän päähallintakohteen hallintakohdetyyppi. Koodisto.")
  hallintakohdetyyppi?: string;
  @Title("Käyttötarkoitus")
  @Description("Osakeryhmän päähallintakohteen käyttötarkoitus. Koodisto.")
  kayttotarkoitus?: string;
  @Title("Rekisteröintipäivämäärä")
  @Description("Päivä, jolloin osakeryhmän syntyminen on rekisteröity kaupparekisteriin.")
  rekisterointipvm?: string;
  @Title("Päättymispäivämäärä")
  @Description("Päivä, jolloin osakeryhmän lakkaaminen on rekisteröity kaupparekisteriin.")
  lakkaamispvm?: string;
  @Title("Sähköinen omistusmerkintä")
  @Description("Tieto kertoo, perustuuko osakeryhmän omistus sähköiseen merkintään osakehuoneistorekisterissä. Osakehuoneistorekisterin tieto.")
  sahkoinenOmistusmerkinta?: boolean;
  @Title("Sähköisen omistuksen alkamispäivämäärä")
  @Description("Päivä, jolloin osakeryhmälle on ensimmäisen kerran kirjattu sähköiseen merkintään perustuva omistus.")
  sahkoisenOmistuksenAlkamispvm?: string;
  @Title("Muutospäivämäärä Osakehuoneistorekisterissä")
  @Description("Viimeisin muutosaikaleima osakehuoneistorekisterissä.")
  muutospvmOsakehuoneistorekisterissa?: string;
  @Title("Muutospäivämäärä Kaupparekisterissä")
  @Description("Viimeisin muutosaikaleima kaupparekisterissä.")
  muutospvmKaupparekisterissa?: string;
  @Title("Yhtiön viite")
  @Description("Asiakasjärjestelmän viite.")
  yhtionViite?: string;
  @Title("Osoite")
  @Description("Osakeryhmän päähallintakohteena olevan huoneiston osoite.")
  osoite?: Osoite;
  @Title("Olotila")
  @Description("Osakeryhmän tila. Koodisto. Tietopalvelua annetaan vain voimassa olevista ja lakkaavista osakeryhmistä.")
  olotila?: string;
  @Title("Voimaantulopäivämäärä Osakehuoneistorekisterissä")
  @Description("Päivä, jolloin osakeryhmä on tullut huoneistotietojärjestelmän tietopalveluun. Uusilla perustetuilla yhtiöillä osakeryhmien voimaantulopvm on sama kuin kaupparekisterin rekisteröintipvm. Yhtiöjärjestyksen muutoksissa syntyvillä osakeryhmillä voimaantulopvm voi olla myöhäisempi kuin kaupparekisterin rekisteröintipvm. Kun yhtiöjärjestyksen muutoksessa osakeryhmä lakkaa ja sen tilalle syntyy uusi osakeryhmä, huoneistotietojärjestelmän tietopalvelussa esitetään vain lakkaavaa osakeryhmää siihen saakka, kunnes kaikki lakkaavaan osakeryhmään kohdistuvat oikeudet on siirretty syntyvälle osakeryhmälle. Sen jälkeen rekisterinpidossa muutetaan lakkaava osakeryhmä lakanneeksi ja syntyvä osakeryhmä voimassa olevaksi. Voimaantulopvm on se päivä, jolloin osakeryhmän tila muuttuu syntyvästä voimassa olevaksi. Yhtiöillä, jotka siirtävät osakeluettelonsa, osakeryhmien voimaantulopvm on osakeluettelon siirtopäivä.")
  voimaantulopvmOsakehuoneistorekisterissa?: string;
  @Title("Lakkaamispäivämäärä Osakehuoneistorekisterissä")
  @Description("Päivä, jolloin osakeryhmä on poistunut huoneistotietojärjestelmän tietopalvelusta. Yhtiöjärjestyksen muutoksissa lakkaavilla osakeryhmillä lakkaamispvmOsakehuoneistorekisterissa voi olla myöhäisempi kuin kaupparekisterin lakkaamispvm. Kun yhtiöjärjestyksen muutoksessa osakeryhmä lakkaa ja sen tilalle syntyy uusi osakeryhmä, huoneistotietojärjestelmän tietopalvelussa esitetään vain lakkaavaa osakeryhmää siihen saakka, kunnes kaikki lakkaavaan osakeryhmään kohdistuvat oikeudet on siirretty syntyvälle osakeryhmälle. Sen jälkeen rekisterinpidossa muutetaan lakkaava osakeryhmä lakanneeksi ja syntyvä osakeryhmä voimassa olevaksi. LakkaamispvmOsakehuoneistorekisterissa on se päivä, jolloin osakeryhmän tila muuttuu lakkaavasta lakanneeksi.")
  lakkaamispvmOsakehuoneistorekisterissa?: string;
  @Title("Osakesarjat")
  @Description("Osakesarjat.")
  osakesarja?: Osakesarja;
  @Title("Hallintakohde")
  @Description("Hallintakohde, johon osakeryhmä antaa hallintaoikeuden.")
  hallintakohde?: Hallintakohde;
  @Title("Omistaja")
  @Description("Osakeryhmän omistaja.")
  omistajat?: Omistaja[];
}

export class Osakesarja extends Base {
  @Title("Alkunumero")
  @Description("Numerosarjan ensimmäinen numero.")
  alkunumero?: number;
  @Title("Loppunumero")
  @Description("Numerosarjan viimeinen numero.")
  loppunumero?: number;
  @Title("Poikkeava osakenumero")
  @Description("Muu kuin numeromuotoinen osakenumero. Joissain tapauksissa vanhassa aineistossa numero voi sisältää kirjaimia.")
  poikkeavaOsakenumero?: string;
  @Title("Osakelukumäärä")
  @Description("Osakkeiden lukumäärä.")
  osakelukumaara?: number;
  @Title("Tarkenne")
  @Description("Osakesarjan tarkenne, jota voidaan nimittää myös osakelajiksi.")
  tarkenne?: string;
}

export class Osoite extends Base {
  @Title("Kadunnimi")
  @Description("Suomalaisen osoitteen kadun nimi eri kielillä.")
  katunimet?: string;
  @Title("Osoitenumeron numero-osa")
  @Description("Suomalaisen osoitteen katunumero.")
  katunumero?: string;
  @Title("Postitoimipaikka")
  @Description("Suomalaisen osoitteen kunta.")
  osoitteenKunta?: string;
  @Title("Ulkomainen osoite")
  @Description("Ulkomainen osoite yhtenä merkkijonona.")
  ulkomainenOsoite?: string;
}

export class Paakayttotarkoitus extends Base {
  @Title("Käyttötarkoitus")
  @Description("Koodisto, joka on joko huoneiston käyttötarkoitus tai muun tilan käyttötarkoitus.")
  kayttotarkoitus?: string;
  @Title("Selite")
  @Description("Käyttötarkoitukseen 999=Muu liittyvä yksikielinen seliteteksti.")
  selite?: string;
}

export type PintaAla = object;

export type Rajoitukset = object;

export class Rakennus extends Base {
  @Title("Pysyvä rakennustunnus")
  @Description("Rakennuksen pysyvä tunnus.")
  pysyvarakennustunnus?: string;
  @Title("Sijainti")
  @Description("Paikka, jossa rakennus sijaitsee.")
  sijainti?: Sijainti;
}

export class Sijainti extends Base {
  @Title("Osoite")
  @Description("Sijaintipaikan osoittavien tietojen kokonaisuus.")
  osoite?: Osoite[];
}

export class Valtio extends Base {
  @Title("Maatunnus")
  @Description("Maatunnus.")
  maatunnus?: string;
  @Title("Nimet")
  @Description("Valtion nimet ja niiden kielet.")
  nimet?: string;
}

export type Yhteystiedot = object;

export class Yhtio extends Base {
  @Title("YTJ:n yritysmuoto")
  @Description("Yritys- ja yhteisötietojärjestelmän yritysmuodon koodiarvo.")
  yritysmuotoYTJ: string = "";
  @Title("Olotila")
  @Description("Yhtiö voi olla olemassa oleva tai lakannut kaupparekisterissä.")
  olotila: string = "";
  @Title("Rekisteröintipäivämäärä")
  @Description("Päivä, jolloin yhtiön perustaminen on rekisteröity kaupparekisteriin.")
  rekisterointipaivamaara: string = "";
  @Title("Lopettamispäivämäärä")
  @Description("Päivä, jolloin yhtiön lakkaaminen on rekisteröity kaupparekisteriin.")
  lakkaamispvm?: string;
  @Title("Osakeluettelo Osakehuoneistorekisterissä")
  @Description("Tieto kertoo, onko yhtiön osakeluettelo siirretty sähköisenä osakehuoneistorekisteriin.")
  osakeluetteloOsakehuoneistorekisterissa: boolean = false;
  @Title("Osakeluettelon siirtymispäivämäärä")
  @Description("Päivä, jolloin osakeluettelo on siirtynyt sähköisenä osakehuoneistorekisteriin.")
  osakeluettelonSiirtymispvm: string = "";
  @Title("Muutospäivämäärä Osakehuoneistorekisterissä")
  @Description("Viimeisin muutosaikaleima osakehuoneistorekisterissä.")
  muutospvmOsakehuoneistorekisterissa: string = "";
  @Title("Muutospäivämäärä Kaupparekisterissä")
  @Description("Viimeisin muutosaikaleima kaupparekisterissä.")
  muutospvmKaupparekisterissa: string = "";
  @Title("Osakekirjat painettu")
  @Description("Tieto kertoo, onko ennen 1.1.2019 syntyneessä yhtiössä painettu osakekirjat.")
  osakekirjatPainettu: boolean = false;
  @Title("Tietolähde")
  @Description("Tietolähde voi olla Kaupparekisteri tai Yhtiö. Koodisto.")
  tietolahde: string = "";
  @Title("Kotipaikka")
  @Description("Kaupparekisteriin rekisteröity yhtiön kotipaikka.")
  kotipaikka: string = "";
  @Title("Ajantasainen")
  @Description("Yhtiön tiedot eivät ole ajantasaiset silloin, jos yhtiön muutostietojen siirto kaupparekisteristä on estynyt jostain teknisestä syystä tai kaupparekisterissä tapahtuneen sulautumisen tai jakautumisen siirto on kesken tämän yhtiön osalta.")
  ajantasainen: boolean = false;
  @Title("Yhtiön alueet")
  @Description("Kiinteistöt, määräalat, vuokraoikeudet (laitokset) ja ulkomaiset alueet, joilla yhtiön rakennukset sijaitsevat.")
  yhtionAlueet?: YhtionAlue[];
  @Title("Osakeryhmät")
  @Description("Yhtiön osakkeet osakeryhmittäin eriteltynä.")
  osakeryhmat?: Osakeryhma[];
  @Title("Organisaatiotiedot")
  @Description("Yhtiön yleiset organisaatiotiedot.")
  organisaatiotiedot?: JuridinenHenkilo;
  @Title("Nimi")
  @Description("Ainutkertaisen tarkoitteen nimitys")
  nimi: string = "";
  @Title("Y-tunnus")
  @Description("rekisteriviranomaisen antama yksilöivä tunnus elinkeinotoimintaa harjoittavalle henkilölle tai oikeushenkilölle")
  ytunnus: string = "";
}

export class YhtionAlue extends Base {
  @Title("Kiinteistötunnus")
  @Description("Rakennuksen sijaintikiinteistön kiinteistötunnus.")
  kiinteistotunnus: string = "";
  @Title("Määräalatunnus")
  @Description("Rakennuksen sijaintimääräalan määräalatunnus.")
  maaraalatunnus: string = "";
  @Title("Laitostunnus")
  @Description("Rakennuksen sijaintikiinteistölle Kiinteistötietojärjestelmään rekisteröidyn vuokraoikeuden laitostunnus.")
  laitostunnus: string = "";
  @Title("Hallintalaji")
  @Description("Tieto kertoo, onko alue yhtiön oma alue vai vuokrattu alue.")
  hallintalaji: string = "";
  @Title("Ulkomaisen alueen tunnus")
  @Description("Ulkomailla sijaitsevan rakennuksen sijaintialueen tunnus.")
  ulkomaisenAlueenTunnus: string = "";
  @Title("Ulkomaisen alueen nimi")
  @Description("Ulkomailla sijaitsevan rakennuksen sijaintialueen nimi.")
  ulkomaisenAlueenNimi: string = "";
  @Title("Ulkomaisen alueen rekisteri")
  @Description("Ulkomailla sijaitsevan rakennuksen sijaintialueen tunnuksen antaneen rekisterin nimi.")
  ulkomaisenAlueenRekisteri: string = "";
  @Title("Tietolähde")
  @Description("Tietolähde voi olla Kaupparekisteri tai Yhtiö. Koodisto.")
  tietolahde: string = "";
  @Title("Sijaintikunta")
  @Description("Suomessa olevan kiinteistön, määräalan tai vuokra-alueen sijaintikunta.")
  sijaintikunta2?: Kunta;
  @Title("Ulkomaisen alueen maa")
  @Description("Ulkomailla sijaitsevan rakennuksen sijaintialueen maa.")
  ulkomaisenalueenmaa?: Maa;
}