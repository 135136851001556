import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';
import { BuildingPlanTarget } from './buildingPlanTarget';

export class BuildingPlan extends Base {
    @Title("Kaavakohde")
    @Description("Kaavan sisältyvä paikkatietokohde, johon voi kohdistua kaavamääräyksiä tai kaavasuosituksia")
    Target: BuildingPlanTarget = new BuildingPlanTarget();
    @Title("Vireilletuloaika")
    @Description("Ajanhetki, jolloin kaava on tullut vireille")
    InitiationDate: string = "";
    @Title("Hyväksymisaika")
    @Description("Ajanhetki, jolloin vastuullinen organisaatio on hyväksynyt kaavan")
    ApprovalDate: string = "";

    public Clone(data: any) {
        super.Clone(data);

        if (data !== undefined && data.Target !== undefined) {
            var b = new BuildingPlanTarget();
            b.Clone(data.Target);
            this.Target = b;
        }
    }
}