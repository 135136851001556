import { Address } from "./address";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export class SiteAddress extends Base{
    @Title("Osoitteen laji")
    @Description("Osoitteen lajeina voivat olla Ensisijainen osoite tai  Rinnakkaisosoite. Kohteella voi olla vain yksi ensisijainen osoite.")
    Address: Address = new Address();;
    
    @Title("Osoite")
    @Description("Osoite")
    AddressType: string = "";
}