import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class InfrastructureInterface extends Base{
    @Title("Kuuluu kohteeseen")
    @Description("Kuuluu kohteeseen")
    BelongsToSite: boolean = false;
    @Title("Laji")
    @Description("liittymän laji (koodisto)")
    InterfaceType: string = "";
}