import { Address, Property, Location, Municipality, OwnerShip, Rule, Compensation, Owner } from "../model/property";

export class ParsingUtils {
    public static CastObject<T>(obj: any, instantiator: () => T, skipNulls: boolean = true): T {
        var object = instantiator() as any;
        
        if (obj !== undefined && obj !== null) {
            var keys = Object.keys(obj);

            if (skipNulls) {
                keys = keys.filter(k => obj[k] !== null);
            }

            keys.forEach(key=>object[key]=obj[key]);
        }
        return object;
        //return { ...obj, ...object};
        //return object;
    }

    public static CastObjectArray<T>(obj: any, instantiator: () => T): T[] {
        var array = [] as T[];
        
        if (obj !== undefined && obj.length > 0) {
        obj.forEach((element: T) => {
            array.push(ParsingUtils.CastObject(element, instantiator));
        });
        }

        return array;
    }

    public static ParseProperty(prop: any): Property
    {
        var p = new Property();
        p.RealEstateId = prop.RealEstateId;
        p.PropertyType = prop.PropertyType;
            p.Name = prop.Name;
            p.RegistrationDate = prop.RegistrationDate;
            p.ExpiryDate = prop.ExpiryDate;
            p.LandArea = prop.LandArea;
            p.WaterArea = prop.WaterArea;
            p.Location = new Location();
            p.Location.Address = new Address();
            p.Location.Municipality = new Municipality();
            p.Location.Address.StreetName = prop.Location.Address.StreetName;
            p.Location.Address.PostalDistrict = prop.Location.Address.PostalDistrict;
            p.Location.Address.PostalCode = prop.Location.Address.PostalCode;

            p.Location.Municipality.Name = prop.Location.Municipality.Name;
            p.Location.Municipality.MunicipalityNumber = prop.Location.Municipality.MunicipalityNumber;

        if (prop.OwnerShip !== null) {
            p.OwnerShip = prop.OwnerShip.map((o: any) => {
                    var ownership = new OwnerShip();
                    ownership.Share = o.Share;
                    if (o.Rule !== null) {
                        ownership.Rule= new Rule();
                        ownership.Rule.RuleDate= o.Rule.RuleDate;
                        ownership.Rule.Compensation = new Compensation();
                        ownership.Rule.Compensation.Amount=  o.Rule.Compensation !== null ? o.Rule.Compensation.Amount : undefined;
                        ownership.Rule.Compensation.Currency = o.Rule.Compensation !== null ? o.Rule.Compensation.Currency : undefined;
                    }
                    else{
                        ownership.Rule = null;
                    }
                    ownership.Owner = new Owner();
                    ownership.Owner.BirthDate = o.Owner.BirthDate;
                    ownership.Owner.FirstNames = o.Owner.FirstNames;
                    ownership.Owner.LastName = o.Owner.LastName;
                    ownership.Owner.PersonalId = o.Owner.PersonalId;
                    ownership.Owner.Resident = o.Owner.Resident

                    return ownership;
                });
        }

        return p;
    }
}
