/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";

import ReactDataGrid, { Row } from "react-data-grid";
import { Editors } from "react-data-grid-addons";

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@material-ui/core';
import _ from 'lodash';
import { Rakennusosa } from '../../../model/servicePlan/rakennusOsa';
import { Aikayksikko } from '../../../model/servicePlan/aika';
import { Heading, suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { PeppolAPI } from '../../../utils/api';
import { JsonObject } from '../../../model/JsonObject';
import { Product } from '../../../model/Product';
import ProductItemAccordion from './ProductItemAccordion';

interface Updater {
  (message: structureEditValue[]): void;
}

interface Props {
  data: structureEditValue[];
  buildingId: string;
  updated: Updater;
}

interface State {
  buildingId: string;
  data: structureEditValue[]
  updated: Updater;
  rowIdx: number,
  selectedRow: structureEditValue|undefined,
  showXml: boolean|undefined,
  products: JsonObject<Product>[]
}

const { DropDownEditor } = Editors as any;

export const buildingTypes = [
  { id: Rakennusosa.NotSet, value:"VALITSE RAKENNUSOSA" },  
  { id: Rakennusosa.Alapohjakanaalit, value:"Alapohja kanaalit" },
    { id: Rakennusosa.Alapohjalaatat, value:"Alapohjalaatat" },
    { id: Rakennusosa.Alapohjat, value:"Alapohjat" },
    { id: Rakennusosa.Anturat, value:"Anturat" },
    { id: Rakennusosa.Erityisetalapohjat, value:"Erityiset alapohjat" },
    { id: Rakennusosa.Erityisetperustukset, value:"Erityiset perustukset" },
    { id: Rakennusosa.Erityisetrunkorakenteet, value:"Erityiset runkorakenteet" },
    { id: Rakennusosa.Kantavatseinat, value:"Kantavat seinat" },
    { id: Rakennusosa.PPP, value:"PPP" },
    { id: Rakennusosa.Palkit, value:"Palkit" },
    { id: Rakennusosa.Perustukset, value:"Perustukset" },
    { id: Rakennusosa.Pilarit, value:"Pilarit" },
    { id: Rakennusosa.Runko, value:"Runko" },
    { id: Rakennusosa.Runkoportaat, value:"Runkoportaat" },
    { id: Rakennusosa.TaloOsat, value:"Talon osat" },
    { id: Rakennusosa.Vaestonsuojat, value:"Vaestonsuojat" },
    { id: Rakennusosa.Valipohjat, value:"Valipohjat" },
    { id: Rakennusosa.Ylapohjat, value:"Ylapohjat" }
  ];

  export const timeFrames = [
    { id: Aikayksikko.Monthly, value:"Kuukauden välein" },
    { id: Aikayksikko.Yearly, value:"Vuoden välein" }
  ];

  const timeFrameEditor = <DropDownEditor options={timeFrames} />;

  const buildingTypeEditor = <DropDownEditor options={buildingTypes} />;

  const columns = [
    { key: "toimenpiteenNimi", name: "Toimenpide", editable: true, width: 140 },
    { key: "toimenpideVuosi", name: "Vuosi", editable: true, width: 60 },
    { key: "kustannukset", name: "Kustannukset", editable: true, width: 120 },
    { key: "peruste", name: "Peruste", editable: true, width: 200 },
    
    { key: "rakennusosa", name: "Rakennusosa", width: 180, editor: buildingTypeEditor },
    { key: "rakennetyyppi", name: "Rakenne tyyppi", editable: true, width: 180 },
    { key: "tarkastusvaliaikavali", name: "", width: 50,editable: true },
    { key: "tarkastusvaliyksikko", name: "Tarkastus", width: 200, editor: timeFrameEditor },
    { key: "huoltovaliaikavali", name: "", width: 50, editable: true },
    { key: "huoltovaliyksikko", name: "Huollot", width: 200, editor: timeFrameEditor }
  ];

  export class structureEditValue {
    public toimenpiteenNimi: string = "";
    public toimenpideVuosi: string = "";
    public kustannukset: string = "";
    public peruste: string = "";
    
    public rakennusosa : string = "";
    public rakennetyyppi : string = "";
    public tarkastusvaliaikavali : string = "";
    public tarkastusvaliyksikko : string = "";
    public huoltovaliaikavali : string = "";
    public huoltovaliyksikko : string = "";
  }

class RakenneEditor extends React.Component<Props, State> {
  constructor(props: Props, data : any) {
    super(props);
    if (props.data) {
      this.setState({data: props.data, buildingId: props.buildingId, selectedRow: undefined});
    }
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  handleChange(e: any) {
    let change = this.state;
    _.set(change, e.target.name, e.target.value)
    this.setState(change)
  }

  onGridRowsUpdated(params: any) {
    var rows = this.state.data;
    
    for (let i = params.fromRow; i <= params.toRow; i++) {
        rows[i] = { ...rows[i], ...params.updated };
    }
    
    this.setState({data: rows});
    this.props.updated(rows);
  };

  addNewStructure() {
      var rows = this.state.data;
      var structure = new structureEditValue();
      structure.rakennusosa = buildingTypes[0].value as any;
      structure.rakennetyyppi = "Syötä rakennetyyppi";
      structure.huoltovaliaikavali = "1";
      structure.huoltovaliyksikko = timeFrames[1].value as any;
      structure.tarkastusvaliaikavali = "1";
      structure.tarkastusvaliyksikko = timeFrames[1].value as any;
      rows.push(structure);
      this.setState({data: rows});
  }

  loadPeppolProductItems(buildingId: string, rowIdx: number) {
    new PeppolAPI().GetCatalogueProductItems(`${buildingId}-${rowIdx}`)
    .then(productObjects => {
      console.log("PeppolProductItems", productObjects);
      this.setState({ ...this.state, products: productObjects })
    })
  }

  render() {
    return (
      <div style={{width: 2000}}>
        <ReactDataGrid
            columns={columns}
            rowGetter={(i:number) => this.state.data[i]}
            rowsCount={this.state.data.length}
            onGridRowsUpdated={this.onGridRowsUpdated.bind(this)}
            enableCellSelect={true}
            headerRowHeight={50}
            onCellSelected={
              (coordinates: {rowIdx: number}) => {
                this.setState({ rowIdx: coordinates.rowIdx, buildingId: this.props.buildingId, selectedRow: this.state.data[coordinates.rowIdx], data: this.state.data })
                this.loadPeppolProductItems(this.props.buildingId, coordinates.rowIdx);
              }
            }
            onCellDeSelected={ () => {
              this.setState({ buildingId: this.state.buildingId, showXml: false, data: this.state.data });
            }}
          />
        <Button disabled={this.state.data.some(s => s.rakennusosa === buildingTypes[0].value)} variant="outlined" color="primary" onClick={this.addNewStructure.bind(this)}>Lisää rakennusosa</Button>
      
        { this.state.selectedRow &&
        <Accordion expanded={true}>
          <AccordionSummary>
          <Typography>Rakenteen tiedot ({this.state.selectedRow.rakennusosa} / {this.state.selectedRow.rakennetyyppi})</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "flex", flexDirection: "column"}}> 
            { this.state.products && 
              <div> 
                <Heading variant='h4'>Tuotteet</Heading> 
                <Box sx={{ flexGrow: 1 }} style={{marginTop: tokens.spacing.xs, marginBottom: tokens.spacing.xs}}>
                  { Object.entries(this.state.products).map(([key, value], i) => <ProductItemAccordion key={i} id={key} itemObject={value} transaction={`${this.state.buildingId}-${this.state.rowIdx}`} />)}
                </Box>
              </div>
            }
          </AccordionDetails>
        </Accordion> 
        }
      
      </div> 
        
      );
}
}

export default RakenneEditor;
