import { ConstructionTargetChange } from "./constructionTargetChange";
import { BuildingSite } from "./buildingSite";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export enum DemolishReasonEnum {
    _01 = 1,
    _02 = 2,
    _03 = 3,
    _04 = 4
}

export enum ActionTypeEnum {
    _01 = 1,
    _02 = 2,
    _03 = 3,
    _04 = 4,
    _05 = 5,
    _06 = 6,
    _80 = 7
}

export class BuildingAction extends Base {
    @Title("Purkamisen syy")
    @Description("Rakennuksen purkamisen syy (ainoastaan purkamistoimenpiteelle).")
    DemolishingReason: DemolishReasonEnum = DemolishReasonEnum._01;
    @Title("Toimenpiteen laji")
    @Description("Rakentamistoimenpiteen laji, arvot koodistosta Toimenpiteen laji.")
    ActionType: ActionTypeEnum = ActionTypeEnum._01;
    @Title("Jatkoajan päivämäärä")
    @Description("Hankkeen loppuunsaattamiselle myönnetyn jatkoajan päivämäärä.")
    ExtensionDate: Date = new Date();
    @Title("Korjausaste")
    @Description("Korjausaste (%)")
    RepairRate: number = 0;
    @Title("Muutosala")
    @Description("Muutostyön pinta-alatieto")
    ModifiedArea: number = 0;
    @Title("Perusparannus")
    @Description("Kyseessä on rakennuksen perusparannustoimenpide.")
    Renovation: boolean = false;
    @Title("Suunniteltu muutos")
    @Description("Suunniteltu muutos")
    Rakentamiskohteenmuutos: ConstructionTargetChange[] = [];
    @Title("Rakentamispaikka")
    @Description("Rakentamispaikka")
    BuildingSite: BuildingSite = new BuildingSite();
    @Title("Raukeamispäivämäärä")
    @Description("Päivämäärä, jolloin lupapäätöksen katsotaan rauenneen kyseisen rakentamistoimenpiteen osalta.")
    ExpirationDate: Date = new Date();
    @Title("Toimenpiteen kuvaus")
    @Description("Toimenpiteen tarkentava kuvaus")
    OperationDescription: string = "";

    public Clone(data: any) {
        super.Clone(data);

        if (data.Rakentamiskohteenmuutos !== undefined && data.Rakentamiskohteenmuutos.length > 0) {
            data.Rakentamiskohteenmuutos.forEach((element : any) => {
                var b = new ConstructionTargetChange();
                b.Clone(element);
                this.Rakentamiskohteenmuutos.push(b);
            });
        }
    }
}

