import { EpcisEvent } from "./EpcisModels";
import { Item } from "./Peppol/PreAwardCatalogue";
import { QuantityType } from "./UBL/UnqualifiedDataTypes";

export class Product extends Item {
  'createdOn'?: string;
  'updatedOn'?: string;
  'quantity': QuantityType;
  'epcisEvents'?: Array<EpcisEvent>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "createdOn",
      "baseName": "createdOn",
      "type": "string"
    },
    {
      "name": "updatedOn",
      "baseName": "updatedOn",
      "type": "string"
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "QuantityType"
    },
    {
      "name": "epcisEvents",
      "baseName": "epcisEvents",
      "type": "Array<EpcisEvent>"
    }
  ];

  static getAttributeTypeMap() {
    return [...Item.attributeTypeMap, ...Product.attributeTypeMap];
  }
}

export const GetProductTemplate = (): Product => {
  return {
    name: {value: ""},
    classifiedTaxCategory: [
      {
        iD: { value: "S", listID: "UNCL5305"},
        percent: { value: 24 },
        taxScheme: {
          iD: {value: "VAT"}
        }
      }
    ],
    quantity: {value: 1}
  }
}
