import { ApartmentEquipment } from "./apartmentEquipment";
import { SiteAddress } from "./siteAddress";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export enum ApartmentTypeEnum {
    _01 = 1,
    _02 = 2
}

export class Apartment extends Base {
    @Title("Huoneistotyyppi")
    @Description("Tieto siitä, mihin tarkoitukseen huoneistoa saa käyttää rakennusluvan mukaisesti. VTJ:ssä sallittuja arvoja ovat asuinhuoneisto ja toimitila.")
    ApartmentType: ApartmentTypeEnum = ApartmentTypeEnum._01;
    @Title("Huoneiston varuste")
    @Description("Huoneiston varuste")
    Hankkeenhuoneistonvaruste: ApartmentEquipment[] = [];
    @Title("Huone")
    @Description("rakennuksen tila, jonka pinta-ala, valoaukko ja huonekorkeus täyttävät laissa säädetyt minimivaatimukset")
    Huone: string[] = [];
    @Title("Huoneiden lukumäärä")
    @Description("Huoneeksi katsotaan rakennuksessa oleva tila, jota ympäröivät lattia, katto ja seinät. Huoneiden lukumäärään ei lasketa keittiötä.")
    NumberOfRooms: number = 0;
    @Title("Huoneistoala")
    @Description("Huoneistoa ympäröivien seinien sisäpintojen mukaan laskettu pinta-ala. Pinta-alaan lasketaan mukaan vain se osa, jossa huonekorkeus ylittää 160 cm.")
    ApartmentArea: number = 0;
    @Title("Huoneistotunnuksen jakokirjain")
    @Description("Kirjain, joka on lisätty huoneistotunnuksen numero-osaan silloin, kun alkuperäinen huoneisto on jaettu kahdeksi tai useammaksi erilliseksi huoneistoksi.")
    ApartmentNumberIdentificationLetter: string = "";
    @Title("Huoneistotunnuksen kirjainosa")
    @Description("Useamman kuin yhden huoneiston käsittävässä rakennuksessa tai rakennuksissa sijaitsevien huoneistojen tunnuksiin kuuluva kirjain")
    ApartmentLetter: string = "";
    @Title("Huoneistotunnuksen numero-osa")
    @Description("Useamman kuin yhden huoneiston käsittävässä rakennuksessa sijaitsevan huoneiston tunnukseen kuuluva numero")
    ApartmentNumber: string = "";
    
    @Title("Huoneistotunnus")
    @Description("Pysyvä huoneistotunnus (VTJ-PHT) on jokaiselle huoneistolle ja uudisrakennushankkeen huoneistolle annettava 10-merkkinen tunnus")
    ApartmentIdentifier: string = "";
    @Title("Käyttöönottopäivämäärä")
    @Description("Päivämäärä, jolloin huoneisto on hyväksytty käyttöönotettavaksi viimeisimmän siihen tehdyn käyttöönottokatselmusta vaativan muutoksen jälkeen.")
    Date: Date = new Date();
    @Title("Keittiötyyppi")
    @Description("Tieto siitä, minkälainen keittiö huoneistossa on. VTJ:ssä sallittuja arvoja ovat esim. keittiö, keittokomero ja keittotila.")
    KitchenType: string = "";
    
    @Title("Muuttokielto")
    @Description("Tieto siitä, että asuinkäyttöön rakennettuun huoneistoon ei saa muuttaa asumaan vakinaisesti.")
    PermanentResidenceProhibited: boolean = false;
    @Title("Muuttovalmis")
    @Description("Onko huoneisto muuttovalmis (käyttöönotettavissa). Voidaan todeta osittaisessa loppukatselmuksessa.")
    ReadyForMoving: boolean = false;
    @Title("Osoitejärjestysnumero")
    @Description("Tieto siitä, mikä rakennuksen osoitteista on huoneiston osoite.")
    AddressOrderNumber: number = 0;
    @Title("Rakentamiskohteen osoite")
    @Description("Huoneistolle osoitetaan rakentamiskohteen (rakennuksen) osoite, jos huoneiston katuosoiteosa ei ole rakentamiskohteen (rakennuksen) esisijainen osoite")
    SiteAddress: SiteAddress = new SiteAddress();

    public Clone(data: any) {
        super.Clone(data);

        if (data.Hankkeenhuoneistonvaruste !== undefined && data.Hankkeenhuoneistonvaruste.length > 0) {
            data.Hankkeenhuoneistonvaruste.forEach((element : any) => {
                var b = new ApartmentEquipment();
                b.Clone(element);
                this.Hankkeenhuoneistonvaruste.push(b);
            });
        }
    }
}

