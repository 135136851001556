import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class ApartmentEquipment  extends Base{
    @Title("Varuste")
    @Description("Varuste")
    Description: string = "";
    @Title("Kuuluu kohteeseen")
    @Description("Kuuluu kohteeseen")
    BelongsToApartment: boolean = false;
}