import { Box, Typography } from '@material-ui/core'
import React from 'react'

type Props = {
  title?: string,
  allowBreak?: boolean,
  children?: JSX.Element | JSX.Element[]
}

const LabelValuesBlock = (props: Props) => {
  return (
    <div style={ props.allowBreak ? {} : {breakInside: 'avoid-column'}}>
      { props.title && <Typography className='LabelValueBlock-title' variant='h4'>{props.title}</Typography> }
      <Box mt={1} mb={3} ml={3}>
        {props.children}
      </Box>
    </div>
  )
}

export default LabelValuesBlock