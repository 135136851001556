import { PermitApplication } from "./permitApplication";
import { Document } from "./document";
import { RequestedException } from "./requestedException";
import { PermitRegulation } from "./permitRegulation";
import { AllowedException } from "./allowedException";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export enum ApplicationTypeEnum {
    _01 = 1,
    _02 = 2,
    _03 = 3,
    _04 = 4
}

export enum DecisionEnum {
    _01 = 1,
    _02 = 2
}

export enum DecisionMakerEnum {
    _01 = 1,
    _02 = 2
}

export class PermitDecision extends Base {
    @Title("Hakemuksen tyyppi")
    @Description("Hakemuksen tyyppi kuvaa, mistä asiassa on kysymys, onko kyseessä rakentamislupa-, purkamislupa-, maisematyöasia jne. Arvot koodistosta Hakemuksen tyyppi.")
    ApplicationType: ApplicationTypeEnum = ApplicationTypeEnum._01;
    
    @Title("Päätöksen tulos")
    @Description("Lupapäätöksen tulos")
    Decision: DecisionEnum = DecisionEnum._01;
    
    @Title("Päättäjän laji")
    @Description("Päätöksen tekijä, monijäseninen toimielin tai viranhaltija")
    Decider: DecisionMakerEnum = DecisionMakerEnum._01;
    
    @Title("Lupahakemus")
    @Description("Lupahakemus")
    PermitApplications: PermitApplication[] = [];
    
    @Title("Dokumentti")
    @Description("Liittyvät dokumentit ja asiakirjat")
    Documents: Document[] = [];
    
    @Title("Myönnetyt poikkeamiset")
    @Description("Rakentamislupapäätökseen sisältyvät hyväksytyt poikkeamiset rakentamispaikkaan kohdistuvista velvottavista määräyksistä.")
    RequestedExceptions: RequestedException = new RequestedException();
    
    @Title("Hakemuksen sisältö")
    @Description("Hakemuksen pääasiallinen sisältö")
    ApplicationDescription: string = "";
    
    @Title("Hakija")
    @Description("Hakija")
    Applicant: string = "";
    
    @Title("Lupamääräys")
    @Description("Lupamääräys")
    PermitRegulations: PermitRegulation[] = [];
    
    @Title("Myönnetyt poikkeamiset")
    @Description("Rakentamislupapäätökseen sisältyvät hyväksytyt poikkeamiset rakentamispaikkaan kohdistuvista velvottavista määräyksistä.")
    Myonnetytpoikkeamiset: AllowedException[] = [];
    
    @Title("Päätöspäivämäärä")
    @Description("Päätöksen päivämäärä")
    DecisionDate: Date = new Date();
    
    @Title("Päätöksen raukeaminen")
    @Description("Päivämäärä, jolloin päätöksen on todettu rauenneen. Päätös katsotaan rauenneeksi, kun rakennustöitä ei ole aloitettu tai saatettu loppuun päätöksen voimassa oloajan tultua loppuun. Päätös merkitään rauenneeksi, kun koko lupapäätös on rauennut eikä rakentamistoimenpiteitä tule valmiiksi, vaan kaikki toimenpiteet perutaan. Päätös voidaan todeta osittain rauenneeksi merkitsemällä joku hakemuksen toimenpiteistä rauenneeksi.")
    DecisionExpiration: Date = new Date();
    
    @Title("Päätöksen tekijä")
    @Description("Päätöksen tekijä")
    DecisionMaker: string = "";
    
    @Title("Päätöspykälä")
    @Description("Päätöksen pykälä kuntajärjestelmässä")
    DecisionSection: string = "";
    
    @Title("Päätösteksti")
    @Description("Päätöksen teksti sisältää varsinaisen päätöstekstin, perustelut, sovelletut oikeusohjeet.")
    DecisionText: string = "";
    
    @Title("Päätös voimassa saakka")
    @Description("Päivämäärä, johon saakka päätös on voimassa, jollei sitä erillisellä päätöksellä myöhemmin jatketa.")
    DecisionExpires: Date = new Date();
    
    @Title("Saapumispäivämäärä")
    @Description("Päivämäärä, jolloin hakemus on saapunut sähköiseen asiointipalveluun tai kirjaamoon.")
    ArrivalDate: Date = new Date();

    public Clone(data: any) {
        super.Clone(data);

        if (data === null || data === undefined) {
            return;
        }

        if (data.PermitApplications !== undefined && data.PermitApplications !== null && data.PermitApplications.length > 0) {
            data.PermitApplications.forEach((element : any) => {
                var b = new PermitApplication();
                b.Clone(element);
                this.PermitApplications.push(b);
            });
        }

        if (data.Documents !== undefined && data.Documents !== null && data.Documents.length > 0) {
            data.Documents.forEach((element : any) => {
                var b = new Document();
                b.Clone(element);
                this.Documents.push(b);
            });
        }

        if (data.PermitRegulations !== undefined && data.PermitRegulations !== null && data.PermitRegulations.length > 0) {
            data.PermitRegulations.forEach((element : any) => {
                var b = new PermitRegulation();
                b.Clone(element);
                this.PermitRegulations.push(b);
            });
        }

        if (data.Myonnetytpoikkeamiset !== undefined && data.Myonnetytpoikkeamiset !== null && data.Myonnetytpoikkeamiset.length > 0) {
            data.Myonnetytpoikkeamiset.forEach((element : any) => {
                var b = new AllowedException();
                b.Clone(element);
                this.Myonnetytpoikkeamiset.push(b);
            });
        }
    }
}
