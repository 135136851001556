/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from 'react';
import {suomifiDesignTokens as tokens} from 'suomifi-ui-components';

const Header: React.FC = () => {
  return (
      <header>
        <div style={{
          display: 'flex',
          fontWeight: 600,
          justifyContent: "space-between",
        }}>
          <div style={{
            color: tokens.colors.brandBase,
            fontSize: '28px',
          }}>
            <a href="/">OmaRakennus</a>
          </div>

        <div style={{
            textAlign: "right",
            lineHeight: 1
          }}>
          </div>
          </div>
      </header>
  );
};

export default Header;
