import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { buildingAppData } from '../../App';
import * as Huontest from "../../model/Huoneistotiedot";
import { UnitSpecification } from "../../model/unitSpecification";
import { Description, Title } from "../../utils/tooltipUtil";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import { HuoneistokohtaisetTiedot, HuoneistokohtaisetTiedotView } from "./asunnonomistus/HuoneistokohtaisetTiedot";
import { TaloyhtiokohtaisetTiedot, TaloyhtiokohtaisetTiedotView, } from "./asunnonomistus/TaloyhtiokohtaisetTiedot";
import { TaloyhtionKiinteistonTiedot, TaloyhtionKiinteistonTiedotView } from "./asunnonomistus/TaloyhtionKiinteistonTiedot";
import { TaloyhtionAlueenKaavatiedot, TaloyhtionAlueenKaavatiedotView } from "./asunnonomistus/TaloyhtionAlueenKaavatiedot";

class AsunnonOmistus {
  @Title("Etunimet")
  @Description("henkilön kaikki etunimet lueteltuna")
  etunimet?: string;
  @Title("Sukunimi")
  @Description("Pääasiallisesti sukuun viittaava rekisteröidyn henkilönnimen osa.")
  sukunimi?: string;
  @Title("Henkilötunnus")
  @Description("Henkilön yksilöivä tunnus, jonka toimivaltainen viranomainen on antanut henkilölle.")
  henkilotunnus?: string;
  @Title("Omistusosuuden nimittäjä")
  @Description("Omistusosuuden nimittäjä")
  omistusosuudennimittaja?: number;
  @Title("Omistusosuuden jakaja")
  @Description("Omistusosuuden jakaja")
  omistusosuudenjakaja?: number;
  @Title("Organisaatio")
  @Description("Organisaatio")
  organisaatio?: Huontest.JuridinenHenkilo;
  @Title("Yhtiö")
  @Description("Kiinteistöosakeyhtiö")
  yhtio?: Huontest.Yhtio;
  @Title("Yhtiön alue")
  @Description("Kiinteistön alue")
  yhtionAlue?: Huontest.YhtionAlue;
  @Title("Hallintakohde")
  @Description("Yhtiön hallinnassa oleva huoneisto, autopaikka tai muu tila.")
  hallintakohde?: Huontest.Hallintakohde;
}

class AsunnonOmistusParts {
  huoneistokohtaisetTiedot?: HuoneistokohtaisetTiedot;
  taloyhtiokohtaisetTiedot?: TaloyhtiokohtaisetTiedot;
  taloyhtionKiinteistonTiedot?: TaloyhtionKiinteistonTiedot;
  taloyhtionAlueenKaavatiedot?: TaloyhtionAlueenKaavatiedot;
}

interface Props {
  data: buildingAppData;
}

const AsunnonOmistusView: React.FC<Props> = (props: Props) => {

  const mapToAsunnonOmistusParts = (data: buildingAppData): AsunnonOmistusParts[] => {
    const aops = data.huoneistoData?.osakeryhmat?.flatMap((osakeryhma): AsunnonOmistusParts[] => {
      if (!osakeryhma.omistajat) return [];
      return osakeryhma.omistajat?.map((omistaja: Huontest.Omistaja): AsunnonOmistusParts => {
        const aop = new AsunnonOmistusParts();
        const unitSpecs = data.buildingData.UnitSpecifications?.find(u => getUnitNumber(u) === osakeryhma.hallintakohde?.tunnus);
        aop.huoneistokohtaisetTiedot = new HuoneistokohtaisetTiedot(osakeryhma.hallintakohde, osakeryhma, omistaja, unitSpecs);
        aop.taloyhtiokohtaisetTiedot = new TaloyhtiokohtaisetTiedot(data.huoneistoData, data.buildingData, props.data.buildingData.BuildingSpecifications, props.data.energyData);
        if (data.huoneistoData.yhtionAlueet && data.huoneistoData.yhtionAlueet.length > 0 && data.property) {
          aop.taloyhtionKiinteistonTiedot = new TaloyhtionKiinteistonTiedot(data.huoneistoData.yhtionAlueet[0], data.property);
        }
        aop.taloyhtionAlueenKaavatiedot = new TaloyhtionAlueenKaavatiedot(data.buildingPlan);
        return aop;
      })
    })
    return aops ?? [];
  }

  const getUnitNumber = (unitSpecs: UnitSpecification): string => {
    const unitStair = unitSpecs.Stair || "";
    const unitNumber = unitSpecs.Number?.toString() || "";
    return unitStair + unitNumber;
  }
  
  const AsunnonOmistusTable: JSX.Element | JSX.Element[] = props.data.huoneistoData ?
    mapToAsunnonOmistusParts(props.data).map((item: AsunnonOmistusParts, index: number) =>
      <Accordion key={`asunnon-omistus-${index}`} defaultExpanded={index === 0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>
          <b>
            {item.huoneistokohtaisetTiedot?.hallintakohde?.hallintakohdetyyppi} {" "}
            {item.huoneistokohtaisetTiedot?.hallintakohde?.porras}{item.huoneistokohtaisetTiedot?.hallintakohde?.huoneistonumero}, {" "}
            {item.huoneistokohtaisetTiedot?.omistaja?.henkiloomistaja ? 
              `${item.huoneistokohtaisetTiedot?.omistaja?.henkiloomistaja.etunimet} ${item.huoneistokohtaisetTiedot?.omistaja?.henkiloomistaja.sukunimi}` : 
              `${item.huoneistokohtaisetTiedot?.omistaja?.organisatioomistaja?.toiminimi}`
            }
          </b>
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FlexCol>
            <FlexRow>
              <HuoneistokohtaisetTiedotView index={index} title={"Huoneistokohtaiset tiedot"} item={item.huoneistokohtaisetTiedot} />
            </FlexRow>
            <FlexRow>
              <TaloyhtiokohtaisetTiedotView title={"Taloyhtiökohtaiset tiedot"} item={item.taloyhtiokohtaisetTiedot} />
            </FlexRow>
            <FlexRow>
              <TaloyhtionKiinteistonTiedotView title={"Taloyhtiön kiinteistön tiedot"} item={item.taloyhtionKiinteistonTiedot} />
            </FlexRow>
            <FlexRow>
              <TaloyhtionAlueenKaavatiedotView title={"Taloyhtiön alueen kaavatiedot"} item={item.taloyhtionAlueenKaavatiedot} />
            </FlexRow>
          </FlexCol>
        </AccordionDetails>
      </Accordion>
    )
    : <div>Ei tietoja saatavilla</div>;
  
  return (
    <>
      {AsunnonOmistusTable}
    </>
  )
}

export default AsunnonOmistusView;