import React, { HTMLInputTypeAttribute, PropsWithChildren, useEffect } from "react";
import { FormikProps, getIn } from "formik";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Grid, Paper, TextField, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol } from '../../common/CommonStyles';
import { Product } from '../../../model/Product';
import { TextType } from '../../../model/UBL/UnqualifiedDataTypes';

declare type Props = {
  fieldName: string,
  fieldPath: string,
  memberOfClass: string,
  formikProps: FormikProps<any>,
  availableValues?: JSX.Element[],
  disabled?: boolean,
  bgColor?: string,
  fieldType?: HTMLInputTypeAttribute
}

export const InputWrapper: React.FC = props => {
  return (
    <Card style={{display: "flex", flexGrow: 1, alignItems: "center", backgroundColor: tokens.colors.highlightLight4, padding: tokens.spacing.xs}} component={Paper}>
      <Box style={{flexGrow: 1}}>
        {props.children}
      </Box>
    </Card>
  );
}

export const FormTextField: React.FC<Props> = ({fieldName, fieldPath, memberOfClass, formikProps, disabled, fieldType}) => {
  return (
    <div style={{display: "flex"}}>
      <TextField 
        type={fieldType}
        name={fieldPath} 
        label={fieldName}
        value={getIn(formikProps.values, fieldPath) ?? ""} 
        onChange={formikProps.handleChange} 
        onBlur={formikProps.handleBlur} 
        error={Boolean(getIn(formikProps.touched, fieldPath)) && Boolean(getIn(formikProps.errors, fieldPath))} 
        helperText={Boolean(getIn(formikProps.touched, fieldPath)) && Boolean(getIn(formikProps.errors, fieldPath)) && getIn(formikProps.errors, fieldPath)} 
        variant="outlined" 
        disabled={disabled}
        style={{backgroundColor: tokens.colors.whiteBase, flexGrow: 1}}
      />
    </div>
  );
}

export const FormAccordion: React.FC<PropsWithChildren<Props>> = ({fieldName, memberOfClass, children, bgColor}) => {
  return (
    <Accordion key={fieldName} style={{backgroundColor: bgColor}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span>{fieldName}</span>
        </AccordionSummary>
      <AccordionDetails>
        <Box style={{ flexGrow: 1 }}>
          <FlexCol>
            {children}
          </FlexCol>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export const FormCard: React.FC<PropsWithChildren<{fieldName: string, memberOfClass: string, bgColor?: string}>> = ({fieldName, memberOfClass, children, bgColor}) => {
  return (
    <Card key={fieldName} style={{backgroundColor: bgColor ?? tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
      <Box style={{display: "flex", alignItems: "center"}}>
        <Typography variant="button" color="primary">{fieldName}</Typography>
      </Box>
      {children}
    </Card>
  );
}

export const DeletableFormCard: React.FC<PropsWithChildren<{fieldName: string, memberOfClass: string, bgColor?: string, onDelete?: () => void}>> = ({fieldName, memberOfClass, children, bgColor, onDelete}) => {
  return (
    <Card key={fieldName} style={{backgroundColor: bgColor ?? tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="button" color="primary">{fieldName}</Typography>
        { onDelete &&
          <Button size="small" color="secondary" onClick={onDelete}>
            Poista
          </Button>
        }
      </Box>
      {children}
    </Card>
  );
}

export const DeletableMuiCard: React.FC<PropsWithChildren<{title?: string, deletable?: boolean, deleteCard?: () => void}>> = ({title, deletable, deleteCard, children}) => {
  return (
    <Card
      style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs, padding: `0 ${tokens.spacing.xs}`, paddingBottom: tokens.spacing.xs}}
      component={Paper}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} style={{display: "flex", justifyContent: "space-between"}}>
          <Box>
            { title &&
              <Typography variant="caption" color="primary">{title}</Typography>
            }
          </Box>
          <Box>
            { deletable &&
              <Button style={{justifySelf: "flex-end"}} size="small" color="secondary" onClick={() => deleteCard && deleteCard()}>
                Poista
              </Button>
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
          { children }
        </Grid>
      </Grid>
    </Card>
  );
}

export const FormAddButton: React.FC<PropsWithChildren<{fieldName: string, memberOfClass: string, bgColor?: string, onAdd: () => void}>> = ({fieldName, memberOfClass, children, bgColor, onAdd}) => {
  return (
    <Card key={fieldName} style={{backgroundColor: bgColor ?? tokens.colors.highlightLight3, padding: "8px"}} component={Paper}>
      <Button variant="outlined" size="small" onClick={onAdd}>
        <Typography>Lisää</Typography>
      </Button>
      {children}
    </Card>
  );
}

export const ItemSpecificationDocumentReferenceForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const addDocItem = () => {
    const docs = formikProps.values.itemSpecificationDocumentReference ?? [];
    docs.push({
      iD: {value: ""}
    });
    formikProps.setFieldValue("itemSpecificationDocumentReference", docs);
  }

  const deleteDocItem = (docIndex: number) => {
    const docs = formikProps.values.itemSpecificationDocumentReference ?? [];
    docs.splice(docIndex, 1);
    formikProps.setFieldValue("itemSpecificationDocumentReference", docs);
  }

  return (
    <FormCard fieldName="Item specification document reference" memberOfClass="Item">
      { formikProps.values.itemSpecificationDocumentReference?.map((doc, docIndex) => {
        return (
          <Card
            key={`itemSpecificationDocumentReference-${docIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <FormTextField fieldName="ID" fieldPath={`itemSpecificationDocumentReference[${docIndex}].iD.value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteDocItem(docIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormTextField fieldName="Document type code" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentTypeCode.value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <FormTextField fieldName="List ID" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentTypeCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputWrapper>
                  <FormTextField fieldName="Document type" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentType.value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputWrapper>
                  <FormTextField fieldName="Document description" fieldPath={`itemSpecificationDocumentReference[${docIndex}].documentDescription[0].value`} memberOfClass="AttachmentDocumentReference" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <FormTextField fieldName="Embedded document binary object" fieldPath={`itemSpecificationDocumentReference[${docIndex}].attachment.embeddedDocumentBinaryObject.value`} memberOfClass="Attachment" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField fieldName="Mime code" fieldPath={`itemSpecificationDocumentReference[${docIndex}].attachment.embeddedDocumentBinaryObject.mimeCode`} memberOfClass="BinaryObjectType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormTextField fieldName="External reference" fieldPath={`itemSpecificationDocumentReference[${docIndex}].attachment.externalReference.URI.value`} memberOfClass="Attachment" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addDocItem()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const ItemCommodityClassificationForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const addClassification = () => {
    const docs = formikProps.values.commodityClassification ?? [];
    docs.push({
      itemClassificationCode: {value: ""}
    });
    formikProps.setFieldValue("commodityClassification", docs);
  }

  const deleteClassification = (docIndex: number) => {
    const docs = formikProps.values.itemSpecificationDocumentReference ?? [];
    docs.splice(docIndex, 1);
    formikProps.setFieldValue("commodityClassification", docs);
  }

  return (
    <FormCard fieldName="Commodity classification" memberOfClass="Item">
      { formikProps.values.commodityClassification?.map((cc, ccIndex) => {
        return (
          <Card
            key={`commodityClassification-${ccIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid item xs>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormTextField fieldName="Item classification code" fieldPath={`commodityClassification[${ccIndex}].itemClassificationCode.value`} memberOfClass="CommodityClassification" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <FormTextField fieldName="List ID" fieldPath={`commodityClassification[${ccIndex}].itemClassificationCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs="auto" style={{display: "flex"}}>
                <Button color="secondary" onClick={() => deleteClassification(ccIndex)}>
                  Poista
                </Button>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addClassification()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const HazardousItemForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const addItem = () => {
    const items = formikProps.values.hazardousItem ?? [];
    items.push({
      iD: {value: ""},
      uNDGCode: {value: "", listID: "UNCL8273"}
    });
    formikProps.setFieldValue("hazardousItem", items);
  }

  const deleteItem = (itemIndex: number) => {
    const items = formikProps.values.hazardousItem ?? [];
    items.splice(itemIndex, 1);
    formikProps.setFieldValue("hazardousItem", items);
  }

  return (
    <FormCard fieldName="Hazardous item" memberOfClass="Item">
      { formikProps.values.hazardousItem?.map((it, itIndex) => {
        return (
          <Card
            key={`hazardousItem-${itIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <FormTextField fieldName="ID" fieldPath={`hazardousItem[${itIndex}].iD.value`} memberOfClass="HazardousItem" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteItem(itIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormTextField fieldName="UNDG code" fieldPath={`hazardousItem[${itIndex}].uNDGCode.value`} memberOfClass="HazardousItem" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <FormTextField fieldName="List ID" fieldPath={`hazardousItem[${itIndex}].uNDGCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItem()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const ItemPropertyForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const addItemProp = () => {
    const itemProps = formikProps.values.additionalItemProperty ?? [];
    itemProps.push({
      name: {value: ""},
      value: {value: ""}
    });
    formikProps.setFieldValue("additionalItemProperty", itemProps);
  }

  const deleteItemProp = (itemIndex: number) => {
    const itemProps = formikProps.values.additionalItemProperty ?? [];
    itemProps.splice(itemIndex, 1);
    formikProps.setFieldValue("additionalItemProperty", itemProps);
  }

  return (
    <FormCard fieldName="Additional item property" memberOfClass="Item">
      { formikProps.values.additionalItemProperty?.map((it, itIndex) => {
        return (
          <Card
            key={`additionalItemProperty-${itIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <FormTextField fieldName="ID" fieldPath={`additionalItemProperty[${itIndex}].iD.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteItemProp(itIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <FormTextField fieldName="Name" fieldPath={`additionalItemProperty[${itIndex}].name.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <FormTextField fieldName="Name code" fieldPath={`additionalItemProperty[${itIndex}].nameCode.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={4}>
                      <FormTextField fieldName="List ID" fieldPath={`additionalItemProperty[${itIndex}].nameCode.listID`} memberOfClass="CodeType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <FormTextField fieldName="Value" fieldPath={`additionalItemProperty[${itIndex}].value.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <FormTextField fieldName="Value qualifier" fieldPath={`additionalItemProperty[${itIndex}].valueQualifier.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const ItemInstanceItemPropertyForm: React.FC<{formikProps: FormikProps<Product>, instanceIndex: number}> = ({formikProps, instanceIndex}) => {
  const itemInstances = formikProps.values.itemInstance;

  if (!itemInstances) {
    return <></>;
  }

  const addItemProp = () => {
    if (itemInstances) {
      const itemProps = itemInstances[instanceIndex].additionalItemProperty ?? [];
      itemProps.push({
        name: {value: ""}
      });
      itemInstances[instanceIndex].additionalItemProperty = itemProps;
      formikProps.setFieldValue("itemInstance", itemInstances);
    }
  }

  const deleteItemProp = (itemIndex: number) => {
    if (itemInstances) {
      const itemProps = itemInstances[instanceIndex].additionalItemProperty ?? [];
      itemProps.splice(itemIndex, 1);
      itemInstances[instanceIndex].additionalItemProperty = itemProps;
      formikProps.setFieldValue("itemInstance", itemInstances);
    }
  }

  return (
    <FormCard fieldName="Additional item property" memberOfClass="ItemInstance">
      { itemInstances[instanceIndex].additionalItemProperty?.map((it, itIndex) => {
        return (
          <Card
            key={`itemInstance-${instanceIndex}-additionalItemProperty-${itIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <FormTextField fieldName="Name" fieldPath={`itemInstance[${instanceIndex}].additionalItemProperty[${itIndex}].name.value`} memberOfClass="ItemProperty" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteItemProp(itIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <FormTextField fieldName="Attribute ID" fieldPath={`itemInstance[${instanceIndex}].additionalItemProperty[${itIndex}].rangeDimension.attributeID.value`} memberOfClass="Dimension" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <FormTextField fieldName="Description" fieldPath={`itemInstance[${instanceIndex}].additionalItemProperty[${itIndex}].rangeDimension.description.value`} memberOfClass="Dimension" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const ItemInstanceForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const addItemInstance = () => {
    const itemProps = formikProps.values.itemInstance ?? [];
    itemProps.push({
    });
    formikProps.setFieldValue("itemInstance", itemProps);
  }

  const deleteItemInstance = (itemIndex: number) => {
    const itemProps = formikProps.values.itemInstance ?? [];
    itemProps.splice(itemIndex, 1);
    formikProps.setFieldValue("itemInstance", itemProps);
  }

  return (
    <FormCard fieldName="Item instance" memberOfClass="Item">
      { formikProps.values.itemInstance?.map((it, itIndex) => {
        return (
          <Card
            key={`itemInstance-${itIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid container item xs spacing={1}>
                  <Grid item xs={12} md={6}>
                    <InputWrapper>
                      <FormTextField fieldName="Best before date" fieldPath={`itemInstance[${itIndex}].bestBeforeDate.valueString`} memberOfClass="ItemInstance" formikProps={formikProps} />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputWrapper>
                      <FormTextField fieldName="Expiry date" fieldPath={`itemInstance[${itIndex}].lotIdentification.expiryDate.valueString`} memberOfClass="LotIdentification" formikProps={formikProps} />
                    </InputWrapper>
                  </Grid>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteItemInstance(itIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ItemInstanceItemPropertyForm formikProps={formikProps} instanceIndex={itIndex} />
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemInstance()}>
          Lisää
        </Button>
      </Box>
    </FormCard>
  )
}

export const ItemCertificateForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const addItemInstance = () => {
    const certificates = formikProps.values.certificate ?? [];
    certificates.push({
      iD: {value: ""},
      certificateTypeCode: {value: ""},
      certificateType: {value: ""}
    });
    formikProps.setFieldValue("certificate", certificates);
  }

  const deleteItemInstance = (itemIndex: number) => {
    const certificates = formikProps.values.certificate ?? [];
    certificates.splice(itemIndex, 1);
    formikProps.setFieldValue("certificate", certificates);
  }

  return (
    <FormCard fieldName="Certificate" memberOfClass="Item">
      { formikProps.values.certificate?.map((cert, certIndex) => {
        return (
          <Card
            key={`certificate-${certIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <FormTextField fieldName="ID" fieldPath={`certificate[${certIndex}].iD.value`} memberOfClass="Certificate" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteItemInstance(certIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputWrapper>
                  <FormTextField fieldName="Certificate type code" fieldPath={`certificate[${certIndex}].certificateTypeCode.value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputWrapper>
                  <FormTextField fieldName="Certificate type" fieldPath={`certificate[${certIndex}].certificateType.value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputWrapper>
                  <FormTextField fieldName="Remarks" fieldPath={`certificate[${certIndex}].remarks[0].value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <FormTextField fieldName="Issuer party" fieldPath={`certificate[${certIndex}].issuerParty.partyName[0].name.value`} memberOfClass="Certificate" formikProps={formikProps} />
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <CertificateDocumentReferenceForm formikProps={formikProps} certificateIndex={certIndex} />
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemInstance()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const CertificateDocumentReferenceForm: React.FC<{formikProps: FormikProps<Product>, certificateIndex: number}> = ({formikProps, certificateIndex}) => {
  const certificates = formikProps.values.certificate;

  if (!certificates) {
    return <></>;
  }

  const addItemProp = () => {
    if (certificates) {
      const docRefs = certificates[certificateIndex].documentReference ?? [];
      docRefs.push({
        iD: {value: ""}
      });
      certificates[certificateIndex].documentReference = docRefs;
      formikProps.setFieldValue("certificate", certificates);
    }
  }

  const deleteItemProp = (itemIndex: number) => {
    if (certificates) {
      const docRefs = certificates[certificateIndex].documentReference ?? [];
      docRefs.splice(itemIndex, 1);
      certificates[certificateIndex].documentReference = docRefs;
      formikProps.setFieldValue("certificate", certificates);
    }
  }

  return (
    <FormCard fieldName="Document reference" memberOfClass="Certificate">
      { certificates[certificateIndex].documentReference?.map((docRef, docRefIndex) => {
        return (
          <Card
            key={`certificate-${certificateIndex}-documentReference-${docRefIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <FormTextField fieldName="Document reference" fieldPath={`certificate[${certificateIndex}].documentReference[${docRefIndex}].iD.value`} memberOfClass="Certificate" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteItemProp(docRefIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const ItemDimensionForm: React.FC<{formikProps: FormikProps<Product>}> = ({formikProps}) => {
  const addItemProp = () => {
    const dimensions = formikProps.values.dimension ?? [];
    dimensions.push({
      attributeID: {value: ""},
      measure: {unitCodeListVersionID: "UNECERec20"},
      minimumMeasure: {unitCodeListVersionID: "UNECERec20"},
      maximumMeasure: {unitCodeListVersionID: "UNECERec20"}
    });
    formikProps.setFieldValue("dimension", dimensions);
  }

  const deleteItemProp = (itemIndex: number) => {
    const dimensions = formikProps.values.dimension ?? [];
    dimensions.splice(itemIndex, 1);
    formikProps.setFieldValue("dimension", dimensions);
  }

  return (
    <FormCard fieldName="Dimension" memberOfClass="Item">
      { formikProps.values.dimension?.map((d, dIndex) => {
        return (
          <Card
            key={`dimension-${dIndex}`}
            style={{background: tokens.colors.whiteBase, marginBottom: tokens.spacing.xs}}
            component={Paper}
          >
            <Grid container spacing={1} style={{padding: "8px"}}>
              <Grid container item spacing={1}>
                <Grid item xs>
                  <InputWrapper>
                    <FormTextField fieldName="Attribute ID" fieldPath={`dimension[${dIndex}].attributeID.value`} memberOfClass="Dimension" formikProps={formikProps} />
                  </InputWrapper>
                </Grid>
                <Grid item xs="auto" style={{display: "flex"}}>
                  <Button color="secondary" onClick={() => deleteItemProp(dIndex)}>
                    Poista
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField fieldName="Measure" fieldPath={`dimension[${dIndex}].measure.value`} memberOfClass="Dimension" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormTextField fieldName="Unit code" fieldPath={`dimension[${dIndex}].measure.unitCode`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormTextField fieldName="Unit code list version ID" fieldPath={`dimension[${dIndex}].measure.unitCodeListVersionID`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField fieldName="Minimum measure" fieldPath={`dimension[${dIndex}].minimumMeasure.value`} memberOfClass="Dimension" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormTextField fieldName="Unit code" fieldPath={`dimension[${dIndex}].minimumMeasure.unitCode`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormTextField fieldName="Unit code list version ID" fieldPath={`dimension[${dIndex}].minimumMeasure.unitCodeListVersionID`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
              <Grid item xs={12}>
                <InputWrapper>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField fieldName="Maximum measure" fieldPath={`dimension[${dIndex}].maximumMeasure.value`} memberOfClass="Dimension" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormTextField fieldName="Unit code" fieldPath={`dimension[${dIndex}].maximumMeasure.unitCode`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormTextField fieldName="Unit code list version ID" fieldPath={`dimension[${dIndex}].maximumMeasure.unitCodeListVersionID`} memberOfClass="MeasureType" formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </InputWrapper>
              </Grid>
            </Grid>
          </Card>
        )
      })}
      <Box style={{marginTop: tokens.spacing.s}}>
        <Button variant="outlined" size="small" onClick={() => addItemProp()}>
          <Typography>Lisää</Typography>
        </Button>
      </Box>
    </FormCard>
  )
}

export const TextFieldListForm: React.FC<{fieldName: string, fieldPath: string, memberOfClass: string, formikProps: FormikProps<any>, required?: boolean}> = ({fieldName, fieldPath, memberOfClass, formikProps, required}) => {
  const fieldProps = formikProps.getFieldProps(fieldPath);
  const values: TextType[] = fieldProps.value ?? [];

  useEffect(() => {
    if (!formikProps.values[fieldPath] && required) {
      add();
    }
  }, []);

  const add = () => {
    values.push({});
    formikProps.setFieldValue(fieldPath, values);
  }

  const del = (itemIndex: number) => {
    values.splice(itemIndex, 1);
    formikProps.setFieldValue(fieldPath, values);
  }

  if (values?.length > 0) {
    return (
      <DeletableFormCard fieldName={fieldName} memberOfClass={memberOfClass}>
        <Grid container spacing={1}>
          { values?.map((it, itIndex) => {
            return (
              <Grid key={`${fieldName}-${itIndex}`} item xs={12} sm={6} md={4} lg={3}>
                <DeletableMuiCard title={`#${itIndex + 1} ${fieldName}`} deletable deleteCard={() => del(itIndex)}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormTextField fieldName={fieldName} fieldPath={`${fieldPath}[${itIndex}].value`} memberOfClass={memberOfClass} formikProps={formikProps} />
                    </Grid>
                  </Grid>
                </DeletableMuiCard>
              </Grid>
            )
          })}
        </Grid>
        <Box style={{marginTop: tokens.spacing.s}}>
          <Button variant="outlined" size="small" onClick={add}>
            <Typography>Lisää</Typography>
          </Button>
        </Box>
      </DeletableFormCard>
    );
  }

  return (
    <FormAddButton fieldName={fieldName} memberOfClass={memberOfClass} onAdd={add} />
  );
}

