import { Title } from '../../utils/tooltipUtil';
import { Description } from "../../utils/tooltipUtil";
import { Base } from '../base';
import TaxBuildingAddress from "./taxBuildingAddress";
import TaxBuildingGrounds from "./taxBuildingGrounds";
import TaxBuildingSection from "./taxBuildingSection";

export default class TaxBuilding extends Base {
    @Title("Pysyvä rakennustunnus")
    @Description("Rakennuksen pysyvä tunnus.")
    PermanentBuildingID: string = "";
    @Title("Käyttötarkoitus")
    @Description("Rakennuksen tai sen osan pääasiallinen käyttötarkoitus.")
    PurposeOfUse: string = "";
    @Title("Kerrosala")
    @Description("Kerrosala poikkeaa normaalisti rakennuksen tietona esitettävästä pinta-alasta. Kerrosalaan ei lasketa ullakolla tai kellarissa sijaitsevia, muita kuin pääasiallisen käyttötarkoituksen mukaisia tiloja.")
    FloorArea: number = 0;
    @Title("Rakennuksen kokonaisala yhteensä")
    @Description("Rakennuksen kaikkien osien yhteenlaskettu kokonaisala.")
    TotalBuildingArea: number = 0;
    @Title("Rakennuksen nimi")
    @Description("Rakennuksen yksilöivä nimitieto, esimerkiksi asiakkaan rakennukselle antama nimi.")
    NameOfBuilding: string = "";
    @Title("Osoite")
    @Description("Sijaintipaikan osoittavien tietojen kokonaisuus")
    Address: TaxBuildingAddress = new TaxBuildingAddress();
    @Title("Maapohja")
    @Description("Maapohja on osapuolen varallisuuden laji, osa kiinteistöä. Kiinteistö koostuu yhdestä tai useammasta maapohjasta, joihin kiinteistö jaetaan arvostamista varten. Saman kiinteistön eri maapohjiin voidaan soveltaa eri kiinteistöveroprosenttia maapohjan käytön mukaan. Maapohja voi olla myös kiinteistöverosta vapaa.")
    Ground: TaxBuildingGrounds = new TaxBuildingGrounds();
    @Title("Rakennuksen osa(t)")
    @Description("Arvostamisen vuoksi rakennus joudutaan tietyissä tilanteissa jakamaan osiin.")
    Sections: TaxBuildingSection[] = [];
}