import { Title } from '../../utils/tooltipUtil';
import { Description } from "../../utils/tooltipUtil";
import { Base } from '../base';

export default class TaxBuildingSection extends Base {
    @Title("Rakennustyyppi")
    @Description("http://uri.suomi.fi/datamodel/ns/kive#buildingType")
    BuildingType: string = "";
    @Title("Rakentamisen aloittamispäivä")
    @Description("Päivämäärä, jolloin rakennusta (rakennuksen osaa) on alettu rakentaa. Vanhemmilla rakennuksilla ei ole aloittamispäivää, ainoastaan valmistumisvuosi.")
    DateOfStart: Date = new Date();
    @Title("Valmistumispäivä")
    @Description("Päivämäärä, jolloin rakennus katsotaan valmistuneeksi. Rakennus katsotaan kiinteistöverotuksessa valmistuneeksi silloin, kun rakennukselle on myönnetty käyttöönottolupa.")
    DateOfCompletion: Date = new Date();
    @Title("Autopaikkojen lukumäärä")
    @Description("Pysäköintitalo -rakennustyypin ominaisuustieto, yksikkö kpl.")
    NumberOfParkingSpaces: number = 0;
    @Title("Hissi")
    @Description("Asuinkerrostalo -rakennustyypin ominaisuustieto. Arvot: kyllä/ei.")
    Elevator: boolean = false;
    @Title("Sähkö")
    @Description("Pientalo ja vapaa-ajan asunto -rakennustyyppien ominaisuustieto. Arvot: kyllä/ei. Rakennuksessa katsotaan olevan sähkö, kun rakennus on liitetty sähköverkkoon tai siihen johdetaan 230V sähkövirta riippumatta sähköenergian tuotantotavasta.")
    Electricity: boolean = false;
    @Title("Viemäri")
    @Description("Pientalon ja vapaa-ajan asunto -rakennustyyppien ominaisuustieto. Viemärinä pidetään sellaista rakennukseen kiinteästi asennettua viemäröintijärjestelmää, joka päättyy alueelliseen viemäriverkostoon, saostus- tai umpikaivoon tai pienpuhdistamoon.")
    Sewer: boolean = false;
    @Title("Vesijohto")
    @Description("Pientalo- ja vapaa-ajan asunto -rakennustyyppien ominaisuustieto. Arvot: kyllä/ei. Vesijohtona pidetään sellaista rakennukseen kiinteästi asennettua vesijohtojärjestelmää, johon vesi tulee alueellisesta vesijohtoverkostosta tai kaivosta (rengas- tai porakaivo) joko paineella tai pumpulla.")
    Water: boolean = false;
    @Title("Kerrosluku")
    @Description("Kerrosten lukumäärä. Asuinkerrostalo, myymälärakennus ja muu rakennus -rakennustyyppien ominaisuustieto (kpl).")
    NumberOfFloors: number = 0;
    @Title("Huoneistojen lukumäärä")
    @Description("Pientalo (paritalot, rivitalot) ja asuinkerrostalo -rakennustyyppien ominaisuustieto (kpl).")
    NumberOfApartments: number = 0;
    @Title("Kantava rakenne")
    @Description("Kaikkien rakennustyyppien ominaisuustieto. Arvot: Puu, Kivi/metalli. Tieto vaikuttaa sovellettavaan ikäalennusprosenttiin.")
    BearingStructure: string = "";
    @Title("Lämmitys")
    @Description("Pientalo -rakennustyypin ominaisuustieto. Arvot keskuslämmitys, muu kuin keskuslämmitys.")
    Heating: string = "";
    @Title("Kokonaisala")
    @Description("Rakennuksen tai sen osan kokonaisala eli pinta-ala ulkomitoin sisältäen kaikkien kerrosten, kellareiden ja lämpöeristettyjen ullakkohuoneiden pinta-alat. Kokonaisalaan ei lasketa parvekkeita, katoksia eikä tiloja, joissa vapaa korkeus on alle 160 cm.")
    TotalArea: number = 0;
    @Title("Kerroskorkeus")
    @Description("Asuinkerrostalo ja myymälärakennus -rakennustyyppien ominaisuustieto.")
    FloorHeight: number = 0;
    @Title("Tilavuus")
    @Description("Rakennuksen tai sen osan tilavuus kuutiometreinä")
    Volume: number = 0;
    @Title("Peruskorjauksen kohde")
    @Description("Eri kohteisiin kohdistuvat peruskorjaukset vähentävät ikäalennuksia eri prosenttiyksikkömäärän. Kohteena voi olla seuraavat: Lämmitysjärjestelmä, Vesijohto ja viemäri, Sähkölaitteet, Ovet ja ikkunat, Vesikate ja ulkoseinien pinnoite, Ulkopuolinen lisäeriste, Koneellinen ilmastointi, Muu.")
    RenovationObject: string = "";
    @Title("Peruskorjausvuosi")
    @Description("Tieto esiintyy muodossa peruskorjausvuosi ja perusparannusvuosi. Kuvaa vuotta, jona peruskorjaukset on tehty. Jos peruskorjaushanke on kestänyt useita vuosia, korjausten valmistumisvuosi.")
    RenovationYear: number = 0;
}