import { Document } from "./document";
import { RequestedException } from "./requestedException";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export enum ApplicationTypeEnum {
    _01 = 1,
    _02 = 2,
    _03 = 3,
    _04 = 4
}

export class PermitApplication extends Base{
    @Title("Hakemuksen tyyppi")
    @Description("Hakemuksen tyyppi kuvaa, mistä asiassa on kysymys, onko kyseessä rakentamislupa-, purkamislupa-, maisematyöasia jne. Arvot koodistosta Hakemuksen tyyppi.")
    ApplicationType: ApplicationTypeEnum = ApplicationTypeEnum._01;
    
    @Title("Dokumentti")
    @Description("Liittyvät dokumentit ja asiakirjat")
    Document: Document = new Document();
    
    @Title("Haetut poikkeamiset")
    @Description("Lupahakemuksen yhteydessä haetut poikkeamiset hankkeen rakennuspaikan rakentamista koskeviin velvottaviin määräyksiin.")
    RequestedExceptions: RequestedException = new RequestedException();
    
    @Title("Hakemuksen sisältö")
    @Description("Hakemuksen pääasiallinen sisältö")
    ApplicationContent: string = "";
    
    @Title("Hakija")
    @Description("Hakija")
    Applicant: string = "";
    
    @Title("Saapumispäivämäärä")
    @Description("Päivämäärä, jolloin hakemus on saapunut sähköiseen asiointipalveluun tai kirjaamoon.")
    ArrivalDate: Date = new Date();
    
    @Title("Sijoittamis-/toteuttamislupa")
    @Description("Haetaanko lupaa sijoittamiselle, toteuttamiselle vai molemmille")
    PlacementImplementationPermit: string = "";
    
}
