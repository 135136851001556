import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class CaseIdentifier extends Base{
    @Title("Tunnus")
    @Description("Rakennusvalvonta-asian tunnus tietojärjestelmässä.")
    Identifier: string = "";
    @Title("Tietojärjestelmätieto")
    @Description("Tietojärjestelmä, jota käytetään asian käsittelemiseen.")
    InformationSystem: string = "";
}