interface Base {
  id: string,
  codeValue: string,
  uri: string,
  url: string,
  prefLabel: LocalizedString,
  created: Date,
  modified?: Date,
  contentModified?: Date,
  statusModified?: Date,
  status: string,
}

export interface CodeList extends Base {
  codesUrl: string,
  extensionsUrl: string,
  codes: CodeItem[],
  description: LocalizedString,
  infoDomains: InfoDomain[],
  languageCodes: CodeItem[],
  organizations: Organization[],
  cumulative: boolean,
  feedbackChannel: LocalizedString,
  codeRegistry: CodeRegistry,
  totalNrOfSearchHitsCodes: number,
  totalNrOfSearchHitsExtensions: number
}

export interface CodeItem extends Base {
  order: number,
  hierarchyLevel: number,
  broaderCode?: CodeItem,
  membersUrl: string,
}

export interface InfoDomain extends CodeItem {
  description: LocalizedString,
}

export interface Organization {
  id: string,
  url: string,
  prefLabel: LocalizedString,
  description: LocalizedString,
  removed: boolean
}

export interface CodeRegistry extends Base {
  codeSchemesUrl: string
}

export type LocalizedString = {
  [key in CodeLang]?: string
}

export enum CodeLang {
  EN = "en",
  FI = "fi",
  SV = "sv"
}

export function getCodeItemByPrefLabel(codeList: CodeList|null, key: CodeLang, prefLabel: string): CodeItem | undefined {
  return codeList?.codes.find(c => prefLabel === c.prefLabel[key]);
};

export function getCodeItemLabelByCode(codeList: CodeList|null, key: CodeLang, code: string): string {
  return (codeList?.codes.find(c => code === c.codeValue)?.prefLabel[key] || "") + " (" + code + ")";
};