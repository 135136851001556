import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class Document extends Base{
    @Title("Asiakirjatyyppi")
    @Description("Tieto, joka kuvaa tuotettavan asiakirjan lajia. Asiakirjoja tuotetaan yleensä asiankäsittelyyn liittyvissä yksittäisissä toimissa tai tapahtumissa (toimenpide). Asiakirjatyyppi noudattaa yhteistä luokitusta/koodistoa, esimerkkejä arvoille ovat raportti, päätös, ilmoitus, muistio, tiedote... RYTJ-määrittelyn TOS")
    DocumentType: string = "";
    
    @Title("Asiakirjatyypin tarkenne")
    @Description("Tieto, joka täsmentää asiakirjatyypin yleistä luokittelua. RYTJ-määrittelyn TOS.")
    DocumentTypeRefinement: string = "";
    
    @Title("Asiasanasto")
    @Description("Tiettyyn aihepiiriin koottu asiasanojen arvojoukko. Asiasana ja asiasanasto tulee esittää parina, jotta aina tiedetään mistä asiasanastosta kyseinen asiasana on poimittu. Huom! Asiasanasto on tarpeellinen metatieto vain tilanteissa, joissa EI käytetä Fintoon URI:lla viittaavaa \"asiasana\" -ominaisuutta.")
    Vocabulary: string = "";
    
    @Title("Identifiointitunnus")
    @Description("Asiakirjan tai dokumentin tunniste")
    Identification: string = "";
    
    @Title("Päivämäärä")
    @Description("Päivämäärä, jolloin asiakirja tai dokumentti on lisätty asiaan")
    DocumentAdded: Date = new Date();
    
    @Title("Vahvistuspäivämäärä")
    @Description("Päivämäärä jolloin asiakirja on viranomaisen vahvistama")
    VerificationDate: Date = new Date();
}