import React from "react";
import { Box, Grid } from '@material-ui/core';
import { buildingAppData } from '../../App';
import Card from '../common/Card';
import AsunnonOmistusView from "./AsunnonOmistusView";
import OmakotitalonOmistusView, { mapToOKTViewParts } from "./OmakotitalonOmistusView";
import KerrostalonTiedotView, { mapToKTViewParts } from "./KerrostalonTiedotView";

interface Props {
  data: buildingAppData;
}

const AiheetView: React.FC<Props> = (props: Props) => {

  const ktViewParts = mapToKTViewParts(props.data);
  const oktViewParts = mapToOKTViewParts(props.data);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container xs={12} md={12}>
        <Grid item xs={12} md={12}>
          <Card title="Asunnon (osakehuoneiston) omistus">
            <AsunnonOmistusView data={props.data}/>
          </Card>
          { oktViewParts && 
            <Card title="Omakotitalon omistus">
              <OmakotitalonOmistusView wp={oktViewParts}/>
            </Card>
          }
          { ktViewParts && 
            <Card title="Kerrostalon taloyhtiön kaikki tiedot">
              <KerrostalonTiedotView wp={ktViewParts}/>
            </Card>
          }
        </Grid>
      </Grid>
    </Box>
  );
}

export default AiheetView;