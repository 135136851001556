export interface KaavaStyles {
  [code: string]: CodeStyles
}

export interface CodeStyles {
  description?: string,
  label: string,
  fill?: Styles,
  stroke?: Styles
}

export interface Styles {
  color: string,
  rgb?: number[],
  description?: string
}

export function getCodeStyles(kaavaStyles: KaavaStyles|null, code: string): CodeStyles | undefined {
  if (!kaavaStyles) {
    return undefined;
  }
  let _code = code;
  let codeStyles: CodeStyles|undefined = undefined;
  while (!codeStyles && _code.length >= 2) {
    codeStyles = kaavaStyles[_code];
    // Take off the last two chars
    _code = _code.substring(0, _code.length - 2);
  }
  return codeStyles;
}