import { Title } from '../utils/tooltipUtil';
import { Description } from "../utils/tooltipUtil";

export class BuildingPermit {
    @Title("Rakennuslupatunnus")
    @Description("rakennusluvan yksilöivä tunnus, joka koostuu kuntanumerosta, tunnusjärjestysnumerosta, myöntämiskuukaudesta ja myöntämisvuodesta")
    PermitID: string = "";
    @Title("Rakennuslupalaji")
    @Description("VTJ:ssä rakennukseen kohdistuva toimenpide, kattaa sekä uudisrakentamisen, laajennuksen, uudisrakentamiseen verrattavat muutostyöt sekä muun muutosrakentamisen")
    BuildingPermitType: string = "";
    @Title("Rakentamistapa")
    @Description("kuvaa rakennuksen rungon pääasiallisen rakennustavan")
    ConstructionMethod: string = "";
    @Title("Pääasiallinen rakentaja")
    @Description("kuvaa onko rakentaminen ollut liiketaloudellista vai muuta rakentamista")
    MainBuilder: string = "";
    @Title("Uusien asuntojen lukumäärä")
    @Description("Rakennukseen tulevien uusien asuntojen lukumäärä")
    NewAppartmentsCount: string = "";
    @Title("Uusien asuntojen pinta-ala")
    @Description("Rakennukseen tulevien uusien asuntojen lukumäärä")
    NewAppartmentsArea: string = "";
    @Title("Muutostyön laji")
    @Description("Muutostyöhön liittyvä kooditieto, jonka arvoja voivat olla: perusparannukseksi katsottava muutos, pääasiallisen käyttötarkoituksen muutos, perustusten ja kantavien rakenteiden muutos, muu muutostyö")
    ChangeWorkType: string = "";
    @Title("Rakentamisen alkamispäivämäärä")
    @Description("Rakennusluvan mukaisen rakennuksen toteuttamisen aloittamispäivämäärä")
    StartDate: Date = new Date();
    @Title("Valmistumispäivämäärä")
    @Description("Rakennusvalvontaviranomaisen ilmoittama luvan mukaisen toimenpiteen valmistumisajankohta päivämäärän tarkkuudella ilmaistuna")
    CompletionDate: Date = new Date();
    @Title("Jatkoajan päättymispäivämäärä")
    @Description("Rakennusvalvontaviranomaisen myöntämä rakennusluvan jatkoajan päättymispäivämäärä (alkuperäisen voimassaoloalan päättymisen jälkeen annettu uusi päättymisajankohta)")
    ExtensionDate: Date = new Date();
    @Title("Poistopäivämäärä")
    @Description("Päivämäärä, jolloin rakennusluvan voimassaoloaika päättyy esimerkiksi rakennushankkeen raukeamisen vuoksi")
    ExpiryDate: Date = new Date();
    @Title("Rakennuksen tiedot")
    @Description("Rakennuksen tiedot rakennusluvassa")
    BuildingData: string = "";
    @Title("Lupahuoneiston tiedot")
    @Description("tiedot rakennusluvan mukaisista huoneistotiedoista, joita ollaan vasta toteuttamassa")
    ApartmentPermitInformation: string = "";
    @Title("Rakennuksen laajennustiedot")
    @Description("Rakennuslupaan liitetyt rakennuksen laajennukseen liittyvät tiedot")
    BuildingExtensionInformation: string = "";
}
  