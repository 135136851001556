import { Title } from '../utils/tooltipUtil';
import { Description } from "../utils/tooltipUtil";

export class EnergyBasics {
    @Title("Pysyvä rakennustunnus")
    @Description("Rakennuksen pysyvä tunnus.")
    PermanentBuildingID: string = "";
    @Title("Kiinteistötunnus")
    @Description("kiinteistörekisteriin merkityn rekisteriyksikön yksilöivä tunnus")
    BuildingID: string = "";
    @Title("Valmistumisvuosi")
    @Description("Rakennuksen lopputarkastuksen ja käyttöönoton ajankohta vuoden tarkkuudella.")
    CompletionYear: number = 0;
    @Title("Tila")
    @Description("(Luonnos), Voimassa, Korvattu, Hylätty")
    Stage: number = 0;
    @Title("Nimi")
    @Description("kiinteistön nimi")
    Name: string = "";
    @Title("Käyttötarkoitus")
    @Description("Ympäristöministeriön asetus rakennuksen energiatodistuksesta mukainen käyttötarkoitus; oma koodisto.")
    IntendedUse: string = "";
    @Title("Sijaintikunta")
    @Description("kunta, jossa kohde sijaitsee")
    LocationMunicipality: number = 0;
    @Title("Rakennuksen laskennallinen kokonaisenergiakulutus")
    @Description("Energiatodistuksen kohteena olevan rakennuksen laskennallinen kokonaisenergiakulutus, ilmaistaan kokonaislukuarvona kWhE / (m2vuosi)")
    E_Value: number = 0;
    @Title("Energiatehokkuusluokka")
    @Description("rakennuksen tai sen osan laskennallisen kokonaisenergiankulutuksen perusteella määräytyvä luokka; koodisto A-G")
    E_Class: string = "";
}