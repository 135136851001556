import { Title, Description } from '../../utils/tooltipUtil';

export enum Aikayksikko {
    NotSet,
    Yearly,
    Monthly,
}

export class Aika {
    @Title("Aikaväli")
    @Description("Aikaväli")
    aikavali: number = 0;

    @Title("Ajan yksikkö")
    @Description("Ajan yksikkö")
    yksikko: Aikayksikko = Aikayksikko.NotSet;
}

