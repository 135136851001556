import { TooltippedText } from "./tooltippedText";

export function GetDescription(item: any, propertyName: string) {
    var returnValue = new TooltippedText();
    returnValue.Value = item[propertyName];
    returnValue.Tooltip = item["__" + propertyName + "_description"];
    return returnValue;

}

export function GetTitleForObject(item: any, propertyName: string) {
    var returnValue = new TooltippedText();
    returnValue.Value = item[propertyName];
    returnValue.Tooltip = item["__" + propertyName + "_description"];
    returnValue.ObjectTooltip = item["__" + propertyName + "_title"];
    return returnValue;

}


export function GetTitle(item: any, propertyName: string) : TooltippedText {
    var returnValue = new TooltippedText();
    returnValue.Value = item["__" + propertyName + "_title"];
    returnValue.Tooltip = item["__" + propertyName + "_description"];
    return returnValue;
}

export function Description(a: string, b?: number) {
    function decorator(target: any, property: string | symbol): void {
        target["__" + (property as string) + "_description"] = a;
    }

    return decorator;
}

export function Title(a: string, b?: number) {
    function decorator(target: any, property: string | symbol): void {
        target["__" + (property as string) + "_title"] = a;
    }

    return decorator;
}