
export class ID {
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    },
  ]

  static getAttributeTypeMap() {
    return ID.attributeTypeMap;
  }
}

export class ReadPoint {
  'id'?: ID;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "ID"
    },
  ]

  static getAttributeTypeMap() {
    return ReadPoint.attributeTypeMap;
  }
}

export class BizLocation {
  'id'?: ID;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "ID"
    },
  ]

  static getAttributeTypeMap() {
    return BizLocation.attributeTypeMap;
  }
}

export class TypeValue {
  'type'?: string;
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "type",
      "baseName": "type",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    },
  ]

  static getAttributeTypeMap() {
    return TypeValue.attributeTypeMap;
  }
}

export class ILMD {
  'items'?: Array<any>;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "items",
      "baseName": "items",
      "type": "Array<any>"
    }
  ]

  static getAttributeTypeMap() {
    return ILMD.attributeTypeMap;
  }
}

export class EpcisEvent {
  readonly eventType: string;
  'eventTime'?: string;
  'recordTime'?: string;
  'eventTimeZoneOffset'?: string;
  'eventId'?: string;
  'action'?: string;
  'bizStep'?: string;
  'disposition'?: string;
  'readPoint'?: ReadPoint;
  'bizLocation'?: BizLocation;
  'bizTransactionList'?: Array<TypeValue>;
  'sourceList'?: Array<TypeValue>;
  'destinationList'?: Array<TypeValue>;
  'ilmd'?: ILMD;

  constructor(type?: string) {
    this.eventType = type ?? "EpcisEvent";
  }

  static discriminator: string | undefined = "eventType";

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "eventType",
      "baseName": "eventType",
      "type": "string"
    },
    {
      "name": "eventTime",
      "baseName": "eventTime",
      "type": "Date"
    },
    {
      "name": "recordTime",
      "baseName": "recordTime",
      "type": "Date"
    },
    {
      "name": "eventTimeZoneOffset",
      "baseName": "eventTimeZoneOffset",
      "type": "string"
    },
    {
      "name": "eventId",
      "baseName": "eventId",
      "type": "string"
    },
    {
      "name": "action",
      "baseName": "action",
      "type": "string"
    },
    {
      "name": "bizStep",
      "baseName": "bizStep",
      "type": "string"
    },
    {
      "name": "disposition",
      "baseName": "disposition",
      "type": "string"
    },
    {
      "name": "readPoint",
      "baseName": "readPoint",
      "type": "ReadPoint"
    },
    {
      "name": "bizLocation",
      "baseName": "bizLocation",
      "type": "BizLocation"
    },
    {
      "name": "bizTransactionList",
      "baseName": "bizTransactionList",
      "type": "Array<TypeValue>"
    },
    {
      "name": "sourceList",
      "baseName": "sourceList",
      "type": "Array<TypeValue>"
    },
    {
      "name": "destinationList",
      "baseName": "destinationList",
      "type": "Array<TypeValue>"
    },
    {
      "name": "ilmd",
      "baseName": "ilmd",
      "type": "ILMD"
    }
  ]

  static getAttributeTypeMap() {
    return EpcisEvent.attributeTypeMap;
  }
}

export class EPC {
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    },
  ]

  static getAttributeTypeMap() {
    return EPC.attributeTypeMap;
  }
}

export class QuantityElement {
  'epcClass'?: string;
  'quantity'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "epcClass",
      "baseName": "epcClass",
      "type": "string"
    },
    {
      "name": "quantity",
      "baseName": "quantity",
      "type": "string"
    }
  ]

  static getAttributeTypeMap() {
    return QuantityElement.attributeTypeMap;
  }
}

export class ObjectEvent extends EpcisEvent {
  'epcList'?: Array<EPC>;
  'quantityList'?: Array<QuantityElement>;

  constructor() {
    super("ObjectEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "epcList",
      "baseName": "epcList",
      "type": "Array<EPC>"
    },
    {
      "name": "quantityList",
      "baseName": "quantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...ObjectEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }
}

export class AggregationEvent extends EpcisEvent {
  'parentID'?: string;
  'childEPCs'?: Array<EPC>;
  'childQuantityList'?: Array<QuantityElement>;

  constructor() {
    super("AggregationEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "parentID",
      "baseName": "parentID",
      "type": "string"
    },
    {
      "name": "childEPCs",
      "baseName": "childEPCs",
      "type": "Array<EPC>"
    },
    {
      "name": "childQuantityList",
      "baseName": "childQuantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...AggregationEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }
}

export class TransactionEvent extends EpcisEvent {
  'parentID'?: string;
  'epcList'?: Array<EPC>;
  'quantityList'?: Array<QuantityElement>;

  constructor() {
    super("TransactionEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "parentID",
      "baseName": "parentID",
      "type": "string"
    },
    {
      "name": "epcList",
      "baseName": "epcList",
      "type": "Array<EPC>"
    },
    {
      "name": "quantityList",
      "baseName": "quantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...TransactionEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }
}

export class TransformationEvent extends EpcisEvent {
  'transformationId'?: string;
  'inputEPCList'?: Array<EPC>;
  'inputQuantityList'?: Array<QuantityElement>;
  'outputEPCList'?: Array<EPC>;
  'outputQuantityList'?: Array<QuantityElement>;

  constructor() {
    super("TransformationEvent");
  }

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "inputEPCList",
      "baseName": "inputEPCList",
      "type": "Array<EPC>"
    },
    {
      "name": "inputQuantityList",
      "baseName": "inputQuantityList",
      "type": "Array<QuantityElement>"
    },
    {
      "name": "outputEPCList",
      "baseName": "outputEPCList",
      "type": "Array<EPC>"
    },
    {
      "name": "outputQuantityList",
      "baseName": "outputQuantityList",
      "type": "Array<QuantityElement>"
    },
    {
      "name": "transformationId",
      "baseName": "transformationId",
      "type": "string"
    }
  ]

  static getAttributeTypeMap() {
    return [...TransformationEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }
}

export class AssociationEvent extends EpcisEvent {
  'parentID'?: string;
  'childEPCs'?: Array<EPC>;
  'childQuantityList'?: Array<QuantityElement>;

  constructor() {
    super("AssociationEvent");
  }

  readonly eventType: string = "AssociationEvent";

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "parentID",
      "baseName": "parentID",
      "type": "string"
    },
    {
      "name": "childEPCs",
      "baseName": "childEPCs",
      "type": "Array<EPC>"
    },
    {
      "name": "childQuantityList",
      "baseName": "childQuantityList",
      "type": "Array<QuantityElement>"
    }
  ]

  static getAttributeTypeMap() {
    return [...AssociationEvent.attributeTypeMap, ...EpcisEvent.attributeTypeMap];
  }
}

export const EpcisTypes: { [index: string]: any } = {
  "ID": ID,
  "ReadPoint": ReadPoint,
  "BizLocation": BizLocation,
  "TypeValue": TypeValue,
  "ILMD": ILMD,
  "EpcisEvent": EpcisEvent,
  "EPC": EPC,
  "QuantityElement": QuantityElement,
  "ObjectEvent": ObjectEvent,
  "AggregationEvent": AggregationEvent,
  "TransactionEvent": TransactionEvent,
  "TransformationEvent": TransformationEvent,
  "AssociationEvent": AssociationEvent
}

export const GetEpcisEventType = (type: string) => {
  switch (type) {
    case EpcisEvent.prototype.eventType:
      return EpcisEvent;
    case ObjectEvent.prototype.eventType:
      return ObjectEvent;
    case AggregationEvent.prototype.eventType:
      return AggregationEvent;
    case TransactionEvent.prototype.eventType:
      return TransactionEvent;
    case TransformationEvent.prototype.eventType:
      return TransformationEvent;
    case AssociationEvent.prototype.eventType:
      return AssociationEvent;
  }
};