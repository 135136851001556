/**
 * UBL Unqualified Data Types
 */


export class AmountType {
  'currencyID'?: string;
  'currencyCodeListVersionID'?: string;
  'value'?: number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "currencyID",
      "baseName": "currencyID",
      "type": "string"
    },
    {
      "name": "currencyCodeListVersionID",
      "baseName": "currencyCodeListVersionID",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "number"
    }];

  static getAttributeTypeMap() {
    return AmountType.attributeTypeMap;
  }
}

export class BinaryObjectType {
  'format'?: string;
  'mimeCode'?: string;
  'encodingCode'?: string;
  'characterSetCode'?: string;
  'uri'?: string;
  'filename'?: string;
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "format",
      "baseName": "format",
      "type": "string"
    },
    {
      "name": "mimeCode",
      "baseName": "mimeCode",
      "type": "string"
    },
    {
      "name": "encodingCode",
      "baseName": "encodingCode",
      "type": "string"
    },
    {
      "name": "characterSetCode",
      "baseName": "characterSetCode",
      "type": "string"
    },
    {
      "name": "uri",
      "baseName": "uri",
      "type": "string"
    },
    {
      "name": "filename",
      "baseName": "filename",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    }];

  static getAttributeTypeMap() {
    return BinaryObjectType.attributeTypeMap;
  }
}

export class CodeType {
  'listID'?: string;
  'listAgencyID'?: string;
  'listAgencyName'?: string;
  'listName'?: string;
  'listVersionID'?: string;
  'name'?: string;
  'languageID'?: string;
  'listURI'?: string;
  'listSchemeURI'?: string;
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "listID",
      "baseName": "listID",
      "type": "string"
    },
    {
      "name": "listAgencyID",
      "baseName": "listAgencyID",
      "type": "string"
    },
    {
      "name": "listAgencyName",
      "baseName": "listAgencyName",
      "type": "string"
    },
    {
      "name": "listName",
      "baseName": "listName",
      "type": "string"
    },
    {
      "name": "listVersionID",
      "baseName": "listVersionID",
      "type": "string"
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string"
    },
    {
      "name": "languageID",
      "baseName": "languageID",
      "type": "string"
    },
    {
      "name": "listURI",
      "baseName": "listURI",
      "type": "string"
    },
    {
      "name": "listSchemeURI",
      "baseName": "listSchemeURI",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    }];

  static getAttributeTypeMap() {
    return CodeType.attributeTypeMap;
  }
}

export class DateType {
  'valueString'?: string;
  'value'?: Date;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "valueString",
      "baseName": "valueString",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "Date"
    }];

  static getAttributeTypeMap() {
    return DateType.attributeTypeMap;
  }
}

export class IdentifierType {
  'schemeID'?: string;
  'schemeName'?: string;
  'schemeAgencyID'?: string;
  'schemeAgencyName'?: string;
  'schemeVersionID'?: string;
  'schemeDataURI'?: string;
  'schemeURI'?: string;
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "schemeID",
      "baseName": "schemeID",
      "type": "string"
    },
    {
      "name": "schemeName",
      "baseName": "schemeName",
      "type": "string"
    },
    {
      "name": "schemeAgencyID",
      "baseName": "schemeAgencyID",
      "type": "string"
    },
    {
      "name": "schemeAgencyName",
      "baseName": "schemeAgencyName",
      "type": "string"
    },
    {
      "name": "schemeVersionID",
      "baseName": "schemeVersionID",
      "type": "string"
    },
    {
      "name": "schemeDataURI",
      "baseName": "schemeDataURI",
      "type": "string"
    },
    {
      "name": "schemeURI",
      "baseName": "schemeURI",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    }];

  static getAttributeTypeMap() {
    return IdentifierType.attributeTypeMap;
  }
}

export class IndicatorType {
  'value'?: boolean;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "value",
      "baseName": "value",
      "type": "boolean"
    }];

  static getAttributeTypeMap() {
    return IndicatorType.attributeTypeMap;
  }
}

export class MeasureType {
  'unitCode'?: string;
  'unitCodeListVersionID'?: string;
  'value'?: number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "unitCode",
      "baseName": "unitCode",
      "type": "string"
    },
    {
      "name": "unitCodeListVersionID",
      "baseName": "unitCodeListVersionID",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "number"
    }];

  static getAttributeTypeMap() {
    return MeasureType.attributeTypeMap;
  }
}

export class NameType {
  'languageID'?: string;
  'languageLocaleID'?: string;
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "languageID",
      "baseName": "languageID",
      "type": "string"
    },
    {
      "name": "languageLocaleID",
      "baseName": "languageLocaleID",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    }];

  static getAttributeTypeMap() {
    return NameType.attributeTypeMap;
  }
}

export class NumericType {
  'format'?: string;
  'value'?: number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "format",
      "baseName": "format",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "number"
    }];

  static getAttributeTypeMap() {
    return NumericType.attributeTypeMap;
  }
}

export class PercentType {
  'format'?: string;
  'value'?: number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "format",
      "baseName": "format",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "number"
    }];

  static getAttributeTypeMap() {
    return PercentType.attributeTypeMap;
  }
}

export class QuantityType {
  'unitCode'?: string;
  'unitCodeListID'?: string;
  'unitCodeListAgencyID'?: string;
  'unitCodeListAgencyName'?: string;
  'value'?: number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "unitCode",
      "baseName": "unitCode",
      "type": "string"
    },
    {
      "name": "unitCodeListID",
      "baseName": "unitCodeListID",
      "type": "string"
    },
    {
      "name": "unitCodeListAgencyID",
      "baseName": "unitCodeListAgencyID",
      "type": "string"
    },
    {
      "name": "unitCodeListAgencyName",
      "baseName": "unitCodeListAgencyName",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "number"
    }];

  static getAttributeTypeMap() {
    return QuantityType.attributeTypeMap;
  }
}

export class RateType {
  'format'?: string;
  'value'?: number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "format",
      "baseName": "format",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "number"
    }];

  static getAttributeTypeMap() {
    return RateType.attributeTypeMap;
  }
}

export class TextType {
  'languageID'?: string;
  'languageLocaleID'?: string;
  'value'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "languageID",
      "baseName": "languageID",
      "type": "string"
    },
    {
      "name": "languageLocaleID",
      "baseName": "languageLocaleID",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "string"
    }];

  static getAttributeTypeMap() {
    return TextType.attributeTypeMap;
  }
}

export class TimeType {
  'valueString'?: string;
  'value'?: Date;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string, baseName: string, type: string }> = [
    {
      "name": "valueString",
      "baseName": "valueString",
      "type": "string"
    },
    {
      "name": "value",
      "baseName": "value",
      "type": "Date"
    }];

  static getAttributeTypeMap() {
    return TimeType.attributeTypeMap;
  }
}

export const UnqualifiedDataTypes: { [index: string]: any } = {
  "AmountType": AmountType,
  "BinaryObjectType": BinaryObjectType,
  "CodeType": CodeType,
  "DateType": DateType,
  "IdentifierType": IdentifierType,
  "IndicatorType": IndicatorType,
  "MeasureType": MeasureType,
  "NameType": NameType,
  "NumericType": NumericType,
  "PercentType": PercentType,
  "QuantityType": QuantityType,
  "RateType": RateType,
  "TextType": TextType,
  "TimeType": TimeType
}