import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from "react-modal";
import './index.css';
//import './fonts.css';
//import './icons.css';
import App from './App';

import "@fontsource/source-sans-pro"; // Defaults to weight 400.


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "@fontsource/material-icons";
// TODO are below icons in use?
// import "@fontsource/material-icons-rounded";
// import "@fontsource/material-icons-outlined";
// import "@fontsource/material-icons-sharp";
// import "@fontsource/material-icons-two-tone";

ReactModal.setAppElement('#root');


ReactDOM.render(<App />, document.getElementById('root'));
