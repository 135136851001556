import { Title, Description } from '../../utils/tooltipUtil';
import { Aika } from './aika';

export class TarkastusJaHuolto {
    @Title("Tarkastusväli")
    @Description("Määräaikaiset viranomaistarkastukset")
    tarkastusvali: Aika = new Aika();

    @Title("Huoltoväli")
    @Description("Suunniteltu huoltoväli")
    huoltovali: Aika = new Aika();
}