/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Building } from '../../../model';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid, Tooltip, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GeneralTableView from '../../../utils/GeneralTableView';
import { Omistaja, Osakeryhma, Yhtio } from '../../../model/Huoneistotiedot';
import TooltippedItem from '../../../utils/tooltippedItem';
import { GetTitleForObject } from '../../../utils/tooltipUtil';

interface Props {
  data: Building;
  yhtio: Yhtio;
}

class HuoneistoView extends React.Component<Props, {data: Building, yhtio: Yhtio}> {
  constructor(props: Props) {
      super(props);
      this.state = {data: props.data, yhtio: props.yhtio};
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data, yhtio: nextProps.yhtio};
  }

  render() {
    const items: any = this.state.yhtio?.osakeryhmat === undefined
                 ? <div>Ei huoneistotietoja saatavilla</div>
                 : this.state.yhtio.osakeryhmat.map((or: Osakeryhma, index: number) =>
                    <Accordion key={`or-${index}`}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                      <Typography><b>{or.hallintakohde?.hallintakohdetyyppi} {or.hallintakohde?.tunnus}</b></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ flexGrow: 1 }}>
                          <div className='text-columns-2'>
                          <Grid container xs={12} md={12}>
                            <Grid container xs={12} md={12} key={"hallintakohde-" + index}>
                              <TooltippedItem data = {GetTitleForObject(or, "hallintakohde")} filter={["pysyvahuoneistotunnus", "hallintakohdetyyppi", "porras", "huoneistonumero", "jakokirjain", "tunnus", "huoneistotyyppi", "sijaintikerros", "autopaikkatyyppi"]} flatView = {true}/>
                            </Grid>
                            <Grid container xs={12} md={12} key={"osakeryhma-" + index}>
                              <Grid item xs={12} md={12}>
                                <Tooltip placement="top" title={"Osakeryhmä"}>    
                                  <b>{"Osakeryhmä"}</b>
                                </Tooltip>
                              </Grid>
                              <GeneralTableView key={`osakeryhma-${index}`} data={or} filter={["osakeryhmatunnus", "osakeryhmanimi", "osakkeet", "osakelukumaara", "hallintakohdetyyppi"]} flatView = {true} indentation = {10}></GeneralTableView>
                            </Grid>
                            <Grid container xs={12} md={12} key={"osakesarja-" + index}>
                              <TooltippedItem data = {GetTitleForObject(or, "osakesarja")} filter={["alkunumero", "loppunumero"]} flatView = {true}/>
                            </Grid>
                            <Grid container xs={12} md={12} key={"omistajat-" + index}>
                              <Grid item xs={12} md={12}>
                                <Tooltip placement="top" title={"Osakeryhmän omistaja."}>    
                                  <b style={{paddingLeft: `10px`}}>{"Omistajat"}</b>
                                </Tooltip>
                              </Grid>
                              { or.omistajat?.map((omistaja: Omistaja, i: number) => 
                                <Grid container xs={12} md={12} key={`omistaja-${index}-${i}`}>
                                  <Grid item xs={12} md={12}>
                                    <Tooltip placement="top" title={"Omistaja"}>    
                                      <b style={{paddingLeft: `20px`}}>{"Omistaja"}</b>
                                    </Tooltip>
                                  </Grid>
                                  {omistaja.henkiloomistaja && 
                                    <GeneralTableView key={`henkiloomistaja-${index}-${i}`} data={omistaja} filter={[{henkiloomistaja: ["etunimet", "sukunimi", "henkilotunnus"]}, "omistusosuudennimittaja", "omistusosuudenjakaja"]} flatView = {true} indentation = {30}/>
                                  }
                                  {omistaja.organisatioomistaja && 
                                    <GeneralTableView key={`organisaatioomistaja-${index}-${i}`} data={omistaja} filter={[{organisatioomistaja: ["toiminimi", "ytunnus"]}, "omistusosuudennimittaja", "omistusosuudenjakaja"]} flatView = {true} indentation = {30}/>
                                  }
                                </Grid>)
                              }
                            </Grid>
                            <Grid container xs={12} md={12} key={"osoite-" + index}>
                              <Grid item xs={12} md={12}>
                                <Tooltip placement="top" title={"Osakeryhmän päähallintakohteena olevan huoneiston osoite."}>    
                                  <b>{"Osoite"}</b>
                                </Tooltip>
                              </Grid>
                              <GeneralTableView key={`osoite-${index}`} data={or.osoite} filter={["katunimet", "katunumero", "osoitteenKunta", "ulkomainenOsoite"]} flatView = {true} indentation = {10}></GeneralTableView>
                            </Grid>
                            <Grid container xs={12} md={12} key={"taloyhtio"}>
                              <Grid item xs={12} md={12}>
                                <Tooltip placement="top" title={"Tiedot voivat olla joko kaupparekisteriin merkittyjä tietoja tai yhtiön tallentamia tietoja."}>    
                                  <b>{"Yhtiö"}</b>
                                </Tooltip>
                              </Grid>
                              <GeneralTableView data={this.state.yhtio} filter={["yritysmuotoYTJ", "rekisterointipaivamaara", "kotipaikka", "nimi", "ytunnus"]} flatView = {true} indentation = {10}></GeneralTableView>
                            </Grid>
                            <Grid container xs={12} md={12} key={"yhtionalue"}>
                              <Grid item xs={12} md={12}>
                                <Tooltip placement="top" title={"yhtionalue"}>    
                                  <b>{"Yhtiön alue"}</b>
                                </Tooltip>
                              </Grid>
                              { this.state.yhtio.yhtionAlueet && this.state.yhtio.yhtionAlueet.length > 0 &&
                                <GeneralTableView data={this.state.yhtio.yhtionAlueet[0]} filter={["kiinteistotunnus", "maaraalatunnus", "laitostunnus", "hallintalaji"]} flatView = {true} indentation = {10}></GeneralTableView>
                              }
                            </Grid>
                          </Grid>
                          </div>
                        </Box>
                      </AccordionDetails>
                  </Accordion>
                  );
    return (
      <div>{items}</div>
    );
  }
}

export default HuoneistoView;
