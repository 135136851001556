import { Title, Description } from '../../utils/tooltipUtil';

export class Company {
    @Title("Nimi")
    @Description("ainutkertaisen tarkoitteen nimitys")
    nimi: string = "";

    @Title("Y-tunnus")
    @Description("rekisteriviranomaisen antama yksilöivä tunnus elinkeinotoimintaa harjoittavalle henkilölle tai oikeushenkilölle")
    ytunnus: string = "";
}