import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Huontest from "../../../model/Huoneistotiedot";
import GeneralTableView from '../../../utils/GeneralTableView';
import { Building } from "../../../model";
import { BuildingSpecification } from "../../../model/buildingSpecification";
import EnergyData from "../../pages/energia/energyData";

export class TaloyhtiokohtaisetTiedot {
  constructor(yhtio?: Huontest.Yhtio, rakennus?: Building, buildingSpecification?: BuildingSpecification, energyData?: EnergyData) {
    this.yhtio = yhtio;
    this.rakennus = rakennus;
    this.buildingSpecification = buildingSpecification;
    this.energyData = energyData;
  }
  yhtio?: Huontest.Yhtio;
  rakennus?: Building;
  buildingSpecification?: BuildingSpecification
  energyData?: EnergyData;
}

interface Props {
  title: string,
  item?: TaloyhtiokohtaisetTiedot
}

export const TaloyhtiokohtaisetTiedotView: React.FC<Props> = ({title, item}: Props) => {
  return (
    <Accordion key={`taloyhtiokohtaisettiedot`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
      <Typography><b>{title}</b></Typography>
      </AccordionSummary>
      <AccordionDetails>
        { item ? 
        <Box sx={{ flexGrow: 1 }}>
          <Grid container xs={12} md={12}>
            { item.yhtio && 
            <Grid container xs={12} md={12} key={"taloyhtio"}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Tiedot voivat olla joko kaupparekisteriin merkittyjä tietoja tai yhtiön tallentamia tietoja."}>    
                  <b>{"Yhtiö"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView data={item?.yhtio} filter={["nimi", "ytunnus"]} flatView = {true} indentation = {10}></GeneralTableView>
            </Grid> }
            <Grid container xs={12} md={12} key={"rakennus"}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"asumista, työskentelyä tm. toimintaa varten vars. maan päälle rakennettu suoja"}>    
                  <b>{"Rakennus"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView data={item?.rakennus} filter={["CompletionYear", "CompletionDate", "IntendedUse", "FloorArea", "AreaPlan", "LivingArea", "TotalArea", "BuildingVolume", "NumberOfFloors", "BasementArea", "MainHeatingMethod", "MainHeatingAgent"]} flatView = {true} indentation = {10}></GeneralTableView>
            </Grid>
            <Grid container xs={12} md={12} key={"rakennuksen-sijaintikoordinaatti"}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Rakennuksen sijaintikoordinaatti"}>    
                  <b style={{paddingLeft: `10px`}}>{"Sijaintikoordinaatti"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView data={item?.rakennus?.Location?.Geometry} filter={["coordinates"]} flatView = {true} indentation = {20}></GeneralTableView>
            </Grid>
            <Grid container xs={12} md={12} key={"rakennuksen-sijainti"}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Rakennuksen sijainti"}>    
                  <b style={{paddingLeft: `10px`}}>{"Sijainti"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView data={item?.rakennus?.location?.address} filter={["kadunnimi", "osoitenumeronnumeroOsa", "osoitenumeronjakokirjain", "huoneistoTunnuksenKirjainosa", "huoneistoTunnuksenNumero", "huoneistoTunnuksenJakokirjain"]} flatView = {true} indentation = {20}></GeneralTableView>
            </Grid>
            <Grid container xs={12} md={12} key={"rakennuksen-varustetiedot"}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Tiedot rakennuksen varusteista"}>    
                  <b style={{paddingLeft: `10px`}}>{"Rakennuksen varustetiedot"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView data={item?.buildingSpecification} filter={["Airconditioning", "SolarPanel", "Elevator", "SwimmingPool", "Sauna"]} flatView = {true} indentation = {20}></GeneralTableView>
            </Grid>
            <Grid container xs={12} md={12} key={"rakennuksen-energiatodistus"}>
              <Grid item xs={12} md={12}>
                <Tooltip placement="top" title={"Asiakirja, jota käytetään rakennusten energiatehokkuuden vertailuun ja joka perustuu rakennuksen ominaisuuksiin."}>    
                  <b style={{paddingLeft: `10px`}}>{"Energiatodistus"}</b>
                </Tooltip>
              </Grid>
              <GeneralTableView data={item?.energyData?.basicEnergyData} filter={["PermanentBuildingID", "Stage", "E_Value", "E_Class"]} flatView = {true} indentation = {20}></GeneralTableView>
            </Grid>
          </Grid>
        </Box>
        : <div>Ei tietoja saatavilla</div>}
      </AccordionDetails>
    </Accordion>
  );
}