export default {
   RHR: process.env.REACT_APP_API_RHR_ENDPOINT       || 'https://rhrapibackend.azurewebsites.net/api',
   MML: process.env.API_MML_ENDPOINT       || 'https://beta-paikkatieto.maanmittauslaitos.fi/address-kky/features/v1/collections/address/items?VTJprt=',
   TAX_ADMINISTRATION: 'https://taxadministrationapi.azurewebsites.net/api/buildings/',
   SERVICE_PLAN: 'https://taxadministrationapi.azurewebsites.net/api/',
   ENERGY: 'https://energiatodistus.azurewebsites.net/api/buildings/',
   BUILDINGPERMIT_API: 'https://rakentamislupaapi.azurewebsites.net/api/',
   REALESTATE: 'https://ktjapifunction.azurewebsites.net/api',
   BUILDING_PLAN_API: 'https://kaavatietomalli.azurewebsites.net/api',
   PROPERTY_API: 'https://kiinteistorekisteri.azurewebsites.net/api',
   HUONEISTOTIEDOT_API: process.env.REACT_APP_HUONEISTOTIEDOT_API_ENDPOINT || 'https://huoneistotiedotapi.azurewebsites.net/api'
}
