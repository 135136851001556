import { Document } from "./document";
import { InfrastructureInterface } from "./infrastructureInterface";
import { BuildingDataModel } from "./buildingDataModel";
import { BuildingSite } from "./buildingSite";
import { SiteAddress } from "./siteAddress";
import { Location } from "./location";
import { BuildingTargetAsset } from "./buildingTargetAsset";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export class BuildingTarget extends Base {
    @Title("Erityissuunnitelma")
    @Description("rakentamista koskeva suunnitelma, joka laaditaan rakennussuunnitelman lisäksi silloin, kun rakennettavan kohteen ominaisuudet ja laajuus sitä edellyttävät")
    SpecialScheme: Document[] = [];
    @Title("Kiinteistö")
    @Description("kiinteistörekisteriin merkitty maan tai vesialueen omistuksen yksikkö")
    Kiinteisto: string = "";
    @Title("Liittymä")
    @Description("Liittymä")
    Interfaces: InfrastructureInterface[] = [];
    @Title("Rakennuksen tietomalli")
    @Description("tietomalli, joka mahdollistaa rakennustietojen vaihdon, jakamisen ja käytön")
    BuildingDataModels: BuildingDataModel[] = [];
    @Title("Rakentamispaikka")
    @Description("Rakentamispaikka")
    BuildingSite: BuildingSite = new BuildingSite();
    @Title("Rakennussuunnitelma")
    @Description("rakentamista koskeva suunnitelma, joka on perustana rakennettavan kohteen erityissuunnittelulle ja muulle suunnittelulle")
    BuildingPlan: Document[] = [];
    @Title("Rakentamiskohteen osoite")
    @Description("Rakentamiskohteen osoite")
    SiteAddress: SiteAddress = new SiteAddress();
    @Title("Sijainti")
    @Description("Sijainti")
    Location: Location = new Location();
    @Title("Varuste")
    @Description("Varuste")
    Varuste: BuildingTargetAsset[] = [];

    public Clone(data: any) {
        super.Clone(data);

        if (data.SpecialScheme !== undefined && data.SpecialScheme !== null && data.SpecialScheme.length > 0) {
            data.SpecialScheme.forEach((element : any) => {
                var b = new Document();
                b.Clone(element);
                this.SpecialScheme.push(b);
            });
        }

        if (data.Interfaces !== undefined && data.Interfaces !== null && data.Interfaces.length > 0) {
            data.Interfaces.forEach((element : any) => {
                var b = new InfrastructureInterface();
                b.Clone(element);
                this.Interfaces.push(b);
            });
        }

        if (data.BuildingDataModels !== undefined && data.BuildingDataModels !== null && data.BuildingDataModels.length > 0) {
            data.BuildingDataModels.forEach((element : any) => {
                var b = new BuildingDataModel();
                b.Clone(element);
                this.BuildingDataModels.push(b);
            });
        }

        if (data.BuildingPlan !== undefined && data.BuildingPlan  !== null && data.BuildingPlan.length > 0) {
            data.BuildingPlan.forEach((element : any) => {
                var b = new Document();
                b.Clone(element);
                this.BuildingPlan.push(b);
            });
        }

        if (data.Varuste !== undefined && data.Varuste !== null && data.Varuste.length > 0) {
            data.Varuste.forEach((element : any) => {
                var b = new BuildingTargetAsset();
                b.Clone(element);
                this.Varuste.push(b);
            });
        }
    }
}