import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export enum RegulationAppliesEnum {
    M01 = 1,
    M0101 = 2,
    M0102 = 3,
    M0103 = 4,
    M0104 = 5,
    M0105 = 6,
    M0106 = 7,
    M0107 = 8,
    M02 = 9,
    M0201 = 10,
    M0202 = 11,
    M03 = 12,
    M0301 = 13,
    M0302 = 14,
    M04 = 15
}

export class  PermitRegulation extends Base{
    @Title("Lupamääräyksen laji")
    @Description("Lupamääräyksen tyyppi, kuvaa asian, jota määräys koskee")
    RegulationApplies: RegulationAppliesEnum = RegulationAppliesEnum.M01;
    
    @Title("Lupamääräysteksti")
    @Description("Lupamääräysteksti")
    RegulationDescription: string = "";
}
