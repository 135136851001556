import React from "react";
import Layout from "../common/Layout";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Home: React.FC = () => {
  return (
    <div>
    <Grid container spacing={1}>
    <Grid item md={2}/>
    <Grid item xs={12} md={8}>
      <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
          <Typography><b>OmaRakennus testaa yhteentoimivia tietorakenteita</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{marginLeft:20}}>
            Rakennettu ympäristö synnyttää jatkuvasti arvokasta tietoa, jonka avulla voimme
            vaikuttaa siihen, kuinka toimiva, terveellinen ja ilmastoviisas elinympäristömme
            on. Tällä hetkellä rakennettua ympäristöä koskeva laaja ja tärkeä tieto on
            hankalasti käytettävissä eri järjestelmissä ja valtakunnallisesti epäyhtenäistä.
            OmaRakennus on vuonna 2021 aloitettu testiprojekti, jonka tuloksena syntyy
            demo, jossa tarkoituksena on koota erilaisia rakennusta, kiinteistöä ja sen
            ympäristöä kuvaavia tietoja nähtäville. Projektia vetää ympäristöministeriön
            Ryhti-hanke (<a href="https://ym.fi/ryhti">lue lisää Ryhti-hankkeesta</a>) ja toteuttajana toimii Verohallinto.
            OmaRakennus-demo havainnollistaa, miten eri lähteistä tulevat tiedot voidaan
            koota. Demon toivotaan auttavan niitä, jotka kehittävät erilaisia palveluja ja
            jatkosovelluksia rakennetun ympäristön tiedoista.
            Vuonna 2022 demoa kehitetään tuomalla sinne uusia tietorakenteita Digi- ja
            väestötietoviraston Yhteentoimivuusalustalta. OmaRakennus on osa Aurora AI
            –verkostoa ja yksi MiniSuomi-kokeiluista.
            </Typography>
          </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item md={2}/>
    <Grid item md={2}/>
    <Grid item xs={12} md={8}>
      <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
          <Typography><b>Miten käytän demoa?</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography style={{marginLeft:20}}>
          Portaalin testidata on vapaasti hyödynnettävissä. Testaajat saavat rakentaa
          niiden avulla uusia innovatiivisia palveluita ja ehdottaa uusia tietokokonaisuuksia
          tai lähteitä lisättäväksi portaaliin.
          Portaalia pääsee tarkastelemaan kahta kautta:
          <ul style={{marginLeft:20}}>
              <li>käyttöliittymässä navigoimalla eri välilehtien välillä, jotka sisältävät
                tietolähdekohtaisia tietoja rakennuksesta. Rakennus tunnistetaan sen
                pysyvällä rakennustunnuksella (PRT) ja rakennuksen alueidenkäytön
                suunnitelmista</li>
                <li>hyödyntämällä API:eja, joiden kautta käyttäjä pääsee käsiksi suoraan
                taustalla oleviin tietoihin. API:t löytyvät viimeisellä välilehdellä (API
                rajapinnat)</li>
            </ul>
          </Typography>
          </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item md={2}/>
    <Grid item md={2}/>
    <Grid item xs={12} md={8}>
      <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
          <Typography><b>Demon tietorakenteet löytyvät soveltamisprofiileina Yhteentoimivuusalustalta</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{marginLeft:20}}>
            Kaikki joko käyttöliittymän tai sovellusliittymien kautta nähtäville saatava data on
            kuvattu semanttisesti yhteentoimivalla tavalla tietolähdekohtaisiksi
            sovellusprofiileiksi, jotka on luotu ja julkaistu valtiovarainministeriön ja Digi- ja
            väestötietoviraston kehittämällä <a href="https://www.suomidigi.fi/ohjeet-ja-tuki/yhteentoimivuusalusta">Yhteentoimivuusalustalla</a>. Soveltamisprofiilit pyrkivät kuvaamaan organisaatiokohtaisten tietojärjestelmien tai tietovarastojen
            sisältämää tietoa yhteistuotettujen, harmonisoitujen ja toimialuekohtaisten
            sanastojen avulla. Sanastot perustuvat toimialuekohtaisiin ontologioihin, jotka
            määrittelevät alan termit ja niiden taustalla olevat käsitteet. <br/><br/>
            Osa Yhteentoimivuusalustan tietorakenteista kuvaa tietojen nykyistä rakennetta
            olemassa olevissa tietojärjestelmissä (esim. ARA:n energiarekisteri ja DVV:n
            rakennus- ja huoneistorekisteri), mutta alustalle on tuotu myös sellaisia
            rakenteita, jotka ovat suunnitteilla ja olemassa vain tietomallina /
            komponenttikirjastona, eikä niillä ole vielä tosiasiallista sisältöä suomalaisessa
            yhteiskunnassa.<br/><br/>
            Linkit soveltamisprofiileihin löytyvät kohdasta “API rajapinnat” &gt; “Käytetyt
            tietomallit”. Tällä hetkellä Yhteentoimivuusalusta toimii suomeksi ja ruotsiksi.
            </Typography>
          </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item md={2}/>
    <Grid item md={2}/>
    <Grid item xs={12} md={8}>
      <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
          <Typography><b>Demossa käytetty testidata</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography  style={{marginLeft:20}}>
            Projektissa olemme kartoittaneet tietokuvaukset joko olemassa olevilla
  testitiedoilla eri rekistereistä tai muista tietolähteistä tai vaihtoehtoisesti luoneet
  omat testitietomme soveltamisprofiilin perusteella. Kannasta löytyy siis muiden
  tahojen tuottamaa testidataa ja itse keksimäämme testidataa.
            </Typography>
          </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item md={2}/>
    <Grid item md={2}/>
    <Grid item xs={12} md={8}>
      <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
          <Typography><b>Kehittäjälle</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography  style={{marginLeft:20}}>
            Yksi liiketoimintalähtöinen ja &quot;käsitteellinen&quot; sovellusprofiili voidaan viedä
  teknisemmäksi kuvaukseksi eri tavoin (OpenAPI, RDF, JSON-LD tai muu).
  Nämä kuvaukset auttavat API-kehittäjiä luomaan OmaRakennus -portaalissa
  julkaistavia API:eja (ja käyttöliittymiä).<br/><br/>
  Koska kaikki käsitteet määrittävät ontologiat, toimialuekohtaiset sanastot sekä
  tietolähdekohtaiset sovellusprofiilit ovat linkitetty yhteen W3C Linked Data -
  standardien kautta, niin sekä käyttöliittymän että API:iden kautta käytettävä data
  on nimetty ja kuvattu johdonmukaisella ja semanttisesti yhteentoimivalla tavalla.
            </Typography>
          </AccordionDetails>
      </Accordion>
    </Grid>

    <Grid item md={2}/>
    <Grid item md={2}/>
    <Grid item xs={12} md={8}>
      <Layout title="">
        OmaRakennus (MyBuilding) is an experimental project funded and lead by the Ministry of the
    Environment and executed by the Finnish Tax Administration, focusing on exposing a number of
    existing datasets describing the properties of a single building and it’s surrounding environment
    (like the real estate it’s located on). The project was initiated in Spring 2021 and will continue at
    least through 2022. During the project we will also look into new untapped data resources that
    either exist today but not in a digitally accessible format or are completely missing in the present
    day Finnish society. Stakeholders are invited to make use of the freely available test data and build
    innovative new services, suggesting new data sets or sources to be added to the portal.<br/><br/>
    The information in the portal can be accessed in two ways, either…<br/><br/>
    &emsp;&emsp;• by means of navigation in the UI between different tabs, that contain the data source
    specific information about a single building, identified through its “permanent building ID”
    (pysyvä rakennustunnus)<br/>
    &emsp;&emsp;• by means of connecting directly to the data through the underlying APIs, which are listed on
    the last tab (“API rajapinnat”)<br/><br/>
    All the data provided either through the UI or the APIs is described in a semantically interoperable
    way as data source specific application profiles, created and published on the Interoperability
    Platform, developed by the Ministry of Finance and the Finnish Digitalization Agency (a.k.a “Digital
    and Population Data Services Agency”). The application profiles are created by subject-matter
    experts, that strive to describe the data contained in organization specific data systems or data
    vaults with the help of co-produced, harmonized and domain-specific vocabularies, based on
    domain-specific ontologies defining terms and their underlying concepts. Links to the application
    profiles can be found under “API rajapinnat” &gt; “Käytetyt tietomallit”. At present there is only limited
    information to be found in English, since the terms describing the data in most national registries
    lack official translations to other languages than Swedish.<br/><br/>
    In the project we have mapped these data descriptions with either existing test data from the
    different underlying registries or other data sources or alternatively generated our own test data
    based on the application profile. A single business-oriented and “conceptual” application profile can
    be exported as a more technical description (OpenAPI, RDF, JSON-LD or other) which assists the
    API developers in creating the APIs (and UIs) published in the OmaRakennus -portal. Since
    everything from the concept-defining ontologies, the domain-specific vocabularies as well as the
    data source specific application profiles is linked together through W3C Linked Data standards, the
    data accessed through both the UI and the APIs is named and described in a consistent and
    semantically interoperable way.
      </Layout>
  </Grid>
  </Grid>
  </div>
  );
};

export default Home;
