import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class Address extends Base{
    @Title("Kadun nimi")
    @Description("katua tai tietä tarkoittava paikannimi")
    StreetName: string = "";
    
    @Title("Osoitenumeron numero-osa")
    @Description("osoitenumeroon kuuluva numero")
    StreetNumber: string = "";
    
    @Title("Osoitenumeron jakokirjain")
    @Description("kirjain, joka on lisätty kiinteistön osoitenumeroon silloin, kun alkuperäinen kiinteistö on jaettu kahdeksi tai useammaksi erilliseksi kiinteistöksi")
    StreetNumberLetter: string = "";
    
    @Title("Huoneistotunnuksen kirjainosa")
    @Description("useamman kuin yhden huoneiston käsittävässä rakennuksessa tai rakennuksissa sijaitsevien huoneistojen tunnuksiin kuuluva kirjain")
    ApartmentIdentifier: string = "";
    
    @Title("Huoneistotunnuksen numero-osa")
    @Description("useamman kuin yhden huoneiston käsittävässä rakennuksessa sijaitsevan huoneiston tunnukseen kuuluva numero")
    ApartmentIdentifierNumber: string = "";
    
    @Title("Huoneistotunnuksen jakokirjain")
    @Description("kirjain, joka on lisätty huoneistotunnuksen numero-osaan silloin, kun alkuperäinen huoneisto on jaettu kahdeksi tai useammaksi erilliseksi huoneistoksi")
    ApartmentIdentifierLetter: string = "";
    
    @Title("Osoitteen lisämääre")
    @Description("opastava tieto, joka täydentää muita osoitetietoja")
    AddressAdditionalInformation: string = "";
    
    @Title("C/o")
    @Description("care of eli jonkun luona")
    AddressCO: string = "";
    
    @Title("Postilokero-osoite")
    @Description("osoite, joka ilmaisee postin vastaanottajan vuokraamaa tai postinjakelijan osoittamaa postinjättöpaikkaa")
    PoBox: string = "";
    
    @Title("Postitoimipaikka")
    @Description("osoitteen osa, joka viittaa tiettyyn maantieteelliseen alueeseen tai organisaatioon")
    PostalDistrict: string = "";
    
    @Title("Postinumero")
    @Description("osoitteen numero-osa, joka viittaa postitoimipaikkaan")
    PostCode: string = "";
}