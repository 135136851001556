import { Description, Title } from "../utils/tooltipUtil";

export class UnitSpecification {
    @Title("Huoneistotunnus (pysyvä)")
    @Description("Huoneistotunnus on jokaiselle huoneistolle ja uudisrakennushankkeen huoneistolle annettava 10-merkkinen tunnus.")
    PermanentShareID?: string;
    @Title("Huoneistotunnuksen jakokirjain")
    @Description("kirjain, joka on lisätty huoneistotunnuksen numero-osaan silloin, kun alkuperäinen huoneisto on jaettu kahdeksi tai useammaksi erilliseksi huoneistoksi")
    huoneistoTunnuksenJakokirjain?: string;
    @Title("Huoneistotunnuksen kirjainosa")
    @Description("useamman kuin yhden huoneiston käsittävässä rakennuksessa tai rakennuksissa sijaitsevien huoneistojen tunnuksiin kuuluva kirjain")
    huoneistoTunnuksenKirjainosa?: string;
    @Title("Huoneistotunnuksen numero-osa")
    @Description("useamman kuin yhden huoneiston käsittävässä rakennuksessa sijaitsevan huoneiston tunnukseen kuuluva numero")
    huoneistoTunnuksenNumero?: number;
    @Title("Huoneistotyyppi")
    @Description("Esimerkiksi \"2h+k\" tai \"3h+k+s\".")
    Specifier?: string;
    @Title("Osoitejärjestysnumero")
    @Description("Tieto siitä, mikä rakennuksen osoitteista on huoneiston osoite.")
    osoitejarjestysnumero?: number;
    @Title("Hallintaperustelaji")
    @Description("Tieto siitä, mihin huoneiston hallinta perustuu. VTJ:ssä käytössä ovat esim. rakennuksen omistajuus, huoneiston omistajuus, huoneiston vuokraus tai asumisoikeus.")
    hallintaperustelaji?: string;
    @Title("Huoneiston käytössäolo")
    @Description("Tieto siitä, onko huoneisto käytössä ja minkälaista tämä käyttö on. VTJ:ssä sallittuja arvoja ovat esim. vakinaisessa asuinkäytössä, toimitila- tai tuotantokäytössä, käyttö loma-asuntona tai tyhjillään.")
    huoneistonkaytossaolo?: string;
    @Title("Huoneistoala")
    @Description("huoneistoa ympäröivien seinien sisäpintojen mukaan laskettu pinta-ala. Pinta-alaan lasketaan mukaan vain se osa, jossa huonekorkeus ylittää 160 cm.")
    Area?: number;
    @Title("Huoneiden lukumäärä")
    @Description("Huoneeksi katsotaan rakennuksessa oleva tila, jota ympäröivät lattia, katto ja seinät. Huoneiden lukumäärään ei lasketa keittiötä.")
    Rooms?: number;
    @Title("Keittiölaji")
    @Description("Tieto siitä, minkälainen keittiö huoneistossa on. VTJ:ssä sallittuja arvoja ovat esim. keittiö, keittokomero ja keittotila.")
    KitchenType?: string;
    @Title("Muuttovalmis")
    @Description("")
    muuttovalmis?: string;
    @Title("Muuttokielto")
    @Description("Tieto siitä, että asuinkäyttöön rakennettuun huoneistoon ei saa muuttaa asumaan vakinaisesti.")
    muuttokielto?: string;
    @Title("Alkunumero")
    @Description("Numerosarjan ensimmäinen numero.")
    ShareStart?: number;
    @Title("Loppunumero")
    @Description("Numerosarjan viimeinen numero.")
    ShareEnd?: number;
    @Title("Pääkäyttötarkoitus")
    @Description("Hallintakohteen pääkäyttötarkoitus.")
    IntendedUse?: number;
    @Title("Hallintakohdetyyppi")
    @Description("Hallintakohdetyyppi voi olla huoneisto, autopaikka tai muu tila. Koodisto.")
    Type?: string;
    @Title("Porras")
    @Description("Portaan tunniste. Esimerkiksi \"A\".")
    Stair?: string;
    @Title("Huoneistonumero")
    @Description("Huoneistonumero")
    Number?: number;
    @Title("Sijaintikerros")
    @Description("Sijaintikerros")
    Floor?: number;
       
    
    
    @Title("WC")
    @Description("Käymälä, jossa ihmisen eritteet huuhdotaan veden avulla viemäriverkostoon.")
    Toilet?: boolean;
    @Title("Suihku")
    @Description("Tieto siitä, onko huoneiston varusteena suihku.")
    Shower?: boolean;
    @Title("Sauna")
    @Description("Tieto siitä, onko huoneisto varustettu saunalla. Saunan lajia ei ole määritelty VTJ:ssä.")
    Sauna?: boolean;
    @Title("Parveke")
    @Description("Parveke tai terassi, johon johtaa ovi rakennuksen sisältä.")
    Balcony?: boolean;
    @Title("Lämmin vesi")
    @Description("Tieto siitä, tuleeko huoneistoon lämmin vesi.")
    WarmWater?: boolean;
}