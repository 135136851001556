/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Accordion, AccordionDetails, Box } from '@material-ui/core';
import { OwnerAddress } from '../../../model/ownerAddress';

interface Props {
  data?: OwnerAddress
}

class OsoiteView extends React.Component<Props, {data: OwnerAddress}> {
  constructor(props: Props, data : any) {
    super(props);
    if (props.data) this.state = {data: props.data};
  }
  
  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  render() {
    if (this.state.data === undefined || this.state.data === null) {
      return <div>Ei osoitetietoja saatavilla</div>
  }
  else {
    return (
        // <Accordion expanded={true}>
        //   <AccordionDetails>
        <Box sx={{ my: 1 }}>
          <table>
            <tbody>
            <tr><td>Katuosoite</td><td>{this.state.data.Address}</td></tr>
            <tr><td>Postilokero</td><td>{this.state.data.PoBox}</td></tr>
            <tr><td>Postinumero</td><td>{this.state.data.PostalCode}</td></tr>
            <tr><td>Postitoimipaikka</td><td>{this.state.data.PostalDistrict}</td></tr>
            </tbody>
        </table>
        </Box>
        //   </AccordionDetails>
        // </Accordion>
      );
  }
}
}

export default OsoiteView;
