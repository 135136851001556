import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Huontest from "../../../model/Huoneistotiedot";
import GeneralTableView from '../../../utils/GeneralTableView';
import { Property } from "../../../model/property";

export class TaloyhtionKiinteistonTiedot {
  constructor(yhtionAlue?: Huontest.YhtionAlue, kiinteisto?: Property) {
    this.yhtionAlue = yhtionAlue;
    this.kiinteisto = kiinteisto;
  }
  yhtionAlue?: Huontest.YhtionAlue;
  kiinteisto?: Property;
}

interface Props {
  title: string,
  item?: TaloyhtionKiinteistonTiedot
}

export const TaloyhtionKiinteistonTiedotView: React.FC<Props> = ({title, item}: Props) => {
  return (
    <Accordion key={`taloyhtionkiinteistontiedot`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
      <Typography><b>{title}</b></Typography>
      </AccordionSummary>
      <AccordionDetails>
        { item ? 
          <Box sx={{ flexGrow: 1 }}>
            <Grid container xs={12} md={12}>
              { item.yhtionAlue && 
              <Grid container xs={12} md={12} key={"yhtionalue"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"yhtionalue"}>    
                    <b>{"Yhtiön alue"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.yhtionAlue} filter={["kiinteistotunnus", "maaraalatunnus", "laitostunnus", "hallintalaji"]} flatView = {true} indentation = {10}></GeneralTableView>
              </Grid> }
              { item.kiinteisto && 
              <Grid container xs={12} md={12} key={"kiinteisto"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"kiinteisto"}>    
                    <b>{"Kiinteistö"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.kiinteisto} filter={["RealEstateId", "PropertyType", "Name", "LandArea"]} flatView = {true} indentation = {10}></GeneralTableView>
              </Grid> }
              { item.kiinteisto && 
              <Grid container xs={12} md={12} key={"kiinteiston-osoite"}>
                <Grid item xs={12} md={12}>
                  <Tooltip placement="top" title={"kiinteiston-osoite"}>    
                    <b style={{paddingLeft: `10px`}}>{"Kiinteistön osoite"}</b>
                  </Tooltip>
                </Grid>
                <GeneralTableView data={item.kiinteisto?.Location?.Address} filter={["StreetName", "PostalDistrict", "PostalCode", "LandArea"]} flatView = {true} indentation = {20}></GeneralTableView>
              </Grid> }
            </Grid>
          </Box>
        : <div>Ei tietoja saatavilla</div>}
      </AccordionDetails>
    </Accordion>
  );
}