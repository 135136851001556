import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from '../base';

export class PlanRegulation extends Base {
    @Title("Laji")
    @Description("kuvaa määräyksen tyypin")
    RegulationType: string = "";

    @Title("Elinkaaritila")
    @Description("elinkaaren tila, jossa kaavamääräyksen versio on.")
    LifeCycleState: string = "";
    
    @Title("Lisätieto")
    @Description("määräystä tarkentava tai rajaava tieto")
    AdditionalInfo: string = "";
}