import { ApartmentRenovation } from "./apartmentRenovation";
import { BuildingTarget } from "./buildingTarget";
import { BuildingTargetAsset } from "./buildingTargetAsset";

import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export class ConstructionTargetChange extends Base{
    @Title("Huoneistoalan muutos")
    @Description("Rakennuksen huoneistoja ympäröivien seinien sisäpintojen mukaan laskettu pinta-ala. Pinta-alaan lasketaan mukaan vain se osa, jossa huonekorkeus ylittää 160 cm. Kirjataan, kuinka paljon huoneistoala muuttuu rakentamistoimenpiteellä, johon hankkeen rakennus on liitetty. Muutos voi olla positiivinen tai negatiivinen.")
    ApartmentAreaChange: number = 0;
    
    @Title("Huoneiston muutos")
    @Description("Huoneiston muutos")
    ApartmentRenovations: ApartmentRenovation[] = [];
    
    @Title("Kellarialan muutos")
    @Description("Kellarin pinta-alan muutos rakentamistoimenpiteen yhteydessä.")
    BasementAreaChange: number = 0;
    
    @Title("Kerrosalan muutos")
    @Description("Kerrosten alat ulkoseinien ulkopinnan mukaan laskettuina sekä se kellarikerroksen tai ullakon ala, johon sijoitetaan tai voidaan näiden tilojen sijainnista, yhteyksistä, koosta, valoisuudesta ja muista ominaisuuksista päätellen sijoittaa rakennuksen pääasiallisen käyttötarkoituksen mukaisia tiloja. Kirjataan, kuinka paljon kerrosala muuttuu rakentamistoimenpiteellä, johon hankkeen rakennus on liitetty. Muutos voi olla positiivinen tai negatiivinen.")
    FloorAreaChange: number = 0;
    
    @Title("Kerrosluvun muutos")
    @Description("Rakennushankkeella kerrosluvun muuttuminen voi olla positiivinen tai negatiivinen.")
    FloorNumberChange: number = 0;
    
    @Title("Kohde ennen muutosta")
    @Description("Tiedot Rakentamiskohteesta tai -kohteista sellaisena, kun on tallennettu ennen tätä muutosta.")
    TargetBeforeChange: BuildingTarget = new BuildingTarget();

    @Title("Kohde muutoksen jälkeen")
    @Description("Kohde muutoksen jälkeen")
    TargetAfterChange: BuildingTarget = new BuildingTarget();
    
    @Title("Kokonaisalan muutos")
    @Description("kaikkien kerrosten, kellareiden ja lämpöeristettyjen ullakoiden pinta-ala yhteensä ulkomitoin. Kokonaisalaan ei lasketa parvekkeita, katoksia eikä 160 cm:ä matalampia tiloja. Kirjataan, kuinka paljon kokonaisala muuttuu rakentamistoimenpiteellä, johon hankkeen rakennus on liitetty. Muutos voi olla positiivinen tai negatiivinen.")
    TotalAreaChange: number = 0;
    
    @Title("Rakennusoikeudellisen kerrosalan muutos")
    @Description("Kerrosalasta huomioidaan ulkoseinien sisällä oleva pinta-ala ja ulkoseinistä vain se osa, joka on paksuudeltaan enintään 250 mm. Kirjataan, jos tieto muuttuu rakentamistoimenpiteellä, johon hankkeen rakennus on liitetty. Kirjataan, kuinka paljon rakennusoikeudellinen kerrosala muuttuu rakentamistoimenpiteellä, johon hankkeen rakennus on liitetty. Muutos voi olla positiivinen tai negatiivinen.")
    PermittedFloorAreaChange: number = 0;
    
    @Title("Rakentamiskohteen varusteen muutos")
    @Description("Rakentamiskohteen varusteen muutos")
    BuildingTargetAssets: BuildingTargetAsset[] = [];
    
    @Title("Tilavuuden muutos")
    @Description("Kirjataan, kuinka paljon rakennuksen tilavuus muuttuu rakentamistoimenpiteellä, johon hankkeen rakennus on liitetty. Muutos voi olla positiivinen tai negatiivinen.")
    VolumeChange: number = 0;

    public Clone(data: any) {
        super.Clone(data);

        if (data.ApartmentRenovations !== undefined && data.ApartmentRenovations.length > 0) {
            data.ApartmentRenovations.forEach((element : any) => {
                var b = new ApartmentRenovation();
                b.Clone(element);
                this.ApartmentRenovations.push(b);
            });
        }

        if (data.BuildingTargetAssets !== undefined && data.BuildingTargetAssets.length > 0) {
            data.BuildingTargetAssets.forEach((element : any) => {
                var b = new BuildingTargetAsset();
                b.Clone(element);
                this.BuildingTargetAssets.push(b);
            });
        }
    }
}