import { LocationUtils } from "../model/kaava/locationUtils";
import { Feature } from "../model/KtmkBuildingApiModels";

// TODO make this more better...
export const parseCoordinates = (coordinatesJsonString: string, crs: string) => {
  const coordinateSets = JSON.parse(coordinatesJsonString) as number[][][][];
  convertCoordinates(coordinateSets, crs);
  return coordinateSets;
}


export const convertCoordinates = (coordinateSets: number[][][][], crs: string): void => {
    
  coordinateSets.forEach((coordinateGroup) => {
    coordinateGroup.forEach((coordinates) => {
      for (var i = 0; i < coordinates.length; i++) {
        const coord = LocationUtils.Convert(coordinates[i][1], coordinates[i][0]);
        coordinates[i] = [coord.X, coord.Y];
      }
    });
  });
}

export const oskariDrawFeatures = (channel: any, layerId: string, layerName: string, features: Feature[], removeOldLayer: boolean = true) => {

  const featureOptions = {
    layerId: layerId,
    prio: 100,
    showLayer: true,
    layerName: layerName,
    layerDescription: layerName,
    visible: true,
    hover: {
      'featureStyle':  {
          'inherit': true,
          'effect': 'darken'
      }
    },

  };

  // TODO lataa jostain muualta tämä tieto...
  var featureCollection = {
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name":  "EPSG:3067"
      }
    },
    "features": features
  };

  if (removeOldLayer) {
    channel.postRequest('MapModulePlugin.RemoveFeaturesFromMapRequest', [null,null,layerId]);
  }
  channel.postRequest('MapModulePlugin.AddFeaturesToMapRequest', [featureCollection, featureOptions]);
};