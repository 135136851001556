import { EnergyBasics } from "../model/energyBasics";
import { EnergyInitialData } from "../model/energyInitialData";
import { OwnerAddress } from "../model/ownerAddress";
import { Building } from '../model';
import Apis from '../utils/apis'
import { BuildingPermit } from "../model/buildingPermit";
import { ParsingUtils } from "./parsingUtils";
import { BuildingSpecification } from "../model/buildingSpecification";
import { UnitSpecification } from "../model/unitSpecification";
import { Property } from "../model/property";
import { ConstructionSupervisionCase } from "../model/buildingPermit/constructionSupervisionCase";
import { Share } from "../model/share";
import { BuildingPlan } from "../model/buildingPlan/buildingPlan";
import * as Huontest from "../model/Huoneistotiedot";
import TaxBuilding from "../model/tax/taxBuilding";
import { Rakenne } from "../model/servicePlan/rakenne";
import { Kaava } from "../model/kaava/kaava";
import { CodeList } from "../model/koodisto/Koodistot";
import { KaavaStyles } from "../model/kaava/KaavaStyles";
import { JsonObject } from "../model/JsonObject";
import { Product } from "../model/Product";
import { EpcisEvent } from "../model/EpcisModels";

//const apiRootUrl = process.env.API_ROOT_URL || '/example-data'

// RHR API in Azure
export const RhrApi = {
    getBuildings: 
        async () => await fetch(`${Apis.RHR}/buildings`)
                          .then(resp => resp.json())
                          .catch(err => { console.log("No building found!"); return [] } ),
    getOwnerInstance: 
        async (id: string) =>  await fetch(`${Apis.RHR}/instance/${id}`)
                                     .then(resp => resp.json())
                                     .catch(err => console.log(`No instance found by ID ${id}: ${err}`)),

    // haetaan pysyvällä rakennustunnuksella (PermanentBuildingID)
    getSharesByBuildingId: 
        async (id: string) => await fetch(`${Apis.RHR}/buildings/${id}/shares`)
                                    .then(resp => resp.json())
                                    .catch(err => console.log(`No shares found by ID ${id}: ${err}`)),

    getCompanyByBuildingId: 
        async (id: string) => await fetch(`${Apis.RHR}/buildings/${id}/company`)
                                    .then(resp => resp.json())
                                    .catch(err => console.log(`No company found by ID ${id}: ${err}`)),
    getPersonByBuildingId: 
        async (id: string) => await fetch(`${Apis.RHR}/buildings/${id}/person`)
                                    .then(resp => resp.json())
                                    .catch(err => console.log(`No person found by ID ${id}: ${err}`)),  
                                    
    // getBuildingEquipment: 
    //     async (id: string) => await fetch(`${Apis.RHR}/buildings/${id}/equipment`)
    //                                 .then(resp => resp.json())
    //                                 .catch(err => console.log(`No person found by ID ${id}: ${err}`)),  
                                    
    getUnitEquipments:
        async (buildingId: string) => 
            await fetch(`${Apis.RHR}/buildings/${buildingId}/unitequipment`)
                        .then(resp => resp.json())
                        .catch(err => console.log(`No equipments found by buildingId ${buildingId}`)),          
    getBuildingSpecifications: 
        async (id: string) => await fetch(`${Apis.RHR}/buildings/${id}/buildingSpecifications`)
                                    .then(resp => resp.json())
                                    .catch(err => console.log(`No building specification found by ID ${id}: ${err}`)),   
    getUnitSpecifications: 
        async (id: string) => await fetch(`${Apis.RHR}/buildings/${id}/unitSpecifications`)
                                    .then(resp => resp.json())
                                .catch(err => console.log(`No unit specification found by ID ${id}: ${err}`)),      
    getBuildingPermit: 
        async (id: string) => await fetch(`${Apis.RHR}/buildings/${id}/buildingPermit`)
                                .then(resp => resp.json())
                                .catch(err => console.log(`No building permit found by ID ${id}: ${err}`)),                            
    getBuildingById: async (id: string) => {
        if (id !== undefined && id !== '') {  
            let building = await fetch(`${Apis.RHR}/buildings/${id}`)
                                 .then(resp => resp.json())
                                 .catch(err => console.log(`No building found by ID ${id}: ${err}`));

            
            const ownerCall = RhrApi.getOwnerInstance(building.OwnerID);

            const getSharesByBuildingIdCall = RhrApi.getSharesByBuildingId(building.PermanentBuildingID);

            // const getBuildingEquipmentCall = RhrApi.getBuildingEquipment(building.PermanentBuildingID);

            const getUnitEquipmentCall = RhrApi.getUnitEquipments(building.PermanentBuildingID);

            const getUnitSpecificationsCall = RhrApi.getUnitSpecifications(building.PermanentBuildingID);
            
            const getBuildingSpecificationsCall = RhrApi.getBuildingSpecifications(building.PermanentBuildingID);
            
            const getBuildingPermitCall = RhrApi.getBuildingPermit(building.PermanentBuildingID);
            
            const getByBuildingIdCall = MMLApi.getByBuildingId(building.PermanentBuildingID);
            
            //const getByPermanentBuildingIdCall = RealEstateApi.getByPermanentBuildingId(building.PermanentBuildingID);
            
            const owner = await ownerCall;

            if (owner && owner.ID)
                building = {
                    ...building,
                    Owner: {
                        Type: owner.LastName ? false : true,   // 0: person, 1: company
                        //Id: owner.ID as string,
                        Name: owner.LastName ? `${owner.FirstNames} ${owner.LastName}` 
                                             : `${owner.CompanyName} ${owner.CompanyForm}`,
                    }
                }

            // await getBuildingEquipmentCall.then(equipments => {
            //     if (equipments !== undefined && equipments.length > 0)
            //         building = { ...building, Equipments: equipments }
            // }).catch(err => console.log(`No building equipments found by PermanentBuildingID ${building.BuildingID}: ${err}`));

            // Get shares
            await getSharesByBuildingIdCall.then(shares => {
                if (shares !== undefined && shares.length > 0)
                    building = { ...building, Shares: ParsingUtils.CastObjectArray(shares, () => new Share())}
            }).catch(err => console.log(`No shares found by PermanentBuildingID ${building.BuildingID}: ${err}`));

            await getUnitEquipmentCall.then(equipments => {
                if (equipments !== undefined && equipments.length > 0)
                    building = { ...building, UnitEquipments: equipments }
            }).catch(err => console.log(`No unit equipments found by PermanentBuildingID ${building.BuildingID}: ${err}`)); 
            
            await getUnitSpecificationsCall.then(specifications => {
                if (specifications !== undefined && specifications.length > 0)
                    building = { ...building, UnitSpecifications: ParsingUtils.CastObjectArray(specifications, () => new UnitSpecification())}
            }).catch(err => console.log(`No unit specifications found by PermanentBuildingID ${building.BuildingID}: ${err}`)); 

            await getBuildingSpecificationsCall.then(buildingSpecs => {
                if (buildingSpecs !== undefined)
                    building = { ...building, BuildingSpecifications: ParsingUtils.CastObject(buildingSpecs, () => new BuildingSpecification(), true) }
            }).catch(err => console.log(`No unit specifications found by PermanentBuildingID ${building.BuildingID}: ${err}`)); 

            await getBuildingPermitCall.then(buildingPermit => {
                if (buildingPermit !== undefined && buildingPermit.length > 0)
                    building = { ...building, BuildingPermit: ParsingUtils.CastObjectArray(buildingPermit, () => new BuildingPermit())}
            }).catch(err => console.log(`No building permit found by PermanentBuildingID ${building.BuildingID}: ${err}`)); 

            // Get address and location from MML
            await getByBuildingIdCall.then(loc => {
                if (loc.features && loc.features.length > 0)
                    building = {
                        ...building,
                        Location: {
                            Geometry: loc.features[0]?.geometry   ?? false,
                            Address:  loc.features[0]?.properties ?? false
                        }
                    }
            }).catch(err => console.log(`No location found by PermanentBuildingID ${building.PermanentBuildingID}: ${err}`));

            /*await getByPermanentBuildingIdCall.then(resp => {
                console.log("8");
                if (resp !== undefined && resp.length > 0)
                    building = { ...building, RealEstateInfo: resp }
            }).catch(err => console.log(`No unit equipments found by PermanentBuildingID ${building.BuildingID}: ${err}`));             */

            building = ParsingUtils.CastObject(building, () => new Building(), true);

            return building;
        }   
    },
}

 // MML location API
 export const MMLApi = {
     getByBuildingId: async (id: string) =>
        await fetch(Apis.MML + id).then(resp => resp.json()).catch(err => {
            console.log("ERROR: " + err);
        })
 }



 export class EnergyApi{
    public GetEnergyData(permanentBuildingId: string): Promise<EnergyBasics> {
        return fetch(`${Apis.ENERGY}/${permanentBuildingId}/EnergyBasics`)
                .then(resp => resp.json())
                .catch(err => console.log(`No EnergyBasics found by PermanentBuildingID ${permanentBuildingId}: ${err}`))
    }

    public GetEnergyInitialData(permanentBuildingId: string): Promise<EnergyInitialData> {
        return fetch(`${Apis.ENERGY}/${permanentBuildingId}/EnergyInitialData`)
                .then(resp => resp.json())
                .catch(err => console.log(`No EnergyInitialData found by PermanentBuildingID ${permanentBuildingId}: ${err}`))
    }
    public GetUnitEquipments(permanentBuildingId: string, unitCode: string): Promise<EnergyInitialData> {
        return fetch(`${Apis.RHR}/buildings/${permanentBuildingId}/unit/${unitCode}/equipments`)
                .then(resp => resp.json())
                .catch(err => console.log(`No equipments found by PermanentBuildingID ${permanentBuildingId} and UnitCode ${unitCode}: ${err}`))
    }
}

export class BuildingPermitAPI{
    public GetBuildingPermit(permanentBuildingId: string): Promise<ConstructionSupervisionCase> {
        return fetch(`${Apis.BUILDINGPERMIT_API}/${permanentBuildingId}`)
                .then(resp => resp.json())
                .catch(err => console.log(`No BuildingPermit found by PermanentBuildingID ${permanentBuildingId}: ${err}`))
    }
}

export class PropertyApi{
    public GetPropertyData(permanentBuildingId: string): Promise<Property> {
        return fetch(`${Apis.PROPERTY_API}/${permanentBuildingId}`).then(resp => resp.json())
        .catch(err => console.log(`Error when fetching propertyData by building ID ${permanentBuildingId}: ${err}`))
    }
}

export class TaxAdministrationApi{
        public GetBuildingData(permanentBuildingId: string): Promise<TaxBuilding> {
            return fetch(`${Apis.TAX_ADMINISTRATION}/${permanentBuildingId}`)
                                       .then(resp => resp.json())
                                        .catch(err => console.log(`No tax administration building info found by ID ${permanentBuildingId}: ${err}`))
        }

        public GetAddress(permanentBuildingId: string): Promise<OwnerAddress> {
            return fetch(`${Apis.TAX_ADMINISTRATION}/${permanentBuildingId}/OwnerAddress`)
                                       .then(resp => resp.json())
                                        .catch(err => console.log(`No building info found by ID ${permanentBuildingId}: ${err}`))
        }

        public GetServicePlan(permanentBuildingId: string): Promise<Rakenne[]> {
            return fetch(`${Apis.SERVICE_PLAN}eHuoltokirja/${permanentBuildingId}`)
                                       .then(resp => resp.json())
                                        .catch(err => console.log(`No service plan found by ID ${permanentBuildingId}: ${err}`))
        }

        public SaveServicePlan(permanentBuildingId: string, jsonData: string): Promise<string> {
            return fetch(`${Apis.SERVICE_PLAN}eHuoltokirja/${permanentBuildingId}/${jsonData}`,
            {
                method: 'POST'
            })
                                       .then(resp => resp.statusText as any)
                                        .catch(err => console.log(`Error when saving a plan for ${permanentBuildingId}: ${err}`))
        }
    }

export class BuildingPlanAPI {
    public GetBuildingPlan(permanentBuildingId: string): Promise<BuildingPlan> {
        return fetch(`${Apis.BUILDING_PLAN_API}/KaavatietomalliWanha/${permanentBuildingId}`)
                                   .then(resp => resp.json())
                                    .catch(err => console.log(`No building info found by ID ${permanentBuildingId}: ${err}`))
    }
}

export class KaavaAPI {
    public GetBuildingPlan(url?: string): Promise<Kaava> {
        let _url = url ?? "/data/kaavatiedot/valkeakoski.json";
        return fetch(_url)
        .then(resp => resp.json())
        .catch(err => console.log(`What?!`));
    }
    public GetKaavaStyles(): Promise<KaavaStyles> {
        return fetch(`/data/kaavatiedot/kaavamaaraystyylit.json`)
        .then(resp => resp.json())
        .catch(err => console.log(`What?!`));
    }
}

export class KoodistoAPI {
    public GetCodeList(url: string): Promise<CodeList> {
        return fetch(url)
        .then(resp => resp.json())
        .catch(err => console.log(`What?!`));
    }
}


const RealEstateApi = {
    getByPermanentBuildingId: (permanentBuildingId: string)  => 
        fetch(`${Apis.REALESTATE}/Kiinteisto/${permanentBuildingId}`)
            .then(resp => resp.json())
            .catch(err => console.log(`No real estate info found by ID ${permanentBuildingId}: ${err}`))   
}

export class HuoneistotiedotAPI {
    public GetHuoneistotiedot(permanentBuildingId: string): Promise<Huontest.Yhtio | undefined> {
        return fetch(`${Apis.HUONEISTOTIEDOT_API}/Huoneistotiedot/${permanentBuildingId}`)
        .then(resp => resp.json())
        .then(resp => {
            const yhtio = ParsingUtils.CastObject(resp, () => new Huontest.Yhtio());
            if (resp.organisaatiotiedot) {
                yhtio.organisaatiotiedot = ParsingUtils.CastObject(resp.organisaatiotiedot, () => new Huontest.JuridinenHenkilo());
            }
            if (resp.osakeryhmat) {
                yhtio.osakeryhmat = ParsingUtils.CastObjectArray(resp.osakeryhmat, () => new Huontest.Osakeryhma());
                yhtio.osakeryhmat.forEach(osakeryhma => {
                    if (osakeryhma.osoite) osakeryhma.osoite = ParsingUtils.CastObject(osakeryhma.osoite, () => new Huontest.Osoite());
                    if (osakeryhma.osakesarja) osakeryhma.osakesarja = ParsingUtils.CastObject(osakeryhma.osakesarja, () => new Huontest.Osakesarja());
                    if (osakeryhma.hallintakohde) {
                        osakeryhma.hallintakohde = ParsingUtils.CastObject(osakeryhma.hallintakohde, () => new Huontest.Hallintakohde());
                        if (osakeryhma.hallintakohde.paakayttotarkoitus) osakeryhma.hallintakohde.paakayttotarkoitus = ParsingUtils.CastObject(osakeryhma.hallintakohde.paakayttotarkoitus, () => new Huontest.Paakayttotarkoitus());
                        if (osakeryhma.hallintakohde.muukayttotarkoitus) osakeryhma.hallintakohde.muukayttotarkoitus = ParsingUtils.CastObject(osakeryhma.hallintakohde.muukayttotarkoitus, () => new Huontest.MuuKayttotarkoitus());
                    }
                    if (osakeryhma.omistajat) {
                        osakeryhma.omistajat = ParsingUtils.CastObjectArray(osakeryhma.omistajat, () => new Huontest.Omistaja());
                        osakeryhma.omistajat.forEach(omistaja => {
                            if (omistaja.henkiloomistaja) omistaja.henkiloomistaja = ParsingUtils.CastObject(omistaja.henkiloomistaja, () => new Huontest.LuonnollinenHenkilo());
                            if (omistaja.organisatioomistaja) omistaja.organisatioomistaja = ParsingUtils.CastObject(omistaja.organisatioomistaja, () => new Huontest.JuridinenHenkilo());
                        });
                    }
                });
            }
            if (yhtio.yhtionAlueet) {
                yhtio.yhtionAlueet = ParsingUtils.CastObjectArray(resp.yhtionAlueet, () => new Huontest.YhtionAlue());
                yhtio.yhtionAlueet.forEach(alue => {
                    if (alue.sijaintikunta2) {
                        alue.sijaintikunta2 = ParsingUtils.CastObject(alue.sijaintikunta2, () => new Huontest.Kunta());
                    }
                    if (alue.ulkomaisenalueenmaa) {
                        alue.ulkomaisenalueenmaa = ParsingUtils.CastObject(alue.ulkomaisenalueenmaa, () => new Huontest.Maa());
                    }
                });
            } 
            return yhtio;
        })
        .catch(err => {
            console.log(`No Huoneistotiedot found by ID ${permanentBuildingId}: ${err}`);
            return undefined;
        });
        
    }
}


export class PeppolAPI {
    public GetCatalogueProductItems(id: string): Promise<JsonObject<Product>[]> {
        return fetch(`/data/peppol/products/${id}.json`)
        .then(resp => resp.json())
        .catch(err => undefined);
    }
}

export class EpcisAPI {
    public GetEpcisEvents(id: string): Promise<JsonObject<EpcisEvent>[]> {
        return fetch(`/data/epcis/${id}.json`)
        .then(resp => resp.json())
        .catch(err => undefined);
    }
}
