import React from 'react'
import { RAKKaavakohde, RAKKaavamaarays, RAKKaavasuositus } from '../../../api/kaavatietomalli/kaavatietomalli-api'
import { OskariFeature } from '../../../model/kaava/OskariModels'
import LabelValue from '../../common/LabelValue'
import LabelValuesBlock from '../../common/LabelValuesBlock'

type Props = { features: OskariFeature[] }

const Features = (props: Props) => {
  
  const kaavasuositukset: Record<string, RAKKaavasuositus[]> = {};//{ kaavamaarayslaji: string, kaavasuositukset: RAKKaavasuositus[] }[] = [];
  let kaavasuositusTeemat: string[] = [];
  const kaavamaaraykset: Record<string, RAKKaavamaarays[]> = {};//{ kaavamaarayslaji: string, kaavamaaraykset: RAKKaavamaarays[] }[] = [];
  let kaavamaaraysTeemat: string[] = [];
  
  // Group kaavamaaraykset by kaavamaarayslaji, and create kaavasuositukset array
  for (const feature of props.features) {
    if (feature && feature.properties) {
      const kaavakohde = feature.properties as RAKKaavakohde;

      if (kaavakohde.kaavamaarays) {
        for (const maarays of kaavakohde.kaavamaarays) {
          if (maarays.kaavoitusteema) {
            if (kaavamaaraykset[maarays.kaavoitusteema] === undefined) {
              kaavamaaraykset[maarays.kaavoitusteema] = [];
              kaavamaaraysTeemat.push(maarays.kaavoitusteema);
            }
            kaavamaaraykset[maarays.kaavoitusteema].push(maarays);
          }
        }
      }
      if (kaavakohde.kaavasuositus) {
        //kaavasuositukset.push(...kaavakohde.kaavasuositus);
        for (const suositus of kaavakohde.kaavasuositus) {
          if (suositus.kaavoitusteema) {
            if (kaavasuositukset[suositus.kaavoitusteema] === undefined) {
              kaavasuositukset[suositus.kaavoitusteema] = [];
              kaavasuositusTeemat.push(suositus.kaavoitusteema);
            }
            kaavasuositukset[suositus.kaavoitusteema].push(suositus);
          }
        }
      }
    }
  }

  // TODO fix this, it should use number values instead of finnish translations // https://koodistot.suomi.fi/codescheme;registryCode=rytj;schemeCode=RY_Kaavoitusteema_AK
  const teemaOrder: string[] = ["Rakennusoikeus", "Asuminen", "Palvelut", "Elinkeinot", "Viheralueet ja virkistys", "Kadut", "Kunnallistekniikka", "Liikenneverkko", "Kulttuuriympäristöt", "Suojelu", "Muu kaavoitusteema"]; 
  const sortTeema = (a: string, b: string) => { return teemaOrder.indexOf(a) - teemaOrder.indexOf(b) };

  kaavamaaraysTeemat = kaavamaaraysTeemat.sort(sortTeema);
  kaavasuositusTeemat = kaavasuositusTeemat.sort(sortTeema);

  return (
    <React.Fragment>

      { kaavamaaraysTeemat.length > 0 &&
      <LabelValuesBlock title='Valittujen kaavakohteiden määräykset'>
        { kaavamaaraysTeemat.map((kaavoitusteema, i) => {
          return (
            <React.Fragment key={"teema_" + i}>
              { i > 0 && <hr style={{margin: "0 0 8px 0", borderColor: "#ccc"}} /> }
              <LabelValue bold label='Kaavoitusteema' value={kaavoitusteema} oneLine={true}></LabelValue>
              { kaavamaaraykset[kaavoitusteema].map((kaavamaarays, km) => 
                <React.Fragment key={"km_" + km}>
                  <LabelValue label={kaavamaarays.kaavamaarayslaji} value={kaavamaarays.arvo} oneLine={true}></LabelValue>
                  <LabelValue label='Elinkaaren tila' value={kaavamaarays.elinkaarentila} oneLine={true} code='rytj_RY_KaavanElinkaaritila'></LabelValue>
                </React.Fragment>
                )
              }
            </React.Fragment>
              )
           }
          )
        }     
      </LabelValuesBlock>
      }

      { kaavasuositusTeemat.length > 0 &&
      <LabelValuesBlock title='Valittujen kaavakohteiden suositukset'>
        { kaavasuositusTeemat.map((kaavoitusteema, i) => {
          return (
            <React.Fragment key={"teema_" + i}>
              { i > 0 && <hr style={{margin: "0 0 8px 0", borderColor: "#ccc"}} /> }
              <LabelValue bold label='Kaavoitusteema' value={kaavoitusteema} oneLine={true}></LabelValue>
              { kaavasuositukset[kaavoitusteema].map((kaavasuositus, ks) => 
                <React.Fragment key={"ks_" + ks}>
                  <LabelValue value={kaavasuositus.arvo} oneLine={true}></LabelValue>
                  <LabelValue label='Elinkaaren tila' value={kaavasuositus.elinkaarentila} oneLine={true} code='rytj_RY_KaavanElinkaaritila'></LabelValue>
                </React.Fragment>
                )
              }
            </React.Fragment>
              )
           }
          )
        }     
      </LabelValuesBlock>
      }

      
    </React.Fragment>
  )
}

export default Features