/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { Property } from '../../../model/property';
import GeneralTableView from '../../../utils/GeneralTableView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LabelValuesBlock from '../../common/LabelValuesBlock';
import LabelValue from '../../common/LabelValue';

interface Props {
  data: Property | null
}

class KiinteistoView extends React.Component<Props, {data: Property | null}> {
  constructor(props: Props) {
      super(props);
      this.state = {data: props.data};
  }
  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  render() {
    const address = this.state.data?.Location?.Address; 
    const  municipality = this.state.data?.Location?.Municipality; 
    const data = this.state.data;
    return(
      
      this.state.data !== undefined && this.state.data !== null && data !== null &&  this.state.data.Name !== undefined?
      <div>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            <Typography variant='h4'>Perustiedot</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='text-columns-2'>

              <LabelValuesBlock>
                <LabelValue label='Nimi' value={data.Name} />
                <LabelValue label='Kiinteistötunnus' value={data.RealEstateId || "-"} />
                <LabelValue label='Kiinteistölaji' value={data.PropertyType || "-"} />
              </LabelValuesBlock>

              <LabelValuesBlock>
                <LabelValue label='Rekisteröintipäivämäärä' value={data.RegistrationDate || "-"} format="date" />
                <LabelValue label='Lakkaamispäivämäärä' value={data.ExpiryDate || "-"} format="date"  />
              </LabelValuesBlock>

              <LabelValuesBlock>
                <LabelValue label='Maapinta-ala' value={data.LandArea || "-"} />
                <LabelValue label='Vesipinta-ala' value={data.WaterArea || "-"} />
              </LabelValuesBlock>

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            <Typography variant='h4'>Osoite</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='text-columns-2'>

              <LabelValuesBlock title='Sijaintiosoite'>
                <LabelValue label='Katuosoite' value={address?.StreetName || ""} />
                <LabelValue label='Postitoimipaikka' value={address?.PostalDistrict || ""} />
                <LabelValue label='Postinumero' value={address?.PostalCode || ""} />
              </LabelValuesBlock>

              <LabelValuesBlock title='Sijaintikunta'>
                <LabelValue label='Nimi' value={municipality?.Name || ""} />
                <LabelValue label='Kuntanumero' value={municipality?.MunicipalityNumber || ""} />
              </LabelValuesBlock>

            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          <Typography variant='h4'>Perustiedot</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GeneralTableView data={this.state.data} flatView = {true}></GeneralTableView>
          </AccordionDetails>
        </Accordion>
      </div> :
       <div>Ei kiinteistötietoja saatavilla</div>
    );
  }
}

export default KiinteistoView;
