import { Title } from '../../utils/tooltipUtil';
import { Description } from "../../utils/tooltipUtil";
import { Base } from '../base';

export default class TaxBuildingAddress extends Base{
    @Title("Osoite")
    @Description("Osoitetieto suomalaisen osoitejärjestelmän mukaisesti")
    Address: string = "";
    @Title("Postinumero")
    @Description("osoitteen numero-osa, joka viittaa postitoimipaikkaan")
    PostalCode: string = "";
    @Title("Postitoimipaikka")
    @Description("osoitteen osa, joka viittaa tiettyyn maantieteelliseen alueeseen tai organisaatioon")
    PostalDistrict: string = "";
}