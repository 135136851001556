/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from "react";
import { Building } from '../../../model';

interface Props {
  data: Building
}

class KuntaView extends React.Component<Props, {data: Building}> {
  constructor(props: Props) {
      super(props);
      this.state = {data: props.data};
  }
  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }
  render() {
    
      return (
        <div>
          Kunnan oman rakennusrekisterin tiedot
        </div>
      );
  }
}

export default KuntaView;
