import { Tooltip, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { format } from "date-fns";
import rytjCodes from '../../data/rytj-codelist';
//import check from'../../components/icons/checked.png';
//import uncheck from'../../components/icons/unchecked.png';

type Props = {
  label?: string,
  value?: string|Date|number|boolean|ReactElement
  unit?: string,
  code?: string,
  oneLine?: boolean,
  tooltip?: string,
  format?: "date" | "datetime" | "url",
  bold?: true
}

const LabelValue = (props: Props) => {
  const code = props.code && typeof props.value === "string" && rytjCodes[props.code] && rytjCodes[props.code][props.value] ? " - " + rytjCodes[props.code][props.value] : "";
  
  let value: undefined|string|Date|number|boolean|ReactElement = props.value;
  try {
    if (value) {
      if (typeof value === "string" || value instanceof Date) {
        if (props.format === "date") {
          value = format(new Date(value) , "dd.MM.yyyy");
        }
        if (props.format === "datetime") {
          value = format(new Date(value) , "dd.MM.yyyy HH.mm");
        }
      }
      if (props.format === "url" && typeof value === "string") {
        value = <a href={value}>{value.substring(value.lastIndexOf("/")+1)}</a>
      }
      if (typeof value === "number") {
        value = value.toLocaleString("fi-FI");
      }
    }
    if (typeof value === "boolean" /*&& props.format !== "check"*/) {
      value = value === true ? "Kyllä" : "Ei";
    } 
  }
  catch {
    // could not parse date, show it as it is
  }

  let unit : string|ReactElement = props.unit || "";
  switch(unit) {
    case "m2": unit = <span>m<sup>2</sup></span>; break;
    case "m3": unit = <span>m<sup>3</sup></span>; break;
    case "kWh/brm2/vuosi": unit = <span>kWh/brm<sup>2</sup>/vuosi</span>; break;
    default: break;
  }

  const fontStyle = { fontWeight: props.bold ? 600 : 400 };
  const labelFontStyle = { fontWeight: props.bold || !props.oneLine ? 600 : 400 };

  return (
    props.tooltip ? 
      <div style={{breakInside: 'avoid-column', paddingBottom: "8px"}}> 
      { props.oneLine === true ? 
        <Tooltip placement='top' title={props.tooltip} arrow>
          <Typography style={ fontStyle }>{props.label ? props.label + ": " : ""}{value && value !== "null" ? value : " - "}{code}{unit ? " " : ""}{unit}</Typography>
        </Tooltip>
        :
        <React.Fragment>
          { props.label && <Typography className='LabelValue-label' style={{fontWeight: 600}}>{props.label}</Typography> }
          <Tooltip placement='top' title={props.tooltip||""} arrow>
            <Typography style={ fontStyle }>{value && value !== "null" ? value : " - "}{code}{unit ? " " : ""}{unit}</Typography>
          </Tooltip>
        </React.Fragment>
      }  
      </div>
    :
    <div style={{breakInside: 'avoid-column', paddingBottom: "8px"}}> 
      { props.oneLine === true ? 
        <Typography style={ fontStyle }>{props.label ? props.label + ": " : ""}{value && value !== "null" ? value : " - "}{code}{unit ? " " : ""}{unit}</Typography>
        :
        <React.Fragment>
          { props.label && <Typography style={ labelFontStyle } className='LabelValue-label'>{props.label}</Typography> }
          <Typography style={ fontStyle }>{value && value !== "null" ? value : " - "}{code}{unit ? " " : ""}{unit}</Typography>
        </React.Fragment>
      }  
      </div>
  )
}

export default LabelValue