import React, { useState } from "react";
import { useHistory } from 'react-router';
import { JsonObject } from '../../../model/JsonObject';
import { Product } from '../../../model/Product';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, ListItemText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toDateTimeString } from '../../../utils/dateUtils';
import EpcisEventsDialog from './EpcisEventsDialog';

const ProductItemAccordion: React.FC<{id: string, itemObject: JsonObject<Product>, transaction: string}> = ({id, itemObject, transaction}) => {
  const history = useHistory();
  const product = itemObject.jsonData;

  const [showEpcisEvents, setShowEpcisEvents] = useState<boolean>(false);

  const productName = () => {
    if (product.name?.value) {
      return product.name?.value;
    }
    return <i>{"Nimi puuttuu"}</i>;
  }

  const productBrandName = () => {
    return product.brandName ? product.brandName[0].value : "";
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container style={{alignItems: "center"}}>
          <Grid item xs={5} style={{display: "flex", alignItems: "center"}}>
            <ListItemText primaryTypographyProps={{variant: "button", style: {fontWeight: 700}}} primary={productName()} style={{maxWidth: "fit-content"}} />
          </Grid>
          <Grid item xs={5}>
            <ListItemText primaryTypographyProps={{variant: "button"}} primary={productBrandName()} />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => history.push(`/eHuoltoKirja/product/${transaction}/${itemObject.id}`)}>Avaa</Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} style={{alignItems: "center"}}>
          <Grid item container xs={12} md={6}>
            <Grid item xs={6} style={{fontWeight: 700}}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={"Tunniste"}/>
            </Grid>
            <Grid item xs={6}>
              <ListItemText primaryTypographyProps={{variant: "caption"}} primary={itemObject.id}/>
            </Grid>
            <Grid item xs={6} style={{fontWeight: 700}}>
              {"Luotu"}
            </Grid>
            <Grid item xs={6}>
              {toDateTimeString(product.createdOn)}
            </Grid>
            <Grid item xs={6} style={{fontWeight: 700}}>
              {"Muokattu"}
            </Grid>
            <Grid item xs={6}>
              {toDateTimeString(product.updatedOn)}
            </Grid>
            <Grid item xs={6} style={{fontWeight: 700}}>
              {"Tuotekuvaus"}
            </Grid>
            <Grid item xs={6}>
              {product.description && product.description[0].value}
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => history.push(`/product/${transaction}/${itemObject.id}`)}>
                {"Avaa tuotetiedot"}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setShowEpcisEvents(true)}>
                {"Katso EPCIS tapahtumia"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <EpcisEventsDialog open={showEpcisEvents} handleClose={() => setShowEpcisEvents(false)} productObject={itemObject} />
    </Accordion>
  );
}

export default ProductItemAccordion;