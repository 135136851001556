/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from "react"
import TaxBuilding from '../../../model/tax/taxBuilding';
import RealEstateUnitView from './realEstateUnitView';

interface Props {
  data?: TaxBuilding
}

class AssetView extends React.Component<Props, {data: TaxBuilding}> {
  constructor(props: Props, data : any) {
    super(props);
    if (props.data) this.state = {data: props.data};
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {data: nextProps.data};
  }

  render() {

      return (
        <div>
            {this.state.data !== undefined ?
            <RealEstateUnitView data={this.state.data}></RealEstateUnitView>
            : <div>Ei verohallinnon rakennustietoja saatavilla</div>}
        </div>
      );
  }
}

export default AssetView;
