import { Address } from "./address";
import { Location } from "./location";
import { Title, Description } from '../../utils/tooltipUtil';
import { Base } from "../base";

export enum ZoningTypeEnum {
    _1 = 1,
    _11 = 2,
    _12 = 3,
    _2 = 4,
    _21 = 5,
    _22 = 6,
    _23 = 7,
    _24 = 8,
    _25 = 9,
    _26 = 10,
    _3 = 11,
    _31 = 12,
    _32 = 13,
    _33 = 14,
    _34 = 15,
    _35 = 16
}

export class BuildingSite extends Base {
    @Title("Kaavalaji")
    @Description("Maankäyttöpäätös, jonka tuloksena syntyy alueiden käyttöä ohjaava kaava. Sallitut arvot tulevat Kaavatyyppikoodistosta.")
    ZoningType: ZoningTypeEnum = ZoningTypeEnum._1;
    
    @Title("Kaavayksikkö")
    @Description("Asema- tai ranta-asemakaavan pienin yksikkö. Käytännössä kaavayksiköt ovat kortteleita tai sen osia, tai vastaavasti yleisiä alueita tai sen osia. Kaavayksikkö voi olla esimerkiksi sitovan tonttijaon mukainen kaavatontti tai ohjeellisen tonttijaon mukainen rakennuspaikka.")
    ZoningUnit: string[] = [];
    
    @Title("Osoite")
    @Description("sijaintipaikan osoittavien tietojen kokonaisuus")
    Address: Address = new Address();
    
    @Title("Sijainti")
    @Description("Sijainti")
    Location: Location = new Location();
}
